import { type SetStateFn } from '@strise/react-utils'
import React from 'react'
import { ReviewCardContentView } from '@utils/reviewUtils'
import { Button, type ButtonProps } from '@strise/ui-components'

export const ReviewCompanyStartButton = ({
  children,
  fetchReviewCompany,
  setCardView,
  ...props
}: {
  fetchReviewCompany: () => void
  setCardView: SetStateFn<ReviewCardContentView>
} & ButtonProps) => {
  const handleClick = () => {
    setCardView(ReviewCardContentView.LOADING_REVIEW)
    fetchReviewCompany()
  }

  return (
    <Button
      variant='contained'
      palette='primary'
      onClick={handleClick}
      data-track={props['data-track']}
      className='mt-4'
      {...props}
    >
      {children}
    </Button>
  )
}
