import { Alert, Button, IconWarningFill, Switch, Typography } from '@strise/ui-components'
import { type FinancialsMetaFragment, type FinancialsRowFragment, type SidepanelCompanyFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { financialFieldsMap } from '@state'
import { toast, Empty } from '@strise/app-shared'
import { ButtonGroup } from '@strise/ui-components-legacy'
import { getBrowserGlobals, copyElToClipboard } from '@strise/react-utils'
import { type DivProps } from '@strise/react-utils'
import * as React from 'react'
import { SparkLineHighlights } from '../../../SparkLine/SparkLineHighlights'
import { SidepanelCard } from '../../SidepanelCard'
import { SidepanelFinancialsCompare } from './Compare/SidepanelFinancialsCompare'
import { SidepanelFinancialsTable } from './SidepanelFinancialsTable'
import { DataSources } from '@components/Sidepanel/DataSources'
import { SidepanelTab } from '@utils/urls'
import { TestIDs } from '@utils/testIDs'

const copyFigures = (meta: FinancialsMetaFragment[], rows: FinancialsRowFragment[]) => {
  const tableElement = getBrowserGlobals()?.document.createElement('table')
  const tdWithBorder = (value: string | number) => `<td style='border:1px solid #000;'>${value}</td>`

  if (!tableElement) return

  tableElement.innerHTML = [
    '<tr>',
    tdWithBorder(t`Period`),
    meta
      .map((m) => {
        return tdWithBorder(m.periodTitle)
      })
      .join(''),
    '</tr>',
    rows
      .map((row) => {
        const fieldInfo = financialFieldsMap()[row.fieldInfo.id]

        return [
          '<tr>',
          tdWithBorder(fieldInfo?.name ?? ''),
          row.values
            .map((value) => {
              const valueString = value === null ? '' : value.toString()
              return tdWithBorder(valueString.toString().replace(/^-$/, '').replace(/^−/, '-').replaceAll(/\s/g, ''))
            })
            .join(''),
          '</tr>'
        ].join('')
      })
      .join(''),
    '<tr />',
    '<tr>',
    `<td colspan='${rows[0]?.values?.length ?? 1}'>${t`Copied from`} — Strise®</td>`,
    '</tr>'
  ].join('')

  copyElToClipboard(tableElement)
}

interface SidepanelFinancialsCardProps extends DivProps {
  company: SidepanelCompanyFragment
}

export const SidepanelFinancialsCard = React.forwardRef<HTMLDivElement, SidepanelFinancialsCardProps>(
  ({ company }, ref) => {
    const hasConsolidatedFinancials = Boolean(company.consolidatedFinancials)
    const [consolidated, setConsolidated] = React.useState<boolean>(hasConsolidatedFinancials)

    const financials = consolidated ? company.consolidatedFinancials : company.financials

    const lastMeta = financials?.meta[0]
    const lastPeriod = lastMeta?.periodTitle
    const lastYear = lastMeta?.year

    const compareTab = {
      value: 'compare',
      text: t`Compare`,
      buttonProps: { 'data-track': 'Sidepanel / Company / Compare tab' }
    }

    const keyFigureTabs = [
      ...(financials?.sections
        .filter(({ rows }) => rows.length > 0)
        .map(({ key, name }) => ({
          value: key,
          text: name,
          buttonProps: {
            'data-track': `Sidepanel / Company / ${key} tab`
          }
        })) ?? []),
      ...(lastPeriod ? [compareTab] : [])
    ]

    const [activeTab, setActiveTab] = React.useState(keyFigureTabs[0]?.value ?? '')

    const activeFinancialSection = financials?.sections.find(({ key }) => key === activeTab)
    const activeFinancialRows = activeFinancialSection?.rows

    const handleCopy = () => {
      if (!financials || !activeFinancialRows) return

      copyFigures(financials.meta, activeFinancialRows)
      toast.success('Financials copied to clipboard')
    }

    const headerElement = (
      <div className='flex justify-between p-4'>
        <div>
          <Typography variant='subtitle1'>
            <Trans>Financials</Trans>
          </Typography>
          {hasConsolidatedFinancials && (
            <Alert variant='info' size='sm' className='mt-2'>
              <Trans>Has consolidated financials</Trans>
            </Alert>
          )}
        </div>
        {hasConsolidatedFinancials && (
          <div className='text-right'>
            <div className='mb-2 flex items-center'>
              <IconWarningFill className='mr-1 shrink-0 text-semantic-info-shade-50' size='md' />
              <Typography component='div' variant='aLabelSmall'>
                <Trans>Consolidated numbers</Trans>
              </Typography>
            </div>
            <div className='inline-flex items-center'>
              <Switch checked={consolidated} onCheckedChange={setConsolidated} className='mr-1' />
              <Typography variant='aLabel'>{consolidated ? t`On` : t`Off`}</Typography>
            </div>
          </div>
        )}
      </div>
    )

    return (
      <SidepanelCard
        loading={!company}
        ref={ref}
        tab={SidepanelTab.Financials}
        headerElement={headerElement}
        data-id={TestIDs.SidePanel.Financials.root}
      >
        {!financials && <Empty className='items-center p-5 text-text-secondary' title={t`No financials recorded`} />}
        {!!financials && (
          <div className='flex flex-col gap-4 p-4'>
            <div className='mb-4'>
              <SparkLineHighlights financials={financials} settingsKey='sidepanel' country={company.registerCountry} />
            </div>

            <div className='overflow-x-auto' style={{}}>
              <ButtonGroup
                width={1}
                palette='secondary'
                size='sm'
                value={activeTab}
                options={keyFigureTabs}
                onClick={setActiveTab}
                buttonProps={{
                  className: 'basis-auto'
                }}
                mb={4}
              />

              {activeFinancialRows && (
                <SidepanelFinancialsTable meta={financials.meta} financialRows={activeFinancialRows} />
              )}

              {lastPeriod && activeTab === 'compare' && (
                <SidepanelFinancialsCompare
                  company={company}
                  year={lastYear}
                  periodTitle={lastPeriod}
                  hasConsolidated={hasConsolidatedFinancials}
                />
              )}
            </div>

            <div className='flex items-center justify-between'>
              <DataSources className='p-0' dataSources={company.dataSources.financials} />
              {activeFinancialRows && (
                <Button
                  variant='contained'
                  palette='tertiary'
                  onClick={handleCopy}
                  data-track='Button / Sidepanel / Financials / Copy'
                >
                  <Trans>Copy financials</Trans>
                </Button>
              )}
            </div>
          </div>
        )}
      </SidepanelCard>
    )
  }
)
