import { t } from '@lingui/macro'
import { type CompanyNetworkFragment, type PepInfoFragment, type SanctionInfoFragment } from '@graphqlTypes'

export const extractPepInfoTitle = (pepInfo: PepInfoFragment | null) => {
  if (!pepInfo?.pep && !pepInfo?.rca) return t`is not a PEP`

  if (pepInfo.pep) return t`is PEP due to roles:`

  if (pepInfo.rca) return t`is RCA due to relationships:`
}

export const extractSanctionInfoTitle = (sanctionInfo: SanctionInfoFragment | null) => {
  if (!sanctionInfo) return t`has unknown sanction status`

  if (!sanctionInfo.sanctionSources.length) return t`is not sanctioned`

  return t`is sanctioned`
}

export const extractPepAndSanctionsFromCompanyNetwork = (companyNetwork: CompanyNetworkFragment) => {
  const pepEntities = companyNetwork.allPersons.filter((person) => person.pep?.pep || person.pep?.rca)

  const sanctionedPersons = companyNetwork.allPersons
    .filter(({ sanctions }) => sanctions.sanctionedBy.length > 0)
    .map(({ associatedCompanies, person, sanctions }) => ({
      entity: person,
      sanctions,
      associatedCompanies
    }))

  // companyNetwork.company is the company we are currently viewing
  // Destructure to handle the different structure of companyNetwork.company
  const { sanctionInfo, ...sidepanelCompany } = companyNetwork.company
  const companySanctions = { company: sidepanelCompany, sanctions: sanctionInfo }

  const sanctionedCompanies = [companySanctions, ...companyNetwork.otherCompanies]
    .filter(({ sanctions }) => sanctions.sanctionedBy.length > 0)
    .map(({ company, sanctions }) => ({ entity: company, sanctions, associatedCompanies: [] }))

  return {
    pepEntities,
    sanctionedEntities: [...sanctionedCompanies, ...sanctionedPersons]
  }
}
