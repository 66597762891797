import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconGrow = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.4162 11.2978C10.5966 7.40616 7.24917 4.61541 3.35723 4.57901L1.87737 4.56517L2.18902 6.04492C3.00862 9.93656 6.35603 12.7273 10.248 12.7637L11.7278 12.7776L11.4162 11.2978ZM3.37107 3.03153C7.97063 3.07454 11.9267 6.3727 12.8953 10.9719L13.6052 14.3427L10.2341 14.3112C5.63457 14.2682 1.67855 10.97 0.709918 6.37081L0 3L3.37107 3.03153Z'
      fill='#161717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.984 14.2219C18.8429 13.7035 21.8351 10.5185 22.1878 6.55395L22.3218 5.04648L20.8545 5.24359C16.9956 5.76198 14.0034 8.94696 13.6507 12.9115L13.5167 14.419L14.984 14.2219ZM23.6946 6.69416C23.2778 11.3795 19.7416 15.1436 15.181 15.7562L11.8385 16.2052L12.1439 12.7713C12.5607 8.08593 16.0969 4.32186 20.6575 3.70922L24 3.26021L23.6946 6.69416Z'
      fill='#161717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.8182 11.6383L14.8443 22H12.8182L11.2987 13.7107L12.8182 11.6383Z'
      fill='#161717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.4597 12.6826C15.2671 12.2973 16.5715 11.3578 18.2968 9.08887L19.4963 10.0431C17.676 12.4369 16.1944 13.5698 15.1023 14.0911C14.5542 14.3527 14.1065 14.4589 13.7699 14.4923C13.6023 14.5089 13.4647 14.5071 13.3586 14.4985C13.3057 14.4942 13.2609 14.4882 13.2244 14.4821C13.2062 14.479 13.1901 14.4758 13.1761 14.4729L13.1567 14.4685L13.1482 14.4665L13.1442 14.4655L13.1423 14.465C13.1423 14.465 13.1405 14.4646 13.3248 13.7107C13.509 12.9567 13.5072 12.9563 13.5072 12.9563L13.5055 12.9558L13.5021 12.955L13.4958 12.9535L13.4853 12.9511C13.4792 12.9498 13.4748 12.949 13.4723 12.9486L13.4697 12.9482C13.4697 12.9482 13.4728 12.9486 13.4788 12.9491C13.4974 12.9506 13.5458 12.9529 13.6235 12.9452C13.7777 12.93 14.0581 12.8743 14.4597 12.6826Z'
      fill='#161717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.8738 12.2649C12.8738 12.2649 12.8729 12.2649 12.8336 13.0409C12.7943 13.817 12.7933 13.817 12.7933 13.817L12.7902 13.8168L12.7849 13.8165L12.771 13.8155C12.7603 13.8148 12.7465 13.8137 12.7299 13.8121C12.6967 13.8091 12.6521 13.8042 12.5968 13.7968C12.4864 13.7818 12.3336 13.7562 12.1459 13.7126C11.7706 13.6254 11.2546 13.466 10.659 13.1753C9.46183 12.591 7.96464 11.4864 6.64839 9.4181L7.92195 8.57023C9.07717 10.3855 10.3542 11.3044 11.3134 11.7726C11.7959 12.0081 12.2036 12.1322 12.4825 12.1969C12.6218 12.2293 12.7287 12.2468 12.7963 12.2559C12.83 12.2605 12.8539 12.263 12.8669 12.2642L12.8784 12.2652L12.8759 12.265L12.8738 12.2649Z'
      fill='#161717'
    />
  </svg>
))
IconGrow.displayName = 'IconSpark'
