import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconSettings = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M4.497 5.94a2 2 0 0 1 .732-2.732l2.607-1.506a2 2 0 0 1 2.732.732l.636 1.102a8.532 8.532 0 0 1 1.69.003l.664-1.123a2 2 0 0 1 2.721-.716l2.611 1.507a2 2 0 0 1 .73 2.737l-.638 1.097c.16.224.31.455.45.692l.005.008.004.008c.14.247.266.5.38.76h1.212a2 2 0 0 1 1.998 2v3.01a2 2 0 0 1-1.996 2l-1.225.002a8.825 8.825 0 0 1-.878 1.5l.593 1.027a2 2 0 0 1-.732 2.732l-2.65 1.53a2 2 0 0 1-2.733-.732l-.605-1.048c-.583.06-1.17.058-1.751-.004l-.61 1.046a2 2 0 0 1-2.729.724l-2.614-1.512a2 2 0 0 1-.732-2.73l.612-1.062a8.657 8.657 0 0 1-.423-.66l-.002-.002a8.537 8.537 0 0 1-.416-.823l-1.205.003a2 2 0 0 1-2.004-2h2l2.605-.005.232.678a6.538 6.538 0 0 0 1.18 2.086l.45.536L6.1 19.053l2.615 1.51 1.31-2.246.699.138c.797.156 1.619.159 2.42.006l.698-.133 1.3 2.25 2.65-1.53-1.286-2.227.461-.538a6.826 6.826 0 0 0 1.233-2.104l.24-.655 2.59-.005v-3.01l-2.608-.002-.231-.676a6.52 6.52 0 0 0-.488-1.092 7.205 7.205 0 0 0-.69-.975l-.453-.539 1.33-2.286-2.611-1.506-1.36 2.302-.688-.129a6.531 6.531 0 0 0-2.377-.003l-.693.127-1.326-2.296L6.23 4.94l1.323 2.29-.483.54a6.535 6.535 0 0 0-1.25 2.064l-.243.646-2.646.012.001 3.018h-2L.93 10.494a2 2 0 0 1 1.99-2l1.299-.007a8.536 8.536 0 0 1 .893-1.481L4.497 5.94Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-4 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z'
      clipRule='evenodd'
    />
  </svg>
))
IconSettings.displayName = 'IconSettings'
