import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconAvatarInvited = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <circle cx={12} cy={12} r={10} stroke='currentColor' strokeDasharray='2 2' strokeWidth={2} />
    <circle cx={12} cy={9} r={4} stroke='currentColor' strokeWidth={2} />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M5 20.333a10.141 10.141 0 0 0 13.42.477l.58-.477V19a7 7 0 1 0-14 0v1.333Zm12-.947V19a5 5 0 0 0-10 0v.417a8.142 8.142 0 0 0 10-.031Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M1 12c0 6.075 4.925 11 11 11s11-4.925 11-11h-2a9 9 0 1 1-18 0H1Z'
      clipRule='evenodd'
    />
  </svg>
))
IconAvatarInvited.displayName = 'IconAvatarInvited'
