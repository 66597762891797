import { Trans, t } from '@lingui/macro'
import { type BaseEntityLikeFragment } from '@strise/app-shared/src/graphqlTypes'
import { Typography } from '@strise/ui-components'
import { ConfirmDialog } from '@strise/ui-components-legacy'
import { TestIDs } from '@utils/testIDs'
import * as React from 'react'

export const DeleteDialog = ({
  handleCancel,
  handleDeletion,
  ownedEntity,
  ownerEntity
}: {
  handleCancel: () => void
  handleDeletion: () => void
  ownedEntity: BaseEntityLikeFragment
  ownerEntity: BaseEntityLikeFragment
}) => {
  return (
    <ConfirmDialog
      isOpen={true}
      onConfirm={handleDeletion}
      danger={true}
      onClose={handleCancel}
      onCancel={handleCancel}
      cancelButtonProps={{
        'data-track': 'Edit Ownership / Delete owner / Cancel'
      }}
      confirmButtonProps={{
        'data-track': 'Edit Ownership / Delete owner / Confirm',
        'data-id': TestIDs.SidePanel.Ownerships.removeOwnerConfirmButton
      }}
      contentMaxWidth={600}
      title={t`Are you sure you want to remove ${ownerEntity.name} as direct owner of ${ownedEntity.name}?`}
      confirmText={t`Delete`}
      cancelText={t`Cancel`}
    >
      <Typography>
        <Trans>
          This will affect all ownership where {ownerEntity.name} is direct owner of {ownedEntity.name}.
        </Trans>
      </Typography>
      <Typography>
        <Trans>Original versions of ownership charts are always available.</Trans>
      </Typography>
    </ConfirmDialog>
  )
}
