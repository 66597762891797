import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconAmLcheckSettings = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M4 1a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h5.579v-1.117A8.047 8.047 0 0 1 8.755 21H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v5.07c.753.436 1.428.99 2 1.638V4a3 3 0 0 0-3-3H4Z'
    />
    <path
      fill='currentColor'
      d='M7.582 19a7.947 7.947 0 0 1-.52-2H5v2h2.582ZM7.062 15c.087-.699.264-1.37.52-2H5v2h2.062ZM8.755 11a8.038 8.038 0 0 1 2.369-2H5v2h3.755ZM5 7h12V5H5v2Z'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M15 18.667a2.667 2.667 0 1 0 0-5.334 2.667 2.667 0 0 0 0 5.334ZM16.333 16a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M10.486 10.138a1.333 1.333 0 0 0-.488 1.822l.41.71a5.69 5.69 0 0 0-.596.988l-.865.004c-.734.003-1.327.6-1.327 1.333l.001 2.012c0 .737.599 1.334 1.336 1.333l.803-.002a5.7 5.7 0 0 0 .277.549l.001.002c.088.15.182.297.283.439l-.408.708a1.333 1.333 0 0 0 .487 1.82l1.743 1.008a1.333 1.333 0 0 0 1.82-.483l.406-.697c.388.042.78.043 1.168.003l.403.698a1.333 1.333 0 0 0 1.822.488l1.767-1.02a1.333 1.333 0 0 0 .488-1.821l-.396-.684c.228-.314.424-.649.586-1l.816-.002a1.333 1.333 0 0 0 1.33-1.333l.001-2.007c0-.736-.596-1.333-1.332-1.334h-.808a5.689 5.689 0 0 0-.253-.506l-.003-.005-.003-.005a6.15 6.15 0 0 0-.3-.462l.425-.731a1.333 1.333 0 0 0-.486-1.825l-1.741-1.004a1.333 1.333 0 0 0-1.815.477l-.442.748a5.687 5.687 0 0 0-1.126-.002l-.425-.734a1.333 1.333 0 0 0-1.82-.488l-1.739 1.003Zm.205 6.865-1.737.003v-2.011l1.763-.008.162-.43c.19-.506.472-.973.833-1.377l.322-.36-.881-1.527 1.738-1.003.883 1.53.463-.085a4.357 4.357 0 0 1 1.584.003l.46.085.906-1.534 1.74 1.004-.886 1.524.301.359a4.8 4.8 0 0 1 .46.65c.13.232.24.476.326.728l.154.451h1.739v2.008l-1.727.003-.16.437a4.55 4.55 0 0 1-.822 1.402l-.307.359.857 1.485-1.767 1.02-.866-1.5-.466.088c-.534.102-1.082.1-1.613-.004l-.466-.091-.873 1.497-1.743-1.007.864-1.499-.3-.357a4.364 4.364 0 0 1-.786-1.39l-.155-.453Z'
      clipRule='evenodd'
    />
  </svg>
))
IconAmLcheckSettings.displayName = 'IconAmLcheckSettings'
