import { Typography } from '@strise/ui-components'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type SidepanelPersonFragment } from '@graphqlTypes'
import * as React from 'react'
import { PepInfoContent, PepPersonAddressInfo } from './PepPersonContent'
import { extractPepInfoTitle } from './pepAndSanctionsUtils'

export const SidepanelPersonPep: React.FC<{
  person: SidepanelPersonFragment
}> = ({ person }) => {
  const features = useCurrentUserFeatures()

  if (!features.PEP_AND_SANCTIONS_V2) return null

  const pepInfo = person.pepInfo

  const pepInfoTitle = extractPepInfoTitle(pepInfo)
  const isPepOrRca = pepInfo?.pep || pepInfo?.rca

  return (
    <div className='grid w-full gap-4'>
      <div className='grid grid-cols-[1fr_max-content] items-center gap-2'>
        {pepInfo && (
          <div>
            <Typography className='text-text-secondary'>
              {person.name}
              {!isPepOrRca && pepInfoTitle && ` ${pepInfoTitle}`}
            </Typography>
            <PepPersonAddressInfo address={pepInfo.address} />
          </div>
        )}
      </div>
      {isPepOrRca && <PepInfoContent pepInfo={pepInfo} />}
    </div>
  )
}
