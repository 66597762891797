import { useEntityQuery } from '@graphqlOperations'
import { type BaseEntityLikeFragment } from '@graphqlTypes'
import { ContentLanguage } from '@strise/types'

export const useEntityLikeName = (id: string | null): string | null => {
  const { data } = useEntityQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: id! },
    skip: !id
  })
  const entity = data?.entity
  return entity?.name ?? null
}

export const hasEntityAccess = (entity: BaseEntityLikeFragment | null | undefined): boolean => {
  return entity && 'access' in entity ? entity.access.hasAccess : false
}

export const isEntityActive = (entity: BaseEntityLikeFragment | null | undefined): boolean => {
  return entity && 'lifeStatus' in entity ? entity.lifeStatus.isActive : true
}

export const isGraniteEntity = (entity: BaseEntityLikeFragment | null | undefined): boolean => {
  if (entity) {
    if (entity.__typename == 'Company') {
      return entity.isGlobalCompany || entity.registerCountry == ContentLanguage.German
    }
    if (entity.__typename == 'Person') {
      return entity.isGlobalPerson || entity.nationality == ContentLanguage.German
    }
  }

  return false
}

export type EntityIdentity = Pick<BaseEntityLikeFragment, 'id' | '__typename'>
