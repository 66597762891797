import { EntityIdSearchInput } from '@components/Entity/EntitySearchInput'
import { StepContent } from '@components/Ownerships/edit-owner/StepInfo'
import { EntityLocationFilterKind } from '@components/Search/searchUtils'
import { useEntityMetaQuery } from '@graphqlOperations'
import { t, Trans } from '@lingui/macro'
import { type BaseEntityLikeFragment } from '@strise/app-shared/src/graphqlTypes'
import { Button, cn, Label, Radio, TextArea, Typography } from '@strise/ui-components'
import { type SetStateFn } from '@strise/react-utils'
import { ConfirmDialog, Dialog } from '@strise/ui-components-legacy'
import { ApplicationSearchReturnType, CounterPartyDirection } from '@strise/types'
import { TestIDs } from '@utils/testIDs'
import * as React from 'react'

export interface EntityCounterpartyFormData {
  comment: string
  counterpartyEntity: string | null
  direction: CounterPartyDirection | null
  entity: string | null
  id: string
}

enum AddCounterparty {
  Search = 'Search',
  Direction = 'Choose direction',
  AddComment = 'Add comment'
}

export const DeleteCounterpartyDialog = ({
  counterpartyEntityId,
  handleCancel,
  handleDeletion,
  loading
}: {
  counterpartyEntityId: string
  handleCancel: () => void
  handleDeletion: () => void
  loading: boolean
}) => {
  const { data } = useEntityMetaQuery({
    variables: {
      id: counterpartyEntityId
    }
  })

  return (
    <ConfirmDialog
      isOpen
      loading={loading}
      onConfirm={handleDeletion}
      danger
      onClose={handleCancel}
      onCancel={handleCancel}
      cancelButtonProps={{
        'data-track': 'Edit Counterparty / Delete Counterparty / Cancel'
      }}
      confirmButtonProps={{
        'data-track': 'Edit Counterparty / Delete counterparty / Confirm',
        'data-id': TestIDs.SidePanel.Counterparty.deleteCounterpartyConfirmButton
      }}
      contentMaxWidth={600}
      title={t`Are you sure you want to remove ${data?.entity.name} as counterparty?`}
      confirmText={t`Delete`}
      cancelText={t`Cancel`}
    />
  )
}

export const CounterpartyCommentInput = ({
  formData,
  setFormData
}: {
  formData: EntityCounterpartyFormData
  setFormData: SetStateFn<EntityCounterpartyFormData>
}) => {
  const handleChange = (key: keyof EntityCounterpartyFormData) => (value: string) => {
    setFormData({ ...formData, [key]: value })
  }

  return (
    <TextArea
      className='mt-2'
      onChange={(e) => handleChange('comment')(e.currentTarget.value)}
      value={formData.comment}
      placeholder={t`Comment`}
    />
  )
}

export interface AddEditEntityCounterpartyDialogData {
  entity: BaseEntityLikeFragment
  formData: EntityCounterpartyFormData
  handleConfirm: () => void
  loading: boolean
  onClose: () => void
  onCounterpartyDelete: () => void
  setFormData: SetStateFn<EntityCounterpartyFormData>
}

export const AddEditEntityCounterpartyDialog = ({
  entity,
  formData,
  handleConfirm,
  loading,
  onClose,
  onCounterpartyDelete,
  setFormData
}: AddEditEntityCounterpartyDialogData) => {
  const [currentStep, setCurrentStep] = React.useState<AddCounterparty | null>(AddCounterparty.Search)

  const handleDirectionChange = (direction: CounterPartyDirection) => () => {
    setFormData({ ...formData, direction })
  }

  const stepInfos = [
    {
      step: AddCounterparty.Search,
      title: (
        <div className='flex'>
          <Typography variant='aLabelBold'>{t`Search for counterparty`}</Typography>
          <Typography className='text-semantic-danger-main'>*</Typography>
        </div>
      ),
      content: (
        <EntityIdSearchInput
          variant='outlined'
          entityKindFilter={ApplicationSearchReturnType.Company}
          entityLocationFilters={[EntityLocationFilterKind.ALL]}
          selectedEntity={formData.counterpartyEntity ?? null}
          setSelectedEntity={(entityId: string | null) => setFormData({ ...formData, counterpartyEntity: entityId })}
          dataTrack='Add Counterparty / Search Entity'
          data-id={TestIDs.SidePanel.Counterparty.searchEntityTrigger}
          itemsWrapperProps={{ 'data-id': TestIDs.SidePanel.Counterparty.searchEntityResults }}
          inputProps={{ 'data-id': TestIDs.SidePanel.Counterparty.searchEntityInput }}
          singleSelect={true}
        />
      ),
      isCompleted: !!formData.counterpartyEntity,
      keepOpenAfterCompletion: true
    },
    {
      step: AddCounterparty.Direction,
      title: (
        <div className='flex'>
          <Typography variant='aLabelBold'>Choose direction</Typography>
          <Typography className='text-semantic-danger-main'>*</Typography>
        </div>
      ),
      isCompleted: !!formData.direction,
      keepOpenAfterCompletion: true,
      content: (
        <div className='flex flex-col py-4'>
          {Object.values(CounterPartyDirection).map((direction) => (
            <Label
              variant='aLabel'
              className={cn(
                'm-2 inline-flex h-full cursor-pointer items-start border p-4',
                direction === formData.direction ? 'border-blue-50' : 'border-gray-10 hover:bg-gray-5'
              )}
              data-id={`radio-wrapper-${direction}`}
              key={`label-${direction}`}
            >
              <Radio
                className='mr-2'
                data-id={`radio-input-${direction}`}
                value={direction}
                checked={direction === formData.direction}
                name='test-test'
                onChange={handleDirectionChange(direction)}
                key={`radio-${direction}`}
              />

              <div className='pl-2'>
                <Typography variant='body1' className={cn('pb-1 capitalize leading-4')}>
                  {direction.toLowerCase()}
                </Typography>
                <Typography component='div' variant='body1' className={cn('text-gray-50')}>
                  {t`Select this if ${direction === CounterPartyDirection.Inbound ? 'the counterparty' : entity.name} provides something to ${direction === CounterPartyDirection.Inbound ? entity.name : 'the counterparty'}`}
                </Typography>
              </div>
            </Label>
          ))}
        </div>
      )
    },
    {
      step: AddCounterparty.AddComment,
      title: <Typography variant='aLabelBold'>{t`Add Comment`}</Typography>,
      content: <CounterpartyCommentInput formData={formData} setFormData={setFormData} />,
      isCompleted: !!formData.comment,
      keepOpenAfterCompletion: true
    }
  ]

  const content = (
    <div>
      {stepInfos.map((stepInfo, index) => {
        return (
          <StepContent
            key={index}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepInfo={stepInfo}
            previousStepInfo={stepInfos[index - 1]}
            index={index}
          />
        )
      })}
      {formData.id && (
        <Button
          data-track='Edit Counterparty / Remove Counterparty'
          onClick={onCounterpartyDelete}
          variant='ghost'
          palette='danger'
          data-id={TestIDs.SidePanel.Counterparty.deleteCounterpartyButton}
        >
          <Trans>Remove counterparty</Trans>
        </Button>
      )}
    </div>
  )

  return (
    <Dialog
      title={t`Add as counterparty to ${entity.name ?? t`Unknown`}`}
      titleProps={{ variant: 'aLabel' }}
      isOpen
      contentMaxWidth={800}
      body={content}
      onClose={onClose}
      containerProps={{ bgcolor: 'bg-gray-5' }}
      contentProps={{ bgcolor: 'bg-gray-5' }}
    >
      <div className='flex w-full bg-gray-5'>
        <Button
          variant='ghost'
          color='primary'
          onClick={onClose}
          data-track='Add/edit counterparty / Cancel'
          className='w-1/2'
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          className='w-1/2'
          variant='contained'
          palette='primary'
          data-track='Add/edit counterparty / Confirm'
          loading={loading}
          disabled={!formData.counterpartyEntity && !formData.direction}
          onClick={handleConfirm}
          data-id={TestIDs.SidePanel.Counterparty.addCounterpartyConfirmButton}
        >
          <Trans>Save and close</Trans>
        </Button>
      </div>
    </Dialog>
  )
}
