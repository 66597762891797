import { cn, Typography } from '@strise/ui-components'
import { AuditRiskChip } from '@components/AuditRiskChip'
import { ContentViewContext } from '@components/Layout/ContentViewContext'
import { DownloadReviewPdf } from '@components/Review/DownloadReviewPdf'
import { type ReviewFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { CompanyStatusDot, formatDate, UserAvatar } from '@strise/app-shared'
import { useContext, type SetStateFn } from '@strise/react-utils'
import { companyStatusTitles } from '@strise/app-shared/src/i18n'
import { colors } from '@strise/ui-components-legacy'
import { ReviewStatus } from '@strise/types'
import { getTitle } from '@utils/enum'
import { type ReviewCardContentView } from '@utils/reviewUtils'
import * as React from 'react'
import { ReviewCompanyStartButton } from './ReviewCompanyStartButton'
import { TestIDs } from '@utils/testIDs'

export const ReviewCompanyCardSummaryContent = ({
  fetchReviewCompany,
  review,
  setCardView
}: {
  fetchReviewCompany: () => void
  review: ReviewFragment | null
  setCardView: SetStateFn<ReviewCardContentView>
}) => {
  const { isSmallScreen } = useContext(ContentViewContext)
  if (!review) return null

  const statusColor = review.status === ReviewStatus.Approved ? colors.semanticGreen : colors.semanticRed

  const rows = [
    {
      title: t`Date`,
      content: <Typography>{formatDate(review.created, { relative: false })}</Typography>
    },
    {
      title: t`Status`,
      content: review.companyStatus ? (
        <div className='flex'>
          <CompanyStatusDot status={review.companyStatus} />
          <Typography>{getTitle(companyStatusTitles[review.companyStatus])}</Typography>
        </div>
      ) : (
        <Typography>
          <Trans>None</Trans>
        </Typography>
      )
    },
    {
      title: t`Risk`,
      content: <AuditRiskChip className='m-0' auditRisk={review.risk} />
    },
    {
      title: t`Reviewer`,
      content: review.user ? (
        <div className='flex'>
          <UserAvatar user={review.user} className='mr-1 size-6' />
          <Typography>{review.user.name}</Typography>
        </div>
      ) : (
        <Typography>
          <Trans>Unknown</Trans>
        </Typography>
      )
    },
    {
      title: t`Report`,
      content: <DownloadReviewPdf review={review} className='h-auto p-0' />
    }
  ]

  return (
    <div className='px-4 pt-2'>
      <div className={cn('flex justify-between px-5', isSmallScreen ? 'flex-col' : 'flex-row')}>
        {rows.map((row, index) => {
          return (
            <div className={isSmallScreen ? 'my-2' : undefined} key={index}>
              <Typography className='block' variant='aLabelBold'>
                {row.title}
              </Typography>
              <div className='mt-[6px]'>{row.content}</div>
            </div>
          )
        })}
      </div>
      {review.comment && (
        <div className='mt-4 rounded-[0_12px_12px_12px] p-4' style={{ backgroundColor: statusColor[5] }}>
          <Typography className='whitespace-pre-line'>{review.comment}</Typography>
        </div>
      )}

      <div className='text-right'>
        <ReviewCompanyStartButton
          setCardView={setCardView}
          data-track='Review / Start new review'
          fetchReviewCompany={fetchReviewCompany}
          variant='outlined'
          palette='tertiary'
          data-id={TestIDs.Review.Card.startReviewButton}
        >
          <Trans>New Review</Trans>
        </ReviewCompanyStartButton>
      </div>
    </div>
  )
}
