import { defineMessage } from '@lingui/macro'
import { type MessageDescriptor } from '@lingui/core'
import { ActivityFilter } from '@components/TeamActivity/teamActivityUtils'
import { getTitle } from '@utils/enum'

export enum ActivityGroup {
  OVERDUE = 'overdue',
  TODAY = 'today',
  SCHEDULED = 'scheduled'
}

export const activityLabels: { [key in ActivityGroup]: MessageDescriptor } = {
  overdue: defineMessage({ message: 'Overdue' }),
  today: defineMessage({ message: 'Today' }),
  scheduled: defineMessage({ message: 'Scheduled' })
}
export const activityFilterTitles: Partial<Record<ActivityFilter, MessageDescriptor>> = {
  [ActivityFilter.ALL_ACTIVITIES]: defineMessage({ message: 'All activities' }),
  [ActivityFilter.COMPLETED_ACTIVITIES]: defineMessage({ message: 'Completed activities' }),
  [ActivityFilter.PLANNED_ACTIVITIES]: defineMessage({ message: 'Planned activities' })
}

export const activityFilterOptions = (context?: string) =>
  Object.values(ActivityFilter).map((filterValue) => ({
    value: filterValue,
    children: getTitle(activityFilterTitles[filterValue]),
    'data-id': `select-activity-filter${context ? `-${context}` : ''}-${filterValue
      .replaceAll(/\s/g, '-')
      .toLowerCase()}`
  }))
