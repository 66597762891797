import { languageToCountryCode } from '../../utils/language'
import * as React from 'react'
import { NationFlag, type NationFlagBaseProps } from './NationFlag'
import { type ContentLanguage } from '@strise/types'

export const LanguageNationFlag = ({
  className,
  language,
  ...props
}: { language: ContentLanguage | null | undefined } & NationFlagBaseProps) => {
  const countryIsoCode = language ? languageToCountryCode[language] : null

  return <NationFlag className={className} countryIsoCode={countryIsoCode} {...props} />
}
