import { Checkbox, LoaderRound } from '@strise/ui-components'
import * as React from 'react'
import { type DefaultLocationFragment, type RegionFragment } from '@graphqlTypes'
import { type SetStateFn } from '@strise/react-utils'
import { GrowLocationSearchInput, type LocationDownshiftItem } from './GrowLocationSearchInput'
import { useCurrentUserEnabledContentLanguages } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useDefaultCountriesQuery } from '@graphqlOperations'
import { isBoolean, isObject } from 'lodash-es'

const sortedRegion = (region: DefaultLocationFragment['regions']['edges']) => {
  return [...region].sort(({ node: { name: nameA } }, { node: { name: nameB } }) =>
    (nameA || '').localeCompare(nameB || '')
  )
}

const Location = ({
  checked,
  location,
  parent,
  regions,
  setLocationWrapper
}: {
  checked: boolean
  location: DefaultLocationFragment | RegionFragment
  parent?: DefaultLocationFragment
  regions?: Record<string, boolean> | boolean
  setLocationWrapper: (location: RegionFragment, parentId?: string) => void
}) => {
  const defaultLocation = 'regions' in location ? location : null

  const hasCheckedChildren = isObject(regions) && Object.values(regions).some(Boolean)
  const handleCheckboxChange = () => setLocationWrapper(location, parent?.id)

  return (
    <>
      <Checkbox
        id={`filter-location-${location.id}`}
        checked={hasCheckedChildren ? 'indeterminate' : checked}
        label={location.name}
        onCheckedChange={handleCheckboxChange}
        data-track={`Filter / Location / ${location.id}`}
      />
      {defaultLocation && (hasCheckedChildren || checked) && (
        <div className='ml-6'>
          {sortedRegion(defaultLocation.regions.edges).map(({ node }) => (
            <Location
              key={node.id}
              location={node}
              setLocationWrapper={setLocationWrapper}
              checked={isObject(regions) && Boolean(regions[node.id])}
              parent={defaultLocation}
            />
          ))}
        </div>
      )}
    </>
  )
}

type RegionsObject = Record<string, Record<string, boolean> | boolean>

export const GrowLocationFilter = ({
  locations,
  setLocations
}: {
  locations: RegionFragment[]
  setLocations: SetStateFn<RegionFragment[]>
}) => {
  const enabledContentLanguages = useCurrentUserEnabledContentLanguages()
  const { data, loading } = useDefaultCountriesQuery({
    variables: { filter: enabledContentLanguages }
  })
  const initialDefaultLocations = data?.defaultCountries.edges.map(({ node: country }) => country) ?? []

  const [regionsObject, setRegionsObject] = React.useState<RegionsObject>({})

  React.useEffect(() => {
    setRegionsObject((): RegionsObject => {
      return initialDefaultLocations.reduce((acc, defaultLocation) => {
        const value =
          locations.some((loc) => loc.id === defaultLocation.id) ||
          Object.fromEntries(
            defaultLocation.regions.edges.map(({ node: region }) => [
              region.id,
              locations.some((loc) => loc.id === region.id)
            ])
          )

        return {
          ...acc,
          [defaultLocation.id]: value
        }
      }, {})
    })
  }, [JSON.stringify(initialDefaultLocations), JSON.stringify(locations)])

  const setLocationWrapper = (location: RegionFragment, parentId?: string) => {
    setLocations((prevLocations: RegionFragment[]): RegionFragment[] => {
      const toRemove = prevLocations.some((loc) => loc.id === location.id)
      const matchingDefaultLocation = initialDefaultLocations.find(
        (defaultLocation) => defaultLocation.id === location.id
      )
      if (matchingDefaultLocation) {
        const childrenIds = new Set(matchingDefaultLocation.regions.edges.map(({ node }) => node.id))
        if (toRemove) return prevLocations.filter((loc) => loc.id !== location.id)

        return [...prevLocations.filter((loc) => !childrenIds.has(loc.id)), location]
      }

      // child selected
      if (toRemove) return prevLocations.filter((loc) => loc.id !== location.id)

      return [...prevLocations.filter((loc) => loc.id !== parentId), location]
    })
  }

  const addLocationFromSearch = (location: LocationDownshiftItem) => {
    if (locations.some((loc) => loc.id === location.id)) return

    setLocationWrapper(location as RegionFragment, location.parent?.id)
  }

  return (
    <div>
      <GrowLocationSearchInput addLocation={addLocationFromSearch} />
      <div className='my-4'>
        {loading ? (
          <LoaderRound size='sm' className='mx-auto' />
        ) : (
          <>
            {initialDefaultLocations.map((defaultLocation) => {
              return (
                <Location
                  key={defaultLocation.id}
                  location={defaultLocation}
                  setLocationWrapper={setLocationWrapper}
                  checked={isBoolean(regionsObject[defaultLocation.id])}
                  regions={regionsObject[defaultLocation.id]}
                />
              )
            })}
          </>
        )}
      </div>
    </div>
  )
}
