import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconInstagram = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M16.48 2H7.52A5.526 5.526 0 0 0 2 7.52v8.96A5.525 5.525 0 0 0 7.52 22h8.96A5.526 5.526 0 0 0 22 16.48V7.52A5.526 5.526 0 0 0 16.48 2Zm3.746 14.48a3.75 3.75 0 0 1-3.745 3.745H7.519a3.749 3.749 0 0 1-3.744-3.744V7.519a3.749 3.749 0 0 1 3.744-3.744h8.962a3.75 3.75 0 0 1 3.744 3.744v8.962Z'
    />
    <path
      fill='currentColor'
      d='M12 6.847A5.16 5.16 0 0 0 6.847 12 5.16 5.16 0 0 0 12 17.154 5.16 5.16 0 0 0 17.154 12 5.16 5.16 0 0 0 12 6.847Zm0 8.532A3.383 3.383 0 0 1 8.621 12 3.383 3.383 0 0 1 12 8.621a3.383 3.383 0 0 1 3.379 3.38A3.383 3.383 0 0 1 12 15.378ZM17.37 5.342a1.307 1.307 0 0 0-1.302 1.301c0 .342.14.678.382.92s.578.382.92.382c.343 0 .677-.14.92-.381a1.307 1.307 0 0 0 0-1.84 1.307 1.307 0 0 0-.92-.382Z'
    />
  </svg>
))
IconInstagram.displayName = 'IconInstagram'
