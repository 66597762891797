
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ConflictLike": [
      "OwnershipConflict",
      "RoleConflictV2"
    ],
    "EntityLike": [
      "Company",
      "Country",
      "Entity",
      "GlobalCompany",
      "GlobalPerson",
      "Industry",
      "Location",
      "Person",
      "PrivatePerson",
      "SimpleCompany",
      "SimpleEntity",
      "Topic"
    ],
    "Offset": [
      "BodyOffset",
      "TitleOffset"
    ],
    "PropertyValue": [
      "DefaultPropertyValue",
      "FacebookProfileIdPropertyValue",
      "GeoCoordinatePropertyValue",
      "IntPropertyValue",
      "JsonPropertyValue",
      "TwitterHandlePropertyValue",
      "WebsitePropertyValue",
      "WikiMediaImagePropertyValue"
    ],
    "ReviewSetting": [
      "BooleanReviewSetting",
      "EntityReviewSetting",
      "EntityWithCountryReviewSetting",
      "FlagReviewSetting",
      "RiskReviewSetting",
      "StringReviewSetting"
    ],
    "ReviewSettingLike": [
      "BooleanReviewSetting",
      "EntityReviewSetting",
      "EntityWithCountryReviewSetting",
      "FlagReviewSetting",
      "RiskReviewSetting",
      "StringReviewSetting"
    ],
    "SearchResult": [
      "Company",
      "GlobalCompany",
      "GlobalPerson",
      "Person",
      "PrivatePerson"
    ],
    "Shareholder": [
      "Company",
      "GlobalCompany"
    ],
    "TableLabel": [
      "TableLabelEntity",
      "TableLabelEntityRole",
      "TableLabelRole",
      "TableLabelString"
    ],
    "TableLabelLike": [
      "TableLabelEntity",
      "TableLabelEntityRole",
      "TableLabelRole",
      "TableLabelString"
    ],
    "TableRowValue": [
      "TableRowValueCCJs",
      "TableRowValueCreditReport",
      "TableRowValueEvent",
      "TableRowValueFlag",
      "TableRowValueLabeledString",
      "TableRowValueOwnership",
      "TableRowValuePepsWithEntity",
      "TableRowValueRegisterV2",
      "TableRowValueRelatedEntity",
      "TableRowValueRole",
      "TableRowValueRoleForPerson",
      "TableRowValueRoleWithDetailedEntity",
      "TableRowValueRoleWithEntity",
      "TableRowValueSanctions",
      "TableRowValueSanctionsWithEntity",
      "TableRowValueString"
    ],
    "TableRowValueLike": [
      "TableRowValueCCJs",
      "TableRowValueCreditReport",
      "TableRowValueEvent",
      "TableRowValueFlag",
      "TableRowValueLabeledString",
      "TableRowValueOwnership",
      "TableRowValuePepsWithEntity",
      "TableRowValueRegisterV2",
      "TableRowValueRelatedEntity",
      "TableRowValueRole",
      "TableRowValueRoleForPerson",
      "TableRowValueRoleWithDetailedEntity",
      "TableRowValueRoleWithEntity",
      "TableRowValueSanctions",
      "TableRowValueSanctionsWithEntity",
      "TableRowValueString"
    ]
  }
};
      export default result;
    