import * as React from 'react'
import { useTokenMeta } from '@strise/app-shared'
import * as Sentry from '@sentry/react'

export const IdentifyErrorService = ({ children }: { children: React.ReactNode }) => {
  const token = useTokenMeta()
  const userId = token?.sub
  if (userId) {
    Sentry.setUser({ id: userId })
  }

  return <>{children}</>
}
