import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconBurgermenu = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path fill='currentColor' d='M2 11h20v2H2v-2ZM2 19h20v2H2v-2ZM2 3h20v2H2V3Z' />
  </svg>
))
IconBurgermenu.displayName = 'IconBurgermenu'
