import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconBrokenHeart = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M11.046 2.6c.384.284.72.567.99.81.268-.243.602-.525.984-.808C14.01 1.868 15.492 1 17.085 1c3.109 0 5.946 2.583 5.946 6.378 0 3.472-1.552 5.52-3.111 7.58l-.001.002-.221.292c-.79 1.068-2.553 2.952-4.068 4.522a215.48 215.48 0 0 1-2.652 2.7l-.174.174-.062.061-.709.704-.706-.707.71-.706-.71.706-.061-.061-.173-.174a226.858 226.858 0 0 1-2.638-2.7c-1.514-1.573-3.274-3.456-4.08-4.517l-.003-.004-.305-.403c-1.52-2.008-3.066-4.049-3.066-7.47C1.001 3.574 3.858 1 6.963 1c1.6 0 3.088.866 4.083 1.6ZM3.004 7.379C3.004 4.648 4.994 3 6.963 3c.935 0 1.985.539 2.892 1.209.285.21.54.421.753.61l-1.683 1.73 4.701 5.063-3.61 4.715 2.024 4.253-.002.001-.146-.148c-.522-.53-1.234-1.257-1.992-2.046-1.536-1.597-3.206-3.389-3.928-4.34l-.254-.337c-1.546-2.05-2.714-3.597-2.714-6.332Zm10.497 11.717c.222-.228.452-.465.687-.709 1.542-1.599 3.206-3.385 3.901-4.325l.006-.008.16-.213c1.591-2.106 2.774-3.67 2.774-6.462 0-2.73-1.98-4.378-3.944-4.378-.924 0-1.966.537-2.872 1.208a11.384 11.384 0 0 0-1.414 1.25l-.017.018-.002.002-.743.825-.004-.003-.335.372 4.51 4.846-3.932 5.14 1.225 2.437Z'
      clipRule='evenodd'
    />
  </svg>
))
IconBrokenHeart.displayName = 'IconBrokenHeart'
