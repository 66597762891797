import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconArrowUp = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M13.027 3.758V23h-2.054V3.758L12 2.776l1.027.982Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m12 0 11 10.51-1.453 1.387-8.52-8.14L12 2.778l-1.027.98-8.52 8.14L1 10.51 12 0Z'
      clipRule='evenodd'
    />
  </svg>
))
IconArrowUp.displayName = 'IconArrowUp'
