import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconArrowRightSmall = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M16.707 12.293 12 17l-1.414-1.414 2.293-2.293H7.293v-2h5.586L10.586 9 12 7.586l4.707 4.707Z'
      clipRule='evenodd'
    />
  </svg>
))
IconArrowRightSmall.displayName = 'IconArrowRightSmall'
