import { Trans } from '@lingui/macro'
import { cn, Typography } from '@strise/ui-components'
import { ButtonGroup } from '@strise/ui-components-legacy'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import * as React from 'react'

export const OwnershipAbove25Toggle = ({
  above25,
  className,
  edit,
  setAbove25,
  ...props
}: { above25: boolean; edit: boolean; setAbove25: SetStateFn<boolean> } & DivProps) => {
  const baseProps = {
    className: 'px-5 w-8 h-6 flex justify-center',
    disabled: edit,
    'data-track': 'Company / Ownerships / Toggle ownership percentage'
  }

  const showOwnersAbove25Options = [
    {
      value: false,
      text: '5%',
      buttonProps: {
        ...baseProps,
        palette: above25 ? null : ('primary' as const)
      }
    },
    {
      value: true,
      text: '25%',
      buttonProps: {
        ...baseProps,
        palette: above25 ? ('primary' as const) : null
      }
    }
  ]

  const handleAbove25Change = (value: boolean) => setAbove25(value)

  return (
    <div className={cn('flex items-center', className)} {...props}>
      <Typography className={cn('mr-1', edit ? 'text-semantic-disabled-main' : 'text-text-primary')} variant='body2'>
        <Trans>Only show owners above</Trans>
      </Typography>
      <ButtonGroup<boolean> options={showOwnersAbove25Options} value={above25} onClick={handleAbove25Change} />
    </div>
  )
}
