import * as React from 'react'
import { Trans } from '@lingui/macro'
import { type IndustryFragment } from '@graphqlTypes'
import { track } from '@utils/tracking'
import { TrackedActivityKind } from '@strise/types'
import { extractNaceName } from '@utils/industryUtils'
import { Combobox, IconSearch } from '@strise/ui-components'

export interface IndustryDownshiftItem extends IndustryFragment {
  parentId?: string
}

export const IndustriesSearchInput = ({
  addIndustry,
  flattenedIndustries,
  removeIndustry,
  selectedIndustries
}: {
  addIndustry: (industry: IndustryDownshiftItem | null) => void
  flattenedIndustries: IndustryDownshiftItem[]
  removeIndustry: (industry: IndustryDownshiftItem) => void
  selectedIndustries: IndustryFragment[]
}) => {
  const onSelectHandler = (industry: IndustryDownshiftItem | null) => {
    track(TrackedActivityKind.StriseInteraction, {
      action: 'Grow Filter / Industry / Select',
      value: industry?.id
    })

    addIndustry(industry)
  }

  const button = (
    <div className='flex items-center gap-x-2'>
      <IconSearch size='sm' />
      <Trans>Search</Trans>
    </div>
  )

  const items = flattenedIndustries.map((item) => ({
    label: extractNaceName(item),
    id: item.id,
    value: item,
    renderNode: <div className='truncate'>{extractNaceName(item)}</div>
  }))

  const selectedItems = items.filter((item) => selectedIndustries.some(({ id }) => id === item.id))

  // TODO: `cmdk` in `Combobox` is a bit slow at rendering when filtering this many items.
  //        We should look into implementing some virtualization here
  return (
    <div className='mb-2 mt-5'>
      <Combobox
        hideSelected
        inlineSearch
        variant='outlined'
        onAdd={(item) => onSelectHandler(item.value)}
        onRemove={(item) => removeIndustry(item.value)}
        items={items}
        customSelectedItemsRenderer={button}
        data-track='Grow Filter / Industries / Select'
        value={selectedItems}
      >
        {button}
      </Combobox>
    </div>
  )
}
