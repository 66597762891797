import { SidepanelCard } from '../SidepanelCard'
import * as React from 'react'
import { t } from '@lingui/macro'
import { RiskCard } from '@components/RiskSummary/RiskCard'
import { SidepanelTab } from '@utils/urls'
import { type RiskSummaryFragment } from '@graphqlTypes'

interface SidepanelRiskSummaryCardProps {
  riskSummary: RiskSummaryFragment
}

export const SidepanelRiskSummaryCard = React.forwardRef<HTMLDivElement, SidepanelRiskSummaryCardProps>(
  ({ riskSummary }, ref) => {
    const { risks } = riskSummary

    return (
      <SidepanelCard
        ref={ref}
        tab={SidepanelTab.RiskSummary}
        title={t`Risk Summary`}
        description={t`Quick overview of key risk factors. Click the cards for detailed information.`}
        id='risk-summary'
      >
        <div className='grid auto-rows-fr grid-cols-3 gap-2 px-4 pb-4'>
          {risks.map((risk) => (
            <RiskCard key={`${risk.reason}${risk.label}${risk.level}${risk.kind}`} risk={risk} />
          ))}
        </div>
      </SidepanelCard>
    )
  }
)
