import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconEvents = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path fill='currentColor' fillRule='evenodd' d='M21 3H3v18h18V3ZM1 1v22h22V1H1Z' clipRule='evenodd' />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M18 9H6v4h12V9ZM4 7v8h16V7H4ZM18 18H6v3h12v-3ZM4 16v7h16v-7H4Z'
      clipRule='evenodd'
    />
    <path fill='currentColor' d='M3 4h18v2H3V4Z' />
  </svg>
))
IconEvents.displayName = 'IconEvents'
