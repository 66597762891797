import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconChevronRightSmall = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref) => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M10.707 7.293 15.414 12l-4.707 4.707-1.414-1.414L12.586 12 9.293 8.707l1.414-1.414Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconChevronRightSmall.displayName = 'IconChevronRightSmall'
