import React from 'react'
import { type MessageDescriptor } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { getTitle } from '@utils/enum'
import { ApplicationSearchReturnType } from '@strise/types'
import { Select, Typography } from '@strise/ui-components'
import { TestIDs } from '@utils/testIDs'

const entityKindFilterToTitle: {
  [key in ApplicationSearchReturnType]?: MessageDescriptor
} = {
  [ApplicationSearchReturnType.All]: defineMessage({ message: 'All entities' }),
  [ApplicationSearchReturnType.Company]: defineMessage({
    message: 'Companies'
  }),
  [ApplicationSearchReturnType.Person]: defineMessage({ message: 'Persons' }),
  [ApplicationSearchReturnType.PrivatePerson]: defineMessage({ message: 'Private Persons' })
}

export const SearchEntityKindFilter = ({
  entityTypes,
  setState,
  state
}: {
  entityTypes: ApplicationSearchReturnType[]
  setState: SetStateFn<ApplicationSearchReturnType>
  state: ApplicationSearchReturnType
}) => {
  const handleChange = (value: ApplicationSearchReturnType) => {
    setState(value)
  }
  // Sorting to guarantee order (All, Companies, Persons, ...)
  const sortedEntities = [...entityTypes].sort()
  const options = sortedEntities.map((key) => ({
    value: key,
    // TODO: Retrieve title from the backend, for now when a new type is added we need to a title manually
    children: getTitle(entityKindFilterToTitle[key])
  }))

  return (
    <Select
      variant='ghost'
      palette='tertiary'
      value={state}
      onValueChange={handleChange}
      options={options}
      className='h-full min-h-[40px] w-[110px] border-r border-divider bg-white text-sm'
      ariaLabel={t`Filter search result by type`}
      triggerText={
        <Typography variant='aLabelSmall' className='w-[110px] whitespace-nowrap'>
          {getTitle(entityKindFilterToTitle[state])}
        </Typography>
      }
      data-id={TestIDs.Search.kindFilterTrigger}
      contentProps={{ 'data-id': TestIDs.Search.kindFilterOptions }}
    />
  )
}
