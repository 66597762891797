import { t } from '@lingui/macro'
import { Tooltip, type TooltipProps } from '@strise/ui-components'
import * as React from 'react'

interface JsonRawDataTooltipProps extends TooltipProps {
  json: string[]
}
// eslint-disable-next-line @typescript-eslint/promise-function-async -- children is not a promise
export const JsonRawDataTooltip = ({ children, json, ...props }: JsonRawDataTooltipProps) => {
  const jsonWithContent = json.filter((sourceJson) => sourceJson.length > 2)
  if (jsonWithContent.length === 0) return children

  return (
    <Tooltip
      className='max-h-96 overflow-auto'
      content={
        <pre>
          {t`Raw data:`}
          {jsonWithContent.map((sourceJson, index) => {
            return (
              <React.Fragment key={index}>
                <br />
                {sourceJson}
                <br />
                {sourceJson}
              </React.Fragment>
            )
          })}
        </pre>
      }
      {...props}
    >
      {children}
    </Tooltip>
  )
}
