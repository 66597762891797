import * as React from 'react'
import { i18n, type MessageDescriptor } from '@lingui/core'
import { Table, TableCell, TableRow } from '@strise/ui-components-legacy'
import { formatNumber } from '@strise/ts-utils'
import { type CreditReportFragment } from '@graphqlTypes'
import { SidepanelContext } from '../../SidepanelContext/SidepanelContext'
import { defineMessage } from '@lingui/macro'
import { useCompanyCollateralQuery } from '@graphqlOperations'
import { useContext } from '@strise/react-utils'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type DivProps } from '@strise/react-utils'
import { isNil } from 'lodash-es'

const CreditScoreOverviewRow = ({
  className,
  count,
  currency,
  title,
  totalAmount,
  ...props
}: { count?: number | null; currency?: string | null; title: MessageDescriptor; totalAmount?: number | null } & Omit<
  DivProps,
  'title'
>) => {
  const countString = count != null && totalAmount !== undefined ? formatNumber(count) : '-'
  const totalAmountString = isNil(totalAmount) ? '-' : `${formatNumber(totalAmount)} ${currency ?? ''}`

  return (
    <TableRow className={className} {...props}>
      <TableCell size='large' color='text.secondary' pl={0}>
        {i18n._(title)}
      </TableCell>
      <TableCell size='large' color='text.secondary' textAlign='right' numeric>
        {countString}
      </TableCell>
      <TableCell size='large' textAlign='right' width={200} pr={0} numeric>
        {totalAmountString}
      </TableCell>
    </TableRow>
  )
}

const extractTotalAmount = (edges: Array<{ node: { amount: number } }> | undefined) => {
  if (!edges) return null
  if (!edges.length) return 0
  return edges.map(({ node }) => node.amount).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
}

export const SidepanelCreditReportOverview = ({ creditReport }: { creditReport?: CreditReportFragment | null }) => {
  const features = useCurrentUserFeatures()
  const { id } = useContext(SidepanelContext)
  const paymentRemarkEdges = creditReport?.paymentRemarks.edges
  const totalAmount = extractTotalAmount(paymentRemarkEdges)

  const partiallySettledEdges = creditReport?.paymentRemarks.edges.filter(
    ({ node: paymentRemark }) => paymentRemark.partiallySettled
  )
  const totalPartiallySettledAmount = extractTotalAmount(partiallySettledEdges)

  const { data: collateralData } = useCompanyCollateralQuery({
    variables: { id },
    skip: !features.COLLATERAL_DATA_LOSOREREGISTERET
  })
  const collateralEdges = collateralData?.company.collaterals.edges
  const totalCollateralAmount = extractTotalAmount(collateralEdges)

  return (
    <div>
      <Table>
        <tbody>
          <CreditScoreOverviewRow
            title={defineMessage({ message: 'Unsettled remarks' })}
            count={paymentRemarkEdges?.length}
            totalAmount={totalAmount}
            currency={creditReport?.currencyCode}
          />

          <CreditScoreOverviewRow
            title={defineMessage({ message: 'Partially settled remarks' })}
            count={partiallySettledEdges?.length}
            totalAmount={totalPartiallySettledAmount}
            currency={creditReport?.currencyCode}
          />

          <CreditScoreOverviewRow
            className='border-b-0'
            title={defineMessage({ message: 'Voluntary collateral' })}
            count={collateralEdges?.length}
            totalAmount={totalCollateralAmount}
            currency={creditReport?.currencyCode}
          />
        </tbody>
      </Table>
    </div>
  )
}
