import { AddOrUpdateEntityToOwnership, type EntityOwnershipFormData } from './AddEntityOwnerDialog'
import { type SetStateFn } from '@strise/react-utils'
import { t, Trans } from '@lingui/macro'
import { AccordionV2, ConfirmDialog } from '@strise/ui-components-legacy'
import * as React from 'react'
import { type BaseEntityLikeFragment, type EntityLikeMetaFragment } from '@graphqlTypes'
import { useConfirmationBeforeUnload } from '@utils/hooks'
import { EntityLink } from '../../EntityLink/EntityLink'
import { setChildState, useContext } from '@strise/react-utils'
import { OwnershipsContext } from '@components/Ownerships/OwnershipsContext/OwnershipsContext'
import { EntityKeyMetaItems } from '@components/Ownerships/EntityKeyMetaItems'
import { findOwnedCompanies } from '@components/Ownerships/ownershipChartUtils'
import { Typography, IconCompany } from '@strise/ui-components'
import { TestIDs } from '@utils/testIDs'

export const EditOwnerDialog = ({
  entity,
  formData,
  handleCancel,
  handleDelete,
  handleEdit,
  loading,
  setFormData
}: {
  entity: EntityLikeMetaFragment
  formData: EntityOwnershipFormData
  handleCancel: () => void
  handleDelete: () => void
  handleEdit: () => void
  loading: boolean
  setFormData: SetStateFn<EntityOwnershipFormData>
}) => {
  const { owners, rootEntity } = useContext(OwnershipsContext)
  const ownedCompaniesForEntity = findOwnedCompanies(owners.recursiveOwners, entity.id, rootEntity.id)
  const isDirectOwnerToRoot = owners.recursiveOwners.some((owner) => owner.id === entity.id)
  const ownerIds = isDirectOwnerToRoot
    ? [rootEntity, ...ownedCompaniesForEntity.map((o) => o.entity)]
    : ownedCompaniesForEntity

  useConfirmationBeforeUnload()

  return (
    <ConfirmDialog
      isOpen={true}
      onClose={handleCancel}
      title={t`Edit owner of ${rootEntity.name}`}
      onConfirm={handleEdit}
      confirmText={t`Save`}
      cancelText={t`Cancel`}
      onCancel={handleCancel}
      confirmButtonProps={{
        palette: 'primary',
        'data-track': 'Edit ownership / Edit Owner / Confirm',
        'data-id': TestIDs.SidePanel.Ownerships.editOwnerConfirmButton
      }}
      cancelButtonProps={{ 'data-track': 'Edit ownership / Edit Owner / Cancel' }}
      loading={loading}
      contentMaxWidth={800}
    >
      <div className='mb-8 flex flex-col gap-3 border border-gray-15 p-4'>
        <EntityLink entity={entity} withIcon noTooltip noLink />
        <EntityKeyMetaItems className='flex gap-2' entity={entity} expanded />
      </div>
      <div className='flex flex-col gap-6'>
        <Typography className='text-text-primary' variant='aLabel'>
          <Trans>
            Edit the direct ownerships {entity.name} has in {rootEntity.name}
          </Trans>
        </Typography>
        <div className='grid gap-8'>
          {ownerIds.map((owner) => {
            if (!owner) return null
            const isPerson = entity.__typename === 'Person'
            return (
              <AccordionV2
                toggleLabel={
                  <div className='flex items-center gap-4'>
                    <IconCompany className='text-secondary-main' />
                    <Typography variant='aLabelSmall' className='text-text-primary'>
                      {owner.name}
                    </Typography>
                  </div>
                }
                toggleLabelProps={{
                  display: 'flex',
                  color: 'text.primary',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
                key={owner.id}
                open={formData.ownedEntity?.id === owner.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4
                }}
                data-track={`Edit ownership / Edit Owner / Select owner / ${owner.name ?? 'Unknown'}`}
                toggle={() => {
                  if (formData.ownedEntity?.id === owner.id) {
                    setChildState(setFormData, 'ownedEntity')(null)
                  } else {
                    setChildState(setFormData, 'ownedEntity')(owner as BaseEntityLikeFragment)
                  }
                }}
              >
                {formData.ownedEntity && (
                  <AddOrUpdateEntityToOwnership
                    isEdit={true}
                    formData={formData}
                    setFormData={setFormData}
                    showBeneficialOwner={isPerson}
                    handleDeleteOwnership={handleDelete}
                  />
                )}
              </AccordionV2>
            )
          })}
        </div>
      </div>
    </ConfirmDialog>
  )
}
