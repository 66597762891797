import { i18n, type MessageDescriptor } from '@lingui/core'
import { Chip, type ChipProps } from '@strise/ui-components'
import * as React from 'react'
import { activeText, type FromToLike } from '@components/Filter/filterFromToUtils'
import { isNil } from 'lodash-es'

export const FilterFromToActive = <T extends string, I extends FromToLike<T>>({
  className,
  filter,
  suffix,
  ...props
}: { filter: I | null | undefined; suffix: MessageDescriptor } & ChipProps) => {
  if (isNil(filter?.from) && isNil(filter?.to)) return null

  const labels = [...activeText(filter.from ?? null, filter.to ?? null), suffix]

  return <Chip className={className} label={labels.map((text) => i18n._(text)).join(' ')} {...props} />
}
