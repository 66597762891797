import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconBook = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M18 1.168A11.998 11.998 0 0 1 20 1v1.585a6.52 6.52 0 0 1 1.052-.154l2.166-.14v20.765l-1.962-.357a21.002 21.002 0 0 0-7.513 0L12 23.017l-1.743-.316a21.002 21.002 0 0 0-7.514 0l-1.96.356V2.29l2.165.14c.355.022.707.074 1.052.154V1a11.999 11.999 0 0 1 7.2 2.4l.8.6.8-.6A12 12 0 0 1 18 1.168Zm-5 18.708V5.75L14 5a10 10 0 0 1 4-1.798v14.942c-1.76.254-3.458.841-5 1.732ZM11 5.75v14.126a14.002 14.002 0 0 0-5-1.732V3.202A10 10 0 0 1 10 5l1 .75ZM4 4.663V20c.996 0 1.983.124 2.937.365a23.013 23.013 0 0 0-4.155.298V4.424l.038.002c.403.026.8.106 1.18.237Zm13.063 15.702c1.389-.026 2.78.073 4.155.298V4.424l-.038.003a4.52 4.52 0 0 0-1.18.236V20a12 12 0 0 0-2.937.365Z'
      clipRule='evenodd'
    />
  </svg>
))
IconBook.displayName = 'IconBook'
