import { Button, cn, Tooltip, Typography } from '@strise/ui-components'
import { useAssigneeMutations } from '@components/Assignee/assigneeHooks'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useCreditReportCompanyQuery, useUpdateCreditMonitoringMutation } from '@graphqlOperations'
import { type CreditReportCompanyFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { toast, useCurrentUser } from '@strise/app-shared'
import { ConfirmDialog } from '@strise/ui-components-legacy'
import { type SetStateFn } from '@strise/react-utils'
import * as React from 'react'
import { SelectCompanyAssignee } from '../../../Assignee/SelectCompanyAssignee'
import { SelectCompaniesStatus } from '../../../CompanyStatus/SelectCompaniesStatus'
import { companyInPortfolio } from '../../../CompanyStatus/companyStatusUtils'
import { ContactStrise } from '../../../ContactStrise'

const CreditMonitoringCompanyStatusModal = ({
  company,
  editableCompanyStatus = true,
  enableCreditMonitoring,
  setModalOpen
}: {
  company: CreditReportCompanyFragment
  editableCompanyStatus?: boolean
  enableCreditMonitoring: () => void
  setModalOpen: SetStateFn<boolean>
}) => {
  const inPortfolio = companyInPortfolio(company.statusV2?.status)

  const disabledExplanation = inPortfolio ? '' : t`You must add the company to your portfolio before you can edit this.`
  const handleCancel = () => setModalOpen(false)

  const handleConfirm = () => {
    enableCreditMonitoring()
    setModalOpen(false)
  }

  return (
    <ConfirmDialog
      isOpen
      onRequestClose={handleCancel}
      confirmText={t`Confirm`}
      cancelText={t`Cancel`}
      title={editableCompanyStatus ? t`Add company to portfolio` : t`Assign company`}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      contentMaxWidth={500}
      confirmButtonProps={{
        disabled: !inPortfolio || !company.assignee.edges.length
      }}
    >
      <Typography className='mb-2'>
        {editableCompanyStatus ? (
          <Trans>The company must be in your portfolio and assigned to be monitored</Trans>
        ) : (
          <Trans>The company must be assigned to be monitored</Trans>
        )}
      </Typography>
      <SelectCompaniesStatus
        className='mb-2 h-12 w-full'
        companies={[company]}
        removable={false}
        disabled={!editableCompanyStatus}
        data-track-context='sidepanel-credit'
      />

      <Tooltip content={disabledExplanation}>
        <SelectCompanyAssignee
          disabled={!inPortfolio}
          assignees={company.assignee.edges}
          companyIds={[company.id]}
          className='h-12'
          indicatorVariant='show'
          data-track-context='sidepanel-credit'
        />
      </Tooltip>
    </ConfirmDialog>
  )
}

export const CreditReportMonitorButton = ({
  className,
  companyId,
  context,
  ...props
}: { companyId: string; context: 'Sidepanel' | 'Review' } & React.HTMLAttributes<HTMLElement>) => {
  const { data, loading: loadingCompany } = useCreditReportCompanyQuery({
    variables: { entity: companyId }
  })
  const currentUser = useCurrentUser()
  const { assign, assignLoading } = useAssigneeMutations()

  const company = data?.company
  const features = useCurrentUserFeatures()
  const [companyStatusModalOpen, setCompanyStatusModalOpen] = React.useState(false)
  const handleCompleted = () => toast.success(t`Monitoring updated`)
  const [updateCreditMonitoring, { loading: updateCreditMonitoringLoading }] = useUpdateCreditMonitoringMutation({
    onCompleted: handleCompleted
  })

  const isCreditMonitoringAvailable = !company?.legalForm?.hasNorwegianSoleTraderCreditData
  if (!isCreditMonitoringAvailable || !company?.creditReport) return null

  const inPortfolio = companyInPortfolio(company.statusV2?.status)

  const enableCreditMonitoring = async () =>
    await updateCreditMonitoring({
      variables: { company: companyId, enabled: true }
    })

  const isLoadingRequests = updateCreditMonitoringLoading || loadingCompany || assignLoading

  const handleMonitor = async () => {
    if (isLoadingRequests) return

    if (!inPortfolio) {
      setCompanyStatusModalOpen(true)
      return
    }

    if (context === 'Review') {
      // assign to user doing the review
      await assign({
        companies: [companyId],
        existingAssignees: [
          {
            node: currentUser
          }
        ],
        usersToAssign: [currentUser]
      })
    }
    await enableCreditMonitoring()
  }

  const handleStopMonitor = (e: React.MouseEvent) => {
    e.preventDefault()
    if (isLoadingRequests) return

    updateCreditMonitoring({
      variables: { company: companyId, enabled: false }
    })
  }

  if (!features.CREDIT_DATA_MONITORING) {
    return (
      <ContactStrise
        className={cn('w-[205px]', className)}
        intention={t`enable credit data monitoring`}
        additionalText={t`to enable credit data monitoring`}
        {...props}
      />
    )
  }

  if (!company.creditMonitoringEnabled) {
    return (
      <>
        <Button
          variant='contained'
          palette='primary'
          className={className}
          onClick={handleMonitor}
          loading={isLoadingRequests}
          data-track='Button / Sidepanel / Credit report / Monitor'
          {...props}
        >
          <Trans>Monitor</Trans>
        </Button>
        {companyStatusModalOpen && (
          <CreditMonitoringCompanyStatusModal
            company={company}
            setModalOpen={setCompanyStatusModalOpen}
            editableCompanyStatus={context !== 'Review'}
            enableCreditMonitoring={enableCreditMonitoring}
          />
        )}
      </>
    )
  }

  return (
    <Button
      variant='ghost'
      palette='tertiary'
      className={className}
      loading={updateCreditMonitoringLoading}
      onClick={handleStopMonitor}
      data-track='Button / Sidepanel / Credit report / Stop monitoring'
      {...props}
    >
      <Trans>Stop monitoring</Trans>
    </Button>
  )
}
