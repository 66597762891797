import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconCopyPaste = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M1 1h7.414l4 4h6L23 9.586V23H11v-4H1V1Zm10 16V7H7V3H3v14h8ZM9 4.414 9.586 5H9v-.586ZM13 7v14h8V11h-4V7h-4Zm6 1.414.586.586H19v-.586Z'
      clipRule='evenodd'
    />
  </svg>
))
IconCopyPaste.displayName = 'IconCopyPaste'
