import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconChevronUpSmall = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m12 11.414 3.293 3.293 1.414-1.414L12 8.586l-4.707 4.707 1.414 1.414L12 11.414Z'
      clipRule='evenodd'
    />
  </svg>
))
IconChevronUpSmall.displayName = 'IconChevronUpSmall'
