import { toast } from '@strise/app-shared'
import { t } from '@lingui/macro'
import { useUpdateCompanyNotificationSettingsMutation } from '@graphqlOperations'

export const useChangeCompanyNotificationSetting = () => {
  const handleCompleted = () => toast.success(t`Notification settings updated`)
  const [update, { loading }] = useUpdateCompanyNotificationSettingsMutation({
    onCompleted: handleCompleted
  })

  return {
    update,
    loading
  }
}
