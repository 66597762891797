import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconOnoff = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path fill='#000' d='M11.033 1v12h2V1h-2Z' />
    <path fill='#000' d='M3.033 14a9.004 9.004 0 0 1 6-8.488v2.162a7 7 0 1 0 6 0V5.512a9 9 0 1 1-12 8.488Z' />
  </svg>
))
IconOnoff.displayName = 'IconOnoff'
