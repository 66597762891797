import { usePersistentState } from '@strise/react-utils'
import { STORAGE_KEYS } from '@constants'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { useCreateTagMutation, useTagsQuery, CompanyTagFragmentDoc } from '@graphqlOperations'
import { type StickerConnection } from '@strise/types'
import * as React from 'react'
import { type CompanyTagConnectionEdgeFragment, type TagsQuery, type CompanyTagFragment } from '@graphqlTypes'
import { type ComboboxItem } from '@strise/ui-components'

const MAX_RECENTLY = 3

export const useRecentlyUsedTags = () => {
  const [recentlyUsedTags, setRecentlyUsedTags] = usePersistentState<string[]>(STORAGE_KEYS.recentlyUsedTags, [])

  const handleUpdate = (tagId: string) => {
    if (recentlyUsedTags.includes(tagId)) return

    const nextRecentlyUsedTags = [tagId, ...recentlyUsedTags].slice(0, MAX_RECENTLY)
    setRecentlyUsedTags(nextRecentlyUsedTags)
  }

  return [recentlyUsedTags, handleUpdate] as const
}

export const useCreateTag = () => {
  const team = useTeam()
  const [createTag] = useCreateTagMutation({
    update: (cache, { data }) => {
      const newTag = data?.createSticker
      if (newTag) {
        // Write newly added tag to cache
        const newTagRef = cache.writeFragment({
          fragment: CompanyTagFragmentDoc,
          data: { ...newTag, __typename: 'Sticker' }
        })

        // Make sure the tag is visible in the tag selector dropdowns
        cache.modify({
          id: `Team:${team.id}`,
          fields: {
            // @ts-expect-error
            stickers: (existing: StickerConnection | undefined) => ({
              ...existing,
              edges: [{ node: newTagRef, __typename: 'StickerConnectionEdge' }, ...(existing?.edges ?? [])]
            })
          }
        })
      }
    }
  })

  return React.useCallback(async (tagName: string) => {
    return await createTag({ variables: { name: tagName } })
  }, [])
}

export const useExtractTagValue = () => {
  const createTag = useCreateTag()

  // The tag type isn't fully accurate: tags to be created will have a null id and the value is the input string.
  // The label correctly represents the tag name, so we can use that for the new tag creation.
  return async (tag: ComboboxItem<CompanyTagFragment>): Promise<CompanyTagFragment | null | undefined> => {
    if (tag.id) return tag.value

    const res = await createTag(tag.label)
    return res.data?.createSticker
  }
}

export const useSortTags = (
  tags: TagsQuery['team']['tags']['edges'],
  selectedTags: CompanyTagConnectionEdgeFragment[] = []
) => {
  const [recentlyUsedTags] = useRecentlyUsedTags()

  return React.useMemo(() => {
    return [...tags].sort((a, b) => {
      const aSelected = selectedTags.some(({ node }) => node.id === a.node.id) ? 1 : 0
      const bSelected = selectedTags.some(({ node }) => node.id === b.node.id) ? 1 : 0
      const selected = bSelected - aSelected

      const aRecently = aSelected ? -1 : recentlyUsedTags.indexOf(a.node.id)
      const bRecently = bSelected ? -1 : recentlyUsedTags.indexOf(b.node.id)

      if (selected) return selected
      if (aRecently > -1 && bRecently > -1) return aRecently - bRecently
      if (aRecently > -1 || bRecently > -1) return bRecently - aRecently
      return a.node.name.localeCompare(b.node.name)
    })
  }, [tags, selectedTags, JSON.stringify(recentlyUsedTags)])
}

export const useTeamTags = () => {
  const { data, loading } = useTagsQuery({ variables: {} })
  const tags = data?.team.tags.edges ?? []
  return { tags, loading }
}
