import * as React from 'react'
import { type SidepanelCompanyFragment } from '@graphqlTypes'
import { type DivProps } from '@strise/react-utils'
import { SidepanelCard } from '../../SidepanelCard'
import { Button, Typography, cn } from '@strise/ui-components'
import { Trans, t } from '@lingui/macro'
import { hasOneMonthPassed, formatDate, Empty } from '@strise/app-shared'
import { useOrderCcjReportMutation } from '@graphqlOperations'
import { useCustomerId } from '@contexts/TeamContext/TeamContext'
import { useLatestRateLimit } from '@utils/rateLimit'
import { RateLimitKind } from '@strise/types'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { isRateLimitQuotaExceeded } from '@utils/rateLimits'
import { ContactStrise } from '@components/ContactStrise'
import { DataSources } from '../../DataSources'
import { CCJTable } from './CCJTable'
import { SidepanelTab } from '@utils/urls'

interface SidepanelCCJCardProps extends DivProps {
  company: SidepanelCompanyFragment
}

export const SidepanelCCJCard = React.forwardRef<HTMLDivElement, SidepanelCCJCardProps>(
  ({ className, company, ...props }, ref) => {
    const customerId = useCustomerId()
    const features = useCurrentUserFeatures()

    const [orderCCJReport, { loading: orderCCJReportLoading }] = useOrderCcjReportMutation({
      variables: {
        company: company.id,
        customer: customerId
      }
    })

    const { data: rateLimitData, loading: rateLimitLoading } = useLatestRateLimit(
      RateLimitKind.CreditScore,
      !!features.CREDIT_DATA
    )
    const rateLimitQuotaExceeded = isRateLimitQuotaExceeded(rateLimitData)

    const { ccjReport } = company

    const handleOrderCreditReport = () => {
      if (!orderCCJReportLoading) {
        orderCCJReport()
      }
    }

    const header = (
      <div className='flex justify-between p-4'>
        <div className='flex flex-col'>
          <Typography variant='subtitle1'>CCJ's</Typography>
          {ccjReport ? (
            <Typography
              variant='body2'
              className={cn(
                'inline-block text-text-secondary',
                hasOneMonthPassed(ccjReport.date) && 'bg-semantic-notice-shade-5'
              )}
            >
              <Trans>Last fetched</Trans>: {formatDate(ccjReport.date)}
            </Typography>
          ) : (
            <Typography variant='body2' className='text-text-secondary'>
              <Trans>Not fetched yet</Trans>
            </Typography>
          )}
        </div>
        {rateLimitQuotaExceeded ? (
          <ContactStrise
            data-track={`Button / Sidepanel / CCJ's / Contact Strise to extend quota`}
            additionalText={t`to extend quota`}
            intention={t`extend credit score quota`}
          />
        ) : (
          <Button
            onClick={handleOrderCreditReport}
            loading={orderCCJReportLoading}
            data-track="Button / Sidepanel / CCJ's / Fetch report"
            variant={ccjReport ? 'outlined' : 'contained'}
            palette={ccjReport ? 'tertiary' : 'primary'}
            disabled={rateLimitLoading}
          >
            <Trans>Fetch CCJ's</Trans>
          </Button>
        )}
      </div>
    )

    return (
      <SidepanelCard className={cn(className)} headerElement={header} ref={ref} {...props} tab={SidepanelTab.CCJs}>
        <div className='px-4'>
          {!ccjReport && (
            <Typography variant='aLabel' className='text-text-secondary'>
              <Trans>Click Fetch CCJ's to see details</Trans>
            </Typography>
          )}
          {ccjReport?.judgements.length === 0 && (
            <Empty title={t`No CCJ details found`}>
              <Trans>We couldn't find any CCJ's on this company</Trans>
            </Empty>
          )}
          {ccjReport && <CCJTable judgements={ccjReport.judgements} />}
        </div>
        <DataSources dataSources={company.dataSources.credit} />
      </SidepanelCard>
    )
  }
)
