'use client'

import * as React from 'react'
import { cn } from '../../utils/className'
import { composeRefs } from '@strise/react-utils'

export interface TextAreaProps extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onInput'> {
  autoResize?: boolean
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ autoResize, className, value, ...props }, ref) => {
    const resizeRef = React.useRef<HTMLTextAreaElement | null>(null)

    const handleResize = () => {
      if (!autoResize) return
      if (!resizeRef.current) return

      // Temporarily shrink to get the scroll height
      resizeRef.current.style.height = '0px'
      const scrollHeight = resizeRef.current.scrollHeight
      resizeRef.current.style.height = `${scrollHeight}px`
    }

    // Trigger handleResize on all controlled value changes, so it does not only trigger on user input (ex. AI generated text)
    React.useEffect(() => {
      handleResize()
    }, [value])

    return (
      <textarea
        ref={composeRefs(ref, resizeRef)}
        className={cn(
          'min-h-[100px] w-full border-2 border-secondary-shade-10 p-2',
          { 'resize-none overflow-hidden': autoResize },
          className
        )}
        value={value}
        {...props}
      />
    )
  }
)

TextArea.displayName = 'TextArea'
