import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client/index.js'
import { type CachePersistor } from 'apollo3-cache-persist'
import { type useApolloLink } from './useApolloLink'
import { createContext } from '@strise/react-utils'

interface ApolloClientContextType {
  client: ApolloClient<NormalizedCacheObject> | null
  link: ReturnType<typeof useApolloLink>
  persistor: CachePersistor<NormalizedCacheObject> | null
}

export const ApolloClientContext = createContext<ApolloClientContextType>()
