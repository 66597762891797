import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconInvite = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M15.643 11.768a6 6 0 1 0-7.286 0A9.002 9.002 0 0 0 3 20v3h13a5.017 5.017 0 0 1-1.584-2H5v-1a7 7 0 0 1 11.522-5.344 4.97 4.97 0 0 1 2.193-.648 9.024 9.024 0 0 0-3.072-2.24ZM8 7a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z'
      clipRule='evenodd'
    />
    <path fill='currentColor' fillRule='evenodd' d='M18 23v-8h2v8h-2Z' clipRule='evenodd' />
    <path fill='currentColor' fillRule='evenodd' d='M23 20h-8v-2h8v2Z' clipRule='evenodd' />
  </svg>
))
IconInvite.displayName = 'IconInvite'
