import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconDislike = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M17.24 18.055c.268.806.17 1.635-.079 2.336a4.939 4.939 0 0 1-1.172 1.839c-.48.47-1.136.9-1.876.958-.39.03-.8-.046-1.176-.275-.372-.226-.645-.563-.835-.95-.202-.413-.398-.96-.558-1.455-.319-.983-.816-1.866-1.503-2.553a9.007 9.007 0 0 1-2.087-3.268l-.252-.688h-.378c-.353.312-.816.501-1.324.501H3a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h3c.51 0 .975.19 1.328.504L8.667 2h9.754c1.33 0 2.564.698 3.249 1.84.519.864.87 1.82 1.036 2.814l.129.77c.11.661.165 1.33.165 1.999v.931c0 1.198-.193 2.387-.572 3.523A3.104 3.104 0 0 1 19.483 16h-3.006l.462 1.156.3.9ZM6 4.5v8H3v-8h3ZM8 5v7h1.098l.733 1.998c.35.956.905 1.824 1.624 2.543.952.952 1.595 2.128 1.991 3.35.156.479.316.914.452 1.191.03.06.054.094.069.111.09-.011.322-.097.62-.39a2.94 2.94 0 0 0 .69-1.08c.145-.41.155-.766.065-1.035l-.281-.844L13.523 14h5.96c.476 0 .898-.304 1.048-.755.31-.932.469-1.908.469-2.89v-.932c0-.56-.046-1.117-.138-1.67l-.129-.77a6.037 6.037 0 0 0-.778-2.114A1.789 1.789 0 0 0 18.42 4H9.333L8 5Z'
      clipRule='evenodd'
    />
  </svg>
))
IconDislike.displayName = 'IconDislike'
