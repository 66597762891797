import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { objectKeys } from '@strise/ts-utils'
import { useRouter } from 'next/router'
import { isFunction, isObject } from 'lodash-es'
import { type SetStateFn } from '../types/types'

type AnyObject = Record<string, any>

export const flattenPathToObject = (
  value: string,
  parentObject: AnyObject,
  validations: AnyObject,
  path: string[]
): unknown => {
  const pathHead = path.shift()
  if (!pathHead) return parentObject
  if (!validations[pathHead]) return parentObject

  if (isFunction(validations[pathHead]) && validations[pathHead](value) === null) return parentObject

  if (!path.length) {
    return {
      ...parentObject,
      [pathHead]: validations[pathHead](value)
    }
  }
  if (!validations[pathHead]) return parentObject
  return {
    ...parentObject,
    [pathHead]: flattenPathToObject(value, parentObject[pathHead] ?? {}, validations[pathHead], path)
  }
}

export const objectToDotSeparatedQueryParam = <T extends object>(
  initialObject: T,
  initialValidations: AnyObject,
  initialSerializations: AnyObject = {},
  search: string = ''
): URLSearchParams => {
  const params = new URLSearchParams(search)
  const rootKeys = new Set(objectKeys(initialObject))

  const toRemove: string[] = []
  params.forEach((_, key) => {
    const [head] = key.split('.')
    if (rootKeys.has(head as keyof T)) toRemove.push(key)
  })
  toRemove.forEach((key) => params.delete(key))

  const recursivelyAddParam = (
    childObject: AnyObject | null,
    validations: AnyObject,
    serializations?: AnyObject,
    path: string = ''
  ) => {
    if (!childObject) return

    Object.keys(childObject).forEach((key) => {
      const value = childObject[key]
      const currentPath = path.length ? `${path}.${key}` : key

      // It should be impossible to add variable to the query param if you're missing a validation function
      if (validations && !isFunction(validations) && !validations[key]) {
        // Simply skip __typename
        if (key !== '__typename') throw new Error(`Missing validation function for "${key}" of "${currentPath}"`)
        return
      }

      const serializationFn = serializations?.[key]

      if (!isFunction(serializationFn) && isObject(value) && !Array.isArray(value) && !(value instanceof Date)) {
        recursivelyAddParam(value, validations[key], serializations?.[key], currentPath)
      } else if (Array.isArray(value) && !value.length) {
        params.delete(currentPath)
      } else {
        // Undefined should not be added to query params
        if (value === undefined) return

        const valueOrString = value instanceof Date ? value.toISOString() : value
        const serializedValue = serializationFn ? serializationFn(valueOrString) : valueOrString

        // Undefined should not be added to query params
        if (serializedValue === undefined) return

        params.set(currentPath, serializedValue)
      }
    })
  }
  recursivelyAddParam(initialObject, initialValidations ?? {}, initialSerializations)
  params.sort()
  return params
}

export type RecursiveQueryParamsValidation<T> = {
  [P in Exclude<keyof T, '__typename'>]: NonNullable<T[P]> extends Array<infer U>
    ? (values: string) => U[] | null
    : NonNullable<T[P]> extends object
      ? RecursiveQueryParamsValidation<NonNullable<T[P]>> | ((value: string) => NonNullable<T[P]> | null)
      : (value: string) => T[P] | null
}

export const useRecursiveQueryParamsReactRouter = <T extends object>(
  initialValue: T,
  validations: RecursiveQueryParamsValidation<T>,
  serializations?: object,
  skip?: boolean
): [T, SetStateFn<T>] => {
  const navigate = useNavigate()
  const location = useLocation()

  const { currentValue, setState, setValue, urlStateMismatch } = useQueryParamsState<T>(
    initialValue,
    validations,
    location.search,
    serializations,
    skip
  )

  useEffect(() => {
    if (skip) return

    const { mismatch, search } = urlStateMismatch()
    if (mismatch) {
      navigate({ ...location, search }, { replace: true })
    }
  }, [currentValue])

  useEffect(() => {
    if (skip) return

    const { mismatch } = urlStateMismatch()
    if (mismatch) {
      setValue(setState)
    }
  }, [location.search])

  return [currentValue, setValue]
}

export const useRecursiveQueryParamsNextJs = <T extends object>(
  initialValue: T,
  validations: RecursiveQueryParamsValidation<T>,
  serializations?: object,
  skip?: boolean
): [T, SetStateFn<T>] => {
  const router = useRouter()

  const queryString = router.asPath.replace(/^[^?]*/, '')

  const { currentValue, setState, setValue, urlStateMismatch } = useQueryParamsState<T>(
    initialValue,
    validations,
    queryString,
    serializations,
    skip
  )

  useEffect(() => {
    if (skip) return

    const { mismatch, search } = urlStateMismatch()
    if (mismatch) {
      router.replace(`${router.pathname}?${search}`)
    }
  }, [currentValue])

  useEffect(() => {
    if (skip) return

    const { mismatch } = urlStateMismatch()
    if (mismatch) {
      setValue(setState)
    }
  }, [location.search])

  return [currentValue, setValue]
}

const useQueryParamsState = <T extends object>(
  initialValue: T,
  validations: RecursiveQueryParamsValidation<T>,
  queryString: string,
  serializations?: object,
  skip?: boolean
) => {
  const setState = () => {
    if (skip) return {} as T

    const rootKeys = objectKeys(validations)
    const params = new URLSearchParams(queryString)
    let nextValue = { ...initialValue }
    params.forEach((value: string, key: string) => {
      const currentRootKey = rootKeys.find((rootKey) => key.split('.')[0] === rootKey)
      if (currentRootKey) {
        const path = key.split('.')
        nextValue = flattenPathToObject(value, nextValue, validations, path) as T
      }
    })
    return nextValue
  }

  const [currentValue, setValue] = useState<T>(setState())

  const urlStateMismatch = () => {
    const search = objectToDotSeparatedQueryParam<T>(currentValue, validations, serializations, queryString).toString()

    return {
      mismatch: queryString !== `?${search}`,
      search
    }
  }

  return { currentValue, setValue, setState, urlStateMismatch }
}
