import React from 'react'
import { Trans } from '@lingui/macro'
import { Button, IconInfoRound, Typography, cn } from '@strise/ui-components'
import { TestIDs } from '@utils/testIDs'
import { useTriggerConflictModal } from '@utils/hooks'
import { ConflictAlertType } from './ConflictAlertType'
import { type EntityIdentity } from '@utils/entity'
import { useUnresolvedConflicts } from './useHasUnresolvedConflicts'

export const ConflictAlert = ({
  className = '',
  entity,
  type = ConflictAlertType.REVIEW
}: {
  className?: string
  entity: EntityIdentity
  type?: ConflictAlertType
}) => {
  const triggerConflictModal = useTriggerConflictModal()

  const handleClick = () => {
    triggerConflictModal(entity.id)
  }

  const { hasConflicts, hasOwnershipConflicts, hasRoleConflicts } = useUnresolvedConflicts(entity)

  const showConflictAlert =
    ((type === ConflictAlertType.REVIEW || type === ConflictAlertType.SIDEPANEL) && hasConflicts) ||
    (type === ConflictAlertType.ROLES && hasRoleConflicts) ||
    (type === ConflictAlertType.OWNERSHIPS && hasOwnershipConflicts)

  if (!showConflictAlert) {
    return null
  }

  return (
    <div className={cn('mt-0 flex items-center justify-between bg-yellow-10 px-3 py-4', className)}>
      <div className='flex basis-1/2'>
        <div className='flex-none'>
          <IconInfoRound size='sm' className='mr-2' />
        </div>
        <Typography variant='body1'>
          {type === ConflictAlertType.REVIEW && (
            <Trans>This entity has data conflicts that need to be resolved before a review can be started</Trans>
          )}
          {type === ConflictAlertType.SIDEPANEL && (
            <Trans>This entity has data conflicts that need to be resolved</Trans>
          )}
          {type === ConflictAlertType.ROLES && (
            <Trans>This entity has role conflicts that need to be resolved before edits can be made</Trans>
          )}
          {type === ConflictAlertType.OWNERSHIPS && (
            <Trans>This entity has ownership conflicts that need to be resolved before edits can be made</Trans>
          )}
        </Typography>
      </div>

      <Button
        variant='contained'
        palette='secondary'
        onClick={handleClick}
        data-track='Conflict / Resolve Conflict'
        data-id={TestIDs.Review.Card.startReviewButton}
      >
        <Trans>Resolve conflicts</Trans>
      </Button>
    </div>
  )
}
