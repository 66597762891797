import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconGoogle = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m16.654 6.936 2.076-2.234a.325.325 0 0 0-.023-.033 1.308 1.308 0 0 0-.054-.052c-2.613-2.293-5.628-3.11-9.004-2.329-4.67 1.08-7.986 5.487-7.622 10.441.112 1.527.531 2.964 1.32 4.272 1.761 2.919 4.333 4.585 7.72 4.949a9.201 9.201 0 0 0 3.252-.245 9.833 9.833 0 0 0 5.254-3.303 9.674 9.674 0 0 0 2.158-4.637c.07-.401.11-.808.15-1.215.013-.144.028-.288.043-.432.021-.197.037-.395.053-.594l.023-.285h-9.273v3.07h5.599l-.013.042a.63.63 0 0 1-.02.065c-1.045 2.542-2.87 4.108-5.62 4.456-1.614.203-3.136-.151-4.48-1.072-2.417-1.656-3.44-3.989-3.04-6.892.433-3.14 3.045-5.544 6.203-5.798 1.98-.158 3.719.436 5.209 1.756a1.1 1.1 0 0 0 .052.042l.037.028Z'
      clipRule='evenodd'
    />
  </svg>
))
IconGoogle.displayName = 'IconGoogle'
