import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconFemale = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12.054 3a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z'
      clipRule='evenodd'
    />
    <path fill='currentColor' fillRule='evenodd' d='M13.054 18v5h-2v-5h2Z' clipRule='evenodd' />
    <path fill='currentColor' fillRule='evenodd' d='M9.054 20h6v2h-6v-2Z' clipRule='evenodd' />
  </svg>
))
IconFemale.displayName = 'IconFemale'
