import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconAmLcheckPassed = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M2 4a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v7h-2V4a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h5.579v2H5a3 3 0 0 1-3-3V4Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M18 7H6V5h12v2ZM18 11H6V9h12v2ZM11 15H6v-2h5v2ZM11 19H6v-2h5v2Z'
      clipRule='evenodd'
    />
    <path
      fill='#00D100'
      fillRule='evenodd'
      d='M14 13a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-8Zm7.809 2.588-1.618-1.176-3.384 4.653-1.682-1.346-1.25 1.562 3.318 2.654 4.616-6.347Z'
      clipRule='evenodd'
    />
  </svg>
))
IconAmLcheckPassed.displayName = 'IconAmLcheckPassed'
