import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconAdverseFlagInfo = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm1-14V7h-2v2h2Zm0 8v-7h-2v7h2Z'
      clipRule='evenodd'
    />
    <path fill='#fff' d='M13 17h-2v-7h2v7ZM13 9h-2V7h2v2Z' />
  </svg>
))
IconAdverseFlagInfo.displayName = 'IconAdverseFlagInfo'
