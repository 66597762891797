import { Alert, type AlertVariant, cn, Typography, type TypographyProps } from '@strise/ui-components'
import * as React from 'react'
import { type DivProps } from '@strise/react-utils'

interface SettingsChangesSectionHeaderProps extends DivProps {
  description?: string
  descriptionProps?: TypographyProps
  descriptionVariant?: AlertVariant
  title?: string
  titleProps?: TypographyProps
}

export const SettingChangesSectionHeader = ({
  className,
  description,
  descriptionProps,
  descriptionVariant,
  title,
  titleProps,
  ...props
}: SettingsChangesSectionHeaderProps) => {
  const { className: descriptionClassName, ...descriptionPropsRest } = descriptionProps ?? {}

  return (
    <div className={className} {...props}>
      {title && (
        <Typography variant='subtitle1' {...titleProps}>
          {title}
        </Typography>
      )}
      {description && !descriptionVariant && (
        <Typography
          className={cn('mb-2 block text-text-secondary', descriptionClassName)}
          variant='aLabelSmall'
          {...descriptionPropsRest}
        >
          {description}
        </Typography>
      )}

      {description && descriptionVariant && (
        <Alert variant={descriptionVariant} className='mb-4'>
          {description}
        </Alert>
      )}
    </div>
  )
}

interface SettingsChangesProps extends Omit<DivProps, 'title'> {
  children: React.ReactNode
  description?: string
  descriptionProps?: TypographyProps
  title?: string
  titleProps?: TypographyProps
}

export const SettingsChangesSection = ({
  children,
  className,
  description,
  descriptionProps,
  title,
  titleProps,
  ...props
}: SettingsChangesProps) => {
  return (
    <div className={cn('flex flex-col gap-2', className)} {...props}>
      <SettingChangesSectionHeader
        title={title}
        titleProps={titleProps}
        description={description}
        descriptionProps={descriptionProps}
      />
      {children}
    </div>
  )
}
