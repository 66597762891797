import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconSanctionOutlined = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g fill='currentColor' clipPath='url(#IconSanctionOutlined_svg__a)'>
      <path d='m15.744 2.905-7.528.017-5.31 5.334-1.418-1.41L6.799 1.51A2 2 0 0 1 8.212.92l7.527-.016a2 2 0 0 1 1.416.583l5.334 5.31a2 2 0 0 1 .59 1.414l.016 7.527a2 2 0 0 1-.583 1.416l-5.31 5.334a2 2 0 0 1-1.414.59l-7.527.016a2 2 0 0 1-1.416-.583l-5.334-5.311a2 2 0 0 1-.59-1.413L.906 8.261a2 2 0 0 1 .583-1.416l1.417 1.411.017 7.528 5.334 5.31 7.528-.016 5.31-5.334-.016-7.528-5.334-5.311Z' />
      <path d='M11 6h2v9h-2V6ZM13 18h-2v-2h2v2Z' />
    </g>
    <defs>
      <clipPath id='IconSanctionOutlined_svg__a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
))
IconSanctionOutlined.displayName = 'IconSanctionOutlined'
