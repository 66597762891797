import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { t, Trans } from '@lingui/macro'
import * as React from 'react'
import { SidepanelCard } from '../SidepanelCard'
import { type CompanyNetworkFragment } from '@graphqlTypes'
import { Divider, Typography } from '@strise/ui-components'
import { PepCard } from '@components/PepAndSanctions/PepCard'
import { SanctionCard } from '@components/PepAndSanctions/SanctionCard'
import { extractPepAndSanctionsFromCompanyNetwork } from '@components/PepAndSanctions/pepAndSanctionsUtils'

interface SidepanelPepAndSanctionsCardProps {
  companyNetwork: CompanyNetworkFragment
  currentCompanyId: string
}

export const SidepanelPepAndSanctionsCard = ({
  companyNetwork,
  currentCompanyId
}: SidepanelPepAndSanctionsCardProps) => {
  const features = useCurrentUserFeatures()

  if (!features.PEP_AND_SANCTIONS_V2) {
    return null
  }

  const pepEntitiesSearched = companyNetwork.allPersons.length
  // +1 for the company itself
  const sanctionEntitiesSearched = companyNetwork.allPersons.length + companyNetwork.otherCompanies.length + 1
  const { pepEntities, sanctionedEntities } = extractPepAndSanctionsFromCompanyNetwork(companyNetwork)

  return (
    <SidepanelCard title={t`PEPs and Sanctions`} id='pep-and-sanctions'>
      <div className='px-4'>
        <div className='grid grid-cols-3 py-4'>
          <div className='flex flex-col gap-2'>
            <Typography className='text-gray-50' variant='aLabel'>
              <Trans>Sanctions</Trans>
            </Typography>
            <Typography variant='aLabelSmall' className='text-gray-50'>
              <Trans>{sanctionEntitiesSearched} entities screened</Trans>
            </Typography>
          </div>
          <div className='col-span-2 flex flex-col gap-4'>
            {sanctionedEntities.length === 0 && (
              <div>
                <Typography variant='aLabel' className='text-gray-50'>{t`No sanctions to show`}</Typography>
              </div>
            )}
            {sanctionedEntities.map(({ associatedCompanies, entity, sanctions }, index) => (
              <React.Fragment key={entity.id}>
                <SanctionCard
                  entity={entity}
                  sanctions={sanctions}
                  associatedCompanies={associatedCompanies}
                  currentCompanyId={currentCompanyId}
                />
                {index < sanctionedEntities.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </div>
        </div>
        <Divider className='col-span-3' />
        <div className='grid grid-cols-3 py-4'>
          <div className='flex flex-col gap-2'>
            <Typography className='text-gray-50' variant='aLabel'>{t`PEP`}</Typography>
            <Typography variant='aLabelSmall' className='text-gray-50'>
              <Trans>{pepEntitiesSearched} entities screened</Trans>
            </Typography>
          </div>
          <div className='col-span-2 flex flex-col gap-4'>
            {pepEntities.length === 0 && (
              <Typography variant='aLabel' className='text-gray-50'>{t`No PEPs to show`}</Typography>
            )}
            {pepEntities.map((entity, index) => (
              <React.Fragment key={entity.person.id}>
                <PepCard
                  pepInfo={entity.pep}
                  person={entity.person}
                  associatedCompanies={entity.associatedCompanies}
                  currentCompanyId={currentCompanyId}
                />
                {index < pepEntities.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </SidepanelCard>
  )
}
