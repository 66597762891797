import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconArrowDiagonalDown = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref) => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M21 21V4h2v19H4v-2h17Z' clipRule='evenodd' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m2.707 1.293 20 20-1.414 1.414-20-20 1.414-1.414Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconArrowDiagonalDown.displayName = 'IconArrowDiagonalDown'
