'use client'

import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cn } from '../../utils/className'
import { IconCheckMedium, IconMinus } from '../../icons/general'
import { Typography, type TypographyProps } from '../Typography'
import { type VariantProps } from 'class-variance-authority'
import { type DataProps, type DivProps, type ExcludedStyleProps } from '@strise/react-utils'
import { checkboxVariants } from '../variants/checkboxVariants'

export interface CheckboxProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
      ExcludedStyleProps | 'onChange' | 'title'
    >,
    DataProps,
    VariantProps<typeof checkboxVariants> {
  /** Optional props for the container */
  containerProps?: DivProps
  /** Element below label */
  description?: string | null
  /** Optional props for the description */
  descriptionProps?: TypographyProps
  /** Used for connecting label to checkbox */
  id: string
  /** Element to the right of checkbox */
  label?: React.ReactNode | null
  /** Optional props for the label container */
  labelContainerProps?: DivProps
  /** Optional props for the label */
  labelProps?: TypographyProps
  /** Optional function to trigger when the checked value changes */
  onCheckedChange?: (checked: boolean) => void
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  (
    {
      checked,
      className,
      containerProps,
      description,
      descriptionProps,
      id,
      label,
      labelContainerProps,
      labelProps,
      palette = 'secondary',
      ...props
    },
    ref
  ) => {
    const CheckedIcon = checked === 'indeterminate' ? IconMinus : IconCheckMedium

    const { className: containerClassName, ...containerRest } = containerProps || {}
    const { className: labelContainerClassName, ...labelContainerRest } = labelContainerProps || {}
    const { className: labelClassName, ...labelRest } = labelProps || {}
    const { className: descriptionClassName, ...descriptionRest } = descriptionProps || {}

    const labelId = `${id}-label`

    return (
      <div className={cn('flex items-center space-x-2', containerClassName)} {...containerRest}>
        <CheckboxPrimitive.Root
          ref={ref}
          id={id}
          checked={checked}
          className={cn(checkboxVariants({ palette }), className)}
          aria-labelledby={labelId}
          {...props}
        >
          <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
            <CheckedIcon className={`size-[18px] ${palette === 'tertiary' ? 'text-text-primary' : 'text-white'}`} />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {(label || description) && (
          <div
            className={cn(
              { 'text-white': palette === 'tertiary' },
              'cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:text-text-secondary',
              labelContainerClassName
            )}
            {...labelContainerRest}
          >
            {label && (
              <Typography
                asChild
                variant='body1'
                className={cn('block cursor-[inherit]', labelClassName)}
                {...labelRest}
              >
                <label htmlFor={id} id={labelId}>
                  {label}
                </label>
              </Typography>
            )}
            {description && (
              <Typography
                asChild
                variant='body2'
                className={cn('block cursor-[inherit]', descriptionClassName)}
                {...descriptionRest}
              >
                <label htmlFor={id}>{description}</label>
              </Typography>
            )}
          </div>
        )}
      </div>
    )
  }
)

Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
