import { useReducer } from 'react'

interface GrowState {
  hidden: string[]
  processed: string[]
  visible: string[]
}

export type GrowStateAction =
  | { data: GrowState; type: 'init' }
  | { id: string; type: 'replace' }
  | { id: string; index: number; type: 'reAdd' }

const reducer = (state: GrowState, action: GrowStateAction): GrowState => {
  switch (action.type) {
    case 'init': {
      return action.data
    }

    case 'replace': {
      const newId = state.hidden[0]
      const replacedItem = state.visible.find((id) => id === action.id)
      const newIgnored = replacedItem === undefined ? state.processed : [...state.processed, replacedItem]

      return {
        hidden: state.hidden.slice(1),
        visible: newId
          ? state.visible.map((id) => (id === action.id ? newId : id))
          : state.visible.filter((id) => id !== action.id),
        processed: newIgnored
      }
    }

    case 'reAdd': {
      const replaceIndex = action.index
      const newVisible = [...state.visible.slice(0, replaceIndex), action.id, ...state.visible.slice(replaceIndex)]
      const processed = state.processed.filter((id) => id !== action.id)

      return {
        ...state,
        visible: newVisible,
        processed
      }
    }
  }
}

export const useGrowState = () => {
  return useReducer(reducer, { visible: [], hidden: [], processed: [] })
}
