import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconBold = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 16 16'
    {...props}
  >
    <g clipPath='url(#IconBold_svg__a)'>
      <path
        fill='#2E2E2E'
        fillRule='evenodd'
        d='M.68.682h9.325a4.424 4.424 0 0 1 4.423 4.423v.307c0 .812-.235 1.57-.642 2.208a4.424 4.424 0 0 1-2.557 8.034H.681V.682Zm2.723 2.722v3.403h6.908c.77 0 1.395-.625 1.395-1.395v-.306c0-.94-.762-1.702-1.701-1.702H3.402Zm0 6.125v3.403h7.826a1.701 1.701 0 0 0 0-3.403H3.404Z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='IconBold_svg__a'>
        <rect width={16} height={16} fill='#fff' rx={2} />
      </clipPath>
    </defs>
  </svg>
))
IconBold.displayName = 'IconBold'
