import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconOverdue = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M2.434 6.567 6.599 2.4 5.185.987 1.02 5.153l1.414 1.414ZM18.835 1l4.166 4.166-1.415 1.414-4.165-4.166L18.835 1ZM12 23c5.523 0 10-4.477 10-10S17.523 3 12 3 2 7.477 2 13s4.477 10 10 10Zm1-6v2h-2v-2h2ZM11 7v8h2V7h-2Z'
      clipRule='evenodd'
    />
  </svg>
))
IconOverdue.displayName = 'IconOverdue'
