import { Checkbox, cn, Divider, Skeleton, Typography } from '@strise/ui-components'
import { Trans } from '@lingui/macro'
import { reduceFlatten } from '@strise/ts-utils'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import { type TableRowKind } from '@strise/types'
import { type CheckedState, extractTextColorClass } from '@utils/reviewUtils'
import React from 'react'

export const ReviewSelectAllCheckboxSection = ({
  allCustomRowKeys,
  allRowKeys,
  checkedMap,
  className,
  companyId,
  disabled,
  errors,
  loading,
  setChecked,
  ...props
}: {
  allCustomRowKeys: string[]
  allRowKeys: TableRowKind[]
  checkedMap: CheckedState
  companyId: string
  disabled?: boolean
  errors?: boolean
  loading?: boolean
  setChecked: SetStateFn<CheckedState>
} & DivProps) => {
  const checkedCount =
    Object.values(checkedMap.checkedMap).filter(Boolean).length +
    Object.values(checkedMap.customCheckedMap).filter(Boolean).length
  const totalRowKeys = allRowKeys.length + allCustomRowKeys.length

  const isChecked = !!checkedCount
  const allKey = `all-${companyId}${String(errors)}`

  const handleSelectAll = () => {
    setChecked({
      checkedMap: allRowKeys.map((rowKey) => ({ [rowKey]: true })).reduce(reduceFlatten, {}),
      customCheckedMap: allCustomRowKeys.map((rowKey) => ({ [rowKey]: true })).reduce(reduceFlatten, {})
    })
  }

  const handleCheckboxChange = () => {
    setChecked((prevChecked) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const prevCheckedCount = prevChecked
        ? Object.values(checkedMap.checkedMap).filter(Boolean).length +
          Object.values(checkedMap.customCheckedMap).filter(Boolean).length
        : 0

      if (!prevCheckedCount) {
        return {
          checkedMap: allRowKeys.map((rowKey) => ({ [rowKey]: true })).reduce(reduceFlatten, {}),
          customCheckedMap: allCustomRowKeys.map((rowKey) => ({ [rowKey]: true })).reduce(reduceFlatten, {})
        }
      }

      return {
        checkedMap: {},
        customCheckedMap: {}
      }
    })
  }

  const hasError = Boolean(errors && totalRowKeys !== checkedCount)
  const colorClass = extractTextColorClass(hasError, !!checkedCount)

  return (
    <>
      <div className={cn('shrink-0 py-2 pr-1', { 'min-h-[64px]': errors }, className)} {...props}>
        <div className='flex items-center'>
          <Checkbox
            id={allKey}
            labelProps={{ className: colorClass }}
            name={allKey}
            checked={isChecked && checkedCount < totalRowKeys ? 'indeterminate' : isChecked}
            label={loading ? <Skeleton className='h-[15px] w-[21px]' /> : `${checkedCount}/${totalRowKeys}`}
            onCheckedChange={handleCheckboxChange}
            disabled={disabled || loading}
            data-track='Filter / Review / All'
          />
          {hasError && (
            <Typography className='cursor-pointer' onClick={handleSelectAll}>
              <Typography className='ml-1' component='span'>
                -{' '}
              </Typography>{' '}
              <Typography className='text-text-link' component='span'>
                <Trans>Check all</Trans>
              </Typography>
            </Typography>
          )}
        </div>
        {hasError && (
          <Typography className={cn('mt-1', colorClass)}>
            <Trans>Check all boxes before proceeding</Trans>
          </Typography>
        )}
      </div>
      {errors && <Divider />}
    </>
  )
}
