import { set } from 'lodash-es'

const defaultVariables = {}

export const defaultOptions = {
  watchQuery: {
    variables: defaultVariables
  },
  query: {
    variables: defaultVariables
  },
  mutate: {
    variables: defaultVariables
  }
}

export const setClientVariable = (key: string, value: string) => {
  ;['watchQuery', 'query', 'mutate'].forEach((path) => {
    set(defaultOptions, `${path}.variables.${key}`, value)
  })
}
