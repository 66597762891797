import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconArrowDown = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M10.973 20.242V2h2.054v18.242L12 21.224l-1.027-.982Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12 24 1 13.49l1.453-1.387 8.52 8.14 1.027.98 1.027-.98 8.52-8.14L23 13.49 12 24Z'
      clipRule='evenodd'
    />
  </svg>
))
IconArrowDown.displayName = 'IconArrowDown'
