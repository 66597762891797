import * as React from 'react'

interface DarkModeContextProps {
  state: boolean
}

export const DarkModeContext = React.createContext<DarkModeContextProps>({ state: false })

export const useDarkMode = () => {
  const { state } = React.useContext(DarkModeContext)
  return { darkMode: state }
}
