import { type TableRowValueFragment } from '@graphqlTypes'
import { EntityLink } from '@components/EntityLink/EntityLink'
import { Timespan } from '@components/Timespan/Timespan'
import React from 'react'
import { cn } from '@strise/ui-components'
import type { handleEditRowFn } from '@utils/apiTable/apiTableUtils'
import { CustomMeta } from '@utils/apiTable/CustomMeta'
import { PersonReviewInfo } from '@components/PersonReviewInfo'
import { useIsReview } from '@contexts/RenderContext/RenderContext'

export const RoleWithEntity = ({
  editMode,
  handleEdit,
  value
}: {
  editMode?: boolean
  handleEdit?: handleEditRowFn
  value: TableRowValueFragment & { __typename: 'TableRowValueRoleWithEntity' }
}) => {
  const isReview = useIsReview()
  const doesEntityHaveActiveRole = value.roleMetas.some((roleMeta) => roleMeta.isActive)
  // If detailedRoles is false, all the info we need will be in the first roleMeta
  const firstMeta = value.roleMetas.at(0)
  return (
    <div className='flex w-full'>
      {value.detailedRoles && (
        <div className='min-h-flags-chip w-full'>
          <EntityLink entity={value.entity} transparent={!doesEntityHaveActiveRole} />
          {isReview && (value.entity.__typename === 'Person' || value.entity.__typename === 'GlobalPerson') && (
            <PersonReviewInfo person={value.entity} />
          )}
          <div className={cn('flex flex-col border-l border-divider px-2 py-0.5')}>
            {value.roleMetas.map((roleMeta, index) => (
              <div key={index} className='grid grid-cols-3 justify-between'>
                <span>{roleMeta.roleTitleDescription ?? roleMeta.roleTitle}</span>
                <Timespan timespan={roleMeta.period} isActive={roleMeta.isActive} />
                <CustomMeta
                  editMode={editMode}
                  customMeta={roleMeta.customMeta}
                  handleEdit={() => {
                    handleEdit?.(value.entity, {
                      existingRole: true,
                      type: 'EditRole',
                      roleTitleId: roleMeta.roleTitleId,
                      periodFrom: roleMeta.period.from,
                      periodTo: roleMeta.period.to,
                      comment: roleMeta.customMeta?.comment
                    })
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {!value.detailedRoles && firstMeta && (
        <div className='grid w-full grid-cols-[3fr_1fr_80px] items-center justify-center'>
          <div className='min-h-flags-chip'>
            <EntityLink entity={value.entity} transparent={!doesEntityHaveActiveRole} />
            {isReview && (value.entity.__typename === 'Person' || value.entity.__typename === 'GlobalPerson') && (
              <PersonReviewInfo person={value.entity} />
            )}
          </div>
          <div className='flex items-center'>
            <Timespan timespan={firstMeta.period} isActive={firstMeta.isActive} />
          </div>
          <CustomMeta
            editMode={editMode}
            handleEdit={() => {
              handleEdit?.(value.entity, {
                existingRole: true,
                type: 'EditRole',
                roleTitleId: firstMeta.roleTitleId,
                periodFrom: firstMeta.period.from,
                periodTo: firstMeta.period.to,
                comment: firstMeta.customMeta?.comment
              })
            }}
            customMeta={firstMeta.customMeta}
          />
        </div>
      )}
    </div>
  )
}
