import * as React from 'react'
import { useMemo } from 'react'
import { decamelize } from '@strise/ts-utils'
import { AuthError, isRateLimitError, toast, useLogout } from '@strise/app-shared'
import { trackError } from '@utils/errorTracking'
import { getMainDefinition } from '@apollo/client/utilities/index.js'
import { onError } from '@apollo/client/link/error/index.js'
import { spoofUser } from '@state'
import { spoof } from '@utils/spoof'
import { t, Trans } from '@lingui/macro'
import { OpenChatLink } from '@components/ContactStrise'
import { Kind, OperationTypeNode } from 'graphql/language'
import { type Operation } from '@apollo/client'
import { HEADER_REQUEST_ID } from '@constants'
import { type GraphQLFormattedError } from 'graphql/error'
import type { SeverityLevel } from '@sentry/react'

const graphQLErrorSeverityLevel = (statusCode: number | false | null | undefined): SeverityLevel => {
  switch (statusCode) {
    case 401: {
      return 'warning'
    }
    case 404: {
      return 'warning'
    }
    default: {
      return 'error'
    }
  }
}

const logAuthError = (
  statusCode: number | false | null | undefined,
  message: string,
  requestId: string | undefined
) => {
  trackError.auth(statusCode || 'Unknown status code', message, 'warning', requestId)
}

const logGraphQLError = (
  statusCode: number | false | null | undefined,
  message: string,
  requestId: string | undefined
) => {
  trackError.graphQL(statusCode || 'Unknown status code', message, graphQLErrorSeverityLevel(statusCode), requestId)
}

const logNetworkError = (
  statusCode: number | false | null | undefined,
  message: string,
  requestId: string | undefined
) => {
  trackError.network(statusCode || 'Unknown status code', message, 'warning', requestId)
}

export const useErrorLink = () => {
  const logout = useLogout()

  // https://www.apollographql.com/docs/react/data/error-handling/#graphql-errors
  const handleGraphQLErrors = (
    operation: Operation,
    graphQLErrors: readonly GraphQLFormattedError[],
    statusCode: null | undefined | false | number,
    requestId: string | undefined
  ) => {
    const definition = getMainDefinition(operation.query)
    const operationType = definition.kind === Kind.OPERATION_DEFINITION && definition.operation
    const operationName = decamelize(operation.operationName, ' ')

    const showErrorToast = graphQLErrors.every((error) => {
      const errorString = `${operation.operationName}: ${error.message}`
      logGraphQLError(statusCode, errorString, requestId)

      return !isRateLimitError(error)
    })

    if (statusCode === 429) {
      toast.error(
        <>
          Rate limit has been exceeded. Please{' '}
          <OpenChatLink className='inline text-accent-blue-shade-20' msg={t`I want to increase the rate limit.`}>
            <Trans>contact Strise</Trans>
          </OpenChatLink>{' '}
          to increase this limit.
        </>
      )
    } else if (statusCode === 408) {
      toast.error(
        <>
          <Trans>Looks like the server is taking to long to respond ({operationName}). Please try again later or</Trans>{' '}
          <OpenChatLink
            className='inline text-accent-blue-shade-20'
            msg={t`The server is taking long to respond, can you help me?`}
          >
            <Trans>contact Strise</Trans>
          </OpenChatLink>{' '}
          <Trans>if the issue persists.</Trans>
        </>
      )
    } else if (showErrorToast) {
      if (operationType === OperationTypeNode.QUERY && !operation.getContext().hideErrorToast) {
        toast.error(`Error occurred when fetching ${operationName}`)
      } else if (operationType === OperationTypeNode.MUTATION && !operation.getContext().hideErrorToast) {
        toast.error(`Error occurred when trying to ${operationName}`)
      }
    }
  }

  return useMemo(
    () =>
      onError(({ graphQLErrors, networkError, operation }) => {
        const statusCode = networkError && 'statusCode' in networkError && networkError.statusCode
        const operationContext = operation.getContext()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const requestId = operationContext.headers[HEADER_REQUEST_ID] as string | undefined

        if (spoofUser()) {
          spoof.stop()
        } else if (statusCode === 401) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const hasToken = Boolean(operationContext.headers.authorization)
          const errorString = `${operation.operationName} with token: ${String(hasToken)}`
          logAuthError(statusCode, errorString, requestId)
          logout(AuthError.AuthenticationFailed, '401')
        } else if (graphQLErrors) {
          handleGraphQLErrors(operation, graphQLErrors, statusCode, requestId)
        } else if (networkError) {
          const errorString = `NetworkError message: ${networkError.message}`
          logNetworkError(statusCode, errorString, requestId)
        }
      }),
    [logout]
  )
}
