import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconSave = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M7 1h16v22.723l-8-4.571-8 4.571V1Zm2 2v17.277l6-3.429 6 3.429V3H9Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M7 3a4 4 0 0 0-4 4v4h4V3ZM1 7a6 6 0 0 1 6-6h2v12H1V7Z'
      clipRule='evenodd'
    />
  </svg>
))
IconSave.displayName = 'IconSave'
