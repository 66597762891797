import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconOverflowHorizontal = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref) => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' d='M2 10h4v4H2v-4ZM10 10h4v4h-4v-4ZM18 10h4v4h-4v-4Z' />
    </svg>
  )
)
IconOverflowHorizontal.displayName = 'IconOverflowHorizontal'
