import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconAdverseFlagNegative = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref) => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M13.74 3.08c-.765-1.354-2.716-1.354-3.481 0L.687 20.016C-.067 21.349.897 23 2.427 23h19.145c1.532 0 2.495-1.65 1.741-2.984L13.741 3.08ZM13 9h-2v7h2V9Zm0 8h-2v2h2v-2Z'
        clipRule='evenodd'
      />
      <path fill='#fff' d='M11 9h2v7h-2V9ZM11 17h2v2h-2v-2Z' />
    </svg>
  )
)
IconAdverseFlagNegative.displayName = 'IconAdverseFlagNegative'
