import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconOverflowVertical = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path fill='currentColor' d='M14 2v4h-4V2h4ZM14 10v4h-4v-4h4ZM14 18v4h-4v-4h4Z' />
  </svg>
))
IconOverflowVertical.displayName = 'IconOverflowVertical'
