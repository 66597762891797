import * as React from 'react'
import { Button, type ButtonProps, cn, IconInfoRound, IconReminderDefault, Typography } from '@strise/ui-components'
import { useUpdateTeamActivityV2Mutation } from '@graphqlOperations'
import { type TeamActivityFragment, type UpdateTeamActivityV2Mutation } from '@graphqlTypes'
import { t } from '@lingui/macro'
import { formatDate, RichTextEditor, UserAvatar, useUserId } from '@strise/app-shared'
import { type SetStateFn } from '@strise/react-utils'
import {
  useTeamActivityCompleteButtonStyle,
  useTeamActivityKindPrefix,
  useTeamActivityReminderStyle
} from './teamActivityHooks'
import { TeamActivityCompleteButton } from './TeamActivityCompleteButton'
import { refreshActivityViewState } from '@state'
import { ReminderStatus } from '@strise/types'
import { undoToast } from '@strise/app-shared/src/utils/toasts'
import { i18n } from '@lingui/core'
import { TeamActivityMenu } from './TeamActivityMenu'
import { getTeamActivityKindText, getTeamActivityNoteColors, type TeamActivityFormData } from './teamActivityUtils'

const TeamActivityReminderBackground: React.FC<{
  reminderBackgroundColorClass: string | undefined
}> = ({ reminderBackgroundColorClass }) => {
  return (
    <div className='absolute left-0 top-0 z-[-1] size-[121px] overflow-hidden rounded-md'>
      <div className={cn('absolute left-[-30px] top-[-25px] size-full rounded-full', reminderBackgroundColorClass)} />
    </div>
  )
}

export const TeamActivity: React.FC<
  {
    isLast: boolean
    setDeleteDialogOpen: SetStateFn<false | string>
    setFormData: SetStateFn<TeamActivityFormData>
    teamActivitiesLeft: number
    teamActivity: TeamActivityFragment
  } & Omit<ButtonProps, 'onClick'>
> = ({ className, isLast, setDeleteDialogOpen, setFormData, teamActivitiesLeft, teamActivity, ...props }) => {
  const currentUserId = useUserId()
  const [readMore, setReadMore] = React.useState<boolean>(false)
  const [isHovered, setIsHovered] = React.useState<boolean>(false)

  const { noteColor } = getTeamActivityNoteColors(teamActivity.kind)
  const { titlePrefix } = useTeamActivityKindPrefix(
    teamActivity.kind,
    teamActivity.timestamp,
    teamActivity.createdBy?.id
  )
  const noteTitle = getTeamActivityKindText(teamActivity.kind).toLowerCase()

  const userName = teamActivity.createdBy?.name ?? t`Deleted user`

  const name =
    currentUserId === teamActivity.assignee?.id
      ? t({ id: 'You (Du)', message: 'You' })
      : (teamActivity.assignee?.name ?? userName)

  const isTeamActivityCompleted = teamActivity.reminder?.status === ReminderStatus.Inactive

  const isTeamActivityOverdue = teamActivity.reminder?.overdue

  const { ReminderIcon, colorClassName, reminderBackgroundColorClass, reminderIsToday, reminderText } =
    useTeamActivityReminderStyle(teamActivity.timestamp, teamActivity.reminder ?? null)

  const {
    buttonText,
    className: activityClassName,
    palette,
    trackId
  } = useTeamActivityCompleteButtonStyle(teamActivity.timestamp, isTeamActivityCompleted)

  const hasTeamActivityNote = teamActivity.note && teamActivity.note !== ''

  const isAssigneed = teamActivity.assignee && teamActivity.assignee.id !== teamActivity.createdBy?.id

  const hasEditAccess = teamActivity.createdBy?.id === currentUserId

  const handleUpdateCompleted = (data: UpdateTeamActivityV2Mutation) => {
    refreshActivityViewState(refreshActivityViewState() + 1)
    undoToast({
      label: t`Team activity updated`,
      onUndo: async () => await handleUpdateTeamActivityReminderStatus(data.updateTeamActivityV2.teamActivity),
      i18n
    })
  }

  const [update, { loading: updateLoading }] = useUpdateTeamActivityV2Mutation({ onCompleted: handleUpdateCompleted })

  const toggleReadMore = (): void => {
    setReadMore(!readMore)
  }

  const handleMouseEnter = (): void => setIsHovered(true)
  const handleMouseLeave = (): void => setIsHovered(false)

  const handleUpdateTeamActivityReminderStatus = async (activity: TeamActivityFragment) => {
    const input = {
      note: activity.note,
      kind: activity.kind,
      timestamp: activity.timestamp || new Date().toISOString(),
      assignee: activity.assignee?.id
    }

    await update({
      variables: {
        teamActivity: activity.id,
        input: {
          ...input,
          reminderStatus:
            activity.reminder?.status === ReminderStatus.Inactive ? ReminderStatus.Active : ReminderStatus.Inactive
        }
      }
    })
  }

  const user = teamActivity.assignee ?? teamActivity.createdBy

  return (
    <Button
      className={cn(
        'relative z-[1] block h-auto rounded-xl bg-tertiary-shade-5 px-0 hover:bg-tertiary-main',
        isLast ? 'mb-3' : 'mb-9',
        readMore ? 'cursor-auto' : 'cursor-pointer',
        className
      )}
      asChild
      variant={readMore ? undefined : 'contained'}
      palette={readMore ? undefined : 'tertiary'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-track='Team activities / Assign to select'
      onClick={toggleReadMore}
      {...props}
    >
      <div>
        <div className='px-2 pt-4'>
          {reminderBackgroundColorClass && (
            <TeamActivityReminderBackground reminderBackgroundColorClass={reminderBackgroundColorClass} />
          )}

          <div className='mb-1 flex justify-between'>
            <div className='inline-flex h-8 items-center'>
              {ReminderIcon && <ReminderIcon className={cn('ml-1 mr-3', colorClassName)} />}
              {(!ReminderIcon || !teamActivity.reminder) && user && (
                <UserAvatar user={user} className='mr-3 size-8 bg-divider' />
              )}

              <Typography>
                {`${name} ${titlePrefix} `}
                <Typography className={cn('px-1', noteColor)} component='span'>{` ${noteTitle}`}</Typography>
              </Typography>
            </div>
            {hasEditAccess && (
              <div className='self-center'>
                <TeamActivityMenu
                  iconProps={{ className: 'text-secondary-shade-50' }}
                  teamActivity={teamActivity}
                  setFormData={setFormData}
                  setDeleteDialogOpen={setDeleteDialogOpen}
                />
              </div>
            )}
          </div>
          <div className='mb-2 ml-11 flex justify-between'>
            <Typography className={cn('flex items-center', colorClassName)} variant='body2' component='div'>
              {teamActivity.reminder && (
                <TeamActivityCompleteButton
                  className={cn('mr-2', activityClassName)}
                  palette={palette}
                  disabled={updateLoading}
                  onClick={async () => await handleUpdateTeamActivityReminderStatus(teamActivity)}
                  trackId={trackId}
                >
                  {buttonText}
                </TeamActivityCompleteButton>
              )}

              {teamActivity.reminder && !isTeamActivityCompleted && !isTeamActivityOverdue && !reminderIsToday && (
                <IconReminderDefault size='md' className='mr-1' />
              )}

              {reminderText}
            </Typography>
          </div>
          <div className='ml-11'>
            {hasTeamActivityNote && (
              <RichTextEditor readOnly initValue={teamActivity.note ?? null} rows={2} displayAllRows={readMore} />
            )}
          </div>
        </div>
        <div
          className={cn(
            'flex items-center rounded-b-xl py-3 pl-4 text-secondary-shade-40 opacity-0',
            { 'border border-tertiary-main opacity-100': readMore },
            { 'opacity-100': isHovered }
          )}
          style={{
            transition: 'opacity 0.5s ease'
          }}
        >
          <IconInfoRound size='md' />
          <Typography className='ml-2' variant='aLabelSmall'>
            {userName}
            {` ${isAssigneed ? t`assigned this activity to` : t`created this activity`}`}
            {isAssigneed && <>{` ${teamActivity.assignee?.name ?? t`Unknown`}, `}</>}
            {` ${formatDate(teamActivity.created, { relative: false })}`}
          </Typography>
        </div>
        {isLast && !teamActivitiesLeft && <div className='-mt-1 h-[calc(100%-4px)] w-full bg-background-paper' />}
      </div>
    </Button>
  )
}
