import md5 from 'blueimp-md5'
import { isString } from 'lodash-es'

export const decamelize = (str: string, separator: string = '_'): string => {
  return str
    .replaceAll(/([\da-z])([A-Z])/g, `$1${separator}$2`)
    .replaceAll(/([A-Z]+)([A-Z][\da-z]+)/g, `$1${separator}$2`)
    .toLowerCase()
}

export const toTitleCase = (str: string) => {
  // Only capitalizes first letter of each word, and therefore preserves abbreviations, such as ESG and the D in McDonalds
  return str.replaceAll(/(^|\s)\S/g, (t) => {
    return t.toUpperCase()
  })
}

export const ellipsis = (str: string, maxLength: number) => {
  if (str.length <= maxLength) return str
  return `${str.substring(0, maxLength - 3).trim()}...`
}

export const capitalize = (s: string) => {
  if (!isString(s)) return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const pathToCamelCase = (path: string[]): string => {
  if (!path.length) return ''
  const [head] = path

  if (!head) return ''

  return head + capitalize(pathToCamelCase(path.slice(1)))
}

export const camelCaseToText = (value: string): string => {
  return value
    .replaceAll(/([A-Z])/g, ' $1') // insert a space before all caps
    .replace(/^./, (str) => str.toUpperCase()) // uppercase the first characters
}

// https://emailregex.com/
const emailPattern =
  /(?:[\d!#$%&'*+/=?^_`a-z{|}~-]+(?:\.[\d!#$%&'*+/=?^_`a-z{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f!\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/

export const validateEmail = (email: string): boolean => {
  return emailPattern.test(email)
}

const allEmailsPattern = new RegExp(emailPattern.source, 'g')
export const anonymizeEmails = (stringifiedData: string) => {
  return stringifiedData.replaceAll(allEmailsPattern, (email: string) => md5(email))
}

export const compareEmailDomains = (email: string, otherEmail: string): boolean => {
  const split = email.split('@')
  const otherSplit = otherEmail.split('@')

  if (split.length !== 2 || otherSplit.length !== 2) return false

  return split[1] === otherSplit[1]
}

export const enumToCapitalized = (str: string) => {
  return capitalize(str.toLowerCase()).replaceAll('_', ' ')
}

export const snakeCaseToCamelCase = (str: string) => {
  return str.toLowerCase().replaceAll(/([_-][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export const firstLetterToLowerCase = (str: string) => {
  return str.charAt(0).toLowerCase() + str.slice(1)
}

export const trimLines = (str: string): string => {
  return str
    .split('\n')
    .map((line) => line.trim())
    .join('\n')
}
