import * as React from 'react'
import { type EntityLikeMetaFragment, type ShareholderFragment } from '@graphqlTypes'
import { type DivProps, type StyleProps } from '@strise/react-utils'
import { cn, IconBusinessPerson, IconCompany, IconGlobe, IconPerson } from '@strise/ui-components'
import { extractIsGlobalEntity } from '@strise/app-shared'

const entityToIcon: {
  [key in EntityLikeMetaFragment['__typename'] | ShareholderFragment['__typename']]?: React.FC<StyleProps>
} = {
  Company: IconCompany,
  GlobalCompany: IconCompany,
  Person: IconBusinessPerson,
  GlobalPerson: IconBusinessPerson,
  PrivatePerson: IconPerson
}

const SecondaryIcon = ({
  className,
  entity,
  ...props
}: { entity: Pick<EntityLikeMetaFragment, '__typename'> | Pick<ShareholderFragment, '__typename'> } & StyleProps) => {
  const secondaryIconClassName = cn('absolute', className)
  const isGlobal = extractIsGlobalEntity(entity)

  if (!isGlobal) return null

  return <IconGlobe className={cn('text-primary-main', secondaryIconClassName)} {...props} />
}

const iconSizeClassNames = {
  sm: { primary: 'h-4 w-4', secondary: 'h-3 w-3  -bottom-[2px] -right-[2px]' },
  md: { primary: 'h-6 w-6', secondary: 'h-4 w-4  -bottom-1 -right-1' },
  lg: { primary: 'h-8 w-8', secondary: 'h-5 w-5  -bottom-2 -right-2' }
}

export interface EntityIconProps extends DivProps {
  entity: Pick<EntityLikeMetaFragment, '__typename'> | Pick<ShareholderFragment, '__typename'>
  size?: 'sm' | 'md' | 'lg'
}

export const EntityIcon = ({ className, entity, size = 'md', ...props }: EntityIconProps) => {
  const Icon = entityToIcon[entity.__typename]

  if (!Icon) return null

  return (
    <div className={className} {...props}>
      <div className='relative'>
        <Icon className={iconSizeClassNames[size].primary} />
        <SecondaryIcon entity={entity} className={iconSizeClassNames[size].secondary} />
      </div>
    </div>
  )
}
