import * as React from 'react'
import { useActiveContentView } from '@utils/viewsHooks'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { ActivityView } from './Activity/ActivityView'
import { PortfolioView } from './Portfolio/PortfolioView'
import { ReviewView } from './ReviewView'
import { ContentViews } from '@utils/urls'
import { GrowView } from '@views/Grow/GrowView'
import { NotificationsView } from './Notifications/NotificationsView'
import { HomeView } from '@views/Home/HomeView'

const contentViewToView: { [key in ContentViews]: React.FC } = {
  [ContentViews.Activity]: ActivityView,
  [ContentViews.Portfolio]: PortfolioView,
  [ContentViews.Review]: ReviewView,
  [ContentViews.Grow]: GrowView,
  [ContentViews.Notifications]: NotificationsView,
  [ContentViews.Home]: HomeView
}

export const ContentView = () => {
  const team = useTeam()
  const activeContentView = useActiveContentView()

  if (!activeContentView) return null

  const ActiveView = contentViewToView[activeContentView]

  // DO NOT remove `w-full` here. There is some sort of bug in PortfolioView that makes it grow in width indefinitely with out it
  return (
    <div className='w-full' key={[team.id, team.portfolioId].join('-')}>
      <ActiveView />
    </div>
  )
}
