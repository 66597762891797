import { cn, Typography } from '@strise/ui-components'
import { type FinancialFieldInfoFragment } from '@graphqlTypes'
import { FilledSparkLine } from '@strise/app-shared'
import { toReadableFigure } from '@utils/financials'
import { reverse } from 'lodash-es'
import * as React from 'react'
import { type DivProps } from '@strise/react-utils'

export const SparkLineFigure: React.FC<
  {
    fieldInfo: FinancialFieldInfoFragment | null
    figures: number[]
    visualFigures?: boolean
  } & DivProps
> = ({ className, fieldInfo, figures, visualFigures = false, ...props }) => {
  const oldestFigure = figures[0] ?? 0
  const newestFigure = figures[figures.length - 1] ?? 0

  const hasIncreased = oldestFigure >= newestFigure
  const positive = fieldInfo?.isExpense ? !hasIncreased : hasIncreased

  const colorClasses = {
    fill: positive ? 'fill-accent-green-shade-30' : 'fill-accent-pink-shade-20',
    border: positive ? 'border-accent-green-shade-30' : 'border-accent-pink-shade-40'
  }

  const formattedLabel = fieldInfo?.name ?? '-'

  // Need to copy to avoid read-only error
  const reversedFigures = reverse([...figures])

  return (
    <div
      className={cn(
        'relative z-[1] flex h-[56px] items-end justify-end overflow-hidden border px-2 py-1 text-right',
        colorClasses.border,
        className
      )}
      {...props}
    >
      <div className='min-w-0'>
        <Typography data-id='spark-line-figure' variant='h3' className='tabular-nums leading-6'>
          {visualFigures ? '-' : toReadableFigure(oldestFigure, fieldInfo)}
        </Typography>
        <Typography variant='aLabelSmall' component='div' title={formattedLabel} className='truncate'>
          {formattedLabel}
        </Typography>
      </div>
      <FilledSparkLine
        className={cn('absolute bottom-0 left-0 z-[-1] size-full', colorClasses.fill)}
        values={reversedFigures}
      />
    </div>
  )
}
