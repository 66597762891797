import { BigNumber } from 'bignumber.js'
export { BigNumber } from 'bignumber.js'

// Use "no-NO" locale to force spaces as delimiter
const numberFormat = new Intl.NumberFormat('no-NO', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
})
export const formatNumber = (n: number) => {
  return numberFormat.format(n)
}

const formatShortPercentage = (n: BigNumber) => {
  // if less than 0.1% show < 0.1%
  if (n < new BigNumber(0.1)) {
    return '< 0.1%'
  }
  return `${n.decimalPlaces(1, BigNumber.ROUND_DOWN).toString()}%`
}

export const formatShare = (num: BigNumber | number | string, opts = { style: 'percent' }) => {
  const share = num instanceof BigNumber ? num : new BigNumber(num)
  const percentStyle = opts.style === 'percent'

  // Round down
  // So for example 49.5 does not become 50
  return {
    short: percentStyle ? formatShortPercentage(share) : share.decimalPlaces(3, BigNumber.ROUND_DOWN).toString(),
    long: share.toString() + (percentStyle ? '%' : '')
  }
}
