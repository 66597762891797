import { type Duration } from '@strise/types'
import * as React from 'react'
import { getTimeFilterText } from './eventUtils'
import { capitalize } from 'lodash-es'
import { i18n } from '@lingui/core'
import { timeOptions } from '../Filter/FilterTime'
import { Select, type SelectProps } from '@strise/ui-components'

export const EventsTimespan: React.FC<
  {
    duration: Duration
    setDuration: (newDuration: Duration) => void
  } & Omit<SelectProps<Duration>, 'options'>
> = ({ duration, setDuration, ...props }) => {
  const options = React.useMemo(
    () =>
      timeOptions.map((option) => ({
        value: option,
        children: capitalize(i18n._(getTimeFilterText(option)))
      })),
    []
  )

  return (
    <Select
      options={options}
      value={duration}
      placeholder='Time span'
      onValueChange={setDuration}
      variant='ghost'
      data-track='Sidepanel / Events / Time span select'
      {...props}
    />
  )
}
