import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconInfoRound = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm0-2C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1Z'
      clipRule='evenodd'
    />
    <path fill='currentColor' d='M13 18h-2v-8h2v8ZM13 8h-2V6h2v2Z' />
  </svg>
))
IconInfoRound.displayName = 'IconInfoRound'
