import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconArrowRight = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M20.242 13.027H2v-2.054h18.242L21.224 12l-.982 1.027Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M24 12 13.49 23l-1.387-1.453 8.14-8.52.98-1.027-.98-1.027-8.14-8.52L13.49 1 24 12Z'
      clipRule='evenodd'
    />
  </svg>
))
IconArrowRight.displayName = 'IconArrowRight'
