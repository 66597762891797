import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconNotificationAll = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M11 3.082V1h2v2.082a6.124 6.124 0 0 1 4.94 4.556L21.28 21H13v2h-2v-2H2.717l3.34-13.362A6.124 6.124 0 0 1 11 3.082ZM4.092 7.572l-.994-.103-.995-.102V7.36l.001-.01.004-.025.01-.08a7.085 7.085 0 0 1 .285-1.133c.24-.702.671-1.63 1.438-2.526a8.564 8.564 0 0 1 1.317-1.245l1.212 1.59a6.564 6.564 0 0 0-1.009.955A5.754 5.754 0 0 0 4.296 6.76a5.09 5.09 0 0 0-.2.782 1.392 1.392 0 0 0-.004.032v-.002ZM4.092 7.574v.003-.002Z'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m20.9 7.47-.995.102v.002l-.004-.032a5.08 5.08 0 0 0-.2-.782 5.752 5.752 0 0 0-1.065-1.873 6.565 6.565 0 0 0-1.009-.955l1.213-1.59c.424.323.878.733 1.316 1.245a7.753 7.753 0 0 1 1.438 2.527 7.074 7.074 0 0 1 .285 1.133l.01.079.004.026v.009l.001.003v.002l-.994.103Zm-.995.104v.003-.003Z'
      clipRule='evenodd'
    />
  </svg>
))
IconNotificationAll.displayName = 'IconNotificationAll'
