import { cn, IconInfo, Typography } from '@strise/ui-components'
import { type AddressFragment, type PepInfoFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { formatDate, LanguageNationFlag } from '@strise/app-shared'
import { AccordionV2 } from '@strise/ui-components-legacy'
import { type DivProps } from '@strise/react-utils'
import { type PepRole } from '@strise/types'
import { extractAddress, hasAddressValues } from '@utils/address'
import { intervalToDuration, parseISO } from 'date-fns'
import * as React from 'react'
import { useState } from 'react'
import { extractPepInfoTitle } from '@components/PepAndSanctions/pepAndSanctionsUtils'
import { capitalize } from '@strise/ts-utils'

const RoleDurationText: React.FC<{
  role: PepRole
}> = ({ role }) => {
  const isPresent = !role.to

  const from = role.from ?? ''
  const to = role.to ?? ''

  if (!from && !to) return null

  const startDate = from ? formatDate(from, { day: false }) : t`Unknown`
  const endDate = isPresent ? t`Present` : formatDate(to, { day: false })
  const rolePeriod = `${startDate} ${endDate && `- ${endDate}`}`

  const parsedStartDate = from ? parseISO(from) : null
  const parsedEndDate = to ? parseISO(to) : new Date()

  const roleDuration = parsedStartDate ? intervalToDuration({ start: parsedStartDate, end: parsedEndDate }) : null

  const years = roleDuration?.years ? `${roleDuration.years} ${t`yr`}` : null
  const months = roleDuration?.months ? `${roleDuration.months} ${t`m`}` : null
  const days = roleDuration?.days ? `${roleDuration.days} ${t`d`}` : null

  const duration = [years, months, days].filter(Boolean).join(' ')

  return (
    <div className='flex flex-col items-end text-right'>
      <Typography>{rolePeriod}</Typography>
      <Typography className='text-text-secondary' variant='aLabelSmall'>
        {duration}
      </Typography>
    </div>
  )
}

export const PepPersonAddressInfo = ({ address }: { address: AddressFragment }) => {
  if (!hasAddressValues(address)) return null

  return (
    <div className='flex items-center'>
      <IconInfo className='mr-1 text-text-secondary' size='md' />
      <Typography className='text-text-secondary' variant='body2'>
        {extractAddress(address)}
      </Typography>
    </div>
  )
}

const PepRoles = ({ roles }: { roles: PepRole[] }) => {
  return (
    <div className='flex flex-col'>
      {roles.map((role, index) => {
        return (
          <div className='flex w-full items-center justify-between gap-1 py-2' key={index}>
            <div>
              <Typography variant='body1'>{role.roleCategory}</Typography>
              <div className='flex items-center gap-1'>
                <LanguageNationFlag language={role.countryOfJurisdiction} className='size-4' />
                <Typography className='text-text-secondary' variant='aLabelSmall'>
                  {role.localizedRoleDescription}
                </Typography>
              </div>
            </div>
            <RoleDurationText role={role} />
          </div>
        )
      })}
    </div>
  )
}

const PepRelation = ({
  expanded,
  relation
}: {
  expanded?: boolean
  relation: PepInfoFragment['relations'][number]
}) => {
  const [openSection, setOpenSection] = useState<boolean>(!!expanded)

  const handleToggle = () => setOpenSection(!openSection)

  if (!relation.relatedPep.pep) return null

  return (
    <div>
      <div className='flex w-full items-center justify-between pt-2'>
        <Typography variant='body1'>
          {relation.relationDescription} <strong>{relation.relatedPep.name}</strong>
        </Typography>
      </div>
      <PepPersonAddressInfo address={relation.relatedPep.address} />
      <AccordionV2
        open={openSection}
        toggleLabel={expanded ? null : t`More info`}
        label={
          <Typography variant='aLabelSmallBold'>
            <Trans>{relation.relatedPep.name} is a PEP due to roles:</Trans>
          </Typography>
        }
        toggle={expanded ? null : handleToggle}
      >
        <PepRoles roles={relation.relatedPep.roles} />
      </AccordionV2>
    </div>
  )
}

export const PepInfoContent = ({
  className,
  expanded,
  pepInfo,
  ...props
}: {
  expanded?: boolean
  pepInfo: PepInfoFragment | null
} & DivProps) => {
  const pepInfoTitle = extractPepInfoTitle(pepInfo)

  if (!pepInfo) return null
  if (!pepInfo.pep && !pepInfo.rca) return <Typography>{pepInfoTitle}</Typography>

  return (
    <div className={cn('w-full', className)} {...props}>
      {pepInfoTitle && <Typography className='text-text-secondary'>{capitalize(pepInfoTitle)}</Typography>}
      {pepInfo.pep && (
        <>
          {!pepInfo.roles.length && (
            <Typography>
              <Trans>Unknown roles</Trans>
            </Typography>
          )}

          <PepRoles roles={pepInfo.roles} />
        </>
      )}

      {pepInfo.rca && (
        <>
          {pepInfo.pep && (
            <Typography className='mt-4 text-text-secondary'>
              <Trans>Is an RCA due to relationships:</Trans>
            </Typography>
          )}

          {!pepInfo.relations.length && (
            <Typography>
              <Trans>Unknown relationships</Trans>
            </Typography>
          )}

          {pepInfo.relations.map((relation, index) => {
            return <PepRelation key={index} relation={relation} expanded={expanded} />
          })}
        </>
      )}
    </div>
  )
}
