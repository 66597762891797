import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconPlusSmall = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path fill='currentColor' fillRule='evenodd' d='M11 16V8h2v8h-2Z' clipRule='evenodd' />
    <path fill='currentColor' fillRule='evenodd' d='M8 11h8v2H8v-2Z' clipRule='evenodd' />
  </svg>
))
IconPlusSmall.displayName = 'IconPlusSmall'
