import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconInbox = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M1 11h3.414l1.222 1.222a9 9 0 0 0 12.728 0L19.586 11H23v12H1V11Zm2 2v8h18v-8h-.586l-.636.636c-4.296 4.296-11.26 4.296-15.556 0L3.586 13H3ZM11 10.5V1h2v9.5h-2Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m7.293 7.707 1.414-1.414L12 9.586l3.293-3.293 1.414 1.414L12 12.414 7.293 7.707Z'
      clipRule='evenodd'
    />
  </svg>
))
IconInbox.displayName = 'IconInbox'
