import { cva } from 'class-variance-authority'
import { baseCardClasses, baseCardHighlightClasses } from './common'

export const baseCardVariants = cva('group flex flex-col justify-between overflow-hidden rounded-xl', {
  variants: {
    variant: {
      contained: 'border border-gray-10',
      outlined: 'border-2'
    },
    rounded: {
      xl: 'rounded-xl',
      lg: 'rounded-lg',
      md: 'rounded-md',
      sm: 'rounded',
      none: 'rounded-none'
    },
    size: {
      main: 'h-[17rem] sm:w-96 md:w-64 lg:w-80 xl:h-[17rem] xl:w-96',
      md: 'h-72 w-96',
      sm: 'p-2',
      lg: 'p-4',
      xl: 'p-8'
    },
    highlight: {
      bottom: undefined,
      top: undefined,
      left: 'flex-row-reverse',
      right: 'flex-row'
    },
    highlightPalette: {
      primary: undefined,
      secondary: undefined,
      tertiary: undefined,
      danger: undefined
    },
    palette: baseCardClasses.palette
  },
  compoundVariants: [
    {
      variant: 'contained',
      highlight: 'bottom',
      highlightPalette: 'primary',
      className: baseCardClasses.contained.hover.primary
    },
    {
      highlight: 'right',
      highlightPalette: 'secondary',
      variant: 'contained',
      className: baseCardClasses.contained.highlight.secondary
    },
    {
      highlight: 'left',
      highlightPalette: 'secondary',
      variant: 'contained',
      className: baseCardClasses.contained.highlight.secondary
    }
  ],
  defaultVariants: {
    variant: 'outlined',
    size: 'md',
    palette: 'white',
    rounded: 'xl'
  }
})

export const highlightCardVariants = cva('flex border-none', {
  variants: {
    size: {
      sm: 'p-4',
      md: 'p-6',
      lg: 'p-8'
    },
    palette: {
      primary: 'bg-primary-main text-primary-contrastText',
      secondary: 'bg-secondary-main text-secondary-contrastText',
      tertiary: 'bg-tertiary-main text-tertiary-contrastText',
      danger: 'bg-semantic-danger-main text-white'
    },
    position: {
      bottom: 'items-center',
      top: 'items-center',
      left: 'bg-transparent',
      right: 'items-center bg-transparent'
    },
    justify: {
      start: 'justify-start',
      center: 'justify-center',
      end: 'justify-end',
      between: 'justify-between',
      around: 'justify-around',
      evenly: 'justify-evenly'
    }
  },
  compoundVariants: [
    {
      position: 'bottom',
      palette: 'primary',
      className: baseCardHighlightClasses.bottom.primary
    }
  ],
  defaultVariants: {
    position: 'bottom',
    size: 'sm',
    palette: 'primary',
    justify: 'between'
  }
})
