import { IconButton, IconPdf } from '@strise/ui-components'
import * as React from 'react'
import { toast } from '@strise/app-shared'
import { t } from '@lingui/macro'
import { type SidepanelCompanyFragment } from '@graphqlTypes'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useDownloadReview, useHasReviewFile } from '@utils/reviewUtils'
import { AuditRisk, ReviewStatus, TrackedActivityKind } from '@strise/types'
import { useCreateReviewMutation } from '@graphqlOperations'
import { ExportOwnerships } from '../../Ownerships/ExportOwnerships'
import { track } from '@utils/tracking'
import { getElementAsEncodedBase64 } from '@strise/react-utils'

export const SidepanelCreateReviewPdf: React.FC<{
  company: SidepanelCompanyFragment
}> = ({ company }) => {
  const features = useCurrentUserFeatures()
  const [loading, setLoading] = React.useState(false)
  const ownershipsReportRef = React.useRef<HTMLDivElement | null>(null)
  const [create, { data }] = useCreateReviewMutation()
  const { downloadReview } = useDownloadReview()

  // First the user clicks the Icon, we set loading to true - this will load the ExportOwnerships component
  const handleClick = () => {
    setLoading(true)
  }

  // When the ExportOwnerships component is loaded, we create the Review
  const createReport = async (hasCustomOwners: boolean) => {
    const organizationOverviewImage = await getElementAsEncodedBase64(ownershipsReportRef.current, {
      name: 'Review PDF ownership',
      id: company.id
    })

    create({
      variables: {
        entity: company.id,
        input: {
          reviewStatus: ReviewStatus.Approved,
          auditRisk: AuditRisk.None,
          organizationOverviewImage
        }
      }
    })

    track(TrackedActivityKind.StriseReviewCreated, {
      companyId: company.id,
      legalForm: company.legalForm,
      auditRisk: AuditRisk.None,
      reviewStatus: ReviewStatus.Approved,
      companyStatus: company.statusV2?.status,
      hasCustomOwnership: hasCustomOwners,
      origin: 'SIDEPANEL_HEADER'
    })
  }

  // After the Review is created, we need to wait for the PDF to be created
  const reviewHistory = data?.createReviewV2.company.reviews.edges ?? []
  const createdReview = reviewHistory[0]?.node
  const { hasFile } = useHasReviewFile(createdReview)

  // When the PDF is created, trigger download
  React.useEffect(() => {
    if (!hasFile) return
    if (!createdReview) return

    toast.success(features.EMAIL_REVIEW ? t`Review created. The report is sent to your email.` : t`Review created`)

    downloadReview(createdReview.id, () => setLoading(false)).then((r) => r)
  }, [hasFile])

  return (
    <>
      <IconButton
        className='size-10'
        variant='ghost'
        onClick={handleClick}
        disabled={!features.REVIEW_PDF}
        palette='tertiary'
        loading={loading}
        data-track='Sidepanel / Create risk assessment report'
        aria-label={t`Download PDF report`}
      >
        <IconPdf />
      </IconButton>
      {loading && (
        <ExportOwnerships
          entity={company}
          ref={ownershipsReportRef}
          onLoaded={createReport}
          includeFlags
          includeSubsidiaryCount
        />
      )}
    </>
  )
}
