import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconArrowLeftSmall = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M7.293 11.293 12 6.586 13.414 8l-2.293 2.293h5.586v2h-5.586l2.293 2.293L12 16l-4.707-4.707Z'
      clipRule='evenodd'
    />
  </svg>
))
IconArrowLeftSmall.displayName = 'IconArrowLeftSmall'
