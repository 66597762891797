import { Button } from '@strise/ui-components'
import React from 'react'
import { CompanyStatus } from '@strise/types'
import { SidepanelCard } from '../SidepanelCard'
import { t, Trans } from '@lingui/macro'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { SidepanelCreateReviewPdf } from './SidepanelCreateReviewPdf'
import { DataSourceTooltip } from '../../DataSourceTooltip'
import { SidepanelNotificationSettings } from '../SidepanelNotificationSettings'
import { SidepanelEntityKeyMetaDetails } from '../SidepanelEntityKeyMetaDetails'
import { useLocation, useNavigate } from 'react-router-dom'
import { SidepanelCopyEntityLink } from '../SidepanelCopyEntityLink'
import { SidepanelHasAccessAlert, SidepanelInactiveAlert, SidepanelSanctionsAlert } from '../SidepanelEntityAlerts'
import { type SidepanelCompanyFragment } from '@graphqlTypes'
import { useSelectCompaniesStatus } from '../../CompanyStatus/useSelectCompaniesStatus'
import { EntityLink } from '../../EntityLink/EntityLink'
import { ContentViews, SidepanelTab } from '@utils/urls'
import { hasEntityAccess } from '@utils/entity'
import { TestIDs } from '@utils/testIDs'
import { ConflictAlert } from '@components/Conflicts/ConflictAlert'
import { ConflictAlertType } from '@components/Conflicts/ConflictAlertType'
import { getBrowserGlobals } from '@strise/react-utils'

interface MetaCardContentProps {
  company: SidepanelCompanyFragment
}

export const HeaderCardContent: React.FC<MetaCardContentProps> = ({ company }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { loading: statusLoading, updateCompanyStatus } = useSelectCompaniesStatus([company])

  const features = useCurrentUserFeatures()

  const isoCountryCode = company.primaryCountry?.isoAlpha2Code.toLowerCase() ?? ''
  const companyOrgNum = company.organizationNumberV2?.value ?? ''

  const entityLink = `${getBrowserGlobals()?.window.location.origin || ''}/company/${company.isGlobalCompany ? company.id : `${isoCountryCode}/${companyOrgNum}`}`

  const handleAddToReview = async () => {
    await updateCompanyStatus(CompanyStatus.InQualification, company.statusV2?.status ?? null, true)
    navigate(`/${ContentViews.Review}${location.search}`)
  }

  return (
    <>
      <SidepanelHasAccessAlert entity={company} />

      <SidepanelInactiveAlert entity={company} />

      <div className='flex flex-col gap-2 p-4'>
        <div className='flex flex-wrap justify-between gap-2'>
          <EntityLink
            variant='h3'
            entity={company}
            iconWrapperProps={{
              className: 'p-2 pl-0 mr-1'
            }}
            wrapperProps={{ className: 'max-w-[350px] text-wrap' }}
            withIcon
            noTooltip
            noLink
            sanctionsLink
          />

          {hasEntityAccess(company) && (
            <div className='shrink-0'>
              <div className='flex flex-wrap items-center gap-2'>
                <DataSourceTooltip content={t`Copy company link to clipboard`}>
                  <div>
                    <SidepanelCopyEntityLink entityLink={entityLink} />
                  </div>
                </DataSourceTooltip>
                <SidepanelNotificationSettings notificationEntity={company} />
                {features.REVIEW_PDF && !company.isGlobalCompany && (
                  <DataSourceTooltip content={t`Download PDF report`}>
                    <div>
                      <SidepanelCreateReviewPdf company={company} />
                    </div>
                  </DataSourceTooltip>
                )}

                {features.REVIEW && !company.isGlobalCompany && (
                  <Button
                    variant='contained'
                    palette='primary'
                    className='px-4'
                    onClick={handleAddToReview}
                    loading={statusLoading}
                    data-track='Company / Add to Review'
                    data-id={TestIDs.SidePanel.Review.Add}
                  >
                    <Trans>Add to Review</Trans>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
        <ConflictAlert type={ConflictAlertType.SIDEPANEL} entity={company} className='-mx-4' />
        <div>
          <SidepanelEntityKeyMetaDetails entity={company} />
          {!company.riskSummary && <SidepanelSanctionsAlert className='mt-2' entityId={company.id} />}
        </div>
      </div>
    </>
  )
}

interface MetaCardProps {
  company: SidepanelCompanyFragment
}

export const SidepanelCompanyHeaderCard = React.forwardRef<HTMLDivElement, MetaCardProps>(
  ({ company, ...props }, ref) => {
    return (
      <SidepanelCard ref={ref} tab={SidepanelTab.Company}>
        <HeaderCardContent company={company} {...props} />
      </SidepanelCard>
    )
  }
)
