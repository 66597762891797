import { Typography } from '@strise/ui-components'
import { TableCell, TableRow } from '@strise/ui-components-legacy'
import * as React from 'react'

export const SidepanelFinancialTableRowTitle = ({ value }: { value: string }) => {
  return (
    <TableRow borderBottom={0}>
      <TableCell p={0} height={48}>
        <Typography className='pt-6' component='div' variant='aLabelBold'>
          {value && `${value}:`}
        </Typography>
      </TableCell>
    </TableRow>
  )
}
