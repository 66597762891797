import * as React from 'react'

import { type CompanyNetworkEntityPersonFragment } from '@graphqlTypes'
import { getBrowserGlobals } from '@strise/react-utils'
import { EntityIcon } from '@components/EntityIcon'
import { cn, Typography } from '@strise/ui-components'

interface AssociatedCompanyLinkProps {
  associatedCompany: CompanyNetworkEntityPersonFragment['associatedCompanies'][0]
  currentCompanyId: string
}

export const AssociatedCompanyLink = ({ associatedCompany, currentCompanyId }: AssociatedCompanyLinkProps) => {
  const scrollToCompanyInChart = () => {
    const node = getBrowserGlobals()?.document.querySelector(`[data-node-id="${associatedCompany.id}"]`)
    if (node) {
      node.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    }
    return
  }

  const isCurrentCompany = associatedCompany.id === currentCompanyId

  return (
    <div className='flex items-center'>
      <EntityIcon entity={associatedCompany} className="mr-1 block pl-0" size='sm' />
      <Typography
        className={cn(
          { 'text-text-link': !isCurrentCompany },
          { 'cursor-pointer': !isCurrentCompany },
          { 'hover:underline': !isCurrentCompany },
          'text-xs'
        )}
        onClick={isCurrentCompany ? undefined : scrollToCompanyInChart}
      >
        {associatedCompany.name}
      </Typography>
    </div>
  )
}
