import * as React from 'react'

export enum RenderContextType {
  Review = 'review',
  Sidepanel = 'sidepanel'
}

export const RenderContext = React.createContext<RenderContextType | null>(null)

export const useIsReview = () => {
  return React.useContext(RenderContext) === RenderContextType.Review
}

export const useIsSidepanel = () => {
  return React.useContext(RenderContext) === RenderContextType.Sidepanel
}
