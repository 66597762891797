import * as React from 'react'
import { type DivProps, useContext, useKeyboardShortcut } from '@strise/react-utils'
import { ApplicationSearchReturnType, TrackedActivityKind } from '@strise/types'
import { type EntityLikeMetaFragment } from '@graphqlTypes'
import {
  Button,
  type ButtonProps,
  cn,
  IconButton,
  IconCross,
  IconCrossSmall,
  IconSearch,
  Input,
  Skeleton,
  Typography
} from '@strise/ui-components'
import { t, Trans } from '@lingui/macro'
import { track } from '@utils/tracking'
import { useCombobox, type UseComboboxState, type UseComboboxStateChangeOptions } from 'downshift'
import { useDebounceValue } from 'usehooks-ts'
import { EntityIcon } from '../EntityIcon'
import { useNavigateEntity } from '../EntityLink/entityLinkUtils'
import { EntityMeta } from '../EntityMeta/EntityMeta'
import { SearchEntityKindFilter } from './SearchEntityKindFilter'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useIsMobile } from '@utils/hooks'
import { SearchEntityLocationFilter } from './SearchEntityLocationFilter'
import { EntityLocationFilterKind, useRecentlyVisitedEntities, useSearch } from '@components/Search/searchUtils'
import { LastVisited } from '@components/LastVisited'
import { RecentlyVisitedEntitiesContext } from '@contexts/RecentlyVisitedEntitiesContext/RecentlyVisitedEntitiesContext'
import { TestIDs } from '@utils/testIDs'
import { isObject } from 'lodash-es'
import { useTeamSettingsQuery } from '@graphqlOperations'

interface SearchResultItemProps extends ButtonProps {
  entity: EntityLikeMetaFragment
  selected: boolean
}

const SearchResultItem = React.forwardRef<HTMLButtonElement, SearchResultItemProps>(
  ({ children, className, entity, selected, ...props }, ref) => {
    const isMobile = useIsMobile()

    return (
      <Button
        data-track='Search / Click entity'
        className={cn(
          'mx-4 mb-2 mt-1 grid h-auto grid-cols-[36px_1fr_100px] justify-start rounded py-2 pl-2 pr-4 hover:bg-tertiary-main',
          !selected && 'bg-transparent',
          className
        )}
        palette='tertiary'
        asChild
        key={entity.id}
        variant='contained'
        ref={ref}
        {...props}
      >
        <div>
          <EntityIcon className='mr-3 shrink-0' entity={entity} />
          <EntityMeta className='overflow-hidden' entity={entity} variant='small' noTooltip={false} column={isMobile} />
          {children}
        </div>
      </Button>
    )
  }
)

const SearchItem = React.forwardRef(
  (
    {
      className,
      item,
      onRemove,
      selected,
      shouldEnrich,
      ...props
    }: {
      item: SearchItemEntity
      onRemove?: (id: string) => void
      selected: boolean
      shouldEnrich?: boolean
    } & ButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const handleRemove = (e: React.MouseEvent) => {
      e.stopPropagation()
      if (onRemove) onRemove(item.id)
    }

    return (
      <SearchResultItem className={className} entity={item} ref={ref} selected={selected} {...props}>
        <div className='justify-self-end text-right'>
          {selected && shouldEnrich ? (
            <IconButton
              className='ml-auto p-2 text-text-secondary'
              onClick={handleRemove}
              data-track='Button / Search / Remove recent search'
            >
              <IconCrossSmall />
            </IconButton>
          ) : (
            <LastVisited lastVisited={item.visitedAt} />
          )}
        </div>
      </SearchResultItem>
    )
  }
)

const SearchItemSkeleton = () => (
  <div className='mx-4 mb-2 mt-1 flex items-center py-2 pl-2 pr-4'>
    <Skeleton className='mr-3 size-6' />
    <div>
      <Skeleton className='my-2 h-5 w-52' />
      <div className='flex items-center gap-2'>
        <Skeleton className='size-4' variant='circle' />
        <Skeleton className='h-3 w-20' />
        <Skeleton className='size-4' variant='circle' />
        <Skeleton className='h-3 w-20' />
        <Skeleton className='size-4' variant='circle' />
        <Skeleton className='h-3 w-20' />
      </div>
    </div>
  </div>
)

const stateReducer = (
  state: UseComboboxState<EntityLikeMetaFragment | null>,
  actionAndChanges: UseComboboxStateChangeOptions<EntityLikeMetaFragment | null>
) => {
  const { changes, type } = actionAndChanges

  switch (type) {
    case useCombobox.stateChangeTypes.FunctionSelectItem: {
      // This makes sure `inputValue` is not set to "" when calling `selectItem(null)`
      // We are setting `selectItem(null)` after you click a search item.
      // But want to keep the input value.
      // basically reverts this addition:
      // https://github.com/downshift-js/downshift/pull/1055/files
      return {
        ...changes,
        inputValue: state.selectedItem ? state.inputValue : changes.inputValue
      }
    }
    case useCombobox.stateChangeTypes.InputKeyDownEnter: {
      return {
        ...changes,
        isOpen: true
      }
    }
    default: {
      return changes
    }
  }
}

export type SearchItemEntity = EntityLikeMetaFragment & { visitedAt?: number }

export const SearchBox = ({ className, focusInputRef, ...props }: { focusInputRef: boolean } & DivProps) => {
  const features = useCurrentUserFeatures()
  // Fallback to "All" in case we can't retrieve the entity types
  // This would provide an degradated experience to our customers instead of breaking the search
  const { data: { entityTypes = [ApplicationSearchReturnType.All] } = {} } = useTeamSettingsQuery({ variables: {} })

  const inputRef = React.useRef<null | HTMLInputElement>(null)
  const [inputValue, setInputValue] = React.useState('')
  const [debouncedInputValue] = useDebounceValue(inputValue, 500)
  const [entityLocationFilterState, setEntityLocationFilterState] = React.useState(
    features.GLOBAL_ENTITIES || features.CONTENT_UNITED_KINGDOM
      ? EntityLocationFilterKind.ALL
      : EntityLocationFilterKind.NORDICS
  )
  const [entityKindFilterState, setEntityKindFilterState] = React.useState(ApplicationSearchReturnType.All)
  const isMobile = useIsMobile()
  const navigateEntity = useNavigateEntity()

  const focusSearchBox = React.useCallback((e: KeyboardEvent) => {
    if (inputRef.current) {
      e.preventDefault()
      inputRef.current.focus()
    }
  }, [])

  useKeyboardShortcut('/', focusSearchBox)

  const { loading, searchItems } = useSearch(debouncedInputValue, [entityLocationFilterState], entityKindFilterState)
  const { recentlyVisitedEntitiesMap, removeRecentlyVisitedEntity } = useContext(RecentlyVisitedEntitiesContext)
  const { recentlyVisitedEntities, recentlyVisitedEntitiesLoading } = useRecentlyVisitedEntities(
    entityLocationFilterState,
    entityKindFilterState
  )

  const showRecent = !loading && !debouncedInputValue && !!recentlyVisitedEntities.length
  const showResults = debouncedInputValue && !!searchItems.length
  const showNoResults = !loading && !showResults && Boolean(debouncedInputValue)

  const itemsToShow: SearchItemEntity[] = debouncedInputValue
    ? searchItems.map((i) => {
        // if we have a recent search item with the same id, add the visitedAt date to it, if not set it not null
        const recentItem = recentlyVisitedEntitiesMap[i.id]
        return { ...i, visitedAt: recentItem?.visitedAt }
      })
    : recentlyVisitedEntities

  React.useEffect(() => {
    if (inputRef.current && focusInputRef) inputRef.current.focus()
  }, [focusInputRef])

  React.useEffect(() => {
    if (debouncedInputValue) {
      track(TrackedActivityKind.StriseSearchInput, {
        query: debouncedInputValue,
        results: itemsToShow.length
      })
    }
  }, [debouncedInputValue, itemsToShow.length])

  const handleSelectItem = (selectedItem: EntityLikeMetaFragment | null | undefined, openInNewTab?: boolean) => {
    if (!selectedItem) return

    const resultIndex = itemsToShow.findIndex((item) => item.id === selectedItem.id) + 1
    track(TrackedActivityKind.StriseSearchSelected, {
      [`${selectedItem.__typename.toLowerCase()}Id`]: selectedItem.id,
      resultIndex
    })

    navigateEntity(selectedItem.id, openInNewTab)
  }

  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    inputValue: formInputValue,
    isOpen,
    openMenu,
    selectItem
  } = useCombobox<EntityLikeMetaFragment | null>({
    items: itemsToShow,
    stateReducer,
    itemToString: (searchItem) => searchItem?.name || 'Unknown',
    onInputValueChange: ({ inputValue: newInputValue, isOpen: isInputOpen }) => {
      if (isInputOpen) {
        setInputValue(newInputValue || '')
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      handleSelectItem(selectedItem)

      if (inputRef.current) inputRef.current.blur()
      selectItem(null)
    }
  })

  const search = () => {
    setInputValue(formInputValue)
    openMenu()
  }

  const handleResetInput = () => {
    if (!inputRef.current) return

    setInputValue('')
    inputRef.current.focus()
  }

  const IconRight = (
    <>
      {inputValue && (
        <IconButton
          className='mr-2 rounded-full p-2'
          variant='contained'
          palette='tertiary'
          onClick={handleResetInput}
          data-track='Search / Reset'
        >
          <IconCross size='xs' />
        </IconButton>
      )}

      <IconSearch onClick={search} cursor='pointer' />
    </>
  )

  const openInNewTab = (item: EntityLikeMetaFragment, event: React.MouseEvent) => {
    if (event.ctrlKey || event.metaKey) {
      // @ts-expect-error
      event.nativeEvent.preventDownshiftDefault = true
      handleSelectItem(item, true)
    }
  }

  // TODO: Replace to retrieve this information from the backend.
  const placeholdersMap = {
    [ApplicationSearchReturnType.All]: t`Search by name or company ID`,
    [ApplicationSearchReturnType.Company]: t`Search by company’s name or ID`,
    [ApplicationSearchReturnType.Person]: t`Search by person's name`,
    [ApplicationSearchReturnType.PrivatePerson]: t`Search by private person's name`
  }

  const baseInputProps: unknown = getInputProps({
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value),
    placeholder: placeholdersMap[entityKindFilterState],
    onFocus: () => !!itemsToShow.length && openMenu(),
    ref: inputRef,
    value: inputValue
  })

  return (
    <div className={cn('flex h-full flex-1', className)} id='app-search' {...props}>
      <div className={cn('flex-1', isMobile ? '' : 'relative')}>
        <div className='flex h-full'>
          <SearchEntityKindFilter
            entityTypes={entityTypes}
            state={entityKindFilterState}
            setState={setEntityKindFilterState}
          />
          <Input
            className='size-full bg-background-paper'
            inputClassName='py-5 px-4'
            endIcon={IconRight}
            data-id={TestIDs.Search.root}
            variant={null}
            {...(isObject(baseInputProps) ? baseInputProps : {})}
          />
        </div>
        <div {...getMenuProps()}>
          {isOpen && (
            <div
              className='absolute left-0 right-[calc(0px-theme(height.header))] top-[theme(height.header)] max-h-[calc(100vh-theme(height.header))] max-w-[100vw] overflow-auto bg-white shadow-md'
              data-id={TestIDs.Search.result}
            >
              <SearchEntityLocationFilter state={entityLocationFilterState} setState={setEntityLocationFilterState} />
              <div>
                <Skeleton
                  isLoaded={!loading && !recentlyVisitedEntitiesLoading}
                  className={cn('mx-4 mt-3 w-fit text-text-secondary', showNoResults && 'mb-4')}
                  loadingOnlyClassName='w-24'
                >
                  <Typography component='div' variant='aLabelSmall'>
                    {showRecent && <Trans>Recently visited</Trans>}
                    {showResults && <Trans>Top results</Trans>}
                    {showNoResults && <Trans>No results</Trans>}
                  </Typography>
                </Skeleton>
                {itemsToShow.map((item, index) => {
                  return (
                    <SearchItem
                      key={item.id}
                      item={item}
                      selected={highlightedIndex === index}
                      onRemove={showRecent ? removeRecentlyVisitedEntity : undefined}
                      shouldEnrich={showRecent}
                      {...getItemProps({
                        item,
                        index,
                        onClick: (event) => openInNewTab(item, event)
                      })}
                    />
                  )
                })}
                {loading && <SearchItemSkeleton />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
