import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconBusinessPerson = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M15.643 11.768A5.99 5.99 0 0 0 18 7h3V5h-3V2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v3H3v2h3a5.99 5.99 0 0 0 2.357 4.768A9.002 9.002 0 0 0 3 20v3h18v-3a9.002 9.002 0 0 0-5.357-8.232ZM19 21v-1a7 7 0 1 0-14 0v1h14Zm-7-10a4 4 0 0 1-4-4h8a4 4 0 0 1-4 4Zm4.006-7.999V5H7.994V3.001h8.012Z'
      clipRule='evenodd'
    />
  </svg>
))
IconBusinessPerson.displayName = 'IconBusinessPerson'
