import * as React from 'react'
import { LoadingView } from '@views/LoadingView'
import { PreLoaderContext } from './PreLoaderContext'

export const PreLoaderContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [isHydrated, setIsHydrated] = React.useState<boolean>(false)

  // useEffect only runs on the client, so can be used to check if the client is hydrated
  React.useEffect(() => {
    setIsHydrated(true)
  }, [])

  const value = React.useMemo(
    () => ({
      loading,
      setLoading
    }),
    [loading]
  )

  if (!isHydrated) return null

  return (
    <PreLoaderContext.Provider value={value}>
      {loading && <LoadingView />}
      {children}
    </PreLoaderContext.Provider>
  )
}
