import { getBrowserGlobals } from '@strise/react-utils'

const changeEvent = 'systemOnChange'

export interface SystemChangeEventDetail {
  action?: string
  value: string
}

export const getNewSystemChangeEvent = (detail: SystemChangeEventDetail) => {
  const browserGlobals = getBrowserGlobals()
  if (browserGlobals) {
    return new browserGlobals.window.CustomEvent(changeEvent, { detail, bubbles: true })
  }
  return null
}

export const isSystemChangeEvent = (event: Event) => {
  const browserGlobals = getBrowserGlobals()

  if (!browserGlobals) return false

  const customEvent = browserGlobals.window.CustomEvent<SystemChangeEventDetail>
  return event instanceof customEvent
}
