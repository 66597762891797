import * as React from 'react'
import { useState } from 'react'
import { EventsCard } from '../SidepanelCards/SidepanelEventsCard'
import { Helmet } from 'react-helmet'
import { SidepanelPersonHeaderCard } from '../SidepanelCards/SidepanelPersonHeaderCard'
import { PersonDetailsCard } from '../SidepanelCards/SidepanelPersonDetailsCard'
import { SidepanelOwnershipsCard } from '../SidepanelCards/SidepanelOwnershipsCard'
import { type TabRefs } from '../SidepanelTabs/sidepanelTabsUtils'
import { SidepanelSanctionsCard } from '../SidepanelCards/SidepanelSanctionsCard'
import { SidepanelRolesCard } from '../SidepanelCards/SidepanelRolesCard'
import { SidepanelSimilarEntities } from '../SidepanelCards/SidepanelSimilarEntities'
import { type SidepanelPersonFragment } from '@graphqlTypes'
import { SidepanelTab } from '@utils/urls'
import { hasEntityAccess, isGraniteEntity } from '@utils/entity'

export const SidepanelPersonLayout: React.FC<{
  person: SidepanelPersonFragment
  tabRefs: TabRefs
}> = ({ person, tabRefs }) => {
  const [showOriginal, setShowOriginal] = useState(false)

  return (
    <>
      <Helmet>
        <title>{person.name}</title>
      </Helmet>
      <SidepanelPersonHeaderCard ref={tabRefs[SidepanelTab.Person]} person={person} />
      {hasEntityAccess(person) && (
        <>
          {!person.isGlobalPerson && !isGraniteEntity(person) && <SidepanelSimilarEntities />}
          <PersonDetailsCard person={person} />
          <SidepanelSanctionsCard entity={person} />
          <SidepanelRolesCard entity={person} ref={tabRefs[SidepanelTab.Roles]} sidepanelTab={SidepanelTab.Roles} />
          <SidepanelOwnershipsCard
            ref={tabRefs[SidepanelTab.Ownership]}
            sidepanelTab={SidepanelTab.Ownership}
            entity={person}
            showOriginal={showOriginal}
            setShowOriginal={setShowOriginal}
          />
          {!person.isGlobalPerson && <EventsCard ref={tabRefs[SidepanelTab.Events]} />}
        </>
      )}
    </>
  )
}
