import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconStriseDesign = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g clipPath='url(#IconStriseDesign_svg__a)'>
      <path fill='#00FA26' d='M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Z' />
      <path
        fill='currentColor'
        d='M12 20.938a8.624 8.624 0 0 1-6.325-2.627A8.58 8.58 0 0 1 3.062 12a8.58 8.58 0 0 1 2.613-6.311A8.621 8.621 0 0 1 12 3.062a8.622 8.622 0 0 1 6.325 2.627A8.62 8.62 0 0 1 20.937 12a8.621 8.621 0 0 1-2.612 6.311A8.623 8.623 0 0 1 12 20.938Zm0-1.142a7.52 7.52 0 0 0 5.5-2.296 7.467 7.467 0 0 0 2.282-5.5A7.52 7.52 0 0 0 17.5 6.5 7.521 7.521 0 0 0 12 4.204 7.549 7.549 0 0 0 6.5 6.5 7.521 7.521 0 0 0 4.204 12 7.466 7.466 0 0 0 6.5 17.5a7.549 7.549 0 0 0 5.5 2.296Zm-5.253-7.301h.702a4.249 4.249 0 0 0 4.55 3.63 4.249 4.249 0 0 0 4.552-3.685h.701a5.872 5.872 0 0 1-1.65 3.74A4.952 4.952 0 0 1 12 17.555 4.895 4.895 0 0 1 8.41 16.18a5.885 5.885 0 0 1-1.664-3.685Zm2.407-1.87a1.374 1.374 0 0 1-1.004-.412 1.375 1.375 0 0 1 0-1.994 1.444 1.444 0 0 1 2.021 0 1.374 1.374 0 0 1 .413 1.003 1.376 1.376 0 0 1-.427.99 1.376 1.376 0 0 1-1.003.413Zm5.692 0a1.375 1.375 0 0 1-1.004-.412 1.376 1.376 0 0 1 0-1.994 1.376 1.376 0 0 1 1.004-.413 1.374 1.374 0 0 1 1.004.413 1.374 1.374 0 0 1 0 1.994 1.375 1.375 0 0 1-1.004.412Z'
      />
    </g>
    <defs>
      <clipPath id='IconStriseDesign_svg__a'>
        <path fill='#fff' d='M1 1h22v22H1z' />
      </clipPath>
    </defs>
  </svg>
))
IconStriseDesign.displayName = 'IconStriseDesign'
