import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconNewspaper = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M7 1h16v22H7v-.07A7.001 7.001 0 0 1 1 16V7h6V1Zm0 8H3v7a5.002 5.002 0 0 0 4 4.9V9Zm2 12h12V3H9v18Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M20 17H10v-2h10v2ZM20 13H10v-2h10v2ZM20 9H8V7h12v2Z'
      clipRule='evenodd'
    />
  </svg>
))
IconNewspaper.displayName = 'IconNewspaper'
