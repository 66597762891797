import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconAvatar = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M16.8 21.9A11 11 0 0 0 23 12c0-6.075-4.925-11-11-11S1 5.925 1 12c0 4.188 2.34 7.83 5.786 9.688A10.152 10.152 0 0 0 16.8 21.9Zm2.095-4.115a9 9 0 1 0-13.79 0 7.008 7.008 0 0 1 3.644-4.986 5 5 0 1 1 6.502 0 7.008 7.008 0 0 1 3.644 4.986Zm-3.005 2.333c.384-.21.756-.455 1.11-.732V19a5 5 0 0 0-10 0v.417c.25.193.507.37.772.53A8.96 8.96 0 0 0 12 21a8.964 8.964 0 0 0 3.89-.882ZM12 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
      clipRule='evenodd'
    />
  </svg>
))
IconAvatar.displayName = 'IconAvatar'
