import { type BaseReviewCompanyFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { companyStatusModifiedByKindToTitle } from '@strise/app-shared'
import { IconInfo, LinkButton, Typography } from '@strise/ui-components'
import { CompanyStatusModifiedByKind } from '@strise/types'
import { getTitle } from '@utils/enum'
import { SettingsModalContext } from '@views/Settings/SettingsModalContextProvider'
import { SettingsTab } from '@views/Settings/UserSettings/components/SettingsTab'
import * as React from 'react'
import { SelectCompaniesStatus } from '../CompanyStatus/SelectCompaniesStatus'
import { TestIDs } from '@utils/testIDs'
import { useContext } from '@strise/react-utils'

const RemoveReviewCompanyStatusSelect = ({ company }: { company: BaseReviewCompanyFragment }) => {
  return (
    <SelectCompaniesStatus
      companies={[company]}
      removable={false}
      triggerIconVisibility='hover'
      triggerText={t`Remove from Review`}
      className='ml-auto rounded-br-xl border-l border-secondary-shade-15 px-5 py-2 hover:border-none hover:bg-secondary-shade-15'
      data-track-context='remove-from-review'
      data-id={TestIDs.Review.Card.RemoveFromReview.trigger}
      contentProps={{ 'data-id': TestIDs.Review.Card.RemoveFromReview.options }}
      ariaLabel={t`Remove from Review by selecting a different status`}
    />
  )
}

const CompanyAddedBy = ({ company }: { company: BaseReviewCompanyFragment }) => {
  const { setActiveTab, setIsSettingsOpen } = useContext(SettingsModalContext)
  if (!company.statusV2) return null
  if (company.statusV2.modifiedByKind === CompanyStatusModifiedByKind.User && !company.statusV2.modifiedBy) return null

  const addedByText =
    company.statusV2.modifiedByKind === CompanyStatusModifiedByKind.User ? (
      <>{company.statusV2.modifiedBy?.name}</>
    ) : (
      <LinkButton
        data-track='Review / Added by / Open team triggers settings'
        variant='ghost'
        onClick={() => {
          setActiveTab(SettingsTab.TEAM_TRIGGERS)
          setIsSettingsOpen(true)
        }}
        className='ml-1 h-4 pl-0 hover:underline'
      >
        <Typography variant='aLabelSmall'>
          {getTitle(companyStatusModifiedByKindToTitle[company.statusV2.modifiedByKind])}
        </Typography>
      </LinkButton>
    )

  return (
    <div className='flex items-center p-2'>
      <IconInfo />
      <Typography className='ml-2 flex items-center' variant='aLabelSmall'>
        <Trans>Added by</Trans> {addedByText}
      </Typography>
    </div>
  )
}

export const ReviewCardFooter = ({
  company,
  isReviewFinished
}: {
  company: BaseReviewCompanyFragment
  isReviewFinished: boolean
}) => {
  return (
    <div className='flex items-center justify-between rounded-b-[12px] border border-solid border-tertiary-shade-15 bg-tertiary-main'>
      <CompanyAddedBy company={company} />
      {!isReviewFinished && <RemoveReviewCompanyStatusSelect company={company} />}
    </div>
  )
}
