import { type Descendant } from 'slate'

export type DraftBlockType = 'unstyled' | 'unordered-list-item'

export interface RawDraftContentBlock {
  children?: RawDraftContentBlock[]
  data?: unknown
  depth: number
  entityRanges: unknown[]
  inlineStyleRanges?: InlineStyleRange[]
  key: string
  text: string
  type: DraftBlockType
}

export interface InlineStyleRange {
  length: number
  offset: number
  style: string
}

export interface RawDraftContentState {
  blocks: RawDraftContentBlock[]
}

export const convertFromRawDraftToSlate = (rawDraft: RawDraftContentState) => {
  const slateNodes = rawDraft.blocks.reduce<Descendant[]>((acc, block, i) => {
    const textNodes = getTextNodesFromBlock(block)
    if (block.type === 'unstyled') {
      return [
        ...acc,
        {
          type: 'paragraph' as const,
          children: textNodes
        }
      ]
    }
    // draftjs keeps list items in a flat structure, so we need to check if the previous block is a list item
    const prev = acc[i - 1]
    if (prev && 'type' in prev && prev.type === 'bulleted-list') {
      return [
        ...acc.slice(0, i - 1),
        {
          type: 'bulleted-list' as const,
          children: [
            ...prev.children,
            {
              type: 'list-item' as const,
              children: textNodes
            }
          ]
        }
      ]
    }
    return [
      ...acc,
      {
        type: 'bulleted-list' as const,
        children: [
          {
            type: 'list-item' as const,
            children: textNodes
          }
        ]
      }
    ]
  }, [])
  return slateNodes
}

const getTextNodesFromBlock = (block: RawDraftContentBlock) => {
  if (block.inlineStyleRanges && !!block.inlineStyleRanges.length) {
    const styledTextsIndexes = block.inlineStyleRanges.reduce<Array<[number, number]>>((acc, curr, _, ranges) => {
      const start = curr.offset
      const end = curr.offset + curr.length
      // avoid duplicates
      if (acc.some((range) => range[0] === start && range[1] === end)) {
        return acc
      }

      const overlapping = ranges.find(
        (range) => range.offset < end && range.offset > start && range.offset + range.length > start
      )
      if (overlapping) {
        return [...acc, [start, overlapping.offset]]
      }
      return [...acc, [start, end]]
    }, [])

    const unstyledTextIndexes = styledTextsIndexes.reduce<Array<[number, number]>>((acc, curr, index) => {
      if (!styledTextsIndexes.length) {
        return [[0, block.text.length]]
      }
      if (curr[0] === 0) {
        return acc
      }
      if (index === 0) {
        return [[0, curr[0]]]
      }
      if (index === styledTextsIndexes.length - 1) {
        return [...acc, [curr[1], block.text.length]]
      }
      // We check that we are not on index 0, so we can safely access the previous element
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const prev = styledTextsIndexes[index - 1]!
      if (prev[1] === curr[0]) {
        return acc
      }
      return [...acc, [prev[1], curr[0]]]
    }, [])
    const sortedByOffset = [...unstyledTextIndexes, ...styledTextsIndexes].sort((a, b) => a[0] - b[0])
    return sortedByOffset
      .map(([start, end]) => {
        const text = block.text.slice(start, end)
        const isStyleInRange = createStyleInRange(start, end - start, block.inlineStyleRanges)
        const isBold = isStyleInRange('BOLD')
        const isItalic = isStyleInRange('ITALIC')
        const isUnderlined = isStyleInRange('UNDERLINE')
        return {
          text,
          // Only include the style if it is true
          ...(isBold && { bold: isBold }),
          ...(isItalic && { italic: isItalic }),
          ...(isUnderlined && { underline: isUnderlined })
        }
      })
      .filter(({ text }) => text !== '')
  }
  return [{ text: block.text }]
}

const createStyleInRange = (offset: number, length: number, ranges?: InlineStyleRange[]) => (style: string) =>
  ranges?.some(
    (range) => range.style === style && range.offset <= offset && range.offset + range.length >= offset + length
  )
