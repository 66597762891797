import { type BaseEntityLikeFragment } from '@graphqlTypes'
import React from 'react'
import { useTransformedTable } from '@utils/apiTable/apiTableValueBaseUtils'
import { EntityMetaItem } from './EntityMetaItem'
import { extractFilteredTransformedApiTableRows } from '@utils/apiTable/apiTableUtils'
import { cn } from '@strise/ui-components'

export const EntityMetaItems = ({
  column,
  entity,
  maxItems,
  noTooltip
}: {
  column?: boolean
  entity: BaseEntityLikeFragment
  maxItems?: number
  noTooltip?: boolean
}) => {
  const transformedTable = useTransformedTable(
    entity,
    // @ts-expect-error Suppressing this because we've forced TableValueContentProps to use TableRowFragment
    'keyMeta' in entity ? entity.keyMeta : null
  )

  if (!transformedTable) return null

  const filteredRows = extractFilteredTransformedApiTableRows(transformedTable)

  if (!filteredRows.length) return null

  const renderedRows = maxItems ? filteredRows.slice(0, maxItems) : filteredRows

  return (
    <div className={cn('items-center', column ? 'block' : 'flex')}>
      {renderedRows.map((item, index) => {
        const isLast = index === filteredRows.length - 1
        return (
          <EntityMetaItem
            key={index}
            row={item}
            noTooltip={noTooltip}
            className={cn(column && isLast ? 'mb-0' : 'mb-1', !column && isLast ? 'mr-0' : 'mr-4')}
          />
        )
      })}
    </div>
  )
}
