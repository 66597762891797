import {
  type CompanyStickerConnectionEdge,
  type NotificationConnection,
  type PortfolioCompaniesArgs,
  type PortfolioCompanyConnection,
  type QueryListViewArgs,
  type QueryNotificationsArgs,
  type QueryTeamActivitiesArgs,
  type SimpleCompanyConnection,
  type StickerConnectionEdge,
  type TeamActivityConnection,
  type TeamSimpleUserConnection,
  type TeamSimpleUsersArgs
} from '@strise/types'

import { type ConflictsQuery } from '@graphqlTypes'

import { newCompaniesState } from '@state'
import { eventsCache } from '@components/Events/eventsCache'
import { type TypePolicies } from '@apollo/client'

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      listView: {
        keyArgs: false,
        // @ts-expect-error
        merge(
          existing: SimpleCompanyConnection | undefined,
          incoming: SimpleCompanyConnection | undefined,
          { args }: { args: QueryListViewArgs | null }
        ) {
          const offset: number | null = args?.page?.offset ?? null
          if (offset === null) return existing

          const newEdges = incoming?.edges ?? []

          if (offset === 0) {
            newCompaniesState([])
            return { ...incoming, edges: newEdges }
          }

          const newCompanies = newCompaniesState()
          const edges = [...(existing?.edges ?? [])]

          const updatedEdges = [
            ...edges.slice(0, offset + newCompanies.length),
            ...newEdges,
            ...edges.slice(offset + newCompanies.length + newEdges.length)
          ]

          return { ...incoming, edges: updatedEdges }
        }
      },
      notifications: {
        keyArgs: false,
        merge(
          existing: NotificationConnection | undefined,
          incoming: NotificationConnection | undefined,
          { args }: { args: QueryNotificationsArgs | null }
        ) {
          const offset = args?.offset ?? 0

          if (!incoming?.edges) {
            return { ...existing, ...incoming, edges: existing?.edges ?? [] }
          }

          if (offset === 0) {
            return { ...incoming, edges: incoming.edges }
          }

          return {
            ...existing,
            edges: [...(existing?.edges ?? []), ...incoming.edges]
          }
        }
      },
      teamActivities: {
        keyArgs: ['reminderStatus', 'filter', 'team', 'user'],
        // @ts-expect-error
        merge(
          existing: TeamActivityConnection | undefined,
          incoming: TeamActivityConnection | undefined,
          { args }: { args: QueryTeamActivitiesArgs | null }
        ) {
          const offset = args?.page.offset ?? 0

          if (offset === 0) {
            return { ...incoming, edges: incoming?.edges ?? [] }
          }

          return {
            ...existing,
            edges: [...(existing?.edges ?? []), ...(incoming?.edges ?? [])]
          }
        }
      }
    }
  },
  Company: {
    fields: {
      teamActivities: {
        keyArgs: ['reminderStatus', 'filter', 'team', 'id'],
        // @ts-expect-error
        merge(
          existing: TeamActivityConnection | undefined,
          incoming: TeamActivityConnection | undefined,
          { args }: { args: QueryTeamActivitiesArgs | null }
        ) {
          const offset = args?.page.offset ?? 0

          if (offset === 0) {
            return { ...incoming, edges: incoming?.edges ?? [] }
          }

          return {
            ...existing,
            edges: [...(existing?.edges ?? []), ...(incoming?.edges ?? [])]
          }
        }
      },
      events: eventsCache,
      conflicts: {
        keyArgs: ['team', 'resolved'],
        merge(_, incoming: ConflictsQuery) {
          // Always return the incoming conflicts list
          return incoming
        }
      }
    }
  },
  Team: {
    fields: {
      simpleUsers: {
        // Caching with `q` arg for now, so we don't have to map and filter out existing users when searching
        keyArgs: ['id', 'q'],
        merge(
          existing: TeamSimpleUserConnection | undefined,
          incoming: TeamSimpleUserConnection | undefined,
          { args }: { args: TeamSimpleUsersArgs | null }
        ) {
          const offset = args?.page?.offset ?? 0
          const existingEdges = existing?.edges ?? []
          const incomingEdges = incoming?.edges ?? []

          if (offset === 0) {
            return { ...incoming, edges: incomingEdges }
          }

          // If offset is less than the length of existing items, we need to check for overlap.
          if (offset <= existingEdges.length) {
            // Calculate how many items overlap
            const overlap = existingEdges.length - offset
            // Remove overlapping items from the incoming edges
            const newEdges = incomingEdges.slice(overlap)
            // Merge the non-overlapping incoming items with the existing ones
            return {
              ...existing,
              edges: [...existingEdges, ...newEdges]
            }
          }

          // If no overlap, simply concatenate the edges
          return {
            ...existing,
            edges: [...existingEdges, ...incomingEdges]
          }
        }
      }
    }
  },
  CounterParty: {
    keyFields: ['id', 'direction']
  },
  OwnershipChartNode: {
    // isLeaf can change between custom and official ownership charts. It's used to determine if a node can be connected to other nodes.
    keyFields: ['id', 'indirectShareValue', 'isLeaf']
  },
  Person: {
    fields: {
      events: eventsCache
    }
  },
  Location: {
    fields: {
      events: eventsCache
    }
  },
  Topic: {
    fields: {
      events: eventsCache
    }
  },
  Industry: {
    fields: {
      events: eventsCache
    }
  },
  Portfolio: {
    fields: {
      companies: {
        keyArgs: ['filter'],
        merge(
          existing: PortfolioCompanyConnection | undefined,
          incoming: PortfolioCompanyConnection | undefined,
          { args }: { args: PortfolioCompaniesArgs | null }
        ) {
          const offset = args?.offset ?? 0

          if (offset === 0) {
            return { ...incoming, edges: incoming?.edges ?? [] }
          }

          return {
            ...existing,
            edges: [...(existing?.edges ?? []), ...(incoming?.edges ?? [])]
          }
        }
      }
    }
  },
  CompanyStickerConnection: {
    keyFields: ['key'],
    fields: {
      edges(existing: CompanyStickerConnectionEdge[] | undefined, { canRead }) {
        return (existing || []).filter(({ node }) => canRead(node))
      }
    }
  },
  StickerConnection: {
    fields: {
      edges(existing: StickerConnectionEdge[] | undefined, { canRead }) {
        return (existing || []).filter(({ node }) => canRead(node))
      }
    }
  },
  Address: {
    merge: true
  }
}
