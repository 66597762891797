import { Skeleton } from '@strise/ui-components'
import * as React from 'react'

export const ActivityActiveMembersLoader = ({ size = 5 }) => (
  <>
    {Array.from({ length: size }, (_, idx) => (
      <div className='mb-4 flex items-center' key={idx}>
        <Skeleton className='mr-3 size-10' variant='circle' />
        <Skeleton className='h-5 w-32' />
      </div>
    ))}
  </>
)

export const ActivityUserStatsLoader = () => (
  <div className='flex w-full grow gap-x-2'>
    <Skeleton className='h-[90px] w-full' />
    <Skeleton className='h-[90px] w-full' />
  </div>
)
