import * as React from 'react'
import { useTrackUser } from '@utils/tracking'
import { ChatFallback } from './Chat'
import { useLoadChat } from '@utils/intercom'

export const IdentifyServices = ({ children }: { children: React.ReactNode }) => {
  useTrackUser()
  useLoadChat()

  return (
    <>
      {children}
      <ChatFallback />
    </>
  )
}
