import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconSkip = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M3 3.259 17.985 12 3 20.741V3.259Zm2 3.482V17.26L14.015 12 5 6.741ZM19 16V8h2v8h-2Z'
      clipRule='evenodd'
    />
  </svg>
))
IconSkip.displayName = 'IconSkip'
