import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconAvatarAdd = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M20.966 12.783a9 9 0 1 0-15.861 5.001A7.008 7.008 0 0 1 8.75 12.8a5 5 0 1 1 6.8-.277 6.02 6.02 0 0 0-2.183 1.667A5 5 0 0 0 7 19v.417c.25.193.507.37.772.53a8.96 8.96 0 0 0 5.011 1.02 6.03 6.03 0 0 0 1.643 1.853 10.156 10.156 0 0 1-7.64-1.132A10.998 10.998 0 0 1 1 12C1 5.925 5.925 1 12 1s11 4.925 11 11c0 .802-.086 1.583-.248 2.336a6.03 6.03 0 0 0-1.786-1.553ZM12 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
      clipRule='evenodd'
    />
    <path fill='currentColor' d='M19 17h3v2h-3v3h-2v-3h-3v-2h3v-3h2v3Z' />
  </svg>
))
IconAvatarAdd.displayName = 'IconAvatarAdd'
