import * as React from 'react'
import { type Node, type NodeProps, Handle, Position } from '@xyflow/react'
import { Button, cn, LoaderRound } from '@strise/ui-components'

export type ExpandSubsidiariesNodeType = Node<
  {
    numOwnerships: number
    onExpandSubsidiaries: (id: string, isExpanded: boolean) => Promise<void>
    subsidiaryId: string
  },
  'expandSubsidiaries'
>

interface ExpandSubsidiariesNodeprops extends NodeProps<ExpandSubsidiariesNodeType> {}

const handleClassName = '!min-h-0 !min-w-0 !size-0'

export const ExpandSubsidiariesNode = ({ data }: ExpandSubsidiariesNodeprops) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const toggleExpanded = async () => {
    const nextIsExpanded = !isExpanded
    setLoading(true)
    try {
      await data.onExpandSubsidiaries(data.subsidiaryId, nextIsExpanded)
    } finally {
      setLoading(false)
    }

    setIsExpanded(nextIsExpanded)
  }

  return (
    <>
      <Handle className={cn(handleClassName)} position={Position.Top} type='target' />
      <div className='flex items-center justify-center'>
        {loading ? (
          <LoaderRound palette='white' size='sm' />
        ) : (
          <Button
            variant='contained'
            className='relative size-8 rounded-full px-0 text-sm'
            palette='tertiary'
            onClick={toggleExpanded}
            data-track='Sidepanel / Ownerships / Expand subsidiaries'
          >
            {isExpanded ? '-' : `+${data.numOwnerships}`}
          </Button>
        )}
      </div>
      <Handle className={cn(handleClassName)} position={Position.Bottom} type='source' id='expandNodeBottomHandle' />
    </>
  )
}
