import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconAdverseFlag = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g clipPath='url(#IconAdverseFlag_svg__a)'>
      <path fill='#fff' d='M2 2h20v20H2z' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M3 1a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3Zm17.803 16.787L13.32 3.94c-.567-1.049-2.072-1.049-2.639 0L3.196 17.787A1.5 1.5 0 0 0 4.516 20h14.968a1.5 1.5 0 0 0 1.32-2.213ZM11 14.5V7h2v7.5h-2Zm0 3.5v-2h2v2h-2Z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='IconAdverseFlag_svg__a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
))
IconAdverseFlag.displayName = 'IconAdverseFlag'
