import { type TimespanFragment } from '@strise/app-shared/src/graphqlTypes'
import { t } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { parseISO } from 'date-fns'

const getToText = (isActive: boolean) => (isActive ? t`Now` : t`Unknown`)

export const extractTimespanDuration = (timespan: TimespanFragment, isActive: boolean) => {
  if (!timespan.from && !timespan.to) return null
  if (!timespan.to && !isActive) return ['·', t`Unknown`].join(' ')
  const yearString = timespan.yearsBetween ? `${timespan.yearsBetween}y` : null
  const monthString = timespan.monthsBetween ? `${timespan.monthsBetween}m` : null

  if (!yearString && !monthString) return null

  return [yearString, monthString].join(' ')
}
export const extractTimespanPeriod = (timespan: TimespanFragment, isActive: boolean) => {
  if (!timespan.from && !timespan.to) return ''

  const fromString = timespan.from ? formatDate(parseISO(timespan.from), { relative: false, day: false }) : t`N/A`
  const toString = timespan.to
    ? formatDate(parseISO(timespan.to), { relative: false, day: false })
    : getToText(isActive)

  return [fromString, toString].join(' - ')
}
