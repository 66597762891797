import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconLock = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path fill='currentColor' fillRule='evenodd' d='M2 10h20v12H2V10Zm2 2v8h16v-8H4Z' clipRule='evenodd' />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M5 12V8a7 7 0 0 1 14 0v4H5Zm2-2V8a5 5 0 0 1 10 0v2H7ZM11 18.5v-4.75h2v4.75h-2Z'
      clipRule='evenodd'
    />
  </svg>
))
IconLock.displayName = 'IconLock'
