import { type TableRowValueFragment } from '@graphqlTypes'
import { Timespan } from '@components/Timespan/Timespan'
import React from 'react'
import { type handleEditRowFn } from '@utils/apiTable/apiTableUtils'
import { CustomMeta } from '@utils/apiTable/CustomMeta'

export const RoleWithEntityForPerson = ({
  editMode,
  handleEdit,
  value
}: {
  editMode?: boolean
  handleEdit?: handleEditRowFn
  value: TableRowValueFragment & { __typename: 'TableRowValueRoleForPerson' }
}) => {
  return (
    <div className='flex w-full items-center justify-center'>
      <div className='w-1/2'>{value.roleMeta.roleTitle}</div>
      <div className='flex min-h-flags-chip w-1/2 items-center'>
        <Timespan timespan={value.roleMeta.period} isActive={value.roleMeta.isActive} />
      </div>
      <CustomMeta
        editMode={editMode}
        handleEdit={() => {
          handleEdit?.(value.roleEntity, {
            existingRole: true,
            type: 'EditRole',
            roleTitleId: value.roleMeta.roleTitleId,
            periodFrom: value.roleMeta.period.from,
            periodTo: value.roleMeta.period.to,
            comment: value.roleMeta.customMeta?.comment
          })
        }}
        customMeta={value.roleMeta.customMeta}
      />
    </div>
  )
}
