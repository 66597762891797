import * as React from 'react'
import { Box, type BoxProps } from './Box'
import { IconChevronDown, IconChevronRight } from '@strise/ui-components'

export interface AccordionProps extends BoxProps {
  children: React.ReactNode
  disabled?: boolean
  label?: string | React.ReactNode
  labelProps?: BoxProps
  open: boolean
  toggle?: (() => void) | null
}

export const Accordion = ({ children, disabled, label, labelProps, open, toggle, ...props }: AccordionProps) => {
  return (
    <Box
      border='1px solid'
      borderColor='secondary'
      bgcolor={disabled ? 'background.default' : 'transparent'}
      pb={2}
      onClick={!open && !disabled && toggle ? toggle : undefined}
      style={{ cursor: !open && !disabled && toggle ? 'pointer' : 'auto' }}
      {...props}
    >
      <Box
        py={2}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        onClick={open && !disabled && toggle ? toggle : undefined}
        style={{ cursor: open && !disabled && toggle ? 'pointer' : 'inherit' }}
        {...labelProps}
      >
        {label}
        {toggle && (open ? <IconChevronDown className='ml-4' /> : <IconChevronRight className='ml-4' />)}
      </Box>
      {open && children}
    </Box>
  )
}
