export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Base64EncodedFile: { input: string; output: string; }
  Base64EncodedImage: { input: string; output: string; }
  Cursor: { input: string; output: string; }
  ISODateTime: { input: string; output: string; }
  Long: { input: number; output: number; }
  OrganizationNumber: { input: string; output: string; }
};

/** An access token used to communicate with the Strise.ai APIs. */
export type AccessToken = {
  __typename: 'AccessToken';
  /** The timestamp representing when the token is expiring. This is seconds since epoch. */
  expires: Scalars['Int']['output'];
  /** The access token as a JWT. */
  token: Scalars['String']['output'];
};

export type ActivityStat = {
  __typename: 'ActivityStat';
  counts: Array<Scalars['Long']['output']>;
  percentageChange?: Maybe<Scalars['Float']['output']>;
  totalCount: Scalars['Long']['output'];
};

export type ActivityStats = {
  __typename: 'ActivityStats';
  completedTeamActivity: ActivityStat;
  createdTeamActivity: ActivityStat;
  prospect: ActivityStat;
  review: ActivityStat;
  search: ActivityStat;
};

export type ActivityStatsView = {
  __typename: 'ActivityStatsView';
  mostActiveUsers: Array<SimpleUser>;
  teamStats: ActivityStats;
  userStats: ActivityStats;
};

/** An object representing an address. */
export type Address = {
  __typename: 'Address';
  /** The address line of the address. */
  addressLine?: Maybe<Scalars['String']['output']>;
  /** The city of the address. */
  city?: Maybe<Scalars['String']['output']>;
  /** The country of the address. */
  country?: Maybe<Scalars['String']['output']>;
  /** The zip code of the address. */
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AdminDashboard = {
  __typename: 'AdminDashboard';
  customerCount: Array<Scalars['Long']['output']>;
  teamCount: Array<Scalars['Long']['output']>;
  userCount: Array<Scalars['Long']['output']>;
};

/** An object representing a flag. */
export type AdverseFlag = {
  __typename: 'AdverseFlag';
  /** The date for the flagging. */
  date: Scalars['ISODateTime']['output'];
  /** The entity that triggered this flag. */
  entity: EntityLike;
  /** The event that triggered this flag. */
  event?: Maybe<Event>;
  /** The id of the event that triggered the flag, or that was generated by the flag. */
  eventId?: Maybe<Scalars['ID']['output']>;
  /** The ID of this flag. */
  id: Scalars['ID']['output'];
  /** The reason for the flagging. */
  reason: Scalars['String']['output'];
  /** The severity of the flag. */
  severity: FlagSeverity;
  /** The topic of the flagging. */
  topic?: Maybe<EntityLike>;
};

export type AdverseFlagConnection = {
  __typename: 'AdverseFlagConnection';
  edges: Array<AdverseFlagConnectionEdge>;
};

export type AdverseFlagConnectionEdge = {
  __typename: 'AdverseFlagConnectionEdge';
  node: AdverseFlag;
};

/**
 *
 * An alias for an entity. These are used to determine the disambiguation strategy for the alias.
 * The origins indicate where the alias came from and who gave it its most recent label.
 *
 */
export type Alias = {
  __typename: 'Alias';
  /** Where the alias originated from. */
  aliasOrigin: Array<AliasOrigin>;
  /** The alias color. */
  color: AliasColor;
  /** Who gave this alias its current color color. */
  colorOrigin: Array<LabelOrigin>;
  /** Whether the alias is disabled. */
  disabled: Scalars['Boolean']['output'];
  /** If this alias is this entity's name. */
  isName: Scalars['Boolean']['output'];
  /** The alias value. */
  value: Scalars['String']['output'];
};

/** An enum representing supported languages. */
export enum AliasColor {
  /** Indicates that the alias should never be trusted. */
  Black = 'BLACK',
  /** Indicates that the alias is disabled. */
  Disabled = 'DISABLED',
  /** Indicates if the context should be used when disambiguating between choices. */
  Grey = 'GREY',
  /** Indicates if the alias should be trusted uncritically. */
  White = 'WHITE'
}

/**
 *
 * An alias for an entity. This contains three fields: `value`, `color`, and `disabled`. These
 * are used to determine the disambiguation strategy for the alias.
 *
 */
export type AliasInput = {
  /** The alias color. */
  color: AliasColor;
  /** Whether the alias is disabled. */
  disabled: Scalars['Boolean']['input'];
  /** The alias value. */
  value: Scalars['String']['input'];
};

export enum AliasOrigin {
  AliasCleaner = 'ALIAS_CLEANER',
  Atten81 = 'ATTEN81',
  CompanyUtils = 'COMPANY_UTILS',
  PersonUtils = 'PERSON_UTILS',
  Register = 'REGISTER',
  TopicEnrich = 'TOPIC_ENRICH',
  User = 'USER',
  Wikidata = 'WIKIDATA',
  Wikipedia = 'WIKIPEDIA'
}

export type AmsEventFeedbackInput = {
  entity: Scalars['ID']['input'];
  event: Scalars['ID']['input'];
  kind: AmsEventFeedbackKind;
};

export enum AmsEventFeedbackKind {
  IsAms = 'IS_AMS',
  IsNotAms = 'IS_NOT_AMS',
  None = 'NONE'
}

/** Annotation representing an Entity Mention in an Event */
export type Annotation = {
  __typename: 'Annotation';
  /** An Entity */
  annotated: EntityLike;
  /** Whether or not this annotation was made by a human or a robot. */
  annotator: Annotator;
  /** Relevance predictions for all verticals as well as direct topic relations. */
  detailedRelevance: DetailedRelevance;
  /** The extractor that asserted this Entity. */
  extractors: Array<Extractor>;
  /** Annotation ID. */
  id: Scalars['ID']['output'];
  /** Annotation length. */
  length?: Maybe<Scalars['Int']['output']>;
  /** Annotation offset. */
  offset?: Maybe<Offset>;
  /** The textual phrase. */
  phrase?: Maybe<Scalars['String']['output']>;
  /** Whether the given entity is relevant for this event. */
  relevance: EventRelevance;
};

export type AnnotationInput = {
  entity: Scalars['ID']['input'];
  offset: Scalars['Int']['input'];
  phrase: Scalars['String']['input'];
};

/** Annotators used to assert an entity in text. */
export enum Annotator {
  /** Annotated by both human and robot. */
  Both = 'BOTH',
  /** Annotated by a human. */
  Human = 'HUMAN',
  /** Annotated by a robot (e.g. one of our disambiguation engines). */
  Robot = 'ROBOT'
}

export type ApiClient = {
  __typename: 'ApiClient';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export type ApplicationSearchInput = {
  countries?: InputMaybe<Array<ContentLanguage>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  returnType?: InputMaybe<ApplicationSearchReturnType>;
  withGlobal?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The entity type returned for the search. */
export enum ApplicationSearchReturnType {
  /** Search returning both companies and persons . */
  All = 'ALL',
  /** Search returning companies. */
  Company = 'COMPANY',
  /** Search returning persons. */
  Person = 'PERSON',
  /** Search returning private persons. */
  PrivatePerson = 'PRIVATE_PERSON'
}

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type ApplicationSettings = {
  __typename: 'ApplicationSettings';
  /** Default display language. */
  displayLanguage?: Maybe<DisplayLanguage>;
  externalDepartment?: Maybe<Scalars['String']['output']>;
  externalRole?: Maybe<Scalars['String']['output']>;
  /** The number of financials to show on opportunities. */
  opportunityFinancialHighlightCount?: Maybe<OpportunityFinancialHighlightCount>;
  /** A set of financial number highlights used for opportunities. */
  opportunityFinancialHighlightsSelector?: Maybe<Array<Scalars['ID']['output']>>;
  positionKind?: Maybe<UserPositionKind>;
  /** A set of financial number highlights used in the side-panel. */
  sidePanelFinancialHighlightsSelector?: Maybe<Array<Scalars['ID']['output']>>;
  timeZone?: Maybe<Scalars['String']['output']>;
  useCaseKinds?: Maybe<Array<UserUseCaseKind>>;
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type ApplicationSettingsInput = {
  /** Default display language. */
  displayLanguage?: InputMaybe<DisplayLanguage>;
  externalDepartment?: InputMaybe<Scalars['String']['input']>;
  externalRole?: InputMaybe<Scalars['String']['input']>;
  /** The number of financials to show on opportunities. */
  opportunityFinancialHighlightCount?: InputMaybe<OpportunityFinancialHighlightCount>;
  /** A set of financial number highlights used for opportunities. */
  opportunityFinancialHighlightsSelector?: InputMaybe<Array<Scalars['ID']['input']>>;
  positionKind?: InputMaybe<UserPositionKind>;
  /** A set of financial number highlights used in the side-panel. */
  sidePanelFinancialHighlightsSelector?: InputMaybe<Array<Scalars['ID']['input']>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  useCaseKinds?: InputMaybe<Array<UserUseCaseKind>>;
};

/** An enum representing supported audit risks */
export enum AuditRisk {
  /** High. */
  High = 'HIGH',
  /** Low. */
  Low = 'LOW',
  /** Medium. */
  Medium = 'MEDIUM',
  /** None. */
  None = 'NONE'
}

export type BeneficialOwnerMeta = {
  __typename: 'BeneficialOwnerMeta';
  familyRelationships: Array<FamilyRelationship>;
  isAlternative: Scalars['Boolean']['output'];
  meta?: Maybe<FieldValuePair>;
  reason?: Maybe<Scalars['String']['output']>;
  votingPowerIncludingFamily?: Maybe<Scalars['Float']['output']>;
};

export enum BeneficialOwnerRelationshipKind {
  HasChild = 'HAS_CHILD',
  HasChildInLaw = 'HAS_CHILD_IN_LAW',
  HasParent = 'HAS_PARENT',
  HasSpouseOrPartner = 'HAS_SPOUSE_OR_PARTNER',
  SameAddress = 'SAME_ADDRESS',
  SameLastName = 'SAME_LAST_NAME'
}

/** A body offset. */
export type BodyOffset = Offset & {
  __typename: 'BodyOffset';
  /** Offset relative to the body. */
  offset: Scalars['Int']['output'];
};

export type BooleanReviewSetting = ReviewSettingLike & {
  __typename: 'BooleanReviewSetting';
  enabled: Scalars['Boolean']['output'];
  kind: ReviewSettingKind;
};

export type BusinessRoleInput = {
  period: TimespanInput;
  roleTitleId: Scalars['String']['input'];
};

export type ClientCredentials = {
  __typename: 'ClientCredentials';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export type ClientCredentialsInput = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
};

/** An object representing an company's collaterals. */
export type Collateral = {
  __typename: 'Collateral';
  /** The amount of the collateral. */
  amount: Scalars['Float']['output'];
  /** The creditor of the collateral. */
  creditor?: Maybe<EntityLike>;
  /** The name of the creditor. */
  creditorName?: Maybe<Scalars['String']['output']>;
  /** The currency of the collateral. */
  currency: Currency;
  /** The date of the collateral. */
  date: Scalars['ISODateTime']['output'];
  /** The description of collateral. */
  description: Scalars['String']['output'];
};

export type CollateralConnection = {
  __typename: 'CollateralConnection';
  edges: Array<CollateralConnectionEdge>;
};

export type CollateralConnectionEdge = {
  __typename: 'CollateralConnectionEdge';
  node: Collateral;
};

/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type Company = EntityLike & {
  __typename: 'Company';
  /** Access information for this entity. */
  access: EntityAccess;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  /** The assigned user for this company, if any. */
  assignee: CompanyUserConnection;
  /** The company's company branches */
  branches: CompanyBranchConnection;
  /** The last county court judgement report this team has ordered, if any. */
  ccjReport?: Maybe<CountyCourtJudgementsReport>;
  /** The company's collaterals */
  collaterals: CollateralConnection;
  companyInformation: Table;
  /** The company network for this company. */
  companyNetwork: CompanyNetwork;
  conflicts: Array<ConflictLike>;
  /** The coordinates of the headquarters of the company. */
  coordinates?: Maybe<Scalars['String']['output']>;
  /** The corporate purpose of the company. */
  corporatePurpose?: Maybe<Scalars['String']['output']>;
  counterParties: Array<CounterParty>;
  /** Whether this company's credit events are monitored by this team or not. */
  creditMonitoringEnabled: Scalars['Boolean']['output'];
  /** The last credit report this team has ordered, if any. */
  creditReport?: Maybe<CreditReport>;
  /** The predicted credit risk for this company. */
  creditRisk?: Maybe<CreditRisk>;
  dataSources: EntityDataSources;
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** The official facebook profile ID. The corresponding facebook page should be `https://facebook.com/{facebookProfileId}`. */
  facebookProfileId?: Maybe<Scalars['String']['output']>;
  financials?: Maybe<Financials>;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  /** The headquarters location of the company. */
  headquartersLocation?: Maybe<Location>;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  /** Determines whether the company is a global company. */
  isGlobalCompany: Scalars['Boolean']['output'];
  /** Determines whether the company is a slim company. */
  isSlim: Scalars['Boolean']['output'];
  keyMeta: Table;
  /** The legal for of this company. */
  legalForm?: Maybe<LegalForm>;
  /** The life status of this company. */
  lifeStatus: CompanyLifeStatus;
  /** The linkedin ID of the company */
  linkedInId?: Maybe<Scalars['String']['output']>;
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  numOwnerships: Scalars['Int']['output'];
  /** The organization number of the company along with link to official register. */
  organizationNumberV2?: Maybe<RichOrganizationNumber>;
  ownerChart: OwnershipChart;
  owners: Table;
  /** The ownerships of the company based on percentage owned. */
  ownerships: ShareholderConnection;
  primaryCountry?: Maybe<Country>;
  realEstates: Array<RealEstate>;
  /** The nationality where country is registered. */
  registerCountry?: Maybe<ContentLanguage>;
  /** The nationality where country is registered. */
  registerCountryV2?: Maybe<Location>;
  /** Registers for this entity. */
  registers: RegisterConnection;
  /** The reminders for this company. */
  reminders: ReminderConnection;
  /** The review on this company. */
  review?: Maybe<Review>;
  reviewTables: Array<Table>;
  /** The review on this company. */
  reviews: EntityReviewConnection;
  /** The risk summary for this company. */
  riskSummary?: Maybe<RiskSummary>;
  roleTableV2: Table;
  sanctionInfo: SanctionInfoV2;
  showOwnerChart: Scalars['Boolean']['output'];
  /** Similar companies, in terms of size, location, and industry. */
  similarCompanies: SimilarCompanyConnection;
  /** Similar global companies by name. */
  similarEntities: SearchResultConnection;
  status?: Maybe<CompanyStatus>;
  /** The modified date for the status of this company. */
  statusModified?: Maybe<Scalars['ISODateTime']['output']>;
  statusV2?: Maybe<TeamCompanyStatus>;
  /** The stickers for this company. */
  stickers: CompanyStickerConnection;
  /** The team activities on this company. */
  teamActivities: TeamActivityConnection;
  /** The official twitter username. The corresponding twitter page should be `https://twitter.com/{twitterUsername}`. */
  twitterUsername?: Maybe<Scalars['String']['output']>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyAssigneeArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyCcjReportArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyCompanyNetworkArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyConflictsArgs = {
  resolved: Scalars['Boolean']['input'];
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyCounterPartiesArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyCreditMonitoringEnabledArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyCreditReportArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyFinancialsArgs = {
  consolidated: Scalars['Boolean']['input'];
  size: Scalars['Int']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyNumOwnershipsArgs = {
  from?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyOwnerChartArgs = {
  ignoreCustomOwnerships?: InputMaybe<Scalars['Boolean']['input']>;
  team: Scalars['ID']['input'];
  threshold: Scalars['Float']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyOwnersArgs = {
  ignoreCustomOwnerships?: InputMaybe<Scalars['Boolean']['input']>;
  team: Scalars['ID']['input'];
  threshold: Scalars['Float']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyOwnershipsArgs = {
  from?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyRemindersArgs = {
  status?: InputMaybe<Array<ReminderStatus>>;
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyReviewArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyReviewTablesArgs = {
  excludeReviewSections?: InputMaybe<Array<TableSectionKind>>;
  includeReviewSections?: InputMaybe<Array<TableSectionKind>>;
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyReviewsArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyRiskSummaryArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyRoleTableV2Args = {
  filterActive?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreCustomRoles: Scalars['Boolean']['input'];
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanySanctionInfoArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanySimilarCompaniesArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: OpportunitySettingsInput;
  size?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanySimilarEntitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyStatusArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyStatusModifiedArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyStatusV2Args = {
  portfolio: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyStickersArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a company. From here you may currently access the base information, such as title, and stories related to the company. */
export type CompanyTeamActivitiesArgs = {
  filter?: InputMaybe<TeamActivityFilterKind>;
  page: TeamActivityPageInfoInput;
  reminderStatus?: InputMaybe<ReminderStatus>;
  team: Scalars['ID']['input'];
};

/** A representation of a company branch of a company. */
export type CompanyBranch = {
  __typename: 'CompanyBranch';
  activeFrom?: Maybe<Scalars['ISODateTime']['output']>;
  activeTo?: Maybe<Scalars['ISODateTime']['output']>;
  /** The address of the company branch. */
  address: Address;
  /** The business number of the company branch. */
  businessNumber: Scalars['String']['output'];
  /** The main industry for this company branch. */
  industry?: Maybe<Industry>;
  isActive: Scalars['Boolean']['output'];
  /** The name of the company branch. */
  name: Scalars['String']['output'];
  /** The number of the employees in the company branch. */
  numberOfEmployees?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Timespan>;
  url?: Maybe<Scalars['String']['output']>;
};

export type CompanyBranchConnection = {
  __typename: 'CompanyBranchConnection';
  edges: Array<CompanyBranchConnectionEdge>;
};

export type CompanyBranchConnectionEdge = {
  __typename: 'CompanyBranchConnectionEdge';
  node: CompanyBranch;
};

/** The company event kinds connected to this event. */
export enum CompanyEventKind {
  CompanyDataUpdate = 'COMPANY_DATA_UPDATE',
  CreditUpdate = 'CREDIT_UPDATE',
  Flag = 'FLAG',
  FlagInactive = 'FLAG_INACTIVE'
}

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type CompanyFilter = {
  __typename: 'CompanyFilter';
  /** Include only from these assignees. */
  assignees?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  /** Include only certain CEOs. */
  ceo?: Maybe<Array<Scalars['ID']['output']>>;
  /** Include only certain companies. */
  companies?: Maybe<Array<Scalars['ID']['output']>>;
  /** Only include companies for a given country. */
  countries?: Maybe<Array<ContentLanguage>>;
  /** Include only phone email. */
  email?: Maybe<Scalars['String']['output']>;
  /** Limiting the companies, based on number of employees. */
  employees?: Maybe<EmployeeFilter>;
  /** Filter companies based on equity ratio. */
  equityRatio?: Maybe<EquityRatioFilter>;
  /** Whether or not to include reminders in the inbox. */
  includeReminders: Scalars['Boolean']['output'];
  /** Whether or not to include subsidiaries in the inbox. */
  includeSubsidiaries: Scalars['Boolean']['output'];
  /** Include only from. */
  locations?: Maybe<Array<Scalars['ID']['output']>>;
  /** Limit the search by prefix. */
  name?: Maybe<Scalars['String']['output']>;
  /** Whether or not to only show companies with events. */
  onlyActive: Scalars['Boolean']['output'];
  /** Include only phone numbers. */
  phone?: Maybe<Scalars['String']['output']>;
  /** Filter companies based on revenue. */
  revenue?: Maybe<RevenueFilter>;
  /** Only include companies in ListView that have flags with these severities. */
  severity?: Maybe<Array<FlagSeverity>>;
  /** Filter the results by company status. */
  statuses?: Maybe<Array<CompanyStatus>>;
  /** Include only from these stickers. */
  stickers?: Maybe<Array<Scalars['ID']['output']>>;
  /** Only include companies with these websites. */
  websites?: Maybe<Array<Scalars['String']['output']>>;
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type CompanyFilterInput = {
  /** Include only from these assignees. */
  assignees?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include only certain CEOs. */
  ceo?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Include only certain companies. */
  companies?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Only include companies for a given country. */
  countries?: InputMaybe<Array<ContentLanguage>>;
  /** Include only phone email. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Limiting the companies, based on number of employees. */
  employees?: InputMaybe<EmployeeFilterInput>;
  /** Filter companies based on equity ratio. */
  equityRatio?: InputMaybe<EquityRatioFilterInput>;
  /** Whether or not to include reminders in the inbox. */
  includeReminders?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to include subsidiaries in the inbox. */
  includeSubsidiaries?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include only from. */
  locations?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Limit the search by prefix. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to only show companies with events. */
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include only phone numbers. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Filter companies based on revenue. */
  revenue?: InputMaybe<RevenueFilterInput>;
  /** Only include companies in ListView that have flags with these severities. */
  severity?: InputMaybe<Array<FlagSeverity>>;
  /** Filter the results by company status. */
  statuses?: InputMaybe<Array<CompanyStatus>>;
  /** Include only from these stickers. */
  stickers?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Only include companies with these websites. */
  websites?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The life status of a company. */
export type CompanyLifeStatus = {
  __typename: 'CompanyLifeStatus';
  /** The date of the status update. */
  date?: Maybe<Scalars['ISODateTime']['output']>;
  /** Whether or not this company is active. */
  isActive: Scalars['Boolean']['output'];
  /** The status of the company. */
  lifeStatus: Scalars['String']['output'];
};

/** A representation of a company network. */
export type CompanyNetwork = {
  __typename: 'CompanyNetwork';
  /** All persons in the company's network. */
  allPersons: Array<CompanyNetworkEntityPerson>;
  /** The company whose network this CompanyNetwork represents. */
  company: Company;
  /** All companies in the company's network. Does not include the company itself. */
  otherCompanies: Array<CompanyNetworkEntityCompany>;
};

/** A company in the company network. */
export type CompanyNetworkEntityCompany = {
  __typename: 'CompanyNetworkEntityCompany';
  /** The company in the company network. */
  company: Company;
  /** Sanction status of this company. */
  sanctions: SanctionInfoV2;
};

/** A person in a company network. */
export type CompanyNetworkEntityPerson = {
  __typename: 'CompanyNetworkEntityPerson';
  /** IDs of companies this person is associated with. */
  associatedCompanies: Array<Company>;
  /** PEP status of this person. */
  pep?: Maybe<PepInfo>;
  /** The person in the company network. */
  person: Person;
  /** Sanction status of this person. */
  sanctions: SanctionInfoV2;
};

/** An enum representing company status, such as prospect, customer, etc. */
export enum CompanyStatus {
  /** Company is your current customer. */
  Account = 'ACCOUNT',
  /** Company is a disqualified lead. */
  Disqualified = 'DISQUALIFIED',
  /** Company is watched. */
  Following = 'FOLLOWING',
  /** Company is currently ignored. */
  Ignored = 'IGNORED',
  /** Company is currently in qualification. */
  InQualification = 'IN_QUALIFICATION',
  /** Company is a prospect, or potential customer. */
  Prospect = 'PROSPECT',
  /** Company is a qualified lead. */
  Qualified = 'QUALIFIED'
}

/** An enum representing a company status trigger source. E.g. if the status was changed by a user, or an automated review trigger. */
export enum CompanyStatusModifiedByKind {
  ConnectedApp = 'CONNECTED_APP',
  ReviewTrigger = 'REVIEW_TRIGGER',
  User = 'USER'
}

export type CompanyStickerConnection = {
  __typename: 'CompanyStickerConnection';
  edges: Array<CompanyStickerConnectionEdge>;
  key: Scalars['ID']['output'];
};

export type CompanyStickerConnectionEdge = {
  __typename: 'CompanyStickerConnectionEdge';
  modified?: Maybe<Scalars['ISODateTime']['output']>;
  node: Sticker;
};

/** A Connection containing simple users. */
export type CompanyUserConnection = {
  __typename: 'CompanyUserConnection';
  edges: Array<CompanyUserConnectionEdge>;
};

/** An edge containing the user. */
export type CompanyUserConnectionEdge = {
  __typename: 'CompanyUserConnectionEdge';
  modified: Scalars['ISODateTime']['output'];
  /** The simple user. */
  node: SimpleUser;
};

export type ComparedCompanies = {
  __typename: 'ComparedCompanies';
  /** The compared financials. First value is average, second value is median. */
  financials: Array<FinancialsSection>;
};

/** A conflict between custom data and updated official data. */
export type ConflictLike = {
  /** Entity which has the conflict. */
  entity: EntityLike;
  id: Scalars['String']['output'];
  /** The reason for the conflict. */
  reason?: Maybe<ConflictReasonKind>;
  /** If the conflict is resolved or not */
  resolved: Scalars['Boolean']['output'];
  /** The user who resolved the conflict */
  resolvedBy?: Maybe<SimpleUser>;
  /** Which part of the conflict was selected when resolved. */
  resolvedSelection?: Maybe<RoleConflictSelectionKind>;
};

export enum ConflictReasonKind {
  /** Conflict due to entity added. */
  Added = 'ADDED',
  /** Conflict due to entity mutated. */
  Mutated = 'MUTATED',
  /** Conflict due to updates to the entity, but no direct match. */
  Pseudo = 'PSEUDO',
  /** Conflict due to entity removed. */
  Removed = 'REMOVED'
}

/** A representation of a Connected app. */
export type ConnectedApp = {
  __typename: 'ConnectedApp';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: ConnectedAppKind;
  organizationNumberFieldName?: Maybe<Scalars['String']['output']>;
  tagFieldName?: Maybe<Scalars['String']['output']>;
};

export type ConnectedAppConnection = {
  __typename: 'ConnectedAppConnection';
  edges: Array<ConnectedAppConnectionEdge>;
};

export type ConnectedAppConnectionEdge = {
  __typename: 'ConnectedAppConnectionEdge';
  node: ConnectedApp;
};

/** An enum representing a Connected app. */
export enum ConnectedAppKind {
  Hubspot = 'HUBSPOT',
  MicrosoftDynamics = 'MICROSOFT_DYNAMICS',
  Pipedrive = 'PIPEDRIVE',
  Salesforce = 'SALESFORCE'
}

/** An enum representing supported content languages. */
export enum ContentLanguage {
  Danish = 'DANISH',
  English = 'ENGLISH',
  Finnish = 'FINNISH',
  French = 'FRENCH',
  German = 'GERMAN',
  Norwegian = 'NORWEGIAN',
  Spanish = 'SPANISH',
  Swedish = 'SWEDISH'
}

/** Latitude and longitude of a location. */
export type Coordinate = {
  __typename: 'Coordinate';
  /** Coordinate latitude */
  latitude: Scalars['Float']['output'];
  /** Coordinate longitude */
  longitude: Scalars['Float']['output'];
};

/** Latitude and longitude of a location. */
export type CoordinateInput = {
  /** Coordinate latitude */
  latitude: Scalars['Float']['input'];
  /** Coordinate longitude */
  longitude: Scalars['Float']['input'];
};

export type CounterParty = {
  __typename: 'CounterParty';
  /** Free text comment */
  comment?: Maybe<Scalars['String']['output']>;
  /** Counter party entity object */
  counterPartyEntity: EntityLike;
  /** Free text comment */
  createdBy: SimpleUser;
  /** Direction of the counter party relationship */
  direction: CounterPartyDirection;
  /** Counter party relation ID */
  id: Scalars['ID']['output'];
  /** Free text comment */
  lastModified: Scalars['ISODateTime']['output'];
};

export enum CounterPartyDirection {
  /** Inbound counter party direction */
  Inbound = 'INBOUND',
  /** Outbound counter party direction */
  Outbound = 'OUTBOUND'
}

export type CounterPartyInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  counterPartyEntity: Scalars['ID']['input'];
  direction: CounterPartyDirection;
  entity: Scalars['ID']['input'];
};

export type Country = EntityLike & {
  __typename: 'Country';
  /** Access information for this entity. */
  access: EntityAccess;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  englishName: Scalars['String']['output'];
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  isoAlpha2Code: Scalars['String']['output'];
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  /** Registers for this entity. */
  registers: RegisterConnection;
};


export type CountryAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


export type CountryDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


export type CountryEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type CountryNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


export type CountryNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};

export enum CountryKind {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei Darussalam */
  Bn = 'BN',
  /** Bolivia (Plurinational State of) */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo, Democratic Republic of the */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom of Great Britain and Northern Ireland */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran (Islamic Republic of) */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Korea (Democratic People's Republic of) */
  Kp = 'KP',
  /** Korea, Republic of */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Lao People's Democratic Republic */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova, Republic of */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russian Federation */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syrian Arab Republic */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan, Province of China */
  Tw = 'TW',
  /** Tanzania, United Republic of */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela (Bolivarian Republic of) */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Viet Nam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Kosovo */
  Xk = 'XK',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** Yugoslavia */
  Yu = 'YU',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** A county court judgement. */
export type CountyCourtJudgement = {
  __typename: 'CountyCourtJudgement';
  /** The amount to be paid. */
  amount?: Maybe<Scalars['Int']['output']>;
  /** The case number for the court case. */
  caseNumber?: Maybe<Scalars['String']['output']>;
  /** The court that made the judgement. */
  court?: Maybe<Scalars['String']['output']>;
  /** The currency of the amount. */
  currencyCode?: Maybe<Scalars['String']['output']>;
  /** The date the case was registered. */
  date?: Maybe<Scalars['ISODateTime']['output']>;
  /** Shows what the company match was made on. */
  incomingRecordDetails?: Maybe<Scalars['String']['output']>;
  /** If the company match was an exact name match, or partial. */
  isExactMatch: Scalars['Boolean']['output'];
  /** If the amount is paid, this will be the date of the payment. */
  paidDate?: Maybe<Scalars['ISODateTime']['output']>;
  /** The status of the judgement. */
  status?: Maybe<Scalars['String']['output']>;
};

/** A county court judgement report. */
export type CountyCourtJudgementsReport = {
  __typename: 'CountyCourtJudgementsReport';
  /** When this report was created. */
  date: Scalars['ISODateTime']['output'];
  /** The list of county court judgements. */
  judgements: Array<CountyCourtJudgement>;
};

export type CreateEntityDispositionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  kind: EntityDispositionKind;
  status: EntityDispositionStatusKind;
};

/** The international rating */
export enum CreditRatingKind {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E'
}

/** A credit report for a company. */
export type CreditReport = {
  __typename: 'CreditReport';
  /** The average value of an invoice. */
  averageInvoiceValue?: Maybe<Scalars['Float']['output']>;
  /** The company's collateral. Only available for Norwegian ENKs. */
  collateral?: Maybe<CollateralConnection>;
  /** Approximate maximum contract capacity on a single contract over a 12-month period. */
  contractLimit?: Maybe<Scalars['Float']['output']>;
  /** The company's credit limit. */
  creditLimit?: Maybe<Scalars['Float']['output']>;
  /** Currency code for monetary values in this report. */
  currencyCode?: Maybe<Scalars['String']['output']>;
  /** When this report was created. */
  date: Scalars['ISODateTime']['output'];
  /** Number of days an average invoice is overdue. */
  dbt?: Maybe<Scalars['Float']['output']>;
  /** The ID of the report. */
  id: Scalars['ID']['output'];
  /** The company's income. Only available for Norwegian ENKs. */
  incomes?: Maybe<IncomeConnection>;
  /** The company's income. Only available for Norwegian ENKs. */
  incomesV2: CreditReportIncomeV2;
  /** Number of days an average invoice is overdue in the company's industry. */
  industryDBT?: Maybe<Scalars['Float']['output']>;
  /** The credit rating. */
  internationalRating: CreditRatingKind;
  /** Currency code for monetary values in this report. */
  paymentRemarks: PaymentRemarkConnection;
  /** Number of available invoices. */
  paymentsOnFile?: Maybe<Scalars['Int']['output']>;
  /** Number of invoices paid. */
  paymentsPaid?: Maybe<Scalars['Int']['output']>;
  /** Number of invoices still owing. */
  paymentsStillOwing?: Maybe<Scalars['Int']['output']>;
  /** The rating's underlying numerical value. No value means the company is not rated. */
  ratingValue?: Maybe<Scalars['Int']['output']>;
};

/** An object representing a given income from a credit report. */
export type CreditReportIncome = {
  __typename: 'CreditReportIncome';
  grossIncome?: Maybe<Scalars['Float']['output']>;
  incomeYear?: Maybe<Scalars['Int']['output']>;
  municipalityCode?: Maybe<Scalars['String']['output']>;
  netIncome?: Maybe<Scalars['Float']['output']>;
  netWealth?: Maybe<Scalars['Float']['output']>;
  sumTax?: Maybe<Scalars['Float']['output']>;
  taxClass?: Maybe<Scalars['String']['output']>;
};

/** An object representing a given income from a credit report. */
export type CreditReportIncomeV2 = {
  __typename: 'CreditReportIncomeV2';
  grossIncome: Array<Maybe<Scalars['Float']['output']>>;
  incomeYear: Array<Maybe<Scalars['Int']['output']>>;
  municipalityCode: Array<Maybe<Scalars['String']['output']>>;
  netIncome: Array<Maybe<Scalars['Float']['output']>>;
  netWealth: Array<Maybe<Scalars['Float']['output']>>;
  sumTax: Array<Maybe<Scalars['Float']['output']>>;
  taxClass: Array<Maybe<Scalars['String']['output']>>;
};

/** Predicted credit risk for a company. */
export enum CreditRisk {
  /** High credit risk. */
  High = 'HIGH',
  /** Low credit risk. */
  Low = 'LOW',
  /** Medium credit risk. */
  Medium = 'MEDIUM'
}

export type CrmField = {
  __typename: 'CrmField';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CrmSchema = {
  __typename: 'CrmSchema';
  /** The fields of the CRM schema */
  fields: Array<CrmField>;
};

/** The stats for syncing a CRM with Strise */
export type CrmSyncInfo = {
  __typename: 'CrmSyncInfo';
  /** The number of companies that can be exported from the Strise portfolio to the CRM portfolio. */
  addableToCrmCount: Scalars['Int']['output'];
  /** The number of companies that can be imported from the CRM portfolio to the Strise portfolio. */
  addableToStriseCount: Scalars['Int']['output'];
  /** The number of companies in the Strise portfolio that already exists in the CRM portfolio. */
  alreadyInCrmCount: Scalars['Int']['output'];
  /** The number of companies in the CRM portfolio that already exists in the Strise portfolio. */
  alreadyInStriseCount: Scalars['Int']['output'];
  /** The number of companies that doesn't have an organization number in the CRM portfolio. */
  crmMissingOrganizationNumberCount: Scalars['Int']['output'];
  /** The number of companies in the CRM portfolio. */
  crmPortfolioCount: Scalars['Int']['output'];
  /** The Strise user emails that doesn't exist in the CRM. */
  missingCrmUserEmails: Array<Scalars['String']['output']>;
  /** The CRM user emails that doesn't exist in Strise. */
  missingUserEmails: Array<Scalars['String']['output']>;
  /** The number of companies in the CRM portfolio that were not found by organization number in Strise. */
  notFoundCount: Scalars['Int']['output'];
  /** The number of companies in the Strise portfolio. */
  portfolioCount: Scalars['Int']['output'];
};

/** An enum representing supported currency. */
export enum Currency {
  /** Andorran peseta */
  Adp = 'ADP',
  /** United Arab Emirates dirham */
  Aed = 'AED',
  /** Afghan afghani */
  Afa = 'AFA',
  /** Afghan afghani */
  Afn = 'AFN',
  /** Old Albanian lek */
  Alk = 'ALK',
  /** Albanian lek */
  All = 'ALL',
  /** Armenian dram */
  Amd = 'AMD',
  /** Netherlands Antillean guilder */
  Ang = 'ANG',
  /** Angolan kwanza */
  Aoa = 'AOA',
  /** Angolan kwanza */
  Aok = 'AOK',
  /** Angolan novo kwanza */
  Aon = 'AON',
  /** Angolan kwanza reajustado */
  Aor = 'AOR',
  /** Argentine austral */
  Ara = 'ARA',
  /** Argentine peso argentino */
  Arp = 'ARP',
  /** Argentine peso */
  Ars = 'ARS',
  /** Argentine peso */
  Ary = 'ARY',
  /** Austrian schilling */
  Ats = 'ATS',
  /** Australian dollar */
  Aud = 'AUD',
  /** Aruban florin */
  Awg = 'AWG',
  /** Azerbaijan manat */
  Aym = 'AYM',
  /** Azerbaijani manat */
  Azm = 'AZM',
  /** Azerbaijani manat */
  Azn = 'AZN',
  /** Bosnia and Herzegovina dinar */
  Bad = 'BAD',
  /** Bosnia and Herzegovina convertible mark */
  Bam = 'BAM',
  /** Barbados dollar */
  Bbd = 'BBD',
  /** Bangladeshi taka */
  Bdt = 'BDT',
  /** Belgian convertible franc */
  Bec = 'BEC',
  /** Belgian franc */
  Bef = 'BEF',
  /** Belgian financial franc */
  Bel = 'BEL',
  /** Bulgarian lev */
  Bgj = 'BGJ',
  /** Bulgarian lev */
  Bgk = 'BGK',
  /** Bulgarian lev */
  Bgl = 'BGL',
  /** Bulgarian lev */
  Bgn = 'BGN',
  /** Bahraini dinar */
  Bhd = 'BHD',
  /** Burundian franc */
  Bif = 'BIF',
  /** Bermudian dollar */
  Bmd = 'BMD',
  /** Brunei dollar */
  Bnd = 'BND',
  /** Boliviano */
  Bob = 'BOB',
  /** Bolivian peso */
  Bop = 'BOP',
  /** Bolivian Mvdol */
  Bov = 'BOV',
  /** Brazilian cruzeiro */
  Brb = 'BRB',
  /** Brazilian cruzado */
  Brc = 'BRC',
  /** Brazilian cruzeiro */
  Bre = 'BRE',
  /** Brazilian real */
  Brl = 'BRL',
  /** Brazilian cruzado novo */
  Brn = 'BRN',
  /** Brazilian cruzeiro real */
  Brr = 'BRR',
  /** Bahamian dollar */
  Bsd = 'BSD',
  /** Bhutanese ngultrum */
  Btn = 'BTN',
  /** Burmese kyat */
  Buk = 'BUK',
  /** Botswana pula */
  Bwp = 'BWP',
  /** Belarusian ruble */
  Byb = 'BYB',
  /** Belarusian ruble */
  Byn = 'BYN',
  /** Belarusian ruble */
  Byr = 'BYR',
  /** Belize dollar */
  Bzd = 'BZD',
  /** Canadian dollar */
  Cad = 'CAD',
  /** Congolese franc */
  Cdf = 'CDF',
  /** WIR franc (for */
  Chc = 'CHC',
  /** WIR euro */
  Che = 'CHE',
  /** Swiss franc */
  Chf = 'CHF',
  /** WIR franc */
  Chw = 'CHW',
  /** Unidad de Fomento */
  Clf = 'CLF',
  /** Chilean peso */
  Clp = 'CLP',
  /** Chinese yuan */
  Cny = 'CNY',
  /** Colombian peso */
  Cop = 'COP',
  /** Unidad de Valor Real */
  Cou = 'COU',
  /** Costa Rican colon */
  Crc = 'CRC',
  /** Serbian dinar */
  Csd = 'CSD',
  /** Czechoslovak koruna */
  Csj = 'CSJ',
  /** Czechoslovak koruna */
  Csk = 'CSK',
  /** Cuban convertible peso */
  Cuc = 'CUC',
  /** Cuban peso */
  Cup = 'CUP',
  /** Cape Verdean escudo */
  Cve = 'CVE',
  /** Cypriot pound */
  Cyp = 'CYP',
  /** Czech koruna */
  Czk = 'CZK',
  /** East German mark */
  Ddm = 'DDM',
  /** German mark */
  Dem = 'DEM',
  /** Djiboutian franc */
  Djf = 'DJF',
  /** Danish krone */
  Dkk = 'DKK',
  /** Dominican peso */
  Dop = 'DOP',
  /** Algerian dinar */
  Dzd = 'DZD',
  /** Ecuadorian sucre */
  Ecs = 'ECS',
  /** Ecuador Unidad de Valor Constante */
  Ecv = 'ECV',
  /** Estonian kroon */
  Eek = 'EEK',
  /** Egyptian pound */
  Egp = 'EGP',
  /** Eritrean nakfa */
  Ern = 'ERN',
  /** Spanish peseta */
  Esa = 'ESA',
  /** Spanish peseta */
  Esb = 'ESB',
  /** Spanish peseta */
  Esp = 'ESP',
  /** Ethiopian birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Finnish markka */
  Fim = 'FIM',
  /** Fiji dollar */
  Fjd = 'FJD',
  /** Falkland Islands pound */
  Fkp = 'FKP',
  /** French franc */
  Frf = 'FRF',
  /** Pound sterling */
  Gbp = 'GBP',
  /** Georgian kuponi */
  Gek = 'GEK',
  /** Georgian lari */
  Gel = 'GEL',
  /** Ghanaian cedi */
  Ghc = 'GHC',
  /** Ghanaian cedi */
  Ghp = 'GHP',
  /** Ghanaian cedi */
  Ghs = 'GHS',
  /** Gibraltar pound */
  Gip = 'GIP',
  /** Gambian dalasi */
  Gmd = 'GMD',
  /** Guinean syli */
  Gne = 'GNE',
  /** Guinean franc */
  Gnf = 'GNF',
  /** Guinean syli */
  Gns = 'GNS',
  /** Equatorial Guinean ekwele */
  Gqe = 'GQE',
  /** Greek drachma */
  Grd = 'GRD',
  /** Guatemalan quetzal */
  Gtq = 'GTQ',
  /** Guinean escudo */
  Gwe = 'GWE',
  /** Guinea-Bissau peso */
  Gwp = 'GWP',
  /** Guyanese dollar */
  Gyd = 'GYD',
  /** Hong Kong dollar */
  Hkd = 'HKD',
  /** Honduran lempira */
  Hnl = 'HNL',
  /** Croatian dinar */
  Hrd = 'HRD',
  /** Croatian kuna */
  Hrk = 'HRK',
  /** Haitian gourde */
  Htg = 'HTG',
  /** Hungarian forint */
  Huf = 'HUF',
  /** Indonesian rupiah */
  Idr = 'IDR',
  /** Irish pound */
  Iep = 'IEP',
  /** Israeli lira */
  Ilp = 'ILP',
  /** Israeli shekel */
  Ilr = 'ILR',
  /** Israeli new shekel */
  Ils = 'ILS',
  /** Indian rupee */
  Inr = 'INR',
  /** Iraqi dinar */
  Iqd = 'IQD',
  /** Iranian rial */
  Irr = 'IRR',
  /** Icelandic króna */
  Isj = 'ISJ',
  /** Icelandic króna */
  Isk = 'ISK',
  /** Italian lira */
  Itl = 'ITL',
  /** Jamaican dollar */
  Jmd = 'JMD',
  /** Jordanian dinar */
  Jod = 'JOD',
  /** Japanese yen */
  Jpy = 'JPY',
  /** Kenyan shilling */
  Kes = 'KES',
  /** Kyrgyzstani som */
  Kgs = 'KGS',
  /** Cambodian riel */
  Khr = 'KHR',
  /** Comoro franc */
  Kmf = 'KMF',
  /** North Korean won */
  Kpw = 'KPW',
  /** South Korean won */
  Krw = 'KRW',
  /** Kuwaiti dinar */
  Kwd = 'KWD',
  /** Cayman Islands dollar */
  Kyd = 'KYD',
  /** Kazakhstani tenge */
  Kzt = 'KZT',
  /** Lao kip */
  Laj = 'LAJ',
  /** Lao kip */
  Lak = 'LAK',
  /** Lebanese pound */
  Lbp = 'LBP',
  /** Sri Lankan rupee */
  Lkr = 'LKR',
  /** Liberian dollar */
  Lrd = 'LRD',
  /** Lesotho loti */
  Lsl = 'LSL',
  /** Lesotho loti */
  Lsm = 'LSM',
  /** Lithuanian litas */
  Ltl = 'LTL',
  /** Lithuanian talonas */
  Ltt = 'LTT',
  /** Luxembourg convertible franc */
  Luc = 'LUC',
  /** Luxembourg franc */
  Luf = 'LUF',
  /** Luxembourg financial franc */
  Lul = 'LUL',
  /** Latvian lats */
  Lvl = 'LVL',
  /** Latvian ruble[44 */
  Lvr = 'LVR',
  /** Libyan dinar */
  Lyd = 'LYD',
  /** Moroccan dirham */
  Mad = 'MAD',
  /** Moldovan leu */
  Mdl = 'MDL',
  /** Malagasy ariary */
  Mga = 'MGA',
  /** Malagasy franc */
  Mgf = 'MGF',
  /** Macedonian denar */
  Mkd = 'MKD',
  /** Malian franc */
  Mlf = 'MLF',
  /** Myanmar kyat */
  Mmk = 'MMK',
  /** Mongolian tögrög */
  Mnt = 'MNT',
  /** Macanese pataca */
  Mop = 'MOP',
  /** Mauritanian ouguiya */
  Mro = 'MRO',
  /** Mauritanian ouguiya */
  Mru = 'MRU',
  /** Maltese lira */
  Mtl = 'MTL',
  /** Maltese pound */
  Mtp = 'MTP',
  /** Mauritian rupee */
  Mur = 'MUR',
  /** Maldivian rupee */
  Mvq = 'MVQ',
  /** Maldivian rufiyaa */
  Mvr = 'MVR',
  /** Malawian kwacha */
  Mwk = 'MWK',
  /** Mexican peso */
  Mxn = 'MXN',
  /** Mexican peso */
  Mxp = 'MXP',
  /** Mexican Unidad de Inversion */
  Mxv = 'MXV',
  /** Malaysian ringgit */
  Myr = 'MYR',
  /** Mozambican escudo */
  Mze = 'MZE',
  /** Mozambican metical */
  Mzm = 'MZM',
  /** Mozambican metical */
  Mzn = 'MZN',
  /** Namibian dollar */
  Nad = 'NAD',
  /** Nigerian naira */
  Ngn = 'NGN',
  /** Nicaraguan córdoba */
  Nic = 'NIC',
  /** Nicaraguan córdoba */
  Nio = 'NIO',
  /** Dutch guilder */
  Nlg = 'NLG',
  /** Norwegian krone */
  Nok = 'NOK',
  /** Nepalese rupee */
  Npr = 'NPR',
  /** New Zealand dollar */
  Nzd = 'NZD',
  /** Omani rial */
  Omr = 'OMR',
  /** Panamanian balboa */
  Pab = 'PAB',
  /** Peruvian old sol */
  Peh = 'PEH',
  /** Peruvian inti */
  Pei = 'PEI',
  /** Peruvian sol */
  Pen = 'PEN',
  /** Peruvian sol */
  Pes = 'PES',
  /** Papua New Guinean kina */
  Pgk = 'PGK',
  /** Philippine peso */
  Php = 'PHP',
  /** Pakistani rupee */
  Pkr = 'PKR',
  /** Polish złoty */
  Pln = 'PLN',
  /** Polish zloty */
  Plz = 'PLZ',
  /** Portuguese escudo */
  Pte = 'PTE',
  /** Paraguayan guaraní */
  Pyg = 'PYG',
  /** Qatari riyal */
  Qar = 'QAR',
  /** Rhodesian dollar */
  Rhd = 'RHD',
  /** Romanian leu (second */
  Rok = 'ROK',
  /** Romanian leu (third */
  Rol = 'ROL',
  /** Romanian leu */
  Ron = 'RON',
  /** Serbian dinar */
  Rsd = 'RSD',
  /** Russian ruble */
  Rub = 'RUB',
  /** Russian ruble */
  Rur = 'RUR',
  /** Rwandan franc */
  Rwf = 'RWF',
  /** Saudi riyal */
  Sar = 'SAR',
  /** Solomon Islands dollar */
  Sbd = 'SBD',
  /** Seychelles rupee */
  Scr = 'SCR',
  /** Sudanese dinar */
  Sdd = 'SDD',
  /** Sudanese pound */
  Sdg = 'SDG',
  /** Sudanese old pound */
  Sdp = 'SDP',
  /** Swedish krona */
  Sek = 'SEK',
  /** Singapore dollar */
  Sgd = 'SGD',
  /** Saint Helena pound */
  Shp = 'SHP',
  /** Slovenian tolar */
  Sit = 'SIT',
  /** Slovak koruna */
  Skk = 'SKK',
  /** Sierra Leonean leone */
  Sll = 'SLL',
  /** Somali shilling */
  Sos = 'SOS',
  /** Surinamese dollar */
  Srd = 'SRD',
  /** Surinamese guilder */
  Srg = 'SRG',
  /** South Sudanese pound */
  Ssp = 'SSP',
  /** São Tomé and Príncipe */
  Std = 'STD',
  /** São Tomé and Príncipe dobra */
  Stn = 'STN',
  /** Soviet Union ruble */
  Sur = 'SUR',
  /** Salvadoran colón */
  Svc = 'SVC',
  /** Syrian pound */
  Syp = 'SYP',
  /** Swazi lilangeni */
  Szl = 'SZL',
  /** Thai baht */
  Thb = 'THB',
  /** Tajikistani ruble */
  Tjr = 'TJR',
  /** Tajikistani somoni */
  Tjs = 'TJS',
  /** Turkmenistani manat */
  Tmm = 'TMM',
  /** Turkmenistan manat */
  Tmt = 'TMT',
  /** Tunisian dinar */
  Tnd = 'TND',
  /** Tongan paʻanga */
  Top = 'TOP',
  /** Portuguese Timorese escudo */
  Tpe = 'TPE',
  /** Turkish lira */
  Trl = 'TRL',
  /** Turkish lira */
  Try = 'TRY',
  /** Trinidad and Tobago dollar */
  Ttd = 'TTD',
  /** New Taiwan dollar */
  Twd = 'TWD',
  /** Tanzanian shilling */
  Tzs = 'TZS',
  /** Ukrainian hryvnia */
  Uah = 'UAH',
  /** Ukrainian karbovanets */
  Uak = 'UAK',
  /** Ugandan shilling */
  Ugs = 'UGS',
  /** Ugandan shilling */
  Ugx = 'UGX',
  /** Unknown currency */
  Unknown = 'UNKNOWN',
  /** United States dollar */
  Usd = 'USD',
  /** United States dollar */
  Usn = 'USN',
  /** United States dollar */
  Uss = 'USS',
  /** Uruguay Peso en Unidades Indexadas */
  Uyi = 'UYI',
  /** Uruguay new peso */
  Uyn = 'UYN',
  /** Uruguay peso */
  Uyp = 'UYP',
  /** Uruguayan peso */
  Uyu = 'UYU',
  /** Unidad previsional */
  Uyw = 'UYW',
  /** Uzbekistan som */
  Uzs = 'UZS',
  /** Venezuelan bolívar */
  Veb = 'VEB',
  /** Venezuelan bolívar digital */
  Ved = 'VED',
  /** Venezuelan bolívar fuerte */
  Vef = 'VEF',
  /** Venezuelan bolívar soberano */
  Ves = 'VES',
  /** Old Vietnamese dong */
  Vnc = 'VNC',
  /** Vietnamese đồng */
  Vnd = 'VND',
  /** Vanuatu vatu */
  Vuv = 'VUV',
  /** Samoan tala */
  Wst = 'WST',
  /** CFA franc BEAC */
  Xaf = 'XAF',
  /** Silver */
  Xag = 'XAG',
  /** Gold */
  Xau = 'XAU',
  /** European Composite Unit */
  Xba = 'XBA',
  /** European Monetary Unit */
  Xbb = 'XBB',
  /** European Unit of Account 9 */
  Xbc = 'XBC',
  /** European Unit of Account 17 */
  Xbd = 'XBD',
  /** East Caribbean dollar */
  Xcd = 'XCD',
  /** Special drawing rights */
  Xdr = 'XDR',
  /** European Currency Unit */
  Xeu = 'XEU',
  /** CFA franc BCEAO */
  Xof = 'XOF',
  /** Palladium */
  Xpd = 'XPD',
  /** CFP franc */
  Xpf = 'XPF',
  /** Platinum */
  Xpt = 'XPT',
  /** SUCRE */
  Xsu = 'XSU',
  /** ADB Unit of Account */
  Xua = 'XUA',
  /** South Yemeni dinar */
  Ydd = 'YDD',
  /** Yemeni rial */
  Yer = 'YER',
  /** Yugoslav dinar */
  Yud = 'YUD',
  /** Yugoslav dinar */
  Yum = 'YUM',
  /** Yugoslav dinar */
  Yun = 'YUN',
  /** South African financial rand */
  Zal = 'ZAL',
  /** South African rand */
  Zar = 'ZAR',
  /** Zambian kwacha */
  Zmk = 'ZMK',
  /** Zambian kwacha */
  Zmw = 'ZMW',
  /** Zairean new zaire */
  Zrn = 'ZRN',
  /** Zairean zaire */
  Zrz = 'ZRZ',
  /** Rhodesian dollar */
  Zwc = 'ZWC',
  /** Zimbabwean dollar */
  Zwl = 'ZWL'
}

export type CustomMeta = {
  __typename: 'CustomMeta';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISODateTime']['output'];
  createdBy?: Maybe<SimpleUser>;
  hidden: Scalars['Boolean']['output'];
  lastModifiedAt: Scalars['ISODateTime']['output'];
  lastModifiedBy?: Maybe<SimpleUser>;
};

/** A representation of an customer. */
export type Customer = {
  __typename: 'Customer';
  /** The number of companies for this customer. */
  companyCount: Scalars['Int']['output'];
  /** The date of creation. */
  created: Scalars['ISODateTime']['output'];
  /** The customer ID. */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  /** The customer name. */
  name: Scalars['String']['output'];
  /** The organization number of the customer. */
  organizationNumber?: Maybe<Scalars['String']['output']>;
  /** The number of portfolios for this customer. */
  portfolioCount: Scalars['Int']['output'];
  /** The portfolios for this customer. */
  portfolios: PortfolioConnection;
  /** The start date of the customer. */
  startAt?: Maybe<Scalars['ISODateTime']['output']>;
  /** The status of the customer. */
  status?: Maybe<CustomerStatus>;
  /** The date of the status expiration. */
  statusExpiration?: Maybe<Scalars['ISODateTime']['output']>;
  /** The date of last status modification. */
  statusModified?: Maybe<Scalars['ISODateTime']['output']>;
  /** The number of teams for this customer. */
  teamCount: Scalars['Int']['output'];
  /** The teams for this customer. */
  teams: TeamConnection;
  /** The number of users in this customer. */
  userCount: Scalars['Int']['output'];
};

export type CustomerConnection = {
  __typename: 'CustomerConnection';
  edges: Array<CustomerConnectionEdge>;
  nextPage?: Maybe<CustomerPageInfo>;
  previousPage?: Maybe<CustomerPageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type CustomerConnectionEdge = {
  __typename: 'CustomerConnectionEdge';
  node: Customer;
};

export type CustomerPageInfo = {
  __typename: 'CustomerPageInfo';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
};

export type CustomerPageInfoInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** An enum representing customer status. */
export enum CustomerStatus {
  /** Customer is currently in an active contract. */
  Active = 'ACTIVE',
  /** Customer is a demo. */
  Demo = 'DEMO',
  /** Customer is inactive, and probably not paying any bills. */
  Inactive = 'INACTIVE'
}

export type CustomersFilterInput = {
  createdAt?: InputMaybe<TimeFilterInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  startAt?: InputMaybe<TimeFilterInput>;
  status?: InputMaybe<CustomerStatus>;
  statusExpirationAt?: InputMaybe<TimeFilterInput>;
  statusModifiedAt?: InputMaybe<TimeFilterInput>;
};

export type DataSource = {
  __typename: 'DataSource';
  lastUpdatedAt?: Maybe<Scalars['ISODateTime']['output']>;
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** A default implementation of the [PropertyValue](#PropertyValue). If all else fails, this is the go-to property value. */
export type DefaultPropertyValue = PropertyValue & {
  __typename: 'DefaultPropertyValue';
  /** The value. */
  value: Scalars['String']['output'];
};

/** Specific type of relevance for this event in the context of an entity. */
export enum DetailedEventRelevance {
  /** Entity is deemed relevant for this event. */
  Base = 'BASE',
  /** Entity is not deemed relevant for this event. */
  NotRelevant = 'NOT_RELEVANT',
  /** Entity is deemed relevant for this event given the current user's preferences. */
  Personalized = 'PERSONALIZED'
}

/** All relevance predictions and the active topics. */
export type DetailedRelevance = {
  __typename: 'DetailedRelevance';
  /** The topics which this entity gets applied. */
  applied: Array<Topic>;
  /** The topics which this entity applies. */
  applies: Array<Topic>;
  /** The predicted relevance for this entity. */
  relevance: Array<DetailedEventRelevance>;
};

/** An enum representing supported display languages. */
export enum DisplayLanguage {
  /** Danish. */
  Danish = 'DANISH',
  /** English. */
  English = 'ENGLISH',
  /** Norwegian. */
  Norwegian = 'NORWEGIAN',
  /** Swedish. */
  Swedish = 'SWEDISH'
}

/** An enum representing a duration. */
export enum Duration {
  Day = 'DAY',
  FiveYear = 'FIVE_YEAR',
  FourYear = 'FOUR_YEAR',
  HalfYear = 'HALF_YEAR',
  Month = 'MONTH',
  ThreeYear = 'THREE_YEAR',
  TwoYear = 'TWO_YEAR',
  Week = 'WEEK',
  Year = 'YEAR'
}

/** An email. */
export type Email = {
  __typename: 'Email';
  /** The email. */
  email: Scalars['String']['output'];
};

/** Filter on number of employees. */
export type EmployeeFilter = {
  __typename: 'EmployeeFilter';
  /** The lower bound of the number of employees (inclusive). */
  from?: Maybe<Scalars['Int']['output']>;
  /** The upper bound of the number of employees (inclusive). */
  to?: Maybe<Scalars['Int']['output']>;
};

/** Filter on number of employees. */
export type EmployeeFilterInput = {
  /** The lower bound of the number of employees (inclusive). */
  from?: InputMaybe<Scalars['Int']['input']>;
  /** The upper bound of the number of employees (inclusive). */
  to?: InputMaybe<Scalars['Int']['input']>;
};

/** An Entity. */
export type Entity = EntityLike & {
  __typename: 'Entity';
  /** Access information for this entity. */
  access: EntityAccess;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  /** Entity properties. */
  properties: PropertyConnection;
  /** Registers for this entity. */
  registers: RegisterConnection;
  /** The relations to other entities. */
  relations: RelationConnection;
  /** The entity score. */
  score: Scalars['Float']['output'];
  /** Will contain the link to the corresponding wikidata page, if present. */
  wikidataUrl?: Maybe<Scalars['String']['output']>;
};


/** An Entity. */
export type EntityAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An Entity. */
export type EntityDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An Entity. */
export type EntityEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An Entity. */
export type EntityNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An Entity. */
export type EntityNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An Entity. */
export type EntityPropertiesArgs = {
  relationshipFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** An Entity. */
export type EntityRelationsArgs = {
  relationshipFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** An Entity. */
export type EntityScoreArgs = {
  language?: InputMaybe<ContentLanguage>;
};

/** Tells whether a user has access to view the entity or not */
export type EntityAccess = {
  __typename: 'EntityAccess';
  /** Describes why the user does not have access */
  description?: Maybe<Scalars['String']['output']>;
  hasAccess: Scalars['Boolean']['output'];
};

/** Annotation */
export type EntityAnnotation = {
  __typename: 'EntityAnnotation';
  entity?: Maybe<Entity>;
  /** All annotation extractors. */
  extractors: Array<Extractor>;
  /** Annotation length. */
  length: Scalars['Int']['output'];
  /** Annotation offset. */
  offset: Offset;
  /** Annotation phrase. */
  phrase: Scalars['String']['output'];
};

export type EntityDataSources = {
  __typename: 'EntityDataSources';
  baseInfo: Array<DataSource>;
  collateral: Array<DataSource>;
  credit: Array<DataSource>;
  financials: Array<DataSource>;
  peps: Array<DataSource>;
  realEstate: Array<DataSource>;
  roles: Array<DataSource>;
  sanctions: Array<DataSource>;
  shareholders: Array<DataSource>;
};

export type EntityDisposition = {
  __typename: 'EntityDisposition';
  created: Scalars['ISODateTime']['output'];
  createdBy?: Maybe<SimpleUser>;
  entity: Scalars['ID']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: EntityDispositionKind;
  status: EntityDispositionStatusKind;
  team: Scalars['ID']['output'];
};

export enum EntityDispositionKind {
  Pep = 'PEP',
  Sanction = 'SANCTION'
}

export enum EntityDispositionStatusKind {
  ConfirmedFalse = 'CONFIRMED_FALSE',
  ConfirmedTrue = 'CONFIRMED_TRUE',
  SuggestedFalse = 'SUGGESTED_FALSE',
  SuggestedTrue = 'SUGGESTED_TRUE'
}

/** An union type of external entity refs and entities. */
export type EntityLike = {
  /** Access information for this entity. */
  access: EntityAccess;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  /** Registers for this entity. */
  registers: RegisterConnection;
};


/** An union type of external entity refs and entities. */
export type EntityLikeAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An union type of external entity refs and entities. */
export type EntityLikeDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An union type of external entity refs and entities. */
export type EntityLikeEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An union type of external entity refs and entities. */
export type EntityLikeNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An union type of external entity refs and entities. */
export type EntityLikeNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};

export type EntityLikeConnection = {
  __typename: 'EntityLikeConnection';
  edges: Array<EntityLikeConnectionEdge>;
};

export type EntityLikeConnectionEdge = {
  __typename: 'EntityLikeConnectionEdge';
  /** An Entity */
  node: EntityLike;
};

/** A connection used for iterating through news. */
export type EntityLikeEventConnection = {
  __typename: 'EntityLikeEventConnection';
  /** Edges containing the news data. */
  edges: Array<EntityLikeEventConnectionEdge>;
  /** Information regarding the results. */
  pageInfo: PageInfo;
};

/** An edge containing relational information between company and event. */
export type EntityLikeEventConnectionEdge = {
  __typename: 'EntityLikeEventConnectionEdge';
  /** A cursor used for fetching of more data. */
  cursor: Scalars['Cursor']['output'];
  /** Which, if any, of the included companies where mentioned in this event. */
  includedCompaniesMentions: Array<Company>;
  /** The event. */
  node: Event;
  /** Indicates if the event is relevant to the company. */
  relevant: EventRelevance;
  /** A summary in the context of the company. */
  summary: Summary;
  /** The topics for this event. */
  topics: TopicConnection;
};

export type EntityReviewConnection = {
  __typename: 'EntityReviewConnection';
  edges: Array<EntityReviewConnectionEdge>;
};

/** An edge containing a review on an entity. */
export type EntityReviewConnectionEdge = {
  __typename: 'EntityReviewConnectionEdge';
  /** The review. */
  node: Review;
};

export type EntityReviewSetting = ReviewSettingLike & {
  __typename: 'EntityReviewSetting';
  enabled: Scalars['Boolean']['output'];
  kind: ReviewSettingKind;
  riskValues: Array<Scalars['ID']['output']>;
};

export type EntityRoleConnection = {
  __typename: 'EntityRoleConnection';
  edges: Array<EntityRoleConnectionEdge>;
};

export type EntityRoleConnectionEdge = {
  __typename: 'EntityRoleConnectionEdge';
  node: EntityLike;
  role: RoleMeta;
};

/** Selector for different types of Entities. */
export enum EntitySelector {
  /** Entity representing a Company. */
  Company = 'COMPANY',
  /** Entity as a base Entity. This is a 'catch all' if no more concrete match exists. */
  Entity = 'ENTITY',
  /** Entity representing a Global Company. */
  GlobalCompany = 'GLOBAL_COMPANY',
  /** Entity representing a Global Person. */
  GlobalPerson = 'GLOBAL_PERSON',
  /** Entity representing an industry, typically NACE. */
  Industry = 'INDUSTRY',
  /** Entity representing a Location. */
  Location = 'LOCATION',
  /** Entity representing a Person. */
  Person = 'PERSON',
  /** Entity representing a Product. */
  Product = 'PRODUCT',
  /** Entity representing a Topic. */
  Topic = 'TOPIC'
}

export type EntityWithCountry = {
  __typename: 'EntityWithCountry';
  country: CountryKind;
  entity: Scalars['ID']['output'];
};

export type EntityWithCountryInput = {
  country: CountryKind;
  entity: Scalars['ID']['input'];
};

export type EntityWithCountryReviewSetting = ReviewSettingLike & {
  __typename: 'EntityWithCountryReviewSetting';
  enabled: Scalars['Boolean']['output'];
  kind: ReviewSettingKind;
  riskValues: Array<EntityWithCountry>;
};

/** The lower bound of the number of equityRatios (inclusive). */
export type EquityRatioFilter = {
  __typename: 'EquityRatioFilter';
  /** The lower bound of the number of equityRatios (inclusive). */
  from?: Maybe<Scalars['Float']['output']>;
  /** The upper bound of the number of equityRatios (inclusive). */
  to?: Maybe<Scalars['Float']['output']>;
};

/** The lower bound of the number of equityRatios (inclusive). */
export type EquityRatioFilterInput = {
  /** The lower bound of the number of equityRatios (inclusive). */
  from?: InputMaybe<Scalars['Float']['input']>;
  /** The upper bound of the number of equityRatios (inclusive). */
  to?: InputMaybe<Scalars['Float']['input']>;
};

/** An item representing e.g. news articles. */
export type Event = {
  __typename: 'Event';
  /** The entities of the events. */
  annotations: EventAnnotationConnection;
  /** Similar event count. This is an approximated value which is faster than re-computing the similar events query time. */
  approximateSimilarCount: Scalars['Int']['output'];
  /** Authors of this event. */
  authors: Array<Scalars['String']['output']>;
  /** Whether or not the event is behind a paywall. */
  behindPaywall: Scalars['Boolean']['output'];
  /** The body-text of the event. */
  body: Scalars['String']['output'];
  /** The connected company event kinds to this event.  */
  companyEventKinds: Array<CompanyEventKind>;
  /** The entities of the events. */
  entities: EventEntityConnection;
  /** The severity of the flag that the event is based on. */
  flagSeverity?: Maybe<FlagSeverity>;
  /** Whether or not the event is fresh. */
  fresh: Scalars['Boolean']['output'];
  /** The event's hidden status for the given entity. */
  hiddenStatus: EventHidden;
  /** Event id. */
  id: Scalars['ID']['output'];
  /** Images associated with the event. */
  images: Array<Image>;
  /** Some kind of measurement of news impact. */
  impact: Scalars['Float']['output'];
  /** Indexed time. */
  indexed?: Maybe<Scalars['ISODateTime']['output']>;
  /** Is the event published by Strise */
  isPublishedByStrise: Scalars['Boolean']['output'];
  /** The news language. */
  language?: Maybe<ContentLanguage>;
  /** Whether or not the user has liked this event. */
  liked?: Maybe<LikeStatus>;
  /** Modified time. */
  modified?: Maybe<Scalars['ISODateTime']['output']>;
  /** Whether or not the event is an official announcement. */
  official: Scalars['Boolean']['output'];
  /** Event properties. */
  properties: PropertyConnection;
  /** Published time. */
  published: Scalars['ISODateTime']['output'];
  /** The publisher. */
  publisher: Scalars['String']['output'];
  /** The rank of the event. */
  rank: Scalars['Int']['output'];
  /** Whether or not the user has marked this event as read. */
  read: Scalars['Boolean']['output'];
  /** Whether the given entity is relevant for this event. */
  relevant: EventRelevance;
  /** Whether or not the event should redirect to the article. */
  shouldRedirect: Scalars['Boolean']['output'];
  /** Similar events. */
  similar: EventEventConnection;
  /** A summary of the article. */
  summary: Summary;
  /** Generate a tagged version of the URL. */
  taggedUrl: Scalars['String']['output'];
  /** Item title. */
  title: Scalars['String']['output'];
  /** Item URL. */
  url: Scalars['String']['output'];
};


/** An item representing e.g. news articles. */
export type EventAnnotationsArgs = {
  entityFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
  extractorFilters?: InputMaybe<Array<Extractor>>;
  portfolioFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
  showPredictedMentions?: InputMaybe<Scalars['Boolean']['input']>;
};


/** An item representing e.g. news articles. */
export type EventApproximateSimilarCountArgs = {
  clusterScore?: InputMaybe<Scalars['Float']['input']>;
};


/** An item representing e.g. news articles. */
export type EventEntitiesArgs = {
  entityFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
  portfolioFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** An item representing e.g. news articles. */
export type EventHiddenStatusArgs = {
  entity: Scalars['ID']['input'];
};


/** An item representing e.g. news articles. */
export type EventLikedArgs = {
  company: Scalars['ID']['input'];
  portfolio: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** An item representing e.g. news articles. */
export type EventPropertiesArgs = {
  relationshipFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** An item representing e.g. news articles. */
export type EventRelevantArgs = {
  company: Scalars['ID']['input'];
};


/** An item representing e.g. news articles. */
export type EventSimilarArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  clusterScore?: InputMaybe<Scalars['Float']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  onlyIndexed?: InputMaybe<Scalars['Boolean']['input']>;
};


/** An item representing e.g. news articles. */
export type EventSummaryArgs = {
  context?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** An item representing e.g. news articles. */
export type EventTaggedUrlArgs = {
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type EventAnnotationConnection = {
  __typename: 'EventAnnotationConnection';
  edges: Array<EventAnnotationConnectionEdge>;
};

export type EventAnnotationConnectionEdge = {
  __typename: 'EventAnnotationConnectionEdge';
  node: Annotation;
};

export type EventConnection = {
  __typename: 'EventConnection';
  edges: Array<EventConnectionEdge>;
  /** Information regarding the results */
  pageInfo: PageInfo;
};

export type EventConnectionEdge = {
  __typename: 'EventConnectionEdge';
  /** Cursor used for further pagination. */
  cursor: Scalars['Cursor']['output'];
  node: Event;
};

/** A connection between events and entities. */
export type EventEntityConnection = {
  __typename: 'EventEntityConnection';
  /** Accumulated annotations across entities. */
  annotations: Array<EntityAnnotation>;
  /** The edges of the connection. */
  edges: Array<EventEntityConnectionEdge>;
};

/** An edge on a news-entity connection. */
export type EventEntityConnectionEdge = {
  __typename: 'EventEntityConnectionEdge';
  /** The annotations. */
  annotations: Array<EntityAnnotation>;
  /** All annotation extractors. */
  extractors: Array<Extractor>;
  node: EntityLike;
  /** The relevance measure of the entity. */
  relevanceMeasure: Relevance;
  /** Whether the entity is deemed relevant to the event. */
  relevant: EventRelevance;
  /** Event summary in the context of the entity. */
  summary: Summary;
};

/** The connection between a event and a similar event. */
export type EventEventConnection = {
  __typename: 'EventEventConnection';
  /** The edges of the connection. */
  edges: Array<EventEventConnectionEdge>;
  /** Information regarding the results */
  pageInfo: PageInfo;
};

export type EventEventConnectionEdge = {
  __typename: 'EventEventConnectionEdge';
  /** Cursor used for further pagination. */
  cursor: Scalars['Cursor']['output'];
  node: Event;
  /** The similarity of the two connected events. */
  similarityScore: Scalars['Float']['output'];
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type EventFilter = {
  __typename: 'EventFilter';
  /** Include events about the given additional entities. An event will match either parent company or at least one of the entities provided. */
  additionalEntities?: Maybe<Array<Scalars['ID']['output']>>;
  /** Filter the results by topic. */
  dataset?: Maybe<Array<Scalars['ID']['output']>>;
  /** Exclude events. */
  eventsExclude?: Maybe<Array<Scalars['ID']['output']>>;
  /** Whether or not to return clustered news articles. */
  filterOverlapping: Scalars['Boolean']['output'];
  /** Filter events by their connected flag. */
  flaggedEventFilter?: Maybe<Array<Scalars['ID']['output']>>;
  /** Include generated company events */
  includeCompanyEvents: Scalars['Boolean']['output'];
  /** Whether or not to include subsidiaries in the inbox. */
  includeSubsidiaries: Scalars['Boolean']['output'];
  /** Filter the results by language. */
  languages?: Maybe<Array<ContentLanguage>>;
  /** Whether or not to only show only unread events. */
  onlyUnread: Scalars['Boolean']['output'];
  /** Whether or not to search in persistent storage. This is typically our tagged datasets. DO NOT MAKE PUBLIC. */
  persistent: Scalars['Boolean']['output'];
  /** The phrase to search for. */
  phrase?: Maybe<Scalars['String']['output']>;
  /** Filter the results by event relevance. */
  relevance?: Maybe<EventRelevance>;
  /** The strategy used to sort the output. */
  sort?: Maybe<EventSort>;
  /** Include only from. */
  sources?: Maybe<EventTypeFilter>;
  /** Filter by time. */
  time?: Maybe<TimeFilter>;
  /** The title to search for. */
  title?: Maybe<Scalars['String']['output']>;
  /** Filter the results by topic. */
  topics?: Maybe<Array<Scalars['ID']['output']>>;
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type EventFilterInput = {
  /** Include events about the given additional entities. An event will match either parent company or at least one of the entities provided. */
  additionalEntities?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter the results by topic. */
  dataset?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Exclude events. */
  eventsExclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Whether or not to return clustered news articles. */
  filterOverlapping?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter events by their connected flag. */
  flaggedEventFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Include generated company events */
  includeCompanyEvents?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to include subsidiaries in the inbox. */
  includeSubsidiaries?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter the results by language. */
  languages?: InputMaybe<Array<ContentLanguage>>;
  /** Whether or not to only show only unread events. */
  onlyUnread?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to search in persistent storage. This is typically our tagged datasets. DO NOT MAKE PUBLIC. */
  persistent?: InputMaybe<Scalars['Boolean']['input']>;
  /** The phrase to search for. */
  phrase?: InputMaybe<Scalars['String']['input']>;
  /** Filter the results by event relevance. */
  relevance?: InputMaybe<EventRelevance>;
  /** The strategy used to sort the output. */
  sort?: InputMaybe<EventSort>;
  /** Include only from. */
  sources?: InputMaybe<EventTypeFilterInput>;
  /** Filter by time. */
  time?: InputMaybe<TimeFilterInput>;
  /** The title to search for. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter the results by topic. */
  topics?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** An enum representing whether or not an event is hidden. */
export enum EventHidden {
  /** Event is hidden for a specified entity. */
  Hidden = 'HIDDEN',
  /** Event is shown for a specified entity. */
  Shown = 'SHOWN'
}

/** Predicted relevance for event in the context of an entity. */
export enum EventRelevance {
  /** Entity is not deemed relevant for this event. */
  NotRelevant = 'NOT_RELEVANT',
  /** The entity is deemed relevant for this event. */
  Relevant = 'RELEVANT'
}

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type EventSettings = {
  __typename: 'EventSettings';
  /** A cursor used to continue iteration. */
  after?: Maybe<Scalars['Cursor']['output']>;
  /** Filter by time. */
  events: EventFilter;
  /** The number of items to fetch. */
  first: Scalars['Int']['output'];
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type EventSettingsInput = {
  /** A cursor used to continue iteration. */
  after?: InputMaybe<Scalars['Cursor']['input']>;
  /** Filter by time. */
  events: EventFilterInput;
  /** The number of items to fetch. */
  first?: InputMaybe<Scalars['Int']['input']>;
};

/** An enum representing supported EventSorts. */
export enum EventSort {
  /** Sort events by index time. */
  Indexed = 'INDEXED',
  /** Sort events by modified time. */
  Modified = 'MODIFIED',
  /** Sort events randomly. */
  Random = 'RANDOM',
  /** Sort events by when they are published, sorted by recency. */
  Recency = 'RECENCY',
  /** Sort events by impact score. */
  Relevance = 'RELEVANCE'
}

/** An enum representing supported source categories */
export enum EventType {
  /** Public announcements. */
  Announcements = 'ANNOUNCEMENTS',
  CompanyUpdates = 'COMPANY_UPDATES',
  /** Documentation, logging etc. */
  Correspondence = 'CORRESPONDENCE',
  /** Documentation from courts. */
  Courts = 'COURTS',
  /** Events that have triggered a flag */
  FlaggedEvents = 'FLAGGED_EVENTS',
  /** News, blogs etc. */
  Media = 'MEDIA',
  /** Sales trigger events, e.g. positive flags. */
  SalesTriggers = 'SALES_TRIGGERS'
}

/** The lower bound of the number of Sources (inclusive). */
export type EventTypeFilter = {
  __typename: 'EventTypeFilter';
  /** Filter events by type. */
  eventTypes?: Maybe<Array<EventType>>;
  /** Exclude events from these sources. */
  sourcesExclude?: Maybe<Array<Scalars['String']['output']>>;
  /** Include only from these sources. */
  sourcesInclude?: Maybe<Array<Scalars['String']['output']>>;
};

/** The lower bound of the number of Sources (inclusive). */
export type EventTypeFilterInput = {
  /** Filter events by type. */
  eventTypes?: InputMaybe<Array<EventType>>;
  /** Exclude events from these sources. */
  sourcesExclude?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Include only from these sources. */
  sourcesInclude?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Entity extractors used to find entities in text. */
export enum Extractor {
  Annotation = 'ANNOTATION',
  Company = 'COMPANY',
  Coreference = 'COREFERENCE',
  Default = 'DEFAULT',
  Google = 'GOOGLE',
  Matcher = 'MATCHER',
  Meta = 'META',
  Person = 'PERSON',
  Spotlight = 'SPOTLIGHT',
  Ticker = 'TICKER',
  Topic = 'TOPIC',
  Unit = 'UNIT'
}

/** When the value is determined to be a facebook user-id, this implementation can be used to access relevant content. */
export type FacebookProfileIdPropertyValue = PropertyValue & {
  __typename: 'FacebookProfileIdPropertyValue';
  /** A url to the facebook profile. */
  facebookProfileUrl: Scalars['String']['output'];
  /** The value. */
  value: Scalars['String']['output'];
};

export type FamilyRelationship = {
  __typename: 'FamilyRelationship';
  person: Person;
  relationships: Array<BeneficialOwnerRelationshipKind>;
};

export type Feature = {
  __typename: 'Feature';
  category: FeatureCategory;
  description?: Maybe<Scalars['String']['output']>;
  isAdminOnly: Scalars['Boolean']['output'];
  isBeta: Scalars['Boolean']['output'];
  isEditable: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  kind: FeatureKind;
  name: Scalars['String']['output'];
  requiredIntegration?: Maybe<Scalars['ID']['output']>;
  requiresIntegration: Scalars['Boolean']['output'];
};

export type FeatureCategory = {
  __typename: 'FeatureCategory';
  kind: FeatureCategoryKind;
  name: Scalars['String']['output'];
};

export enum FeatureCategoryKind {
  Alerts = 'ALERTS',
  Aml = 'AML',
  Basic = 'BASIC',
  Content = 'CONTENT',
  Other = 'OTHER',
  Premium = 'PREMIUM',
  Sales = 'SALES'
}

export type FeatureInput = {
  isEnabled: Scalars['Boolean']['input'];
  kind: FeatureKind;
};

export enum FeatureKind {
  AbosFolkeregisteret = 'ABOS_FOLKEREGISTERET',
  AdverseMediaScreening = 'ADVERSE_MEDIA_SCREENING',
  AmsEventFeedback = 'AMS_EVENT_FEEDBACK',
  BeneficialOwners = 'BENEFICIAL_OWNERS',
  BusinessMonitoring = 'BUSINESS_MONITORING',
  CollateralDataLosoreregisteret = 'COLLATERAL_DATA_LOSOREREGISTERET',
  ContactPersons = 'CONTACT_PERSONS',
  ContentDanish = 'CONTENT_DANISH',
  ContentFinnish = 'CONTENT_FINNISH',
  ContentGerman = 'CONTENT_GERMAN',
  ContentNorwegian = 'CONTENT_NORWEGIAN',
  ContentNorwegianPremium = 'CONTENT_NORWEGIAN_PREMIUM',
  ContentSwedish = 'CONTENT_SWEDISH',
  ContentUnitedKingdom = 'CONTENT_UNITED_KINGDOM',
  Counterparties = 'COUNTERPARTIES',
  CreditData = 'CREDIT_DATA',
  CreditDataMonitoring = 'CREDIT_DATA_MONITORING',
  CreditDataNorwegianEnks = 'CREDIT_DATA_NORWEGIAN_ENKS',
  EditOwners = 'EDIT_OWNERS',
  EditRoles = 'EDIT_ROLES',
  EmailNewsletter = 'EMAIL_NEWSLETTER',
  EmailNotifications = 'EMAIL_NOTIFICATIONS',
  EmailOnboarding = 'EMAIL_ONBOARDING',
  EmailReminder = 'EMAIL_REMINDER',
  EmailReview = 'EMAIL_REVIEW',
  EmailWeeklySummary = 'EMAIL_WEEKLY_SUMMARY',
  Esg = 'ESG',
  EventAiSummary = 'EVENT_AI_SUMMARY',
  FlagTimeFilter = 'FLAG_TIME_FILTER',
  GlobalEntities = 'GLOBAL_ENTITIES',
  GlobalEntitiesV2 = 'GLOBAL_ENTITIES_V2',
  NewReviewPdf = 'NEW_REVIEW_PDF',
  Opportunities = 'OPPORTUNITIES',
  PepAndSanctionsV2 = 'PEP_AND_SANCTIONS_V2',
  PepDispositioning = 'PEP_DISPOSITIONING',
  PrivatePersons = 'PRIVATE_PERSONS',
  PseudonymizedNames = 'PSEUDONYMIZED_NAMES',
  RealEstateDataKartverket = 'REAL_ESTATE_DATA_KARTVERKET',
  Review = 'REVIEW',
  ReviewAiSummary = 'REVIEW_AI_SUMMARY',
  ReviewCustomCheckboxes = 'REVIEW_CUSTOM_CHECKBOXES',
  ReviewInSidepanelDeprecated = 'REVIEW_IN_SIDEPANEL_DEPRECATED',
  ReviewPdf = 'REVIEW_PDF',
  ReviewTriggers = 'REVIEW_TRIGGERS',
  SensitiveDataPersons = 'SENSITIVE_DATA_PERSONS',
  StriseCreditRisk = 'STRISE_CREDIT_RISK',
  SubsidiaryEvents = 'SUBSIDIARY_EVENTS',
  SuggestedContactPersons = 'SUGGESTED_CONTACT_PERSONS',
  TeamActivitiesAndReminders = 'TEAM_ACTIVITIES_AND_REMINDERS',
  UserAssistedEntityMerging = 'USER_ASSISTED_ENTITY_MERGING'
}

export type Features = {
  __typename: 'Features';
  enabledContentLanguages: Array<ContentLanguage>;
  features: Array<Feature>;
};


export type FeaturesFeaturesArgs = {
  excludeFeatureCategories?: InputMaybe<Array<FeatureCategoryKind>>;
  includeFeatureCategories?: InputMaybe<Array<FeatureCategoryKind>>;
};

export type FieldValuePair = {
  __typename: 'FieldValuePair';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type FinancialFieldInfo = {
  __typename: 'FinancialFieldInfo';
  country: ContentLanguage;
  id: Scalars['ID']['output'];
  isExpense: Scalars['Boolean']['output'];
  kind: FinancialFieldKind;
  matchingInternationalFields: Array<FinancialFieldInfo>;
  name: Scalars['String']['output'];
  renderKind: FinancialFieldRenderKind;
};

export enum FinancialFieldKind {
  Day = 'DAY',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE'
}

export enum FinancialFieldRenderKind {
  Calculation = 'CALCULATION',
  Default = 'DEFAULT',
  Sum = 'SUM'
}

/** Filter companies on financial values. */
export type FinancialFilterV2 = {
  __typename: 'FinancialFilterV2';
  consolidated?: Maybe<Scalars['Boolean']['output']>;
  time?: Maybe<TimeFilter>;
  valueFilters?: Maybe<Array<FinancialValueFilter>>;
};

/** Filter companies on financial values. */
export type FinancialFilterV2Input = {
  consolidated?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<TimeFilterInput>;
  valueFilters?: InputMaybe<Array<FinancialProfileInput>>;
};

/** Filter financial field on specified values. */
export type FinancialProfileInput = {
  from?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  to?: InputMaybe<Scalars['Float']['input']>;
};

/** Metadata about a financial statement. */
export type FinancialStatementMeta = {
  __typename: 'FinancialStatementMeta';
  auditorClarification?: Maybe<Scalars['String']['output']>;
  auditorComment?: Maybe<Scalars['String']['output']>;
  consolidated: Scalars['Boolean']['output'];
  currency: Currency;
  notes: Array<Scalars['String']['output']>;
  periodTitle: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

/** Filter financial field on specified values. */
export type FinancialValueFilter = {
  __typename: 'FinancialValueFilter';
  from?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  to?: Maybe<Scalars['Float']['output']>;
};

/** The financials of a company. */
export type Financials = {
  __typename: 'Financials';
  compare: Array<FinancialsSection>;
  meta: Array<FinancialStatementMeta>;
  operatingRevenues?: Maybe<Scalars['Float']['output']>;
  sections: Array<FinancialsSection>;
};

/** A row in the financials of a company. */
export type FinancialsRow = {
  __typename: 'FinancialsRow';
  afterUnderline: Scalars['Boolean']['output'];
  fieldInfo: FinancialFieldInfo;
  name: Scalars['String']['output'];
  values: Array<Maybe<Scalars['Float']['output']>>;
};

/** A section in the financials of a company. */
export type FinancialsSection = {
  __typename: 'FinancialsSection';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rows: Array<FinancialsRow>;
};

export type FlagKindInfo = {
  __typename: 'FlagKindInfo';
  countries: Array<ContentLanguage>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type FlagReviewSetting = ReviewSettingLike & {
  __typename: 'FlagReviewSetting';
  enabled: Scalars['Boolean']['output'];
  kind: ReviewSettingKind;
  riskValues: Array<FlagSeverity>;
};

/** An enum representing the severity of an flag. */
export enum FlagSeverity {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM',
  /** Positive */
  Positive = 'POSITIVE'
}

export enum Gender {
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE',
  /** Other */
  Other = 'OTHER'
}

/** A geo-coordinate. */
export type GeoCoordinate = {
  __typename: 'GeoCoordinate';
  /** The latitude of the geo-coordinates */
  latitude: Scalars['Float']['output'];
  /** The longitude of the geo-coordinates */
  longitude: Scalars['Float']['output'];
};

/** When the value is determined to be a geo-coordinate, this implementation can be used to access relevant content. */
export type GeoCoordinatePropertyValue = PropertyValue & {
  __typename: 'GeoCoordinatePropertyValue';
  /** The longitude of the geo-coordinates. */
  location: GeoCoordinate;
  /** The value. */
  value: Scalars['String']['output'];
};

/** An object representing a global company. */
export type GlobalCompany = EntityLike & {
  __typename: 'GlobalCompany';
  /** Access information for this entity. */
  access: EntityAccess;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  companyInformation: Table;
  /** The coordinates of the headquarters of the company. */
  coordinates?: Maybe<Scalars['String']['output']>;
  countries: Array<Country>;
  dataSources: EntityDataSources;
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  globalCompanyId?: Maybe<Scalars['ID']['output']>;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  keyMeta: Table;
  legalForm?: Maybe<LegalForm>;
  lifeStatus: CompanyLifeStatus;
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  numOwnerships: Scalars['Int']['output'];
  ownerChart: OwnershipChart;
  owners: Table;
  /** The ownerships of the global company based on percentage owned. */
  ownerships: ShareholderConnection;
  primaryCountry?: Maybe<Country>;
  registerCountry?: Maybe<ContentLanguage>;
  /** Registers for this entity. */
  registers: RegisterConnection;
  roleTableV2: Table;
  sanctionInfo: SanctionInfoV2;
  similarEntities: SearchResultConnection;
};


/** An object representing a global company. */
export type GlobalCompanyAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a global company. */
export type GlobalCompanyDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a global company. */
export type GlobalCompanyEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a global company. */
export type GlobalCompanyNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a global company. */
export type GlobalCompanyNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a global company. */
export type GlobalCompanyNumOwnershipsArgs = {
  from?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};


/** An object representing a global company. */
export type GlobalCompanyOwnerChartArgs = {
  ignoreCustomOwnerships?: InputMaybe<Scalars['Boolean']['input']>;
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  team: Scalars['ID']['input'];
  threshold?: InputMaybe<Scalars['Float']['input']>;
};


/** An object representing a global company. */
export type GlobalCompanyOwnersArgs = {
  ignoreCustomOwnerships?: InputMaybe<Scalars['Boolean']['input']>;
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  team: Scalars['ID']['input'];
  threshold: Scalars['Float']['input'];
};


/** An object representing a global company. */
export type GlobalCompanyOwnershipsArgs = {
  from?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};


/** An object representing a global company. */
export type GlobalCompanyRoleTableV2Args = {
  filterActive?: InputMaybe<Scalars['Boolean']['input']>;
};


/** An object representing a global company. */
export type GlobalCompanySanctionInfoArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a global company. */
export type GlobalCompanySimilarEntitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  team: Scalars['ID']['input'];
};

/** An object representing a global person. */
export type GlobalPerson = EntityLike & {
  __typename: 'GlobalPerson';
  /** Access information for this entity. */
  access: EntityAccess;
  address?: Maybe<Address>;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  birthDate?: Maybe<Scalars['ISODateTime']['output']>;
  countries: Array<Country>;
  dataSources: EntityDataSources;
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  keyMeta: Table;
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  numOwnerships: Scalars['Int']['output'];
  /** The ownerships of the global person based on percentage owned. */
  ownerships: ShareholderConnection;
  pep?: Maybe<Scalars['Boolean']['output']>;
  personInformation: Table;
  primaryCountry?: Maybe<Country>;
  /** Registers for this entity. */
  registers: RegisterConnection;
  roleTableV2: Table;
  sanctionInfo: SanctionInfoV2;
  similarEntities: SearchResultConnection;
};


/** An object representing a global person. */
export type GlobalPersonAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a global person. */
export type GlobalPersonDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a global person. */
export type GlobalPersonEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a global person. */
export type GlobalPersonNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a global person. */
export type GlobalPersonNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a global person. */
export type GlobalPersonNumOwnershipsArgs = {
  from?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};


/** An object representing a global person. */
export type GlobalPersonOwnershipsArgs = {
  from?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};


/** An object representing a global person. */
export type GlobalPersonRoleTableV2Args = {
  filterActive?: InputMaybe<Scalars['Boolean']['input']>;
};


/** An object representing a global person. */
export type GlobalPersonSanctionInfoArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a global person. */
export type GlobalPersonSimilarEntitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  team: Scalars['ID']['input'];
};

/** A representation of an image. */
export type Image = {
  __typename: 'Image';
  /** A URL where the image can be accessed. */
  url: Scalars['String']['output'];
};

/** Filter on number of months since inception. */
export type InceptionFilterInput = {
  /** The upper bound of the number of months since inception (inclusive). */
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  /** The lower bound of the number of months since inception (inclusive). */
  moreThan?: InputMaybe<Scalars['Int']['input']>;
};

export type IncomeConnection = {
  __typename: 'IncomeConnection';
  edges: Array<IncomeConnectionEdge>;
};

export type IncomeConnectionEdge = {
  __typename: 'IncomeConnectionEdge';
  node: CreditReportIncome;
};

/** A representation of an industry. */
export type Industry = EntityLike & {
  __typename: 'Industry';
  /** Access information for this entity. */
  access: EntityAccess;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  /** The sub-industries for this industry, if any. */
  children: IndustryConnection;
  /** The sub-industries for this industry, if any. */
  childrenV2: IndustryConnection;
  country?: Maybe<ContentLanguage>;
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  /** The parent-industry, if any. */
  parent?: Maybe<Industry>;
  /** Registers for this entity. */
  registers: RegisterConnection;
};


/** A representation of an industry. */
export type IndustryAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** A representation of an industry. */
export type IndustryChildrenArgs = {
  locationFilter?: InputMaybe<LocationFilterInput>;
};


/** A representation of an industry. */
export type IndustryChildrenV2Args = {
  countries: Array<ContentLanguage>;
};


/** A representation of an industry. */
export type IndustryDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** A representation of an industry. */
export type IndustryEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** A representation of an industry. */
export type IndustryNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** A representation of an industry. */
export type IndustryNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};

export type IndustryConnection = {
  __typename: 'IndustryConnection';
  edges: Array<IndustryConnectionEdge>;
};

export type IndustryConnectionEdge = {
  __typename: 'IndustryConnectionEdge';
  node: Industry;
};

/** When the value is determined to be an int, this implementation can be used to access relevant content. */
export type IntPropertyValue = PropertyValue & {
  __typename: 'IntPropertyValue';
  /** The value as a number. */
  number?: Maybe<Scalars['Int']['output']>;
  /** The value. */
  value: Scalars['String']['output'];
};

/** A representation of an Integration. */
export type Integration = {
  __typename: 'Integration';
  /** The client credentials for this Integration. */
  credentials: ClientCredentials;
  /** The Integration ID. */
  id: Scalars['ID']['output'];
  /** The Integration. */
  integration: IntegrationKind;
};

/** A representation of type of Integration. */
export type IntegrationKind = {
  __typename: 'IntegrationKind';
  /** The Integration ID. */
  id: Scalars['ID']['output'];
  /** The Integration name. */
  name: Scalars['String']['output'];
};

export type IntegrationsConnection = {
  __typename: 'IntegrationsConnection';
  edges: Array<IntegrationsConnectionEdge>;
};

export type IntegrationsConnectionEdge = {
  __typename: 'IntegrationsConnectionEdge';
  node: Integration;
};

/** A Json implementation of the [PropertyValue](#PropertyValue). If all else fails, this is the go-to property value. */
export type JsonPropertyValue = PropertyValue & {
  __typename: 'JsonPropertyValue';
  /** The value. */
  value: Scalars['String']['output'];
};

export enum LabelOrigin {
  AliasBlacklister = 'ALIAS_BLACKLISTER',
  AliasCleaner = 'ALIAS_CLEANER',
  AliasTrainer = 'ALIAS_TRAINER',
  CompanyUtils = 'COMPANY_UTILS',
  TopicBlacklister = 'TOPIC_BLACKLISTER',
  User = 'USER'
}

/** The legal from of a company. */
export type LegalForm = {
  __typename: 'LegalForm';
  code: Scalars['String']['output'];
  country: ContentLanguage;
  countryV2: CountryKind;
  hasBeneficialOwners: Scalars['Boolean']['output'];
  hasBoard: Scalars['Boolean']['output'];
  hasCollateral: Scalars['Boolean']['output'];
  hasCreditReport: Scalars['Boolean']['output'];
  hasNorwegianSoleTraderCreditData: Scalars['Boolean']['output'];
  hasOwner: Scalars['Boolean']['output'];
  hasPartners: Scalars['Boolean']['output'];
  hasShareClasses: Scalars['Boolean']['output'];
  hasShares: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  kind: LegalFormKind;
  name: Scalars['String']['output'];
};

export type LegalFormConnection = {
  __typename: 'LegalFormConnection';
  edges: Array<LegalFormConnectionEdge>;
};

export type LegalFormConnectionEdge = {
  __typename: 'LegalFormConnectionEdge';
  node: LegalForm;
};

/** The type of LegalForm */
export enum LegalFormKind {
  /** Andelsselskab (-forening) med begrænset ansvar */
  DaAba = 'DA_ABA',
  /** Amtskommune */
  DaAmt = 'DA_AMT',
  /** Andelsselskab (-forening) */
  DaAns = 'DA_ANS',
  /** Anpartsselskab */
  DaAps = 'DA_APS',
  /** Aktieselskab */
  DaAs = 'DA_AS',
  /** Dødsbo */
  DaDod = 'DA_DOD',
  /** Erhvervsdrivende fond */
  DaEfo = 'DA_EFO',
  /** Enkeltmandsvirksomhed */
  DaEnk = 'DA_ENK',
  /** Europæisk Økonomisk Firmagruppe */
  DaEof = 'DA_EOF',
  /** SE-selskab */
  DaEs = 'DA_ES',
  /** Enhed under oprettelse i Erhvervsstyrelsen */
  DaEuo = 'DA_EUO',
  /** Filial af udenlandsk anpartsselskab eller selskab */
  DaFap = 'DA_FAP',
  /** Filial af udenlandsk aktieselskab,  kommanditakties */
  DaFas = 'DA_FAS',
  /** Forening eller selskab med begrænset ansvar */
  DaFba1 = 'DA_FBA1',
  /** Filial af udenlandsk virksomhed med begrænset ansv */
  DaFba2 = 'DA_FBA2',
  /** Fast forretningssted af Europæisk økonomisk Firmag */
  DaFeo = 'DA_FEO',
  /** Filial af SE-selskab */
  DaFes = 'DA_FES',
  /** Frivillig forening */
  DaFfo = 'DA_FFO',
  /** Særlig finansiel virksomhed */
  DaFiv = 'DA_FIV',
  /** Folkekirkelige Institutioner */
  DaFki = 'DA_FKI',
  /** Forening med begrænset ansvar */
  DaFma = 'DA_FMA',
  /** Fonde og andre selvejende institutioner */
  DaFon = 'DA_FON',
  /** Forening */
  DaFor = 'DA_FOR',
  /** Grønlandsk afdeling af udenlandsk selskab eller virksomhed */
  DaGus = 'DA_GUS',
  /** Interessentskab */
  DaIs = 'DA_IS',
  /** Iværksætterselskab */
  DaIvs = 'DA_IVS',
  /** Kommanditaktieselskab/Partnerselskab */
  DaKas = 'DA_KAS',
  /** Primærkommune */
  DaKom = 'DA_KOM',
  /** Kommanditselskab */
  DaKs = 'DA_KS',
  /** Medarbejderinvesteringsselskab */
  DaMss = 'DA_MSS',
  /** Øvrige virksomhedsformer */
  DaOvr = 'DA_OVR',
  /** Partrederi */
  DaPar = 'DA_PAR',
  /** Personligt ejet Mindre Virksomhed */
  DaPmv = 'DA_PMV',
  /** Region */
  DaReg = 'DA_REG',
  /** SCE-selskab */
  DaSce = 'DA_SCE',
  /** Selskab med begrænset ansvar */
  DaSma = 'DA_SMA',
  /** Selvstændig offentlig virksomhed */
  DaSov = 'DA_SOV',
  /** Statslig administrativ enhed */
  DaSta = 'DA_STA',
  /** Trust */
  DaTru = 'DA_TRU',
  /** Anden udenlandsk virksomhed */
  DaUdl = 'DA_UDL',
  /** Uoplyst virksomhedsform */
  DaUop = 'DA_UOP',
  /** Registered Cooperative (eG) */
  DeEg = 'DE_EG',
  /** Registered Civil-Law Partnership (eGbR) */
  DeEgbr = 'DE_EGBR',
  /** Registered Merchant */
  DeEk = 'DE_EK',
  /** Limited Liability Company (GmbH) */
  DeGmbh = 'DE_GMBH',
  /** GmbH & Co. KG */
  DeGmbhCoKg = 'DE_GMBH_CO_KG',
  /** Limited Partnership */
  DeKg = 'DE_KG',
  /** Non-Profit Limited Liability Company (gGmbH) */
  DeNpGmbh = 'DE_NP_GMBH',
  /** General Partnership */
  DeOhg = 'DE_OHG',
  /** Other */
  DeOther = 'DE_Other',
  /** Partnership Company (PartG) */
  DePartg = 'DE_PARTG',
  /** Small Limited Liability Company (UG) */
  DeUg = 'DE_UG',
  /** UG & Co. KG */
  DeUgCoKg = 'DE_UG_CO_KG',
  /** LimitedCompany */
  Fi_01 = 'FI_01',
  /** PrivateBusinessGovCtrl */
  Fi_02 = 'FI_02',
  /** ForeignCompany */
  Fi_03 = 'FI_03',
  /** Bank */
  Fi_04 = 'FI_04',
  /** SoleProprietorship */
  Fi_05 = 'FI_05',
  /** GeneralPartnership */
  Fi_06 = 'FI_06',
  /** Society */
  Fi_07 = 'FI_07',
  /** Foundation */
  Fi_08 = 'FI_08',
  /** HousingCompany */
  Fi_09 = 'FI_09',
  /** StateCountyCompany */
  Fi_10 = 'FI_10',
  /** ReligiousOrganisation */
  Fi_11 = 'FI_11',
  /** InsuranceCompany */
  Fi_12 = 'FI_12',
  /** Collaborations */
  Fi_13 = 'FI_13',
  /** Other2 */
  Fi_20 = 'FI_20',
  /** Unknown */
  Fi_99 = 'FI_99',
  /** DeathNest */
  Fi_100 = 'FI_100',
  /** Limited Partnership */
  Fi_101 = 'FI_101',
  /** Shipping Partnership */
  Fi_102 = 'FI_102',
  /** Corporation */
  Fi_103 = 'FI_103',
  /** Business Foundation */
  Fi_104 = 'FI_104',
  /** Association */
  Fi_105 = 'FI_105',
  /** Cooperative */
  Fi_106 = 'FI_106',
  /** Volunteer Association */
  Fi_107 = 'FI_107',
  /** Association or Limited Liability Company */
  Fi_108 = 'FI_108',
  /** Limited Liability Company */
  Fi_109 = 'FI_109',
  /** Limited Association Company */
  Fi_110 = 'FI_110',
  /** State Administration */
  Fi_111 = 'FI_111',
  /** European Financial Company Group */
  Fi_112 = 'FI_112',
  /** SCE Company */
  Fi_113 = 'FI_113',
  /** Special Financial Business */
  Fi_114 = 'FI_114',
  /** Partnership */
  Fi_115 = 'FI_115',
  /** Sub Division */
  Fi_120 = 'FI_120',
  /** European Economic Interest Group */
  Fi_130 = 'FI_130',
  /** Limited Liability Stock Company */
  Fi_140 = 'FI_140',
  /** Open Trading Company */
  Fi_141 = 'FI_141',
  /** Statutory Corporation */
  Fi_142 = 'FI_142',
  /** Professional Partnership */
  Fi_143 = 'FI_143',
  /** Independent Subsidiary */
  Fi_144 = 'FI_144',
  /** Dependant Subsidiary */
  Fi_145 = 'FI_145',
  /** Societas Cooperativa Europaea */
  Fi_146 = 'FI_146',
  /** Societas Europaea */
  Fi_147 = 'FI_147',
  /** Freier Beruf */
  Fi_148 = 'FI_148',
  /** Non Profit Stock Company */
  Fi_149 = 'FI_149',
  /** Entrepreneurial Limited Company */
  Fi_150 = 'FI_150',
  /** Limited company */
  FiLc = 'FI_LC',
  /** Other */
  FiOt = 'FI_OT',
  /** Ikke næringsdrivende virksomhet */
  NoAafy = 'NO_AAFY',
  /** Administrativ enhet -offentlig sektor */
  NoAdos = 'NO_ADOS',
  /** Annen juridisk person */
  NoAnna = 'NO_ANNA',
  /** Ansvarlig selskap med solidarisk ansvar */
  NoAns = 'NO_ANS',
  /** Aksjeselskap */
  NoAs = 'NO_AS',
  /** Allmennaksjeselskap */
  NoAsa = 'NO_ASA',
  /** Selskap med begrenset ansvar */
  NoBa = 'NO_BA',
  /** Boligbyggelag */
  NoBbl = 'NO_BBL',
  /** Bedrift */
  NoBedr = 'NO_BEDR',
  /** Andre bo */
  NoBo = 'NO_BO',
  /** Borettslag */
  NoBrl = 'NO_BRL',
  /** Ansvarlig selskap med delt ansvar */
  NoDa = 'NO_DA',
  /** Enkeltpersonforetak */
  NoEnk = 'NO_ENK',
  /** Europeisk økonomisk foretaksgruppe */
  NoEofg = 'NO_EOFG',
  /** Eierseksjonssameie */
  NoEsek = 'NO_ESEK',
  /** Fylkeskommunalt foretak */
  NoFkf = 'NO_FKF',
  /** Forening/lag/innretning */
  NoFli = 'NO_FLI',
  /** Fylkeskommune */
  NoFylk = 'NO_FYLK',
  /** Gjensidig forsikringsselskap */
  NoGfs = 'NO_GFS',
  /** Andre ikke-juridiske personer */
  NoIkjp = 'NO_IKJP',
  /** Interkommunalt selskap */
  NoIks = 'NO_IKS',
  /** Konkursbo */
  NoKbo = 'NO_KBO',
  /** Kommunalt foretak */
  NoKf = 'NO_KF',
  /** Den norske kirke */
  NoKirk = 'NO_KIRK',
  /** Kommune */
  NoKomm = 'NO_KOMM',
  /** Kommandittselskap */
  NoKs = 'NO_KS',
  /** Kontorfellesskap */
  NoKtrf = 'NO_KTRF',
  /** Norskregistrert utenlandsk foretak */
  NoNuf = 'NO_NUF',
  /** Særskilt oppdelt enhet */
  NoOpmv = 'NO_OPMV',
  /** Organisasjonsledd */
  NoOrgl = 'NO_ORGL',
  /** Andre enkeltpersoner som registreres i tilknyttet register */
  NoPers = 'NO_PERS',
  /** Pensjonskasse */
  NoPk = 'NO_PK',
  /** Partrederi */
  NoPre = 'NO_PRE',
  /** Samvirkeforetak */
  NoSa = 'NO_SA',
  /** Annet foretak ifølge særskilt lov */
  NoSaer = 'NO_SAER',
  /** Tingsrettslig sameie */
  NoSam = 'NO_SAM',
  /** Europeisk selskap */
  NoSe = 'NO_SE',
  /** Statsforetak */
  NoSf = 'NO_SF',
  /** Sparebank */
  NoSpa = 'NO_SPA',
  /** Staten */
  NoStat = 'NO_STAT',
  /** Stiftelse */
  NoSti = 'NO_STI',
  /** Tvangsregistrert for MVA */
  NoTvam = 'NO_TVAM',
  /** Verdipapirfond */
  NoVpfo = 'NO_VPFO',
  /** Privat aktiebolag */
  SvAb = 'SV_AB',
  /** Allmän försäkringskassa */
  SvAfor = 'SV_AFOR',
  /** Aktiebolag */
  SvAkti = 'SV_AKTI',
  /** Bankaktiebolag */
  SvBaab = 'SV_BAAB',
  /** Bostadsrättsförening */
  SvBf = 'SV_BF',
  /** Bostadsförening */
  SvBost = 'SV_BOST',
  /** Erkänd arbetslöshetskassa */
  SvEarb = 'SV_EARB',
  /** Enkelt bolag */
  SvEb = 'SV_EB',
  /** Enskild firma */
  SvEf = 'SV_EF',
  /** Ekonomisk förening */
  SvEkf = 'SV_EKF',
  /** Europakooperativ */
  SvEuko = 'SV_EUKO',
  /** Europabolag */
  SvEuro = 'SV_EURO',
  /** Familjestiftelse */
  SvFams = 'SV_FAMS',
  /** Försäkringsaktiebolag */
  SvFoab = 'SV_FOAB',
  /** Försäkringsbolag */
  SvFobo = 'SV_FOBO',
  /** Filial till utländskt bolag */
  SvFtu = 'SV_FTU',
  /** Handelsbolag */
  SvHb = 'SV_HB',
  /** Hypoteksförening */
  SvHypo = 'SV_HYPO',
  /** Ideell förening */
  SvIf = 'SV_IF',
  /** Kommanditbolag */
  SvKb = 'SV_KB',
  /** Kooperativ Hyresgästförening */
  SvKohy = 'SV_KOHY',
  /** Kommunförbund */
  SvKomf = 'SV_KOMF',
  /** Kommun */
  SvKomm = 'SV_KOMM',
  /** Landsting */
  SvLand = 'SV_LAND',
  /** Livförsäkringsbolag */
  SvLivf = 'SV_LIVF',
  /** Medlemsbank */
  SvMeba = 'SV_MEBA',
  /** Oskiftat dödsbo */
  SvOd = 'SV_OD',
  /** Okänd juridisk form */
  SvOkjf = 'SV_OKJF',
  /** Offentlig korporation och anstalt */
  SvOkoa = 'SV_OKOA',
  /** Ömsesidigt försäkringsbolag */
  SvOmfo = 'SV_OMFO',
  /** Övrig svensk juridisk person */
  SvOsjp = 'SV_OSJP',
  /** Publikt aktiebolag */
  SvPa = 'SV_PA',
  /** Partrederi */
  SvPart = 'SV_PART',
  /** Privat bankaktiebolag */
  SvPbab = 'SV_PBAB',
  /** Publikt bankaktiebolag */
  SvPban = 'SV_PBAN',
  /** Privat livförsäkringsbolag */
  SvPrfo = 'SV_PRFO',
  /** Privat skadeförsäkringsbolag */
  SvPska = 'SV_PSKA',
  /** Publikt livförsäkringsbolag */
  SvPuli = 'SV_PULI',
  /** Publikt skadeförsäkringsbolag */
  SvPusk = 'SV_PUSK',
  /** Regional statlig myndighet */
  SvRsmy = 'SV_RSMY',
  /** Registrerat trossamfund */
  SvRt = 'SV_RT',
  /** Sambruksförening */
  SvSamb = 'SV_SAMB',
  /** Samfällighet */
  SvSf = 'SV_SF',
  /** Skadeförsäkringsbolag */
  SvSkad = 'SV_SKAD',
  /** Sparbank */
  SvSpar = 'SV_SPAR',
  /** Statlig enhet */
  SvStat = 'SV_STAT',
  /** Stiftelse/Fond */
  SvSti = 'SV_STI',
  /** Stiftelse */
  SvStif = 'SV_STIF',
  /** Tjänstepensionsförening */
  SvTjan = 'SV_TJAN',
  /** Utländsk Banks Filial */
  SvUbaf = 'SV_UBAF',
  /** Utländsk juridisk person */
  SvUjp = 'SV_UJP',
  /** Understödsförening */
  SvUsfo = 'SV_USFO',
  /** Värdepapperfond */
  SvVppf = 'SV_VPPF',
  /** Other */
  UkUk0 = 'UK_UK0',
  /** Private unlimited with Share Capital */
  UkUk1 = 'UK_UK1',
  /** Private limited with Share Capital */
  UkUk2 = 'UK_UK2',
  /** Public limited with Share Capital */
  UkUk3 = 'UK_UK3',
  /** Old Public Limited Company */
  UkUk4 = 'UK_UK4',
  /** Private Limited by guarantee without Share Capital Exempt from using Limited */
  UkUk5 = 'UK_UK5',
  /** Limited Partnership */
  UkUk6 = 'UK_UK6',
  /** Private Ltd By Guarantee w/o Share Cap */
  UkUk7 = 'UK_UK7',
  /** Company Converted/Closed */
  UkUk8 = 'UK_UK8',
  /** Unlimited/No Share Capital */
  UkUk9 = 'UK_UK9',
  /** Limited */
  UkUka = 'UK_UKA',
  /** Societas Europaea (SE) */
  UkUkb = 'UK_UKB',
  /** Scottish Partnership */
  UkUkc = 'UK_UKC',
  /** Further education or sixth form college corporation */
  UkUke = 'UK_UKE',
  /** Overseas Entity */
  UkUkr = 'UK_UKR',
  /** Sole Trader */
  UkUks = 'UK_UKS'
}

/** An enum representing like status. */
export enum LikeStatus {
  Disliked = 'DISLIKED',
  Liked = 'LIKED',
  None = 'NONE'
}

/** An enum representing a listViewSortField. */
export enum ListViewField {
  Assignee = 'ASSIGNEE',
  Ceo = 'CEO',
  Country = 'COUNTRY',
  Employees = 'EMPLOYEES',
  EquityRatio = 'EQUITY_RATIO',
  Flag = 'FLAG',
  Location = 'LOCATION',
  Name = 'NAME',
  Revenue = 'REVENUE',
  Status = 'STATUS',
  StatusModified = 'STATUS_MODIFIED',
  Tags = 'TAGS'
}

export type ListViewPageInfo = {
  __typename: 'ListViewPageInfo';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  sort?: Maybe<Array<ListViewSort>>;
};

export type ListViewPageInfoInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ListViewSortInput>>;
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type ListViewSettings = {
  __typename: 'ListViewSettings';
  /** Filter the companies. */
  companies: CompanyFilter;
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type ListViewSettingsInput = {
  /** Filter the companies. */
  companies: CompanyFilterInput;
};

export type ListViewSort = {
  __typename: 'ListViewSort';
  field: ListViewField;
  ordering: SortOrdering;
};

export type ListViewSortInput = {
  field: ListViewField;
  ordering: SortOrdering;
};

/** A representation of a physical location. E.g. a country, a region, municipality or city. */
export type Location = EntityLike & {
  __typename: 'Location';
  /** Access information for this entity. */
  access: EntityAccess;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  /** List all countries for this location */
  countries: LocationConnection;
  countriesV2: Array<Country>;
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  documentationUrl?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  /** Location type */
  kind?: Maybe<LocationKind>;
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  /** List all sub-regions for this location */
  regions: LocationConnection;
  /** Registers for this entity. */
  registers: RegisterConnection;
};


/** A representation of a physical location. E.g. a country, a region, municipality or city. */
export type LocationAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** A representation of a physical location. E.g. a country, a region, municipality or city. */
export type LocationDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** A representation of a physical location. E.g. a country, a region, municipality or city. */
export type LocationEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** A representation of a physical location. E.g. a country, a region, municipality or city. */
export type LocationNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** A representation of a physical location. E.g. a country, a region, municipality or city. */
export type LocationNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};

export type LocationConnection = {
  __typename: 'LocationConnection';
  edges: Array<LocationConnectionEdge>;
};

export type LocationConnectionEdge = {
  __typename: 'LocationConnectionEdge';
  node: Location;
};

/** Location filter used for filtering potential opportunities for a portfolio. */
export type LocationFilter = {
  __typename: 'LocationFilter';
  /** Location IDs. */
  locations?: Maybe<Array<Scalars['ID']['output']>>;
  /** Polygon with coordinates. */
  polygon?: Maybe<Array<Coordinate>>;
};

/** Location filter used for filtering potential opportunities for a portfolio. */
export type LocationFilterInput = {
  /** Location IDs. */
  locations?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Polygon with coordinates. */
  polygon?: InputMaybe<Array<CoordinateInput>>;
};

/** An enum representing supported locations types. */
export enum LocationKind {
  Collection = 'COLLECTION',
  Country = 'COUNTRY',
  County = 'COUNTY',
  Municipality = 'MUNICIPALITY'
}

/** Filter on number of months since inception. */
export type MonthsAgoFilter = {
  __typename: 'MonthsAgoFilter';
  /** The upper bound of the number of months since inception (inclusive). */
  lessThan?: Maybe<Scalars['Int']['output']>;
  /** The lower bound of the number of months since inception (inclusive). */
  moreThan?: Maybe<Scalars['Int']['output']>;
};

/** The root mutation object. From here you may update the Mito.ai Graph. */
export type Mutation = {
  __typename: 'Mutation';
  /** Add an integration. */
  addIntegration: MutationQuery;
  /**
   *
   * Add a property to the entity. The `language` can be used to add a language specific property. This
   * field will return an entity if found, else `null`.
   *
   */
  addPropertyToEntity?: Maybe<Entity>;
  /**
   *
   * Add a relation to the entity. The `language` can be used to add a language specific property. This
   * field will return an entity if found, else `null`.
   *
   */
  addRelationToEntity?: Maybe<Entity>;
  /** Validate a CSV file to be used in portfolio population. */
  addRowsToPortfolio: MutationQuery;
  /** Add a sticker to a company. */
  addStickersToCompanies: MutationQuery;
  /** Creates new users for this team. */
  addUsersToTeamV2: MutationQuery;
  /** Assign a Role to a user. */
  assignUserRole: MutationQuery;
  /** Assign a user to company. */
  assignUsersToCompanies: MutationQuery;
  createApiClient: MutationQuery;
  createCounterParty: MutationQuery;
  /** Create a new customer :) */
  createCustomer?: Maybe<Customer>;
  /**
   *
   * Create a new entity by supplying the name. This entity will get a unique ID and can subsequently be modified.
   * The name should be that of the default name, but can be modified later on.
   *
   */
  createEntity?: Maybe<Entity>;
  createEntityDispositions: MutationQuery;
  /** Create an even from scratch. */
  createEvent?: Maybe<Event>;
  /** Create an OAuth connected app. */
  createOAuthConnectedApp: MutationQuery;
  createReviewV2: MutationQuery;
  /** Creates a new SSO connection for the given domain. */
  createSsoConfiguration: MutationQuery;
  /** Create a new Sticker for this team. */
  createSticker?: Maybe<Sticker>;
  /** Create a new team. */
  createTeam?: Maybe<Team>;
  createTeamActivityV2: MutationQuery;
  /** Create a topic */
  createTopic?: Maybe<Topic>;
  /** Delete a connected app. */
  deleteConnectedApp: MutationQuery;
  deleteCounterParty: MutationQuery;
  /** Delete a customer :( */
  deleteCustomer: MutationQuery;
  /** Delete a rate limit. */
  deleteRateLimit: MutationQuery;
  /** Deletes the SSO connection for the given domain. */
  deleteSsoConfiguration: MutationQuery;
  /** Delete an team. */
  deleteTeam: MutationQuery;
  /** Remove an activity. */
  deleteTeamActivity: MutationQuery;
  /** Enrich an Entity third party data. */
  enrichEntity?: Maybe<Entity>;
  /** Export portfolio from Strise to CRM. */
  exportPortfolioToCrm: MutationQuery;
  /** Generate a new access token for a client. */
  generateClientAccessToken: AccessToken;
  /** Generate an ownership conflict. */
  generateOwnershipConflict: MutationQuery;
  /** Generate a role conflict. */
  generateRoleConflict: MutationQuery;
  /** Import portfolio to Strise from CRM. */
  importPortfolioFromCrm: MutationQuery;
  /** Invite a news user to this service. */
  inviteUser?: Maybe<User>;
  /** Marks all the user's unread notifications as read. */
  markAllNotificationsAsRead: MutationQuery;
  /** Generate credit report for a company. */
  orderCreditReport: MutationQuery;
  /** Update the pipeline of a event */
  pipelineEvent?: Maybe<Event>;
  /** Re-assign a users to company. This will remove the previous assignee. */
  reassignUsersToCompanies: MutationQuery;
  refreshUser: MutationQuery;
  /**
   *
   * Remove a property from an entity. The `language` can be used to add a language specific property. This
   * field will return an entity if found, else `null`.
   *
   */
  removePropertyFromEntity?: Maybe<Entity>;
  /**
   *
   * Remove a relation from an entity. The `language` can be used to add a language specific property. This
   * field will return an entity if found, else `null`.
   *
   */
  removeRelationFromEntity?: Maybe<Entity>;
  /** Delete a sticker. This **WILL** remove the Sticker from **ALL** companies! */
  removeSticker: MutationQuery;
  /** Remove a sticker from a company. */
  removeStickersFromCompanies: MutationQuery;
  /** Remove an team from a Customer. This will **NOT** delete the team, only remove it from this Customer. */
  removeTeamFromCustomer: MutationQuery;
  /** Remove a user from an team. */
  removeUserFromTeam: MutationQuery;
  requestUsersToTeam: MutationQuery;
  resetEntityOwners: MutationQuery;
  resetEntityRoles: MutationQuery;
  /** Reset a users password. */
  resetPassword?: Maybe<User>;
  /** Reset user settings to factory default. If no user is provided the current user will be used. */
  resetSettings: MutationQuery;
  resetTeamReviewSettings: MutationQuery;
  /** @deprecated Use resetTeamReviewSettings instead */
  resetTeamReviewSettingsV2: MutationQuery;
  resolveOwnershipConflicts: MutationQuery;
  resolveRoleConflict: MutationQuery;
  /** Creates new users for this team. */
  setupUsersV2: MutationQuery;
  /** Remove an assigned user from a company. */
  unassignUsersFromCompanies: MutationQuery;
  /** Annotate an event with the correct Entities. */
  updateAnnotations: MutationQuery;
  /**
   *
   * Update companies has never been so easy! By supplying an mandatory id, and optionally specifying which language the
   * changes should be made upon, you can update the `name`, `description`, and `aliases` on this field. Un-supplied arguments
   * will not be modified. This field will return the entity if found, else `null`.
   *
   */
  updateCompany: MutationQuery;
  /** Update company statuses. */
  updateCompanyStatuses?: Maybe<MutationQuery>;
  updateCounterParty: MutationQuery;
  /** Toggle credit monitoring for a company. */
  updateCreditMonitoring: MutationQuery;
  /** Update the organization number field name in CRM. */
  updateCrmOrganizationNumberField: MutationQuery;
  /** Update the tag number field name in CRM. */
  updateCrmTagField: MutationQuery;
  /** Update a customer's meta, like name and so on. */
  updateCustomer: MutationQuery;
  /**
   *
   * Update entities has never been so easy! By supplying an mandatory id, and optionally specifying which language the
   * changes should be made upon, you can update the `name`, `description`, and `aliases` on this field. Un-supplied arguments
   * will not be modified. This field will return the entity if found, else `null`.
   *
   * Notice that for adding properties and relations, you should use the [Mutation.addPropertyToEntity](#Mutation.addPropertyToEntity),
   * [Mutation.removePropertyFromEntity](#Mutation.removePropertyFromEntity), [Mutation.addRelationToEntity](#Mutation.addRelationToEntity), and
   * [Mutation.removeRelationFromEntity](#Mutation.removeRelationFromEntity), to add a property, remove a property, add a relation, or remove a relation
   * respectively.
   *
   */
  updateEntity: MutationQuery;
  updateEntityBusinessRole: MutationQuery;
  /** Updates the user's notification settings for the entity. */
  updateEntityNotificationSettings: MutationQuery;
  updateEntityOwner: MutationQuery;
  /** Hide an event for an entity. */
  updateEventHidden: MutationQuery;
  /** Whether or not the user has liked this event. */
  updateEventLikeStatus?: Maybe<Event>;
  /** Update the read status of events. */
  updateEventReadStatus: Array<Event>;
  /** Create a notification for the user. */
  updateNotifications: MutationQuery;
  /** Update portfolio. */
  updatePortfolio?: Maybe<Portfolio>;
  /** Update a rate limit. */
  updateRateLimit: MutationQuery;
  /** Update the name. */
  updateStickerName: MutationQuery;
  /** Update an team's meta, like name and so on. */
  updateTeam: MutationQuery;
  updateTeamActivityV2: MutationQuery;
  updateTeamFeaturesV2: MutationQuery;
  updateTeamSettingsV2: MutationQuery;
  updateTeamUser: MutationQuery;
  /** Update a topic */
  updateTopic: MutationQuery;
  updateUser: MutationQuery;
  updateUserFeaturesV2: MutationQuery;
  updateUserSettings: MutationQuery;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationAddIntegrationArgs = {
  credentials: ClientCredentialsInput;
  integration: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationAddPropertyToEntityArgs = {
  id: Scalars['ID']['input'];
  relationship: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationAddRelationToEntityArgs = {
  entity: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  relationship: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationAddRowsToPortfolioArgs = {
  mergeStrategyKind: PortfolioMergeStrategyKind;
  portfolio: Scalars['ID']['input'];
  rows: Array<PortfolioCompanyRowInput>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationAddStickersToCompaniesArgs = {
  companies: Array<Scalars['ID']['input']>;
  stickers: Array<Scalars['ID']['input']>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationAddUsersToTeamV2Args = {
  team: Scalars['ID']['input'];
  users: UsersCreationInputV2;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationAssignUserRoleArgs = {
  role: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationAssignUsersToCompaniesArgs = {
  companies: Array<Scalars['ID']['input']>;
  team: Scalars['ID']['input'];
  users: Array<Scalars['ID']['input']>;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateApiClientArgs = {
  email: Scalars['String']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateCounterPartyArgs = {
  input: CounterPartyInput;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateCustomerArgs = {
  expiration?: InputMaybe<Scalars['ISODateTime']['input']>;
  name: Scalars['String']['input'];
  organizationNumber?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['ISODateTime']['input']>;
  status: CustomerStatus;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateEntityArgs = {
  name: Scalars['String']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateEntityDispositionsArgs = {
  entity: Scalars['ID']['input'];
  inputs: Array<CreateEntityDispositionInput>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateEventArgs = {
  authors: Array<Scalars['String']['input']>;
  body: Scalars['String']['input'];
  published: Scalars['ISODateTime']['input'];
  summary?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateOAuthConnectedAppArgs = {
  code: Scalars['String']['input'];
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateReviewV2Args = {
  entity: Scalars['ID']['input'];
  input: ReviewInputV2;
  portfolio: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateSsoConfigurationArgs = {
  domain: Scalars['String']['input'];
  input: TeamSsoConfigurationInput;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateStickerArgs = {
  name: Scalars['String']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateTeamArgs = {
  customer: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateTeamActivityV2Args = {
  company: Scalars['ID']['input'];
  input: TeamActivityCreateInput;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationCreateTopicArgs = {
  name: Scalars['String']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationDeleteConnectedAppArgs = {
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationDeleteCounterPartyArgs = {
  counterPartyId: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationDeleteCustomerArgs = {
  id: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationDeleteRateLimitArgs = {
  rateLimit: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationDeleteSsoConfigurationArgs = {
  connectionId: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationDeleteTeamArgs = {
  id: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationDeleteTeamActivityArgs = {
  team: Scalars['ID']['input'];
  teamActivity: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationEnrichEntityArgs = {
  entity: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationExportPortfolioToCrmArgs = {
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationGenerateClientAccessTokenArgs = {
  api?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationGenerateOwnershipConflictArgs = {
  conflictingOwnershipInput: UpdateEntityOwnerInput;
  customOwnershipInput: UpdateEntityOwnerInput;
  entity: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationGenerateRoleConflictArgs = {
  conflictingRoleInput: UpdateEntityBusinessRoleInput;
  customRoleInput: UpdateEntityBusinessRoleInput;
  entity: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationImportPortfolioFromCrmArgs = {
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationInviteUserArgs = {
  user: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationOrderCreditReportArgs = {
  company: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationPipelineEventArgs = {
  event: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationReassignUsersToCompaniesArgs = {
  companies: Array<Scalars['ID']['input']>;
  team: Scalars['ID']['input'];
  users: Array<Scalars['ID']['input']>;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationRefreshUserArgs = {
  user: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationRemovePropertyFromEntityArgs = {
  id: Scalars['ID']['input'];
  relationship: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationRemoveRelationFromEntityArgs = {
  entity: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  relationship: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationRemoveStickerArgs = {
  stickers: Array<Scalars['ID']['input']>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationRemoveStickersFromCompaniesArgs = {
  companies: Array<Scalars['ID']['input']>;
  stickers: Array<Scalars['ID']['input']>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationRemoveTeamFromCustomerArgs = {
  customer: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationRemoveUserFromTeamArgs = {
  team: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationRequestUsersToTeamArgs = {
  emails: Array<Scalars['String']['input']>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationResetEntityOwnersArgs = {
  entity: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationResetEntityRolesArgs = {
  entity: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationResetPasswordArgs = {
  user: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationResetSettingsArgs = {
  application?: InputMaybe<Scalars['Boolean']['input']>;
  events?: InputMaybe<Scalars['Boolean']['input']>;
  listView?: InputMaybe<Scalars['Boolean']['input']>;
  opportunity?: InputMaybe<Scalars['Boolean']['input']>;
  privacy?: InputMaybe<Scalars['Boolean']['input']>;
  review?: InputMaybe<Scalars['Boolean']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationResetTeamReviewSettingsArgs = {
  sections: Array<ReviewSectionKind>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationResetTeamReviewSettingsV2Args = {
  sections: Array<TableSectionKind>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationResolveOwnershipConflictsArgs = {
  input: ResolveConflictsInput;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationResolveRoleConflictArgs = {
  input: ResolveRoleConflictInput;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationSetupUsersV2Args = {
  language?: InputMaybe<DisplayLanguage>;
  team: Scalars['ID']['input'];
  updateTeamFeatures?: InputMaybe<Scalars['Boolean']['input']>;
  users: UsersCreationInputV2;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUnassignUsersFromCompaniesArgs = {
  companies: Array<Scalars['ID']['input']>;
  team: Scalars['ID']['input'];
  users: Array<Scalars['ID']['input']>;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateAnnotationsArgs = {
  annotations: Array<AnnotationInput>;
  event: Scalars['ID']['input'];
  selector: TextSelection;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateCompanyArgs = {
  aliases?: InputMaybe<Array<AliasInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<ContentLanguage>;
  name?: InputMaybe<Scalars['String']['input']>;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateCompanyStatusesArgs = {
  companies: Array<Scalars['ID']['input']>;
  portfolio: Scalars['ID']['input'];
  status?: InputMaybe<CompanyStatus>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateCounterPartyArgs = {
  comment: Scalars['String']['input'];
  counterPartyId: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateCreditMonitoringArgs = {
  company: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateCrmOrganizationNumberFieldArgs = {
  fieldName: Scalars['String']['input'];
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateCrmTagFieldArgs = {
  fieldName: Scalars['String']['input'];
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateCustomerArgs = {
  expiration?: InputMaybe<Scalars['ISODateTime']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationNumber?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['ISODateTime']['input']>;
  status: CustomerStatus;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateEntityArgs = {
  aliases?: InputMaybe<Array<AliasInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<ContentLanguage>;
  name?: InputMaybe<Scalars['String']['input']>;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateEntityBusinessRoleArgs = {
  entity: Scalars['ID']['input'];
  input: UpdateEntityBusinessRoleInput;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateEntityNotificationSettingsArgs = {
  inputs: Array<NotificationSettingInput>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateEntityOwnerArgs = {
  entity: Scalars['ID']['input'];
  input: UpdateEntityOwnerInput;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateEventHiddenArgs = {
  entity: Scalars['ID']['input'];
  event: Scalars['ID']['input'];
  hiddenStatus: EventHidden;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateEventLikeStatusArgs = {
  company: Scalars['ID']['input'];
  event: Scalars['ID']['input'];
  portfolio: Scalars['ID']['input'];
  status?: InputMaybe<LikeStatus>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateEventReadStatusArgs = {
  context?: InputMaybe<Scalars['ID']['input']>;
  events: Array<Scalars['ID']['input']>;
  read: Scalars['Boolean']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateNotificationsArgs = {
  notifications: Array<Scalars['ID']['input']>;
  status: NotificationStatus;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdatePortfolioArgs = {
  name: Scalars['String']['input'];
  portfolio: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateRateLimitArgs = {
  interval?: InputMaybe<RecurringInterval>;
  quota?: InputMaybe<Scalars['Int']['input']>;
  rateLimit: Scalars['ID']['input'];
  resetAt?: InputMaybe<Scalars['ISODateTime']['input']>;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateStickerNameArgs = {
  name: Scalars['String']['input'];
  sticker: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateTeamArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateTeamActivityV2Args = {
  input: TeamActivityUpdateInput;
  teamActivity: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateTeamFeaturesV2Args = {
  features: Array<FeatureInput>;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateTeamSettingsV2Args = {
  input: TeamSettingsInput;
  team: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateTeamUserArgs = {
  features?: InputMaybe<Array<FeatureInput>>;
  role?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<TeamUserStatus>;
  team: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateTopicArgs = {
  aliases?: InputMaybe<Array<AliasInput>>;
  children?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<ContentLanguage>;
  name?: InputMaybe<Scalars['String']['input']>;
  topLevel?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateUserArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  user: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateUserFeaturesV2Args = {
  features: Array<FeatureInput>;
  team: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


/** The root mutation object. From here you may update the Mito.ai Graph. */
export type MutationUpdateUserSettingsArgs = {
  input: UserSettingsInput;
  user: Scalars['ID']['input'];
};

/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQuery = {
  __typename: 'MutationQuery';
  activityStats?: Maybe<ActivityStatsView>;
  /** Fetch admin dashboard stats. */
  adminStats: AdminDashboard;
  clientCredentials: ApiClient;
  /** Find company by ID. */
  company: Company;
  companyByOrganizationNumber: Company;
  /** Compare a list of companies. */
  compareCompanies: ComparedCompanies;
  /** A team's connected app of a specific kind */
  connectedApp?: Maybe<ConnectedApp>;
  /** A list of a team's connected apps */
  connectedApps: ConnectedAppConnection;
  countries: LocationConnection;
  /** Fetch the company schema from CRM. */
  crmCompanySchema?: Maybe<CrmSchema>;
  /** Fetch the CRM sync info for a portfolio. */
  crmSyncInfo?: Maybe<CrmSyncInfo>;
  /** Returns the current user */
  currentUser?: Maybe<User>;
  /** Fetch a customer. */
  customer: Customer;
  customerUsageReportCsv: Scalars['String']['output'];
  /** Search for customers. */
  customers: CustomerConnection;
  /** Default locations for quick location lookup */
  defaultCountries: LocationConnection;
  defaultFeatures: Array<Feature>;
  defaultFinancialFieldsByContentLanguage: Array<FinancialFieldInfo>;
  /** The default settings for this team. */
  defaultSettings: Settings;
  /** Find entities by query string. */
  entities: EntityLikeConnection;
  /** Find entity by ID. Retrieves the entity if found, else `null`. */
  entity: EntityLike;
  /** Find entity by ID. Retrieves the entity if found, else `null`. */
  entityOLD: Entity;
  entityRoleConflicts: Array<RoleConflict>;
  entityTypes: Array<ApplicationSearchReturnType>;
  /** Retrieve a event by the internal GraphQL ID or URL. If the item doesn't exists null is returned. */
  event: Event;
  /** Search for events. */
  events: EventConnection;
  financialFields: Array<FinancialFieldInfo>;
  flagKinds: Array<FlagKindInfo>;
  /** Find global company by ID. */
  globalCompany: GlobalCompany;
  /** Find global person by ID. */
  globalPersonV2: GlobalPerson;
  /** Fetch top-level industries. */
  industries: IndustryConnection;
  /** Fetch industry. */
  industry: Industry;
  /** Fetch an integration by team. */
  integration: Integration;
  /** Fetch the integrations by team. */
  integrations: IntegrationsConnection;
  /** Fetch the latest rate limit by kind. */
  latestRateLimit?: Maybe<RateLimit>;
  legalForms: LegalFormConnection;
  /** Fetch a list of companies and associated metadata. */
  listView: SimpleCompanyConnection;
  /** Fetch a location by ID. */
  location: Location;
  /** Search all locations. */
  locationsV2: LocationConnection;
  /** Retrieve a notification by id. */
  notification: Notification;
  /** List notifications. */
  notifications: NotificationConnection;
  /** Fetch opportunities. */
  opportunityCompanies: OpportunityCompanyConnection;
  /** Find person by ID. */
  person: Person;
  /** Find companies and persons by query string. */
  personsOrCompanies: SearchResultConnection;
  /** The list of possible positive flag kinds, used in opportunity filtering. */
  positiveFlagKinds: Array<FlagKindInfo>;
  /** Find private person by ID. */
  privatePerson?: Maybe<PrivatePerson>;
  /** Fetch a rate limit by id. */
  rateLimit: RateLimit;
  rateLimits: RateLimitConnection;
  /** Find relationships by query string. */
  relationships: RelationshipConnection;
  review: Review;
  reviewAiSummary: ReviewAiSummary;
  reviewCompanyData: ReviewCompanyData;
  reviewPdfSections: Array<ReviewPdfSection>;
  reviewStatementInfoV2: Array<ReviewTriggerStatementInfo>;
  reviewStats: Array<ReviewStats>;
  roleConflicts: Array<RoleConflict>;
  roleTitles: Array<RoleTitle>;
  /** Returns the various roles in the Strise system. */
  roles: Array<UserRole>;
  /** Fetch a single Simple User by ID. */
  simpleUser: SimpleUser;
  /** Fetches the SSO connections for the given domain. */
  ssoConfiguration?: Maybe<TeamSsoConfiguration>;
  /** Fetch a single Sticker by id. */
  sticker: Sticker;
  /** The Strise GraphQL API */
  strise: Scalars['String']['output'];
  /** Fetch an team. */
  team: Team;
  teamActivities: TeamActivityConnection;
  teamActivity: TeamActivity;
  /** The team this user is member of. */
  teams: TeamConnection;
  /** Find topic by ID. */
  topic: Topic;
  /** Find topics by query string. */
  topics: TopicConnection;
  unresolvedRoleConflictCount: Scalars['Int']['output'];
  /** Fetch a single user by ID. */
  user: User;
  /** Search for a user by query. */
  users: UserConnection;
  /** Validate a CSV file to be used in portfolio population. */
  validatePortfolioCsv: ParsedPortfolioCsv;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryActivityStatsArgs = {
  team: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryClientCredentialsArgs = {
  userId: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryCompanyByOrganizationNumberArgs = {
  country: ContentLanguage;
  organizationNumber: Scalars['OrganizationNumber']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryCompareCompaniesArgs = {
  consolidated: Scalars['Boolean']['input'];
  ids: Array<Scalars['ID']['input']>;
  year: Scalars['Int']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryConnectedAppArgs = {
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryConnectedAppsArgs = {
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryCountriesArgs = {
  includeCollections: Scalars['Boolean']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryCrmCompanySchemaArgs = {
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryCrmSyncInfoArgs = {
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryCurrentUserArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryCustomerArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryCustomerUsageReportCsvArgs = {
  from: Scalars['ISODateTime']['input'];
  id: Scalars['ID']['input'];
  to: Scalars['ISODateTime']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryCustomersArgs = {
  filter?: InputMaybe<CustomersFilterInput>;
  page?: InputMaybe<CustomerPageInfoInput>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryDefaultCountriesArgs = {
  filter?: InputMaybe<Array<ContentLanguage>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryDefaultFeaturesArgs = {
  excludeFeatureCategories?: InputMaybe<Array<FeatureCategoryKind>>;
  includeFeatureCategories?: InputMaybe<Array<FeatureCategoryKind>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryDefaultFinancialFieldsByContentLanguageArgs = {
  contentLanguage: ContentLanguage;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryDefaultSettingsArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryEntitiesArgs = {
  entitySelector?: InputMaybe<Array<EntitySelector>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  language?: InputMaybe<ContentLanguage>;
  q?: InputMaybe<Scalars['String']['input']>;
  relationshipFilters?: InputMaybe<Array<RelationshipFilterInput>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryEntityArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryEntityOldArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryEntityRoleConflictsArgs = {
  entities: Array<Scalars['ID']['input']>;
  resolved: Scalars['Boolean']['input'];
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryEntityTypesArgs = {
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryEventsArgs = {
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryFinancialFieldsArgs = {
  countries?: InputMaybe<Array<ContentLanguage>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryFlagKindsArgs = {
  countries?: InputMaybe<Array<ContentLanguage>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryGlobalCompanyArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryGlobalPersonV2Args = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryIndustriesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryIndustryArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryIntegrationArgs = {
  id: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryIntegrationsArgs = {
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryLatestRateLimitArgs = {
  customer: Scalars['ID']['input'];
  kind: RateLimitKind;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryLegalFormsArgs = {
  countries?: InputMaybe<Array<ContentLanguage>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryListViewArgs = {
  page?: InputMaybe<ListViewPageInfoInput>;
  portfolio: Scalars['ID']['input'];
  settings: ListViewSettingsInput;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryLocationArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryLocationsV2Args = {
  first?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Array<LocationKind>>;
  q: Scalars['String']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryNotificationArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryNotificationsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryOpportunityCompaniesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  portfolio: Scalars['ID']['input'];
  settings: OpportunitySettingsInput;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryPersonArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryPersonsOrCompaniesArgs = {
  input: ApplicationSearchInput;
  q: Scalars['String']['input'];
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryPositiveFlagKindsArgs = {
  countries?: InputMaybe<Array<ContentLanguage>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryPrivatePersonArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryRateLimitArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryRateLimitsArgs = {
  customer?: InputMaybe<Scalars['ID']['input']>;
  kind?: InputMaybe<RateLimitKind>;
  latestOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryRelationshipsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryReviewArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryReviewAiSummaryArgs = {
  summaryKind?: InputMaybe<SummarySelection>;
  team: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryReviewCompanyDataArgs = {
  excludeSections: Array<ReviewSectionKind>;
  id: Scalars['ID']['input'];
  includeSections: Array<ReviewSectionKind>;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryReviewStatementInfoV2Args = {
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryReviewStatsArgs = {
  from?: InputMaybe<Scalars['ISODateTime']['input']>;
  teams: Array<Scalars['ID']['input']>;
  to?: InputMaybe<Scalars['ISODateTime']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryRoleConflictsArgs = {
  resolved: Scalars['Boolean']['input'];
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryRoleTitlesArgs = {
  contentLanguage?: InputMaybe<ContentLanguage>;
  useGlobal?: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQuerySimpleUserArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQuerySsoConfigurationArgs = {
  domain: Scalars['String']['input'];
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryStickerArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryTeamArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryTeamActivitiesArgs = {
  filter?: InputMaybe<TeamActivityFilterKind>;
  page: TeamActivityPageInfoInput;
  reminderStatus?: InputMaybe<ReminderStatus>;
  team: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryTeamActivityArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryTopicArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryTopicsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  topLevel: Scalars['Boolean']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryUnresolvedRoleConflictCountArgs = {
  entities: Array<Scalars['ID']['input']>;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryUserArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryUsersArgs = {
  filter?: InputMaybe<UserSearchFilterInput>;
  page?: InputMaybe<UserPageInfoInput>;
  q?: InputMaybe<Scalars['String']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type MutationQueryValidatePortfolioCsvArgs = {
  csv: Scalars['String']['input'];
  team: Scalars['ID']['input'];
};

/** The notification for a user */
export type Notification = {
  __typename: 'Notification';
  /** The body of the notification. */
  body: Scalars['String']['output'];
  category: NotificationCategory;
  /** The creation date of the notification. */
  created: Scalars['ISODateTime']['output'];
  /** The creator of the notification */
  createdBy?: Maybe<SimpleUser>;
  /** The entity of the notification */
  entity?: Maybe<EntityLike>;
  /** The flag of the notification */
  event?: Maybe<Event>;
  /** The event id of the notification */
  eventId?: Maybe<Scalars['ID']['output']>;
  /** The creator of the notification */
  flag?: Maybe<AdverseFlag>;
  /** The ID of the notification. */
  id: Scalars['ID']['output'];
  /** The image of the notification */
  image: Scalars['String']['output'];
  /** The kind of the notification. */
  kind?: Maybe<NotificationKind>;
  /** The message of the notification. */
  message?: Maybe<Scalars['String']['output']>;
  /** The user the notification is about */
  otherUser?: Maybe<SimpleUser>;
  /** The date of when user processed the notification. */
  processedAt?: Maybe<Scalars['ISODateTime']['output']>;
  /** The date of when the user read the notification. */
  readAt?: Maybe<Scalars['ISODateTime']['output']>;
  /** The status of the notification. */
  status: NotificationStatus;
  /** The team of the notification. */
  team?: Maybe<Team>;
  /** The title of the notification. */
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  /** The owner of the notification. */
  user: SimpleUser;
};

/** An enum representing supported notification categories */
export enum NotificationCategory {
  Choice = 'CHOICE',
  Info = 'INFO'
}

export type NotificationConnection = {
  __typename: 'NotificationConnection';
  edges: Array<NotificationConnectionEdge>;
  unreadCount: Scalars['Int']['output'];
};

/** An edge containing a notification on an entity. */
export type NotificationConnectionEdge = {
  __typename: 'NotificationConnectionEdge';
  /** The notification. */
  node: Notification;
};

/** An enum representing supported notification setting statuses */
export enum NotificationKind {
  BeneficialOwnerUpdated = 'BENEFICIAL_OWNER_UPDATED',
  CompanyDataUpdated = 'COMPANY_DATA_UPDATED',
  CreditUpdated = 'CREDIT_UPDATED',
  EntityAssigned = 'ENTITY_ASSIGNED',
  EntityUnassigned = 'ENTITY_UNASSIGNED',
  FlagUpdated = 'FLAG_UPDATED',
  OwnershipConflict = 'OWNERSHIP_CONFLICT',
  ReminderAssigned = 'REMINDER_ASSIGNED',
  ReviewTriggered = 'REVIEW_TRIGGERED',
  RoleConflict = 'ROLE_CONFLICT',
  TeamActivityAssigned = 'TEAM_ACTIVITY_ASSIGNED',
  UserRequested = 'USER_REQUESTED'
}

/** The user's notification setting for an entity */
export type NotificationSetting = {
  __typename: 'NotificationSetting';
  created: Scalars['ISODateTime']['output'];
  entity?: Maybe<EntityLike>;
  status: NotificationSettingStatus;
  user: SimpleUser;
};

export type NotificationSettingInput = {
  entity: Scalars['ID']['input'];
  status: NotificationSettingStatus;
};

/** An enum representing supported notification setting statuses */
export enum NotificationSettingStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

/** An enum representing supported notification statuses */
export enum NotificationStatus {
  Approved = 'APPROVED',
  Disapproved = 'DISAPPROVED',
  Ignored = 'IGNORED',
  Processed = 'PROCESSED',
  Read = 'READ',
  Unread = 'UNREAD'
}

/** An union type of external entity refs and entities. */
export type Offset = {
  /** Some offset */
  offset: Scalars['Int']['output'];
};

export type OpportunityCompanyConnection = {
  __typename: 'OpportunityCompanyConnection';
  edges: Array<OpportunityCompanyConnectionEdge>;
};

export type OpportunityCompanyConnectionEdge = {
  __typename: 'OpportunityCompanyConnectionEdge';
  node: Company;
};

/** Number of highlighted financials to show on opportunities. */
export enum OpportunityFinancialHighlightCount {
  Six = 'SIX',
  Three = 'THREE'
}

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type OpportunitySettings = {
  __typename: 'OpportunitySettings';
  /** Only include opportunities with these accountants. */
  accountants?: Maybe<Array<Scalars['ID']['output']>>;
  /** Compute opportunities based on those already assigned to the user. Empty filter means all companies in portfolio. */
  assignees?: Maybe<Array<Scalars['ID']['output']>>;
  /** Only include opportunities with these collateral creditors. */
  collateralCreditors?: Maybe<Array<Scalars['ID']['output']>>;
  /** Exclude this companies. */
  companiesExclude?: Maybe<Array<Scalars['ID']['output']>>;
  /** Limiting the companies, based on number of employees. */
  employees?: Maybe<EmployeeFilter>;
  /** Filter companies based on financial statements. */
  financials?: Maybe<FinancialFilterV2>;
  /** Only include companies with active flags of this kind */
  flagKinds?: Maybe<Array<Scalars['ID']['output']>>;
  /** Only include companies with inception within this timespan. */
  inception?: Maybe<MonthsAgoFilter>;
  /** Include only from these industries. */
  industries?: Maybe<Array<Scalars['ID']['output']>>;
  /** Only include companies with these legal forms. */
  legalForms?: Maybe<Array<LegalFormKind>>;
  /** List of location IDs to filter on. */
  locations?: Maybe<LocationFilter>;
  /** Only include opportunities with these real estate collateral creditors. */
  realEstateCollateralCreditors?: Maybe<Array<Scalars['ID']['output']>>;
  /** Whether or not to require accountant for the opportunities. */
  requireNoAccountant?: Maybe<Scalars['Boolean']['output']>;
  /** Compute opportunities based on companies with these stickers. No filter means all stickers. */
  stickers?: Maybe<Array<Scalars['ID']['output']>>;
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type OpportunitySettingsInput = {
  /** Only include opportunities with these accountants. */
  accountants?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Compute opportunities based on those already assigned to the user. Empty filter means all companies in portfolio. */
  assignees?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Only include opportunities with these collateral creditors. */
  collateralCreditors?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Exclude this companies. */
  companiesExclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Limiting the companies, based on number of employees. */
  employees?: InputMaybe<EmployeeFilterInput>;
  /** Filter companies based on financial statements. */
  financials?: InputMaybe<FinancialFilterV2Input>;
  /** Only include companies with active flags of this kind */
  flagKinds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Only include companies with inception within this timespan. */
  inception?: InputMaybe<InceptionFilterInput>;
  /** Include only from these industries. */
  industries?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Only include companies with these legal forms. */
  legalForms?: InputMaybe<Array<LegalFormKind>>;
  /** List of location IDs to filter on. */
  locations?: InputMaybe<LocationFilterInput>;
  /** Only include opportunities with these real estate collateral creditors. */
  realEstateCollateralCreditors?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Whether or not to require accountant for the opportunities. */
  requireNoAccountant?: InputMaybe<Scalars['Boolean']['input']>;
  /** Compute opportunities based on companies with these stickers. No filter means all stickers. */
  stickers?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OwnershipChart = {
  __typename: 'OwnershipChart';
  edges: Array<OwnershipChartEdge>;
  hasCustomOwners: Scalars['Boolean']['output'];
  hasGlobalOwners: Scalars['Boolean']['output'];
  lastModifiedAt?: Maybe<Scalars['ISODateTime']['output']>;
  nodes: Array<OwnershipChartNode>;
};

export type OwnershipChartEdge = {
  __typename: 'OwnershipChartEdge';
  child: Scalars['ID']['output'];
  customMeta?: Maybe<CustomMeta>;
  parent: Scalars['ID']['output'];
  share: Scalars['String']['output'];
};

export type OwnershipChartNode = {
  __typename: 'OwnershipChartNode';
  beneficialOwnerMeta?: Maybe<BeneficialOwnerMeta>;
  entity?: Maybe<EntityLike>;
  hasGlobalOwners: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  indirectShare: Scalars['String']['output'];
  indirectShareValue: Scalars['Float']['output'];
  isLeaf: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  shareClasses: Array<ShareClass>;
};

export type OwnershipConflict = ConflictLike & {
  __typename: 'OwnershipConflict';
  /** The conflicting part. */
  conflictingPart: OwnershipConflictPart;
  /** The current custom part. */
  customPart: OwnershipConflictPart;
  /** Entity which has the conflict. */
  entity: EntityLike;
  id: Scalars['String']['output'];
  /** The reason for the conflict. */
  reason?: Maybe<ConflictReasonKind>;
  /** If the conflict is resolved or not */
  resolved: Scalars['Boolean']['output'];
  /** The user who resolved the conflict */
  resolvedBy?: Maybe<SimpleUser>;
  /** Which part of the conflict was selected when resolved. */
  resolvedSelection?: Maybe<RoleConflictSelectionKind>;
};

export type OwnershipConflictPart = {
  __typename: 'OwnershipConflictPart';
  /** The root entity of the ownership */
  entity?: Maybe<EntityLike>;
  /** Information about the ownership */
  ownership?: Maybe<OwnershipChart>;
};

/** Information regarding the current connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** The cursor of the last result. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** Whether there is a next page. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Whether backwards-pagination is enabled and there is a previous page. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** The size of each page. */
  pageSize: Scalars['Long']['output'];
  /** The total number of results found. */
  totalCount: Scalars['Long']['output'];
};

export type ParsedPortfolioCsv = {
  __typename: 'ParsedPortfolioCsv';
  /** The line numbers of duplicate rows. */
  duplicates: Array<Scalars['Int']['output']>;
  /** Potential errors that happened when trying to parse the portfolio. */
  errors: Array<PortfolioParsingError>;
  /** The raw lines from the csv. */
  lines: Array<Scalars['String']['output']>;
  /** The parsed CSV rows, which can be passed on to set up the portfolio. */
  rows: Array<PortfolioCompanyRow>;
};

/** An object representing a payment remark. */
export type PaymentRemark = {
  __typename: 'PaymentRemark';
  /** The amount of the remark. */
  amount: Scalars['Float']['output'];
  /** The creditor which filed this remark. */
  creditor?: Maybe<EntityLike>;
  /** The name of the creditor which filed this remark. */
  creditorName: Scalars['String']['output'];
  /** The date of this remark. */
  date: Scalars['ISODateTime']['output'];
  /** The id of this payment remark. */
  id: Scalars['ID']['output'];
  /** The kind of this remark. */
  kind: Scalars['String']['output'];
  /** Whether or not the payment remark is partially settled. */
  partiallySettled: Scalars['Boolean']['output'];
  /** A reference number for this remark. */
  referenceNumber?: Maybe<Scalars['String']['output']>;
  /** The source through which this remark was reported. */
  source: Scalars['String']['output'];
};

export type PaymentRemarkConnection = {
  __typename: 'PaymentRemarkConnection';
  edges: Array<PaymentRemarkConnectionEdge>;
};

export type PaymentRemarkConnectionEdge = {
  __typename: 'PaymentRemarkConnectionEdge';
  /** The payment remark. */
  node: PaymentRemark;
};

/** A resource holding PEP information. */
export type PepInfo = {
  __typename: 'PepInfo';
  address: Address;
  birthDate?: Maybe<Scalars['ISODateTime']['output']>;
  birthDateV2?: Maybe<Scalars['String']['output']>;
  countries: Array<Country>;
  disposition?: Maybe<EntityDisposition>;
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  matchPercentage?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  pep?: Maybe<Scalars['Boolean']['output']>;
  probablyTruePositive: Scalars['Boolean']['output'];
  /** Whether or not the person is a RCA (Relatives and Close Associates of a PEP). */
  rca?: Maybe<Scalars['Boolean']['output']>;
  /** A list of related PEPs for the person. */
  relations: Array<PepRelation>;
  /** A list of PEP-roles for the person. */
  roles: Array<PepRole>;
};

/** A pep role. */
export type PepRelation = {
  __typename: 'PepRelation';
  /** The start date of the role. */
  from?: Maybe<Scalars['ISODateTime']['output']>;
  /** The pep person */
  relatedPep: PepInfo;
  relationDescription?: Maybe<Scalars['String']['output']>;
  /** The category name of the role. */
  relationName?: Maybe<Scalars['String']['output']>;
};

/** A pep role. */
export type PepRole = {
  __typename: 'PepRole';
  /** Whether or not the role is active. */
  active?: Maybe<Scalars['Boolean']['output']>;
  countryOfJurisdiction?: Maybe<ContentLanguage>;
  /** The start date of the role. */
  from?: Maybe<Scalars['ISODateTime']['output']>;
  /** The role description localized by the country of jurisdiction. */
  localizedRoleDescription?: Maybe<Scalars['String']['output']>;
  /** The category name of the role. */
  roleCategory?: Maybe<Scalars['String']['output']>;
  /** The end date of the role. */
  to?: Maybe<Scalars['ISODateTime']['output']>;
};

/** An object representing a person. */
export type Person = EntityLike & {
  __typename: 'Person';
  /** Access information for this entity. */
  access: EntityAccess;
  address?: Maybe<Address>;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  birthDate?: Maybe<Scalars['ISODateTime']['output']>;
  countries: Array<Country>;
  dataSources: EntityDataSources;
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  gender?: Maybe<Gender>;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  /** Determines whether the company is a global person. */
  isGlobalPerson: Scalars['Boolean']['output'];
  keyMeta: Table;
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  /** The nationality of the person. */
  nationality?: Maybe<ContentLanguage>;
  notificationSetting?: Maybe<NotificationSetting>;
  numOwnerships: Scalars['Int']['output'];
  /** The ownerships of the company based on percentage owned. */
  ownerships: ShareholderConnection;
  /** Pep information. Costs ~2 kr per new request. */
  pepInfo?: Maybe<PepInfo>;
  pepInfoV2: Array<PepInfo>;
  personInformation: Table;
  primaryCountry?: Maybe<Country>;
  /** Registers for this entity. */
  registers: RegisterConnection;
  roleTableV2: Table;
  sanctionInfo: SanctionInfoV2;
  similarEntities: SearchResultConnection;
};


/** An object representing a person. */
export type PersonAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a person. */
export type PersonDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a person. */
export type PersonEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a person. */
export type PersonNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a person. */
export type PersonNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a person. */
export type PersonNumOwnershipsArgs = {
  from?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};


/** An object representing a person. */
export type PersonOwnershipsArgs = {
  from?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};


/** An object representing a person. */
export type PersonPepInfoArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a person. */
export type PersonPepInfoV2Args = {
  team: Scalars['ID']['input'];
};


/** An object representing a person. */
export type PersonRoleTableV2Args = {
  filterActive?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreCustomRoles: Scalars['Boolean']['input'];
  team: Scalars['ID']['input'];
};


/** An object representing a person. */
export type PersonSanctionInfoArgs = {
  team: Scalars['ID']['input'];
};


/** An object representing a person. */
export type PersonSimilarEntitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  team: Scalars['ID']['input'];
};

export type PersonConnection = {
  __typename: 'PersonConnection';
  edges: Array<PersonConnectionEdge>;
};

export type PersonConnectionEdge = {
  __typename: 'PersonConnectionEdge';
  node: Person;
};

/** An representation of portfolios, which are a collection of entities. */
export type Portfolio = {
  __typename: 'Portfolio';
  /** The companies in the portfolio. */
  companies: PortfolioCompanyConnection;
  /** The number of companies in this portfolio. */
  companyCount: Scalars['Int']['output'];
  /** The name of the portfolio. */
  created: Scalars['ISODateTime']['output'];
  /** The ID of the portfolio. */
  id: Scalars['ID']['output'];
  /** The name of the portfolio. */
  name: Scalars['String']['output'];
};


/** An representation of portfolios, which are a collection of entities. */
export type PortfolioCompaniesArgs = {
  filter?: InputMaybe<CompanyFilterInput>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type PortfolioCompanyConnection = {
  __typename: 'PortfolioCompanyConnection';
  edges: Array<PortfolioCompanyConnectionEdge>;
};

export type PortfolioCompanyConnectionEdge = {
  __typename: 'PortfolioCompanyConnectionEdge';
  node: Company;
};

export type PortfolioCompanyRow = {
  __typename: 'PortfolioCompanyRow';
  /** The assignees. */
  assignees: Array<SimpleUser>;
  /** The company, if it was found. */
  company?: Maybe<Company>;
  /** The errors connected to the row, if it exists. */
  errors: Array<PortfolioParsingError>;
  /** The status of the company. */
  status?: Maybe<CompanyStatus>;
  /** The list of tags to add to the company. */
  tags: Array<PortfolioTag>;
};

export type PortfolioCompanyRowInput = {
  /** The assignee, if it exists. */
  assignees: Array<Scalars['ID']['input']>;
  /** The ID of the company. */
  company: Scalars['ID']['input'];
  /** The status of the company. */
  status: CompanyStatus;
  /** The list of tags to add to the company. */
  tags: Array<Scalars['String']['input']>;
};

export type PortfolioConnection = {
  __typename: 'PortfolioConnection';
  edges: Array<PortfolioConnectionEdge>;
};

export type PortfolioConnectionEdge = {
  __typename: 'PortfolioConnectionEdge';
  node: Portfolio;
};

/** An enum representing how to merge conflicts when uploading a portfolio. */
export enum PortfolioMergeStrategyKind {
  Ignore = 'IGNORE',
  Merge = 'MERGE',
  Overwrite = 'OVERWRITE'
}

export type PortfolioParsingError = {
  __typename: 'PortfolioParsingError';
  /** The line number of the error. */
  line?: Maybe<Scalars['Int']['output']>;
  /** The error message. */
  message: Scalars['String']['output'];
};

export type PortfolioTag = {
  __typename: 'PortfolioTag';
  /** The existing tag, if it exists. */
  existing?: Maybe<Sticker>;
  /** The name of the tag. */
  name: Scalars['String']['output'];
};

/** A representation of Settings used for control output terms. */
export type PrivacySettings = {
  __typename: 'PrivacySettings';
  /** Terms agreement. */
  generalTerms?: Maybe<Scalars['Boolean']['output']>;
  /** Privacy agreement. */
  privacyTerms?: Maybe<Scalars['Boolean']['output']>;
};

/** A representation of Settings used for control output terms. */
export type PrivacySettingsInput = {
  /** Terms agreement. */
  generalTerms?: InputMaybe<Scalars['Boolean']['input']>;
  /** Privacy agreement. */
  privacyTerms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A resource representing a private person. */
export type PrivatePerson = EntityLike & {
  __typename: 'PrivatePerson';
  /** Access information for this entity. */
  access: EntityAccess;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  dataSources: EntityDataSources;
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  /** Whether this private person is monitored or not. */
  isMonitored: Scalars['Boolean']['output'];
  keyMeta: Table;
  /** Business persons that may be the same as this private person. */
  matchingBusinessPersons: PersonConnection;
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  personInformation: Table;
  privatePersonId: Scalars['ID']['output'];
  /** Registers for this entity. */
  registers: RegisterConnection;
  sanctionInfo: SanctionInfoV2;
};


/** A resource representing a private person. */
export type PrivatePersonAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** A resource representing a private person. */
export type PrivatePersonDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** A resource representing a private person. */
export type PrivatePersonEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** A resource representing a private person. */
export type PrivatePersonIsMonitoredArgs = {
  team: Scalars['ID']['input'];
};


/** A resource representing a private person. */
export type PrivatePersonNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** A resource representing a private person. */
export type PrivatePersonNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** A resource representing a private person. */
export type PrivatePersonSanctionInfoArgs = {
  team: Scalars['ID']['input'];
};

/** A connection containing properties. */
export type PropertyConnection = {
  __typename: 'PropertyConnection';
  /** The properties as edges. */
  edges: Array<PropertyConnectionEdge>;
};

/** An edge containing the property and the corresponding relationship. */
export type PropertyConnectionEdge = {
  __typename: 'PropertyConnectionEdge';
  /** The property value. */
  node: PropertyValue;
  /** Indicating the relationship between the nodes of the edge. */
  relationship: Relationship;
};

/** An interface for property values. The concrete types implement custom logic for handling the different type of values. */
export type PropertyValue = {
  /** An string value, which is always present. */
  value: Scalars['String']['output'];
};

/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type Query = {
  __typename: 'Query';
  activityStats?: Maybe<ActivityStatsView>;
  /** Fetch admin dashboard stats. */
  adminStats: AdminDashboard;
  clientCredentials: ApiClient;
  /** Find company by ID. */
  company: Company;
  companyByOrganizationNumber: Company;
  /** Compare a list of companies. */
  compareCompanies: ComparedCompanies;
  /** A team's connected app of a specific kind */
  connectedApp?: Maybe<ConnectedApp>;
  /** A list of a team's connected apps */
  connectedApps: ConnectedAppConnection;
  countries: LocationConnection;
  /** Fetch the company schema from CRM. */
  crmCompanySchema?: Maybe<CrmSchema>;
  /** Fetch the CRM sync info for a portfolio. */
  crmSyncInfo?: Maybe<CrmSyncInfo>;
  /** Returns the current user */
  currentUser?: Maybe<User>;
  /** Fetch a customer. */
  customer: Customer;
  customerUsageReportCsv: Scalars['String']['output'];
  /** Search for customers. */
  customers: CustomerConnection;
  /** Default locations for quick location lookup */
  defaultCountries: LocationConnection;
  defaultFeatures: Array<Feature>;
  defaultFinancialFieldsByContentLanguage: Array<FinancialFieldInfo>;
  /** The default settings for this team. */
  defaultSettings: Settings;
  /** Find entities by query string. */
  entities: EntityLikeConnection;
  /** Find entity by ID. Retrieves the entity if found, else `null`. */
  entity: EntityLike;
  /** Find entity by ID. Retrieves the entity if found, else `null`. */
  entityOLD: Entity;
  entityRoleConflicts: Array<RoleConflict>;
  entityTypes: Array<ApplicationSearchReturnType>;
  /** Retrieve a event by the internal GraphQL ID or URL. If the item doesn't exists null is returned. */
  event: Event;
  /** Search for events. */
  events: EventConnection;
  financialFields: Array<FinancialFieldInfo>;
  flagKinds: Array<FlagKindInfo>;
  /** Find global company by ID. */
  globalCompany: GlobalCompany;
  /** Find global person by ID. */
  globalPersonV2: GlobalPerson;
  /** Fetch top-level industries. */
  industries: IndustryConnection;
  /** Fetch industry. */
  industry: Industry;
  /** Fetch an integration by team. */
  integration: Integration;
  /** Fetch the integrations by team. */
  integrations: IntegrationsConnection;
  /** Fetch the latest rate limit by kind. */
  latestRateLimit?: Maybe<RateLimit>;
  legalForms: LegalFormConnection;
  /** Fetch a list of companies and associated metadata. */
  listView: SimpleCompanyConnection;
  /** Fetch a location by ID. */
  location: Location;
  /** Search all locations. */
  locationsV2: LocationConnection;
  /** Retrieve a notification by id. */
  notification: Notification;
  /** List notifications. */
  notifications: NotificationConnection;
  /** Fetch opportunities. */
  opportunityCompanies: OpportunityCompanyConnection;
  /** Find person by ID. */
  person: Person;
  /** Find companies and persons by query string. */
  personsOrCompanies: SearchResultConnection;
  /** The list of possible positive flag kinds, used in opportunity filtering. */
  positiveFlagKinds: Array<FlagKindInfo>;
  /** Find private person by ID. */
  privatePerson?: Maybe<PrivatePerson>;
  /** Fetch a rate limit by id. */
  rateLimit: RateLimit;
  rateLimits: RateLimitConnection;
  /** Find relationships by query string. */
  relationships: RelationshipConnection;
  review: Review;
  reviewAiSummary: ReviewAiSummary;
  reviewCompanyData: ReviewCompanyData;
  reviewPdfSections: Array<ReviewPdfSection>;
  reviewStatementInfoV2: Array<ReviewTriggerStatementInfo>;
  reviewStats: Array<ReviewStats>;
  roleConflicts: Array<RoleConflict>;
  roleTitles: Array<RoleTitle>;
  /** Returns the various roles in the Strise system. */
  roles: Array<UserRole>;
  /** Fetch a single Simple User by ID. */
  simpleUser: SimpleUser;
  /** Fetches the SSO connections for the given domain. */
  ssoConfiguration?: Maybe<TeamSsoConfiguration>;
  /** Fetch a single Sticker by id. */
  sticker: Sticker;
  /** The Strise GraphQL API */
  strise: Scalars['String']['output'];
  /** Fetch an team. */
  team: Team;
  teamActivities: TeamActivityConnection;
  teamActivity: TeamActivity;
  /** The team this user is member of. */
  teams: TeamConnection;
  /** Find topic by ID. */
  topic: Topic;
  /** Find topics by query string. */
  topics: TopicConnection;
  unresolvedRoleConflictCount: Scalars['Int']['output'];
  /** Fetch a single user by ID. */
  user: User;
  /** Search for a user by query. */
  users: UserConnection;
  /** Validate a CSV file to be used in portfolio population. */
  validatePortfolioCsv: ParsedPortfolioCsv;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryActivityStatsArgs = {
  team: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryClientCredentialsArgs = {
  userId: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryCompanyByOrganizationNumberArgs = {
  country: ContentLanguage;
  organizationNumber: Scalars['OrganizationNumber']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryCompareCompaniesArgs = {
  consolidated: Scalars['Boolean']['input'];
  ids: Array<Scalars['ID']['input']>;
  year: Scalars['Int']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryConnectedAppArgs = {
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryConnectedAppsArgs = {
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryCountriesArgs = {
  includeCollections: Scalars['Boolean']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryCrmCompanySchemaArgs = {
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryCrmSyncInfoArgs = {
  kind: ConnectedAppKind;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryCurrentUserArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryCustomerArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryCustomerUsageReportCsvArgs = {
  from: Scalars['ISODateTime']['input'];
  id: Scalars['ID']['input'];
  to: Scalars['ISODateTime']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryCustomersArgs = {
  filter?: InputMaybe<CustomersFilterInput>;
  page?: InputMaybe<CustomerPageInfoInput>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryDefaultCountriesArgs = {
  filter?: InputMaybe<Array<ContentLanguage>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryDefaultFeaturesArgs = {
  excludeFeatureCategories?: InputMaybe<Array<FeatureCategoryKind>>;
  includeFeatureCategories?: InputMaybe<Array<FeatureCategoryKind>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryDefaultFinancialFieldsByContentLanguageArgs = {
  contentLanguage: ContentLanguage;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryDefaultSettingsArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryEntitiesArgs = {
  entitySelector?: InputMaybe<Array<EntitySelector>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  language?: InputMaybe<ContentLanguage>;
  q?: InputMaybe<Scalars['String']['input']>;
  relationshipFilters?: InputMaybe<Array<RelationshipFilterInput>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryEntityArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryEntityOldArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryEntityRoleConflictsArgs = {
  entities: Array<Scalars['ID']['input']>;
  resolved: Scalars['Boolean']['input'];
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryEntityTypesArgs = {
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryEventsArgs = {
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryFinancialFieldsArgs = {
  countries?: InputMaybe<Array<ContentLanguage>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryFlagKindsArgs = {
  countries?: InputMaybe<Array<ContentLanguage>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryGlobalCompanyArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryGlobalPersonV2Args = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryIndustriesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryIndustryArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryIntegrationArgs = {
  id: Scalars['ID']['input'];
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryIntegrationsArgs = {
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryLatestRateLimitArgs = {
  customer: Scalars['ID']['input'];
  kind: RateLimitKind;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryLegalFormsArgs = {
  countries?: InputMaybe<Array<ContentLanguage>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryListViewArgs = {
  page?: InputMaybe<ListViewPageInfoInput>;
  portfolio: Scalars['ID']['input'];
  settings: ListViewSettingsInput;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryLocationArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryLocationsV2Args = {
  first?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Array<LocationKind>>;
  q: Scalars['String']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryNotificationArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryNotificationsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryOpportunityCompaniesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  portfolio: Scalars['ID']['input'];
  settings: OpportunitySettingsInput;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryPersonArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryPersonsOrCompaniesArgs = {
  input: ApplicationSearchInput;
  q: Scalars['String']['input'];
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryPositiveFlagKindsArgs = {
  countries?: InputMaybe<Array<ContentLanguage>>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryPrivatePersonArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryRateLimitArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryRateLimitsArgs = {
  customer?: InputMaybe<Scalars['ID']['input']>;
  kind?: InputMaybe<RateLimitKind>;
  latestOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryRelationshipsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryReviewArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryReviewAiSummaryArgs = {
  summaryKind?: InputMaybe<SummarySelection>;
  team: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryReviewCompanyDataArgs = {
  excludeSections: Array<ReviewSectionKind>;
  id: Scalars['ID']['input'];
  includeSections: Array<ReviewSectionKind>;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryReviewStatementInfoV2Args = {
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryReviewStatsArgs = {
  from?: InputMaybe<Scalars['ISODateTime']['input']>;
  teams: Array<Scalars['ID']['input']>;
  to?: InputMaybe<Scalars['ISODateTime']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryRoleConflictsArgs = {
  resolved: Scalars['Boolean']['input'];
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryRoleTitlesArgs = {
  contentLanguage?: InputMaybe<ContentLanguage>;
  useGlobal?: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QuerySimpleUserArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QuerySsoConfigurationArgs = {
  domain: Scalars['String']['input'];
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryStickerArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryTeamArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryTeamActivitiesArgs = {
  filter?: InputMaybe<TeamActivityFilterKind>;
  page: TeamActivityPageInfoInput;
  reminderStatus?: InputMaybe<ReminderStatus>;
  team: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryTeamActivityArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryTopicArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryTopicsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  topLevel: Scalars['Boolean']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryUnresolvedRoleConflictCountArgs = {
  entities: Array<Scalars['ID']['input']>;
  team: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryUsersArgs = {
  filter?: InputMaybe<UserSearchFilterInput>;
  page?: InputMaybe<UserPageInfoInput>;
  q?: InputMaybe<Scalars['String']['input']>;
};


/** This is the root query type and as such will be your main entry-point to the Mito API when fetching data. */
export type QueryValidatePortfolioCsvArgs = {
  csv: Scalars['String']['input'];
  team: Scalars['ID']['input'];
};

/** An object representing a rate limit. */
export type RateLimit = {
  __typename: 'RateLimit';
  /** The reset interval of the rate limit. */
  created: Scalars['ISODateTime']['output'];
  /** The customer of the rate limit. */
  customer: Customer;
  /** The rate limit id. */
  id: Scalars['ID']['output'];
  /** The reset interval of the rate limit. */
  interval: RecurringInterval;
  /** The kind of the rate limit. */
  kind: RateLimitKind;
  /** The maximum number of requests that can be used. */
  quota: Scalars['Int']['output'];
  /** The reset interval of the rate limit. */
  resetAt: Scalars['ISODateTime']['output'];
  /** The number of requests already used. */
  used: Scalars['Int']['output'];
};

export type RateLimitConnection = {
  __typename: 'RateLimitConnection';
  edges: Array<RateLimitConnectionEdge>;
};

export type RateLimitConnectionEdge = {
  __typename: 'RateLimitConnectionEdge';
  node: RateLimit;
};

/** An enum representing rate limits. */
export enum RateLimitKind {
  CheckPepV2 = 'CHECK_PEP_V2',
  CreditScore = 'CREDIT_SCORE',
  GlobalEntityGet = 'GLOBAL_ENTITY_GET',
  GlobalOwnership = 'GLOBAL_OWNERSHIP',
  Opportunities = 'OPPORTUNITIES',
  Review = 'REVIEW',
  ReviewAiSummary = 'REVIEW_AI_SUMMARY'
}

export type RealEstate = {
  __typename: 'RealEstate';
  date: Scalars['ISODateTime']['output'];
  documents: Array<RealEstateDocument>;
  info: Array<Scalars['String']['output']>;
  ownership: Scalars['String']['output'];
  ownershipDate: Scalars['ISODateTime']['output'];
  warnings: Array<RealEstateWarning>;
};

export type RealEstateDocument = {
  __typename: 'RealEstateDocument';
  amount?: Maybe<Scalars['Long']['output']>;
  amountTooltip?: Maybe<Scalars['String']['output']>;
  creditors: Array<EntityLike>;
  currency?: Maybe<Scalars['String']['output']>;
  date: Scalars['ISODateTime']['output'];
  id: Scalars['String']['output'];
  otherCreditorNames: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type RealEstateWarning = {
  __typename: 'RealEstateWarning';
  date: Scalars['ISODateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/** An enum representing a recurring interval. */
export enum RecurringInterval {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type Register = {
  __typename: 'Register';
  date?: Maybe<Scalars['ISODateTime']['output']>;
  entity: EntityLike;
  event?: Maybe<Event>;
  isRegistered: Scalars['Boolean']['output'];
  kind: RegisterKind;
  name: Scalars['String']['output'];
  registerId?: Maybe<Scalars['String']['output']>;
  statusDescription: Scalars['String']['output'];
};

export type RegisterConnection = {
  __typename: 'RegisterConnection';
  edges: Array<RegisterConnectionEdge>;
};

export type RegisterConnectionEdge = {
  __typename: 'RegisterConnectionEdge';
  node: Register;
};

export enum RegisterKind {
  Advokatsamfundet = 'ADVOKATSAMFUNDET',
  AdvokatRegister = 'ADVOKAT_REGISTER',
  AsbestRegister = 'ASBEST_REGISTER',
  AutorisationsRegister = 'AUTORISATIONS_REGISTER',
  BemanningsRegister = 'BEMANNINGS_REGISTER',
  ByggRegister = 'BYGG_REGISTER',
  DkTattooRegister = 'DK_TATTOO_REGISTER',
  ElRegister = 'EL_REGISTER',
  Finansinspektionen = 'FINANSINSPEKTIONEN',
  FinanstilsynetDkRegister = 'FINANSTILSYNET_DK_REGISTER',
  FinansRegister = 'FINANS_REGISTER',
  FrivillighetsRegister = 'FRIVILLIGHETS_REGISTER',
  HelseRegister = 'HELSE_REGISTER',
  InnsamlingsRegister = 'INNSAMLINGS_REGISTER',
  Lei = 'LEI',
  MastarRegister = 'MASTAR_REGISTER',
  MerverdiavgiftsRegister = 'MERVERDIAVGIFTS_REGISTER',
  Mesterbrev = 'MESTERBREV',
  Miljofyrtorn = 'MILJOFYRTORN',
  ReisegarantiRegister = 'REISEGARANTI_REGISTER',
  RenholdsRegister = 'RENHOLDS_REGISTER',
  Smilefjes = 'SMILEFJES',
  Spillemyndigheden = 'SPILLEMYNDIGHEDEN',
  StatensVegvesen = 'STATENS_VEGVESEN',
  StiftelsesRegister = 'STIFTELSES_REGISTER',
  TransportorRegister = 'TRANSPORTOR_REGISTER',
  VatromsRegister = 'VATROMS_REGISTER'
}

/** A connection containing entities. */
export type RelationConnection = {
  __typename: 'RelationConnection';
  /** The relation as edges. */
  edges: Array<RelationConnectionEdge>;
};

/** An edge containing the entity and the corresponding relationship. */
export type RelationConnectionEdge = {
  __typename: 'RelationConnectionEdge';
  node: EntityLike;
  /** Indicating the relationship between the nodes of the edge. */
  relationship: Relationship;
};

/** Represents a relationship between two nodes, e.g. between entities, or entities and values. */
export type Relationship = {
  __typename: 'Relationship';
  /** The relationship ID. */
  id: Scalars['ID']['output'];
  /** Some name, if available. */
  name?: Maybe<Scalars['String']['output']>;
};

export type RelationshipConnection = {
  __typename: 'RelationshipConnection';
  edges: Array<RelationshipConnectionEdge>;
};

export type RelationshipConnectionEdge = {
  __typename: 'RelationshipConnectionEdge';
  node: Relationship;
};

/** The relationship filter can be used to filter outputted entities based on their properties and relationships. All fields are optional. */
export type RelationshipFilterInput = {
  /** The property value to filter by. */
  propertyValue?: InputMaybe<Scalars['String']['input']>;
  /** The relation entity id to filter by.  */
  relationId?: InputMaybe<Scalars['ID']['input']>;
  /** The relationship ID. */
  relationshipId?: InputMaybe<Scalars['ID']['input']>;
};

/** Relevance is an internal measurement of how relevant the entity is wrt. the news article. */
export type Relevance = {
  __typename: 'Relevance';
  /** The relevance score */
  score: Scalars['Float']['output'];
};

/** The reminder for a entity */
export type Reminder = {
  __typename: 'Reminder';
  completedAt?: Maybe<Scalars['ISODateTime']['output']>;
  created: Scalars['ISODateTime']['output'];
  id: Scalars['ID']['output'];
  overdue: Scalars['Boolean']['output'];
  status: ReminderStatus;
  team: Team;
  time: Scalars['ISODateTime']['output'];
  user: SimpleUser;
};

export type ReminderConnection = {
  __typename: 'ReminderConnection';
  edges: Array<ReminderConnectionEdge>;
};

/** An edge containing a reminder on an entity. */
export type ReminderConnectionEdge = {
  __typename: 'ReminderConnectionEdge';
  /** The reminder. */
  node: Reminder;
};

/** An enum representing supported reminder status */
export enum ReminderStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type ResolveConflictsInput = {
  conflicts: Array<Scalars['ID']['input']>;
  selection: RoleConflictSelectionKind;
};

export type ResolveRoleConflictInput = {
  id: Scalars['ID']['input'];
  selection: RoleConflictSelectionKind;
};

/** The lower bound of the number of revenues (inclusive). */
export type RevenueFilter = {
  __typename: 'RevenueFilter';
  /** The lower bound of the number of revenues (inclusive). */
  from?: Maybe<Scalars['Float']['output']>;
  /** The upper bound of the number of revenues (inclusive). */
  to?: Maybe<Scalars['Float']['output']>;
};

/** The lower bound of the number of revenues (inclusive). */
export type RevenueFilterInput = {
  /** The lower bound of the number of revenues (inclusive). */
  from?: InputMaybe<Scalars['Float']['input']>;
  /** The upper bound of the number of revenues (inclusive). */
  to?: InputMaybe<Scalars['Float']['input']>;
};

/** An object representing a review. */
export type Review = {
  __typename: 'Review';
  comment?: Maybe<Scalars['String']['output']>;
  companyStatus?: Maybe<CompanyStatus>;
  created: Scalars['ISODateTime']['output'];
  file?: Maybe<Scalars['Base64EncodedFile']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inlineComments: Array<ReviewInlineComment>;
  risk: AuditRisk;
  status: ReviewStatus;
  user?: Maybe<SimpleUser>;
};

/** An object representing a review AI summary. */
export type ReviewAiSummary = {
  __typename: 'ReviewAiSummary';
  summary: Scalars['String']['output'];
};

export type ReviewAmsEventFeedbackInput = {
  feedback: Array<AmsEventFeedbackInput>;
  hits: Array<ReviewAmsHitsInput>;
};

export type ReviewAmsHitsInput = {
  entity: Scalars['ID']['input'];
  hits: Scalars['Int']['input'];
};

export type ReviewAmsSection = {
  __typename: 'ReviewAmsSection';
  entity: ReviewEntity;
  entityName: Scalars['String']['output'];
  events: Array<ReviewEventValue>;
  feedbackDescription?: Maybe<Scalars['String']['output']>;
};

export type ReviewAuditorComment = {
  __typename: 'ReviewAuditorComment';
  comment?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
};

export type ReviewAuditorCommentValue = {
  __typename: 'ReviewAuditorCommentValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewAuditorComment>;
};

export type ReviewBeneficialOwnerMeta = {
  __typename: 'ReviewBeneficialOwnerMeta';
  reason?: Maybe<Scalars['String']['output']>;
  votingPower?: Maybe<Scalars['String']['output']>;
};

export type ReviewBranch = {
  __typename: 'ReviewBranch';
  address?: Maybe<Scalars['String']['output']>;
  industry?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  numberOfEmployees?: Maybe<Scalars['Int']['output']>;
  organizationNumber: Scalars['String']['output'];
};

export type ReviewBranchValue = {
  __typename: 'ReviewBranchValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewBranch>;
};

export type ReviewCcj = {
  __typename: 'ReviewCCJ';
  amount?: Maybe<Scalars['Int']['output']>;
  caseNumber?: Maybe<Scalars['String']['output']>;
  court?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['ISODateTime']['output']>;
  incomingRecordDetails?: Maybe<Scalars['String']['output']>;
  isExactMatch: Scalars['Boolean']['output'];
  paidDate?: Maybe<Scalars['ISODateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ReviewCcjValue = {
  __typename: 'ReviewCCJValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewCcj>;
};

export type ReviewCollateral = {
  __typename: 'ReviewCollateral';
  amount: Scalars['String']['output'];
  creditor?: Maybe<ReviewEntity>;
  creditorName: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type ReviewCollateralValue = {
  __typename: 'ReviewCollateralValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewCollateral>;
};

export type ReviewCompanyData = {
  __typename: 'ReviewCompanyData';
  address?: Maybe<ReviewStringValue>;
  alternativeBeneficialOwners?: Maybe<Array<ReviewOwnerValue>>;
  auditorComments?: Maybe<Array<ReviewAuditorCommentValue>>;
  beneficialOwners?: Maybe<Array<ReviewOwnerValue>>;
  boardGenderDiversity?: Maybe<ReviewStringValue>;
  branches?: Maybe<Array<ReviewBranchValue>>;
  ccjs?: Maybe<Array<ReviewCcjValue>>;
  ceos?: Maybe<Array<ReviewRoleValue>>;
  chairpersons?: Maybe<Array<ReviewRoleValue>>;
  collateral?: Maybe<Array<ReviewCollateralValue>>;
  companyAms?: Maybe<Array<ReviewEventValue>>;
  companyAmsFeedbackDescription?: Maybe<Scalars['String']['output']>;
  companyFlaggedEvents?: Maybe<Array<ReviewFlaggedEventValue>>;
  consolidatedAuditorComments?: Maybe<Array<ReviewAuditorCommentValue>>;
  consolidatedFinancials?: Maybe<ReviewFinancialsValue>;
  corporatePurpose?: Maybe<ReviewStringValue>;
  countries?: Maybe<Array<ReviewStringValue>>;
  creditScore?: Maybe<ReviewCreditScoreValue>;
  financials?: Maybe<ReviewFinancialsValue>;
  /** @deprecated Use companyFlaggedEvents instead */
  flaggedEvents?: Maybe<Array<ReviewFlaggedEventValue>>;
  historicalRoles?: Maybe<Array<ReviewRoleSection>>;
  id: Scalars['ID']['output'];
  inceptionDate?: Maybe<ReviewStringValue>;
  industries?: Maybe<Array<ReviewStringValue>>;
  innehavers?: Maybe<Array<ReviewDetailedRoleValue>>;
  input?: Maybe<ReviewInput>;
  legalForm?: Maybe<ReviewStringValue>;
  name: Scalars['String']['output'];
  numberOfEmployees?: Maybe<ReviewStringValue>;
  organizationNumber?: Maybe<Scalars['String']['output']>;
  otherBoardMembers?: Maybe<Array<ReviewRoleSection>>;
  otherOwners?: Maybe<Array<ReviewOwnerValue>>;
  otherRoles?: Maybe<Array<ReviewRoleSection>>;
  ownershipChart?: Maybe<ReviewOwnershipChartValue>;
  partners?: Maybe<Array<ReviewDetailedRoleValue>>;
  peps?: Maybe<Array<ReviewPepValue>>;
  personFlaggedEvents?: Maybe<Array<ReviewFlaggedEventSection>>;
  personsAms?: Maybe<Array<ReviewAmsSection>>;
  personsWithSignificantControl?: Maybe<Array<ReviewRoleValue>>;
  previousNames?: Maybe<Array<ReviewStringValue>>;
  registers?: Maybe<Array<ReviewRegisterValue>>;
  sanctions?: Maybe<Array<ReviewSanctionValue>>;
  shareCapital?: Maybe<ReviewStringValue>;
  shareClasses?: Maybe<Array<ReviewStringValue>>;
  signatoryRights?: Maybe<ReviewStringValue>;
  subjectToPublicProcurement?: Maybe<ReviewStringValue>;
  subsidiaries?: Maybe<Array<ReviewSubsidiaryValue>>;
  url: Scalars['String']['output'];
};

export type ReviewCreditScore = {
  __typename: 'ReviewCreditScore';
  averageInvoiceValue?: Maybe<Scalars['String']['output']>;
  contractLimit?: Maybe<Scalars['String']['output']>;
  creditLimit?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  dbt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  incomes?: Maybe<ReviewIncomeValue>;
  industryDbt?: Maybe<Scalars['String']['output']>;
  isMonitored: Scalars['Boolean']['output'];
  partiallySettledRemarks?: Maybe<Scalars['String']['output']>;
  paymentRemarks: Array<ReviewPaymentRemarkValue>;
  paymentsOnFile?: Maybe<Scalars['String']['output']>;
  paymentsPaid?: Maybe<Scalars['String']['output']>;
  paymentsStillOwing?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['String']['output']>;
  unsettledRemarks?: Maybe<Scalars['String']['output']>;
};

export type ReviewCreditScoreValue = {
  __typename: 'ReviewCreditScoreValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewCreditScore>;
};

export type ReviewCustomCheckbox = {
  __typename: 'ReviewCustomCheckbox';
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ReviewCustomCheckboxInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type ReviewDetailedRole = {
  __typename: 'ReviewDetailedRole';
  entity: ReviewEntity;
  otherIndustries: Array<Scalars['String']['output']>;
  otherRoles: Array<ReviewOtherRoles>;
  roleMetas: Array<ReviewRoleMeta>;
};

export type ReviewDetailedRoleValue = {
  __typename: 'ReviewDetailedRoleValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewDetailedRole>;
};

export type ReviewEntity = {
  __typename: 'ReviewEntity';
  access: ReviewEntityAccess;
  address?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  relation?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type ReviewEntityAccess = {
  __typename: 'ReviewEntityAccess';
  description?: Maybe<Scalars['String']['output']>;
  hasAccess: Scalars['Boolean']['output'];
};

export type ReviewEvent = {
  __typename: 'ReviewEvent';
  amsTopics: Array<Scalars['String']['output']>;
  externalUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  publishedDate: Scalars['String']['output'];
  publisher: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ReviewEventValue = {
  __typename: 'ReviewEventValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewEvent>;
};

export type ReviewFinancialRow = {
  __typename: 'ReviewFinancialRow';
  afterUnderline: Scalars['Boolean']['output'];
  kind: FinancialFieldKind;
  renderKind: FinancialFieldRenderKind;
  title: Scalars['String']['output'];
  values: Array<Maybe<Scalars['Float']['output']>>;
};

export type ReviewFinancialSection = {
  __typename: 'ReviewFinancialSection';
  rows: Array<ReviewFinancialRow>;
  title: Scalars['String']['output'];
};

export type ReviewFinancials = {
  __typename: 'ReviewFinancials';
  currencies: Array<Scalars['String']['output']>;
  periods: Array<Scalars['String']['output']>;
  sections: Array<ReviewFinancialSection>;
};

export type ReviewFinancialsValue = {
  __typename: 'ReviewFinancialsValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewFinancials>;
};

export type ReviewFlag = {
  __typename: 'ReviewFlag';
  date: Scalars['ISODateTime']['output'];
  event?: Maybe<ReviewEvent>;
  id: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
  severity: Scalars['String']['output'];
};

export type ReviewFlaggedEventSection = {
  __typename: 'ReviewFlaggedEventSection';
  entity: ReviewEntity;
  flags: Array<ReviewFlaggedEventValue>;
};

export type ReviewFlaggedEventValue = {
  __typename: 'ReviewFlaggedEventValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewFlag>;
};

export type ReviewIncome = {
  __typename: 'ReviewIncome';
  grossIncomes: Array<Scalars['String']['output']>;
  incomeYears: Array<Scalars['String']['output']>;
  municipalitiyCodes: Array<Scalars['String']['output']>;
  netIncomes: Array<Scalars['String']['output']>;
  netWealths: Array<Scalars['String']['output']>;
  sumTaxes: Array<Scalars['String']['output']>;
  taxClasses: Array<Scalars['String']['output']>;
};

export type ReviewIncomeValue = {
  __typename: 'ReviewIncomeValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewIncome>;
};

export type ReviewInlineComment = {
  __typename: 'ReviewInlineComment';
  createdAt: Scalars['ISODateTime']['output'];
  createdBy: SimpleUser;
  fieldKind: TableRowKind;
  fieldKindV2: ReviewRowKind;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type ReviewInlineCommentInputV2 = {
  createdAt?: InputMaybe<Scalars['ISODateTime']['input']>;
  createdBy: Scalars['ID']['input'];
  customCheckboxName?: InputMaybe<Scalars['String']['input']>;
  fieldKind: TableRowKind;
  fieldKindV2?: InputMaybe<ReviewRowKind>;
  message: Scalars['String']['input'];
};

export type ReviewInlineCommentResource = {
  __typename: 'ReviewInlineCommentResource';
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  field: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  section?: Maybe<Scalars['String']['output']>;
};

export type ReviewInput = {
  __typename: 'ReviewInput';
  createdAt: Scalars['ISODateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  evaluatedRisk: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inlineComments: Array<ReviewInlineCommentResource>;
  selfAssessment?: Maybe<Scalars['String']['output']>;
};

export type ReviewInputV2 = {
  amsEventFeedback?: InputMaybe<ReviewAmsEventFeedbackInput>;
  auditRisk: AuditRisk;
  comment?: InputMaybe<Scalars['String']['input']>;
  companyStatus?: InputMaybe<CompanyStatus>;
  inlineCommentsV2?: InputMaybe<Array<ReviewInlineCommentInputV2>>;
  organizationOverviewImage?: InputMaybe<Scalars['Base64EncodedImage']['input']>;
  reviewCardImage?: InputMaybe<Scalars['Base64EncodedImage']['input']>;
  reviewStatus: ReviewStatus;
};

export type ReviewOtherRoles = {
  __typename: 'ReviewOtherRoles';
  company: ReviewEntity;
  roles: Array<ReviewRoleMeta>;
};

export type ReviewOwner = {
  __typename: 'ReviewOwner';
  beneficialOwnershipMeta?: Maybe<ReviewBeneficialOwnerMeta>;
  entity?: Maybe<ReviewEntity>;
  entityName: Scalars['String']['output'];
  ownershipPercentage: Scalars['String']['output'];
  shareClasses?: Maybe<Scalars['String']['output']>;
};

export type ReviewOwnerValue = {
  __typename: 'ReviewOwnerValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewOwner>;
};

export type ReviewOwnershipChart = {
  __typename: 'ReviewOwnershipChart';
  edges: Array<ReviewOwnershipChartEdge>;
  hasCustomOwners: Scalars['Boolean']['output'];
  hasGlobalOwners: Scalars['Boolean']['output'];
  lastModifiedAt?: Maybe<Scalars['ISODateTime']['output']>;
  nodes: Array<ReviewOwnershipChartNode>;
};

export type ReviewOwnershipChartEdge = {
  __typename: 'ReviewOwnershipChartEdge';
  child: Scalars['String']['output'];
  parent: Scalars['String']['output'];
  share: Scalars['String']['output'];
};

export type ReviewOwnershipChartNode = {
  __typename: 'ReviewOwnershipChartNode';
  beneficialOwnershipMeta?: Maybe<ReviewBeneficialOwnerMeta>;
  entity?: Maybe<ReviewEntity>;
  id: Scalars['String']['output'];
  indirectShare: Scalars['String']['output'];
  indirectShareValue: Scalars['Float']['output'];
  isLeaf: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  shareClasses: Array<ReviewShareClass>;
};

export type ReviewOwnershipChartValue = {
  __typename: 'ReviewOwnershipChartValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewOwnershipChart>;
};

export type ReviewPaymentRemark = {
  __typename: 'ReviewPaymentRemark';
  amount: Scalars['String']['output'];
  creditor?: Maybe<ReviewEntity>;
  creditorName: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type ReviewPaymentRemarkValue = {
  __typename: 'ReviewPaymentRemarkValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewPaymentRemark>;
};

export type ReviewPdfSection = {
  __typename: 'ReviewPdfSection';
  kind: ReviewPdfSectionKind;
  name: Scalars['String']['output'];
};

export enum ReviewPdfSectionKind {
  AdverseMediaScreening = 'ADVERSE_MEDIA_SCREENING',
  Collateral = 'COLLATERAL',
  CompanyInformation = 'COMPANY_INFORMATION',
  CreditScore = 'CREDIT_SCORE',
  FinancialStatements = 'FINANCIAL_STATEMENTS',
  FlaggedEvents = 'FLAGGED_EVENTS',
  HistoricRoles = 'HISTORIC_ROLES',
  OrganizationOverview = 'ORGANIZATION_OVERVIEW',
  Owners = 'OWNERS',
  PepsAndSanctions = 'PEPS_AND_SANCTIONS',
  Registers = 'REGISTERS',
  ReviewCardImage = 'REVIEW_CARD_IMAGE',
  Roles = 'ROLES',
  Subsidiaries = 'SUBSIDIARIES'
}

export type ReviewPep = {
  __typename: 'ReviewPep';
  entity?: Maybe<ReviewEntity>;
  entityName: Scalars['String']['output'];
  isPep: Scalars['Boolean']['output'];
  isRca: Scalars['Boolean']['output'];
  reason: Scalars['String']['output'];
};

export type ReviewPepValue = {
  __typename: 'ReviewPepValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewPep>;
};

export type ReviewRegister = {
  __typename: 'ReviewRegister';
  event?: Maybe<ReviewEvent>;
  isRegistered: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type ReviewRegisterValue = {
  __typename: 'ReviewRegisterValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewRegister>;
};

export enum ReviewRiskKind {
  HasFlagsOutsideCompany = 'HAS_FLAGS_OUTSIDE_COMPANY',
  HasMultipleValues = 'HAS_MULTIPLE_VALUES',
  HasNoValues = 'HAS_NO_VALUES',
  IsCompany = 'IS_COMPANY'
}

export type ReviewRole = {
  __typename: 'ReviewRole';
  entity: ReviewEntity;
  roleMetas: Array<ReviewRoleMeta>;
};

export type ReviewRoleMeta = {
  __typename: 'ReviewRoleMeta';
  roleTitle: Scalars['String']['output'];
  timespan: Scalars['String']['output'];
};

export type ReviewRoleSection = {
  __typename: 'ReviewRoleSection';
  roleTitle: Scalars['String']['output'];
  roles: Array<ReviewRoleValue>;
};

export type ReviewRoleValue = {
  __typename: 'ReviewRoleValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewRole>;
};

export enum ReviewRowKind {
  AlternativeBeneficialOwners = 'ALTERNATIVE_BENEFICIAL_OWNERS',
  BeneficialOwners = 'BENEFICIAL_OWNERS',
  BoardGenderDiversity = 'BOARD_GENDER_DIVERSITY',
  Ccjs = 'CCJS',
  Ceos = 'CEOS',
  Chairpersons = 'CHAIRPERSONS',
  CompanyAdverseMediaScreening = 'COMPANY_ADVERSE_MEDIA_SCREENING',
  CompanySanctions = 'COMPANY_SANCTIONS',
  CorporatePurpose = 'CORPORATE_PURPOSE',
  Countries = 'COUNTRIES',
  CreditScore = 'CREDIT_SCORE',
  CustomCheckbox = 'CUSTOM_CHECKBOX',
  FlaggedEvents = 'FLAGGED_EVENTS',
  GlobalOwnerships = 'GLOBAL_OWNERSHIPS',
  Industries = 'INDUSTRIES',
  Innehavers = 'INNEHAVERS',
  LegalForm = 'LEGAL_FORM',
  OtherBoardMembers = 'OTHER_BOARD_MEMBERS',
  OtherOwners = 'OTHER_OWNERS',
  OtherRoles = 'OTHER_ROLES',
  Partners = 'PARTNERS',
  Peps = 'PEPS',
  PersonsWithSignificantControl = 'PERSONS_WITH_SIGNIFICANT_CONTROL',
  Registers = 'REGISTERS',
  Roles = 'ROLES',
  RolesAdverseMediaScreening = 'ROLES_ADVERSE_MEDIA_SCREENING',
  Sanctions = 'SANCTIONS',
  ShareClasses = 'SHARE_CLASSES',
  SubjectToTransparencyAct = 'SUBJECT_TO_TRANSPARENCY_ACT'
}

export type ReviewSanction = {
  __typename: 'ReviewSanction';
  entity?: Maybe<ReviewEntity>;
  entityName: Scalars['String']['output'];
  isSanctioned: Scalars['Boolean']['output'];
  reason: Scalars['String']['output'];
};

export type ReviewSanctionValue = {
  __typename: 'ReviewSanctionValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewSanction>;
};

export enum ReviewSectionKind {
  Ams = 'AMS',
  CompanyInformation = 'COMPANY_INFORMATION',
  CustomCheckboxes = 'CUSTOM_CHECKBOXES',
  Esg = 'ESG',
  ManagementAndRightsHolders = 'MANAGEMENT_AND_RIGHTS_HOLDERS',
  PepsAndSanctions = 'PEPS_AND_SANCTIONS'
}

/** A union type of possible review settings. */
export type ReviewSetting = BooleanReviewSetting | EntityReviewSetting | EntityWithCountryReviewSetting | FlagReviewSetting | RiskReviewSetting | StringReviewSetting;

export type ReviewSettingInput = {
  enabled: Scalars['Boolean']['input'];
  entityRiskValues?: InputMaybe<Array<Scalars['ID']['input']>>;
  entityWithCountryRiskValues?: InputMaybe<Array<EntityWithCountryInput>>;
  flagRiskValues?: InputMaybe<Array<FlagSeverity>>;
  kind: ReviewSettingKind;
  reviewRiskValues?: InputMaybe<Array<ReviewRiskKind>>;
  stringRiskValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ReviewSettingKind {
  AlternativeBeneficialOwners = 'ALTERNATIVE_BENEFICIAL_OWNERS',
  BeneficialOwners = 'BENEFICIAL_OWNERS',
  BoardGenderDiversity = 'BOARD_GENDER_DIVERSITY',
  Ccjs = 'CCJS',
  Ceos = 'CEOS',
  Chairpersons = 'CHAIRPERSONS',
  CompanyAdverseMediaScreening = 'COMPANY_ADVERSE_MEDIA_SCREENING',
  CompanySanctions = 'COMPANY_SANCTIONS',
  CorporatePurpose = 'CORPORATE_PURPOSE',
  Countries = 'COUNTRIES',
  CreditScore = 'CREDIT_SCORE',
  EnableCheckAll = 'ENABLE_CHECK_ALL',
  EnableComment = 'ENABLE_COMMENT',
  EnableInlineComments = 'ENABLE_INLINE_COMMENTS',
  EnableRiskAssessmentValue = 'ENABLE_RISK_ASSESSMENT_VALUE',
  FlaggedEvents = 'FLAGGED_EVENTS',
  GlobalOwnerships = 'GLOBAL_OWNERSHIPS',
  Industries = 'INDUSTRIES',
  LegalForms = 'LEGAL_FORMS',
  OtherBoardMembers = 'OTHER_BOARD_MEMBERS',
  OtherOwners = 'OTHER_OWNERS',
  OtherRoles = 'OTHER_ROLES',
  Owners = 'OWNERS',
  Partners = 'PARTNERS',
  Peps = 'PEPS',
  Registers = 'REGISTERS',
  RolesAdverseMediaScreening = 'ROLES_ADVERSE_MEDIA_SCREENING',
  Sanctions = 'SANCTIONS',
  ShareClasses = 'SHARE_CLASSES',
  SubjectToTransparencyAct = 'SUBJECT_TO_TRANSPARENCY_ACT'
}

/** An interface for possible values of review settings */
export type ReviewSettingLike = {
  enabled: Scalars['Boolean']['output'];
  kind: ReviewSettingKind;
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type ReviewSettings = {
  __typename: 'ReviewSettings';
  /** Filter the companies. */
  companies: CompanyFilter;
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type ReviewSettingsInput = {
  /** Filter the companies. */
  companies: CompanyFilterInput;
};

export type ReviewShareClass = {
  __typename: 'ReviewShareClass';
  share: Scalars['String']['output'];
  shareClass?: Maybe<Scalars['String']['output']>;
};

/** Filter that can use to create a more specific review trigger statement. */
export type ReviewStatementFilterKind = {
  __typename: 'ReviewStatementFilterKind';
  countries: Array<ContentLanguage>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** An object representing review stats. */
export type ReviewStats = {
  __typename: 'ReviewStats';
  reviewCount: Scalars['Int']['output'];
  team: Team;
};

export enum ReviewStatus {
  Approved = 'APPROVED',
  Disapproved = 'DISAPPROVED'
}

export type ReviewStringValue = {
  __typename: 'ReviewStringValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ReviewSubsidiary = {
  __typename: 'ReviewSubsidiary';
  entity: ReviewEntity;
  ownershipPercentage: Scalars['String']['output'];
};

export type ReviewSubsidiaryValue = {
  __typename: 'ReviewSubsidiaryValue';
  reviewMeta?: Maybe<ReviewValueMeta>;
  value?: Maybe<ReviewSubsidiary>;
};

export type ReviewTriggerStatementInfo = {
  __typename: 'ReviewTriggerStatementInfo';
  defaultOperator?: Maybe<WhereOperatorKind>;
  kind: ReviewTriggerStatementKind;
  name: Scalars['String']['output'];
  operators: Array<WhereOperatorKind>;
  optionalFilterDefault?: Maybe<Scalars['String']['output']>;
  optionalFilters: Array<ReviewStatementFilterKind>;
  /** If true, it means that this trigger is used for risk class calculation */
  riskLevelIndicator: Scalars['Boolean']['output'];
  /** If true this means that the value is only used in combination with the corresponding statement filter */
  valueAppliesToOptionalFilter: Scalars['Boolean']['output'];
  valueKind?: Maybe<ReviewTriggerStatementValueKind>;
};


export type ReviewTriggerStatementInfoOptionalFiltersArgs = {
  countries?: InputMaybe<Array<ContentLanguage>>;
};

export enum ReviewTriggerStatementKind {
  BeneficialOwnersChange = 'BENEFICIAL_OWNERS_CHANGE',
  CreditChange = 'CREDIT_CHANGE',
  FinancialsPublished = 'FINANCIALS_PUBLISHED',
  FlagPublished = 'FLAG_PUBLISHED',
  PepChange = 'PEP_CHANGE',
  RiskAssessmentValue = 'RISK_ASSESSMENT_VALUE',
  RoleChange = 'ROLE_CHANGE',
  SanctionsChange = 'SANCTIONS_CHANGE',
  TimeSinceLastReview = 'TIME_SINCE_LAST_REVIEW'
}

export enum ReviewTriggerStatementValueKind {
  AuditRisk = 'AUDIT_RISK',
  Currency = 'CURRENCY',
  MonthsAgo = 'MONTHS_AGO'
}

export type ReviewValueMeta = {
  __typename: 'ReviewValueMeta';
  reason: Scalars['String']['output'];
  status: ReviewValueStatusKind;
};

export enum ReviewValueStatusKind {
  Danger = 'DANGER',
  Ok = 'OK',
  Warning = 'WARNING'
}

/** A representation of an organization number. */
export type RichOrganizationNumber = {
  __typename: 'RichOrganizationNumber';
  /** The country specific formatted organization number. */
  formatted: Scalars['String']['output'];
  /** Url to the official register for this organization number, if it exists. */
  registerUrl?: Maybe<Scalars['String']['output']>;
  /** The organization number. */
  value: Scalars['OrganizationNumber']['output'];
};

export type RiskReviewSetting = ReviewSettingLike & {
  __typename: 'RiskReviewSetting';
  enabled: Scalars['Boolean']['output'];
  kind: ReviewSettingKind;
  riskValues: Array<ReviewRiskKind>;
};

/** A representation of a Risk Summary. */
export type RiskSummary = {
  __typename: 'RiskSummary';
  /** The evaluated risks associated with this Risk Summary. */
  risks: Array<RiskSummaryRisk>;
};

/** A representation of a Risk in a Risk Summary. */
export type RiskSummaryRisk = {
  __typename: 'RiskSummaryRisk';
  /** Number of entries found for the given risk, if applicable. */
  found?: Maybe<Scalars['Int']['output']>;
  /** Risk kind. */
  kind: RiskSummaryRiskKind;
  /** A label describing the work done to evaluate this risk. */
  label: Scalars['String']['output'];
  /** Level for this risk. */
  level: RiskSummaryRiskLevel;
  /** The reason for the given risk `level`. */
  reason: Scalars['String']['output'];
  /** Number of entries searched for the given risk, if applicable. */
  searched?: Maybe<Scalars['Int']['output']>;
};

/** The kind of risk. */
export enum RiskSummaryRiskKind {
  Industry = 'INDUSTRY',
  Jurisdiction = 'JURISDICTION',
  Pep = 'PEP',
  Sanction = 'SANCTION',
  Ubo = 'UBO'
}

/** The level of risk. */
export enum RiskSummaryRiskLevel {
  High = 'HIGH',
  Low = 'LOW',
  None = 'NONE',
  NotApplicable = 'NOT_APPLICABLE'
}

export type RoleConflict = {
  __typename: 'RoleConflict';
  /** The conflicting business role. */
  conflictingRole: RoleConflictPart;
  /** The current active custom business role. */
  currentRole: RoleConflictPart;
  /** Entity in which the roles reside. */
  entity: EntityLike;
  id: Scalars['ID']['output'];
  /** If the conflict is resolved or not. */
  resolved: Scalars['Boolean']['output'];
  /** The user who resolved the conflict. */
  resolvedBy?: Maybe<SimpleUser>;
  /** Which part of the conflict was selected when resolved. */
  resolvedSelection?: Maybe<RoleConflictSelectionKind>;
};

export type RoleConflictPart = {
  __typename: 'RoleConflictPart';
  /** The entity that has the role */
  entity?: Maybe<EntityLike>;
  /** Meta information about the role */
  roleMeta?: Maybe<RoleMeta>;
};

export enum RoleConflictSelectionKind {
  /** Select the both roles. */
  Both = 'BOTH',
  /** Select the conflicting role. */
  Conflicting = 'CONFLICTING',
  /** Select the current role */
  Current = 'CURRENT'
}

export type RoleConflictV2 = ConflictLike & {
  __typename: 'RoleConflictV2';
  /** The conflicting part. */
  conflictingPart: RoleConflictPart;
  /** The current custom part. */
  customPart: RoleConflictPart;
  /** Entity which has the conflict. */
  entity: EntityLike;
  id: Scalars['String']['output'];
  /** The reason for the conflict. */
  reason?: Maybe<ConflictReasonKind>;
  /** If the conflict is resolved or not */
  resolved: Scalars['Boolean']['output'];
  /** The user who resolved the conflict */
  resolvedBy?: Maybe<SimpleUser>;
  /** Which part of the conflict was selected when resolved. */
  resolvedSelection?: Maybe<RoleConflictSelectionKind>;
};

export type RoleMeta = {
  __typename: 'RoleMeta';
  customMeta?: Maybe<CustomMeta>;
  isActive: Scalars['Boolean']['output'];
  period: Timespan;
  roleTitle: Scalars['String']['output'];
  roleTitleDescription?: Maybe<Scalars['String']['output']>;
  roleTitleId: Scalars['String']['output'];
};

export type RoleTitle = {
  __typename: 'RoleTitle';
  country?: Maybe<Country>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** A resource holding Sanction information. */
export type SanctionInfoV2 = {
  __typename: 'SanctionInfoV2';
  lastUpdated?: Maybe<Scalars['ISODateTime']['output']>;
  sanctionSources: Array<SanctionSource>;
  sanctionedBy: Array<Scalars['String']['output']>;
  sanctionedSince?: Maybe<Scalars['ISODateTime']['output']>;
  sourceJson: Array<Scalars['String']['output']>;
  sourceLinks: Array<Scalars['String']['output']>;
};

/** An enum for sanction sources. */
export enum SanctionSource {
  DowJones = 'DOW_JONES',
  Eu = 'EU',
  Ofac = 'OFAC',
  Uk = 'UK',
  Un = 'UN'
}

/** A union type of possible search result types. */
export type SearchResult = Company | GlobalCompany | GlobalPerson | Person | PrivatePerson;

export type SearchResultConnection = {
  __typename: 'SearchResultConnection';
  edges: Array<SearchResultConnectionEdge>;
};

export type SearchResultConnectionEdge = {
  __typename: 'SearchResultConnectionEdge';
  /** Search result */
  node: SearchResult;
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type Settings = {
  __typename: 'Settings';
  /** Settings for opportunities. */
  application: ApplicationSettings;
  /** Settings for terms of agreement and privacy. */
  events: EventSettings;
  /** Settings for the list view. */
  listView: ListViewSettings;
  /** Settings for opportunities. */
  opportunities: OpportunitySettings;
  /** Settings for review. */
  review: ReviewSettings;
  /** Settings for terms of agreement and privacy. */
  terms: PrivacySettings;
};

export type ShareClass = {
  __typename: 'ShareClass';
  share: Scalars['String']['output'];
  shareClass?: Maybe<Scalars['String']['output']>;
};

/** A union type of possible shareholders. */
export type Shareholder = Company | GlobalCompany;

/** A connection containing shareholders. */
export type ShareholderConnection = {
  __typename: 'ShareholderConnection';
  edges: Array<ShareholderConnectionEdge>;
};

/** An edge containing the shareholder along with the share percentage. */
export type ShareholderConnectionEdge = {
  __typename: 'ShareholderConnectionEdge';
  /** Whether or not the ownership is uncertain. */
  isUncertain: Scalars['Boolean']['output'];
  /** The shareholder. */
  node: Shareholder;
  /** The share interval of stocks owned. */
  shareInterval?: Maybe<Scalars['String']['output']>;
  shareIntervalValue?: Maybe<Scalars['Float']['output']>;
  /** The share of stocks owned. */
  sharePercentage: Scalars['Float']['output'];
};

export type SimilarCompanyConnection = {
  __typename: 'SimilarCompanyConnection';
  edges: Array<SimilarCompanyConnectionEdge>;
};

export type SimilarCompanyConnectionEdge = {
  __typename: 'SimilarCompanyConnectionEdge';
  node: Company;
};

/** An object representing a Neo4j company. */
export type SimpleCompany = EntityLike & {
  __typename: 'SimpleCompany';
  /** Access information for this entity. */
  access: EntityAccess;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  /** The assigned user for this company, if any. */
  assignee: SimpleUserConnection;
  /** Whether or not the company is currently bankrupt. */
  bankruptcyDate: Scalars['Boolean']['output'];
  /** The date of a potential bankruptcy. */
  deletionDate: Scalars['Boolean']['output'];
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** The financial data of the company for a given time period. */
  financials?: Maybe<SimpleFinancials>;
  /** The number of flags for this company. */
  flagCount: Scalars['Int']['output'];
  /** The flags for this company. */
  flags: AdverseFlagConnection;
  /** The headquarters location of the company. */
  headquartersLocation?: Maybe<SimpleCompany>;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  /** Whether or not the company is currently bankrupt. */
  isOperational: Scalars['Boolean']['output'];
  /** The flags for this company. */
  lifeStatus: CompanyLifeStatus;
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  /** The number of employees in the company. */
  numberOfEmployees?: Maybe<Scalars['Int']['output']>;
  /** The organization number of the company. */
  organizationNumber?: Maybe<Scalars['OrganizationNumber']['output']>;
  primaryCountry?: Maybe<Country>;
  /** The registered country of the company. */
  registerCountry?: Maybe<ContentLanguage>;
  /** Registers for this entity. */
  registers: RegisterConnection;
  /** The watch status on this company. */
  status: CompanyStatus;
  /** The modified date for the status of this company. */
  statusModified: Scalars['ISODateTime']['output'];
  statusV2: TeamCompanyStatus;
  /** The stickers for this company. */
  stickers: CompanyStickerConnection;
};


/** An object representing a Neo4j company. */
export type SimpleCompanyAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a Neo4j company. */
export type SimpleCompanyDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a Neo4j company. */
export type SimpleCompanyEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a Neo4j company. */
export type SimpleCompanyFinancialsArgs = {
  corporate: Scalars['Boolean']['input'];
};


/** An object representing a Neo4j company. */
export type SimpleCompanyNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a Neo4j company. */
export type SimpleCompanyNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};

export type SimpleCompanyConnection = {
  __typename: 'SimpleCompanyConnection';
  edges: Array<SimpleCompanyConnectionEdge>;
  nextPage: ListViewPageInfo;
  previousPage: ListViewPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SimpleCompanyConnectionEdge = {
  __typename: 'SimpleCompanyConnectionEdge';
  node: SimpleCompany;
};

/** An object representing a Neo4j entity. */
export type SimpleEntity = EntityLike & {
  __typename: 'SimpleEntity';
  /** Access information for this entity. */
  access: EntityAccess;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  compile: Scalars['Boolean']['output'];
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  /** Registers for this entity. */
  registers: RegisterConnection;
};


/** An object representing a Neo4j entity. */
export type SimpleEntityAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a Neo4j entity. */
export type SimpleEntityDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a Neo4j entity. */
export type SimpleEntityEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a Neo4j entity. */
export type SimpleEntityNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a Neo4j entity. */
export type SimpleEntityNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};

/** A representation of the financial data of a company, of a given year. */
export type SimpleFinancials = {
  __typename: 'SimpleFinancials';
  /** The currency of the financial numbers. */
  currency: Currency;
  /** The equity ratio of the company. */
  equityRatio: Scalars['Float']['output'];
  /** The operating revenue of the company. */
  operatingRevenue: Scalars['Float']['output'];
  /** The year of the financial data. */
  year: Scalars['Int']['output'];
};

/** A representation of users, which are a collection of entities. */
export type SimpleUser = {
  __typename: 'SimpleUser';
  /** The avatar of the user. */
  avatar?: Maybe<Scalars['String']['output']>;
  /** The email of the user. */
  email?: Maybe<Scalars['String']['output']>;
  /** The ID of the user. */
  id: Scalars['ID']['output'];
  /** The name of the user. */
  name: Scalars['String']['output'];
  /** The pseudonymized name of the user. */
  pseudonymizedName: Scalars['String']['output'];
};

/** A Connection containing simple users. */
export type SimpleUserConnection = {
  __typename: 'SimpleUserConnection';
  edges: Array<SimpleUserConnectionEdge>;
};

/** An edge containing the user. */
export type SimpleUserConnectionEdge = {
  __typename: 'SimpleUserConnectionEdge';
  /** The simple user. */
  node: SimpleUser;
};

/** An enum representing a sortDirection. */
export enum SortOrdering {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

/** A representation of stickers, which are used to label things. */
export type Sticker = {
  __typename: 'Sticker';
  /** The date of creation. */
  created: Scalars['ISODateTime']['output'];
  /** The sticker ID. */
  id: Scalars['ID']['output'];
  /** The sticker name. */
  name: Scalars['String']['output'];
};

export type StickerConnection = {
  __typename: 'StickerConnection';
  edges: Array<StickerConnectionEdge>;
};

export type StickerConnectionEdge = {
  __typename: 'StickerConnectionEdge';
  node: Sticker;
};

export type StringReviewSetting = ReviewSettingLike & {
  __typename: 'StringReviewSetting';
  enabled: Scalars['Boolean']['output'];
  kind: ReviewSettingKind;
  riskValues: Array<Scalars['String']['output']>;
};

/** A summary of a event. */
export type Summary = {
  __typename: 'Summary';
  /** Retrieve the summary text */
  text: Scalars['String']['output'];
};


/** A summary of a event. */
export type SummaryTextArgs = {
  language?: InputMaybe<DisplayLanguage>;
  selection?: InputMaybe<SummarySelection>;
};

/** An enum representing different summarization algorithms. */
export enum SummarySelection {
  AiBullet = 'AI_BULLET',
  AiShort = 'AI_SHORT',
  GenAi = 'GEN_AI',
  Jei = 'JEI',
  Smmry = 'SMMRY'
}

export type Table = {
  __typename: 'Table';
  dataSources: Array<DataSource>;
  description?: Maybe<Scalars['String']['output']>;
  hasCustomData: Scalars['Boolean']['output'];
  lastModifiedAt?: Maybe<Scalars['ISODateTime']['output']>;
  rows: Array<TableRow>;
  title?: Maybe<Scalars['String']['output']>;
};

/** A union type of possible table labels. */
export type TableLabel = TableLabelEntity | TableLabelEntityRole | TableLabelRole | TableLabelString;

export type TableLabelEntity = TableLabelLike & {
  __typename: 'TableLabelEntity';
  description?: Maybe<Scalars['String']['output']>;
  entity: EntityLike;
  isActive: Scalars['Boolean']['output'];
};

export type TableLabelEntityRole = TableLabelLike & {
  __typename: 'TableLabelEntityRole';
  entity: EntityLike;
  isActive: Scalars['Boolean']['output'];
};

/** An interface for possible values in a table label */
export type TableLabelLike = {
  isActive: Scalars['Boolean']['output'];
};

export type TableLabelRole = TableLabelLike & {
  __typename: 'TableLabelRole';
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  roleTitleId: Scalars['String']['output'];
};

export type TableLabelString = TableLabelLike & {
  __typename: 'TableLabelString';
  content: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
};

export type TableRow = {
  __typename: 'TableRow';
  key: Scalars['ID']['output'];
  kind: TableRowKind;
  label: TableLabel;
  paginationThreshold?: Maybe<Scalars['Int']['output']>;
  sections: Array<TableRowSection>;
};

export enum TableRowKind {
  Abos = 'ABOS',
  Address = 'ADDRESS',
  ArticlesOfAssociation = 'ARTICLES_OF_ASSOCIATION',
  BirthDate = 'BIRTH_DATE',
  CompanyId = 'COMPANY_ID',
  CompanyName = 'COMPANY_NAME',
  CorrespondingCompany = 'CORRESPONDING_COMPANY',
  Countries = 'COUNTRIES',
  Country = 'COUNTRY',
  County = 'COUNTY',
  Email = 'EMAIL',
  Employees = 'EMPLOYEES',
  Gender = 'GENDER',
  GlobalTotalRelationsCount = 'GLOBAL_TOTAL_RELATIONS_COUNT',
  Hq = 'HQ',
  Identifier = 'IDENTIFIER',
  Inception = 'INCEPTION',
  Industries = 'INDUSTRIES',
  LegalForm = 'LEGAL_FORM',
  Location = 'LOCATION',
  Nationality = 'NATIONALITY',
  OrganizationNumber = 'ORGANIZATION_NUMBER',
  Other = 'OTHER',
  OtherNames = 'OTHER_NAMES',
  OtherOwners = 'OTHER_OWNERS',
  Pep = 'PEP',
  PersonalNumber = 'PERSONAL_NUMBER',
  Phone = 'PHONE',
  PreviousNames = 'PREVIOUS_NAMES',
  Pscs = 'PSCS',
  RelatedPersons = 'RELATED_PERSONS',
  Revenue = 'REVENUE',
  ReviewAlternativeBeneficialOwners = 'REVIEW_ALTERNATIVE_BENEFICIAL_OWNERS',
  ReviewBeneficialOwners = 'REVIEW_BENEFICIAL_OWNERS',
  ReviewBoardGenderDiversity = 'REVIEW_BOARD_GENDER_DIVERSITY',
  ReviewCcjs = 'REVIEW_CCJS',
  ReviewCeos = 'REVIEW_CEOS',
  ReviewChairpersons = 'REVIEW_CHAIRPERSONS',
  ReviewCompanyAdverseMediaScreening = 'REVIEW_COMPANY_ADVERSE_MEDIA_SCREENING',
  ReviewCompanySanctions = 'REVIEW_COMPANY_SANCTIONS',
  ReviewCorporatePurpose = 'REVIEW_CORPORATE_PURPOSE',
  ReviewCountries = 'REVIEW_COUNTRIES',
  ReviewCreditScore = 'REVIEW_CREDIT_SCORE',
  ReviewCustomCheckbox = 'REVIEW_CUSTOM_CHECKBOX',
  ReviewFlaggedEvents = 'REVIEW_FLAGGED_EVENTS',
  ReviewGlobalOwnerships = 'REVIEW_GLOBAL_OWNERSHIPS',
  ReviewIndustries = 'REVIEW_INDUSTRIES',
  ReviewLegalForm = 'REVIEW_LEGAL_FORM',
  ReviewOtherBoardMembers = 'REVIEW_OTHER_BOARD_MEMBERS',
  ReviewOtherOwners = 'REVIEW_OTHER_OWNERS',
  ReviewOtherRoles = 'REVIEW_OTHER_ROLES',
  ReviewOwners = 'REVIEW_OWNERS',
  ReviewPartners = 'REVIEW_PARTNERS',
  ReviewPeps = 'REVIEW_PEPS',
  ReviewPersonsWithSignificantControl = 'REVIEW_PERSONS_WITH_SIGNIFICANT_CONTROL',
  ReviewRegisters = 'REVIEW_REGISTERS',
  ReviewRoles = 'REVIEW_ROLES',
  ReviewRolesAdverseMediaScreening = 'REVIEW_ROLES_ADVERSE_MEDIA_SCREENING',
  ReviewSanctions = 'REVIEW_SANCTIONS',
  ReviewShareClasses = 'REVIEW_SHARE_CLASSES',
  ReviewSubjectToTransparencyAct = 'REVIEW_SUBJECT_TO_TRANSPARENCY_ACT',
  Roles = 'ROLES',
  ShareClasses = 'SHARE_CLASSES',
  SignatureText = 'SIGNATURE_TEXT',
  Status = 'STATUS',
  TradingAddress = 'TRADING_ADDRESS',
  Ubos = 'UBOS',
  VatNumber = 'VAT_NUMBER',
  Website = 'WEBSITE',
  YearsInOperation = 'YEARS_IN_OPERATION'
}

export type TableRowSection = {
  __typename: 'TableRowSection';
  label?: Maybe<TableLabel>;
  paginationThreshold?: Maybe<Scalars['Int']['output']>;
  values: Array<TableRowValue>;
};

/** A union type of possible row values. */
export type TableRowValue = TableRowValueCcJs | TableRowValueCreditReport | TableRowValueEvent | TableRowValueFlag | TableRowValueLabeledString | TableRowValueOwnership | TableRowValuePepsWithEntity | TableRowValueRegisterV2 | TableRowValueRelatedEntity | TableRowValueRole | TableRowValueRoleForPerson | TableRowValueRoleWithDetailedEntity | TableRowValueRoleWithEntity | TableRowValueSanctions | TableRowValueSanctionsWithEntity | TableRowValueString;

export type TableRowValueCcJs = TableRowValueLike & {
  __typename: 'TableRowValueCCJs';
  ccjs: Array<CountyCourtJudgement>;
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueCreditReport = TableRowValueLike & {
  __typename: 'TableRowValueCreditReport';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  creditReport: CreditReport;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  isMonitored: Scalars['Boolean']['output'];
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueEvent = TableRowValueLike & {
  __typename: 'TableRowValueEvent';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  event: Event;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
  topics: Array<Topic>;
};

export type TableRowValueFlag = TableRowValueLike & {
  __typename: 'TableRowValueFlag';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  flag: AdverseFlag;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueLabeledString = TableRowValueLike & {
  __typename: 'TableRowValueLabeledString';
  content: Scalars['String']['output'];
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

/** An interface for possible values in a table row */
export type TableRowValueLike = {
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueOwnership = TableRowValueLike & {
  __typename: 'TableRowValueOwnership';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  customMeta?: Maybe<CustomMeta>;
  entity?: Maybe<EntityLike>;
  entityName: Scalars['String']['output'];
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['ISODateTime']['output']>;
  ownership: Scalars['String']['output'];
  ownershipV2?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  shareClasses: Array<ShareClass>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
  uboMeta?: Maybe<BeneficialOwnerMeta>;
};

export type TableRowValuePepsWithEntity = TableRowValueLike & {
  __typename: 'TableRowValuePepsWithEntity';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<EntityLike>;
  entityName: Scalars['String']['output'];
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  pepInfo: PepInfo;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueRegisterV2 = TableRowValueLike & {
  __typename: 'TableRowValueRegisterV2';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  register: Register;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueRelatedEntity = TableRowValueLike & {
  __typename: 'TableRowValueRelatedEntity';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  entity: EntityLike;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueReviewMeta = {
  __typename: 'TableRowValueReviewMeta';
  reason: Scalars['String']['output'];
  warning: Scalars['Boolean']['output'];
};

export type TableRowValueRole = TableRowValueLike & {
  __typename: 'TableRowValueRole';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  roleMeta: RoleMeta;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueRoleForPerson = TableRowValueLike & {
  __typename: 'TableRowValueRoleForPerson';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  person: EntityLike;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  roleEntity: EntityLike;
  roleMeta: RoleMeta;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueRoleWithDetailedEntity = TableRowValueLike & {
  __typename: 'TableRowValueRoleWithDetailedEntity';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  entity: EntityLike;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  otherIndustries: Array<Scalars['String']['output']>;
  otherRoles: EntityRoleConnection;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  roleMeta: RoleMeta;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueRoleWithEntity = TableRowValueLike & {
  __typename: 'TableRowValueRoleWithEntity';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  detailedRoles: Scalars['Boolean']['output'];
  entity: EntityLike;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  roleMeta: RoleMeta;
  roleMetas: Array<RoleMeta>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueSanctions = TableRowValueLike & {
  __typename: 'TableRowValueSanctions';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  sanctions: SanctionInfoV2;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueSanctionsWithEntity = TableRowValueLike & {
  __typename: 'TableRowValueSanctionsWithEntity';
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<EntityLike>;
  entityName: Scalars['String']['output'];
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  sanctions: SanctionInfoV2;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export type TableRowValueString = TableRowValueLike & {
  __typename: 'TableRowValueString';
  content: Scalars['String']['output'];
  /** The value to copy to the clipboard. */
  copyValue?: Maybe<Scalars['String']['output']>;
  /** The ID of the icon to display next to the value. */
  iconId?: Maybe<Scalars['String']['output']>;
  /** External link. */
  link?: Maybe<Scalars['String']['output']>;
  reviewMeta?: Maybe<TableRowValueReviewMeta>;
  /** Tooltip shown on hover. */
  tooltip?: Maybe<Scalars['String']['output']>;
};

export enum TableSectionKind {
  ReviewAms = 'REVIEW_AMS',
  ReviewCompanyInformation = 'REVIEW_COMPANY_INFORMATION',
  ReviewCustomCheckboxes = 'REVIEW_CUSTOM_CHECKBOXES',
  ReviewEsg = 'REVIEW_ESG',
  ReviewManagementAndRightsHolders = 'REVIEW_MANAGEMENT_AND_RIGHTS_HOLDERS',
  ReviewPepsAndSanctions = 'REVIEW_PEPS_AND_SANCTIONS'
}

/** A representation of an team. */
export type Team = {
  __typename: 'Team';
  /** The number of companies in this team. */
  companyCount: Scalars['Int']['output'];
  conflicts: Array<ConflictLike>;
  /** The date of creation. */
  created: Scalars['ISODateTime']['output'];
  /** The customer for this team. */
  customer?: Maybe<Customer>;
  featuresV2: Features;
  /** The team ID. */
  id: Scalars['ID']['output'];
  /** The team name. */
  name: Scalars['String']['output'];
  /** Get a portfolio in this team. */
  portfolio?: Maybe<Portfolio>;
  /** The number of portfolios in this team. */
  portfolioCount: Scalars['Int']['output'];
  /** The portfolios in this team. */
  portfolios: PortfolioConnection;
  /** The simple users in the this team. */
  simpleUsers: TeamSimpleUserConnection;
  /** The stickers for this team. */
  stickers: StickerConnection;
  teamSettings: TeamSettings;
  /** The number of users in this team. */
  userCount: Scalars['Int']['output'];
  /** The users in the this team. */
  users: TeamUserConnection;
};


/** A representation of an team. */
export type TeamConflictsArgs = {
  entities: Array<Scalars['ID']['input']>;
  resolved: Scalars['Boolean']['input'];
};


/** A representation of an team. */
export type TeamPortfolioArgs = {
  id: Scalars['ID']['input'];
};


/** A representation of an team. */
export type TeamSimpleUsersArgs = {
  page?: InputMaybe<UserPageInfoInput>;
  q?: InputMaybe<Scalars['String']['input']>;
};

/** An object containing a team activity. */
export type TeamActivity = {
  __typename: 'TeamActivity';
  assignee?: Maybe<SimpleUser>;
  created: Scalars['ISODateTime']['output'];
  createdBy?: Maybe<SimpleUser>;
  entity: EntityLike;
  id: Scalars['ID']['output'];
  kind: TeamActivityKind;
  note?: Maybe<Scalars['String']['output']>;
  reminder?: Maybe<Reminder>;
  timestamp: Scalars['ISODateTime']['output'];
};

export type TeamActivityConnection = {
  __typename: 'TeamActivityConnection';
  edges: Array<TeamActivityConnectionEdge>;
  nextPage: TeamActivityPageInfo;
  previousPage: TeamActivityPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TeamActivityConnectionEdge = {
  __typename: 'TeamActivityConnectionEdge';
  node: TeamActivity;
};

/** An input object representing the contact info of a person. */
export type TeamActivityCreateInput = {
  assignee?: InputMaybe<Scalars['ID']['input']>;
  kind: TeamActivityKind;
  note?: InputMaybe<Scalars['String']['input']>;
  reminderStatus: ReminderStatus;
  timestamp: Scalars['ISODateTime']['input'];
};

/** An enum representing the activity kind. */
export enum TeamActivityFilterKind {
  AssignedActivities = 'ASSIGNED_ACTIVITIES',
  AssignedCompanies = 'ASSIGNED_COMPANIES'
}

/** An enum representing the activity kind. */
export enum TeamActivityKind {
  Call = 'CALL',
  Meeting = 'MEETING',
  Note = 'NOTE'
}

export type TeamActivityPageInfo = {
  __typename: 'TeamActivityPageInfo';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
};

export type TeamActivityPageInfoInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An input object representing the contact info of a person. */
export type TeamActivityUpdateInput = {
  assignee?: InputMaybe<Scalars['ID']['input']>;
  kind?: InputMaybe<TeamActivityKind>;
  note?: InputMaybe<Scalars['String']['input']>;
  reminderStatus?: InputMaybe<ReminderStatus>;
  timestamp?: InputMaybe<Scalars['ISODateTime']['input']>;
};

export type TeamCompanyStatus = {
  __typename: 'TeamCompanyStatus';
  modifiedAt: Scalars['ISODateTime']['output'];
  modifiedBy?: Maybe<SimpleUser>;
  modifiedByKind: CompanyStatusModifiedByKind;
  previousStatus?: Maybe<CompanyStatus>;
  status: CompanyStatus;
};

export type TeamConnection = {
  __typename: 'TeamConnection';
  edges: Array<TeamConnectionEdge>;
};

export type TeamConnectionEdge = {
  __typename: 'TeamConnectionEdge';
  node: Team;
};

export type TeamFlagFilter = {
  __typename: 'TeamFlagFilter';
  period?: Maybe<Duration>;
};

export type TeamFlagFilterInput = {
  period?: InputMaybe<Duration>;
};

export type TeamReviewSettingsV2 = {
  __typename: 'TeamReviewSettingsV2';
  customCheckboxes: Array<ReviewCustomCheckbox>;
  enabledPdfSections: Array<ReviewPdfSectionKind>;
  settings: Array<ReviewSetting>;
};

export type TeamReviewSettingsV2Input = {
  customCheckboxes?: InputMaybe<Array<ReviewCustomCheckboxInput>>;
  enabledPdfSections?: InputMaybe<Array<ReviewPdfSectionKind>>;
  settings: Array<ReviewSettingInput>;
};

export type TeamReviewTrigger = {
  __typename: 'TeamReviewTrigger';
  statements: Array<TeamReviewTriggerStatement>;
};

export type TeamReviewTriggerInput = {
  statements: Array<TeamReviewTriggerStatementInput>;
};

export type TeamReviewTriggerSettings = {
  __typename: 'TeamReviewTriggerSettings';
  triggers: Array<TeamReviewTrigger>;
};

export type TeamReviewTriggerSettingsInput = {
  triggers: Array<TeamReviewTriggerInput>;
};

export type TeamReviewTriggerStatement = {
  __typename: 'TeamReviewTriggerStatement';
  kind: ReviewTriggerStatementKind;
  operator?: Maybe<WhereOperatorKind>;
  statementKindFilter?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type TeamReviewTriggerStatementInput = {
  kind: ReviewTriggerStatementKind;
  operator?: InputMaybe<WhereOperatorKind>;
  statementKindFilter?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type TeamSanctionSettings = {
  __typename: 'TeamSanctionSettings';
  filterOnInception: Scalars['Boolean']['output'];
  filterOnNationality: Scalars['Boolean']['output'];
  similarityScoreThreshold: Scalars['Float']['output'];
};

export type TeamSanctionSettingsInput = {
  filterOnInception: Scalars['Boolean']['input'];
  filterOnNationality: Scalars['Boolean']['input'];
  similarityScoreThreshold: Scalars['Float']['input'];
};

export type TeamSettings = {
  __typename: 'TeamSettings';
  flagFilterSettings: TeamFlagFilter;
  /** Whether or not to only send documents to forward addresses. */
  onlySendDocumentsToTeamEmails: Scalars['Boolean']['output'];
  reviewSettingsV2: TeamReviewSettingsV2;
  reviewTriggerSettings: TeamReviewTriggerSettings;
  sanctionSettings: TeamSanctionSettings;
  /** Email addresses that will get reviews forwarded to them. */
  teamEmails: Array<Email>;
  /** The user that saved these settings */
  user?: Maybe<User>;
};

export type TeamSettingsInput = {
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  flagFilter?: InputMaybe<TeamFlagFilterInput>;
  onlySendDocumentsToTeamEmails?: InputMaybe<Scalars['Boolean']['input']>;
  reviewSettingsV2?: InputMaybe<TeamReviewSettingsV2Input>;
  reviewTriggerSettings?: InputMaybe<TeamReviewTriggerSettingsInput>;
  sanctionSettings?: InputMaybe<TeamSanctionSettingsInput>;
};

export type TeamSimpleUserConnection = {
  __typename: 'TeamSimpleUserConnection';
  edges: Array<TeamSimpleUserConnectionEdge>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TeamSimpleUserConnectionEdge = {
  __typename: 'TeamSimpleUserConnectionEdge';
  node: SimpleUser;
  role: UserRole;
  status: TeamUserStatus;
};

export type TeamSsoConfiguration = {
  __typename: 'TeamSsoConfiguration';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TeamSsoConfigurationInput = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  discoveryUrl: Scalars['String']['input'];
};

export type TeamUserConnection = {
  __typename: 'TeamUserConnection';
  edges: Array<TeamUserConnectionEdge>;
};

export type TeamUserConnectionEdge = {
  __typename: 'TeamUserConnectionEdge';
  node: User;
  role: UserRole;
  status: TeamUserStatus;
};

export enum TeamUserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING'
}

export type TeamUsersFilterInput = {
  statusesExclude?: InputMaybe<Array<TeamUserStatus>>;
  statusesInclude?: InputMaybe<Array<TeamUserStatus>>;
};

export type TeamsFilterInput = {
  createdAt?: InputMaybe<TimeFilterInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Text selector for an event. */
export enum TextSelection {
  /** Body text selector. */
  Body = 'BODY',
  /** Title selector. */
  Title = 'TITLE'
}

export type TimeFilter = {
  __typename: 'TimeFilter';
  /** A timestamp indicating from. */
  from?: Maybe<Scalars['ISODateTime']['output']>;
  /** A duration indicating a time-frame back in time. */
  since?: Maybe<Duration>;
  /** A timestamp indicating to. */
  to?: Maybe<Scalars['ISODateTime']['output']>;
};

export type TimeFilterInput = {
  /** A timestamp indicating from. */
  from?: InputMaybe<Scalars['ISODateTime']['input']>;
  /** A duration indicating a time-frame back in time. */
  since?: InputMaybe<Duration>;
  /** A timestamp indicating to. */
  to?: InputMaybe<Scalars['ISODateTime']['input']>;
};

export type Timespan = {
  __typename: 'Timespan';
  from?: Maybe<Scalars['ISODateTime']['output']>;
  monthsBetween?: Maybe<Scalars['Int']['output']>;
  to?: Maybe<Scalars['ISODateTime']['output']>;
  yearsBetween?: Maybe<Scalars['Int']['output']>;
};

export type TimespanInput = {
  from?: InputMaybe<Scalars['ISODateTime']['input']>;
  to?: InputMaybe<Scalars['ISODateTime']['input']>;
};

/** A title offset. */
export type TitleOffset = Offset & {
  __typename: 'TitleOffset';
  /** Offset relative to the title. */
  offset: Scalars['Int']['output'];
};

/** An object representing a topic. */
export type Topic = EntityLike & {
  __typename: 'Topic';
  /** Access information for this entity. */
  access: EntityAccess;
  /** Entity aliases. */
  aliases?: Maybe<Array<Alias>>;
  /** The children topics. */
  children: TopicConnection;
  /** Entity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Fetch events, within a time-span, related to this entity. */
  events: EntityLikeEventConnection;
  /** Flags for this entity. */
  flags: AdverseFlagConnection;
  /** The internal Strise ID of the company. Save this for fetching this object without searching for an Entity. This can be done with the [Query.entity](#Query.entity) field. */
  id: Scalars['ID']['output'];
  /** Whether or not the topic is hidden. */
  isHidden: Scalars['Boolean']['output'];
  /** Whether or not the topic is a top level topic. */
  isTopLevel: Scalars['Boolean']['output'];
  /** Entity description */
  name?: Maybe<Scalars['String']['output']>;
  notificationSetting?: Maybe<NotificationSetting>;
  /** The parent topics. */
  parents: TopicConnection;
  /** Registers for this entity. */
  registers: RegisterConnection;
};


/** An object representing a topic. */
export type TopicAliasesArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a topic. */
export type TopicChildrenArgs = {
  traverse?: InputMaybe<Scalars['Boolean']['input']>;
};


/** An object representing a topic. */
export type TopicDescriptionArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a topic. */
export type TopicEventsArgs = {
  portfolio?: InputMaybe<Scalars['ID']['input']>;
  settings: EventSettingsInput;
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a topic. */
export type TopicNameArgs = {
  language?: InputMaybe<ContentLanguage>;
};


/** An object representing a topic. */
export type TopicNotificationSettingArgs = {
  team?: InputMaybe<Scalars['ID']['input']>;
};


/** An object representing a topic. */
export type TopicParentsArgs = {
  traverse?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TopicConnection = {
  __typename: 'TopicConnection';
  edges: Array<TopicConnectionEdge>;
};

export type TopicConnectionEdge = {
  __typename: 'TopicConnectionEdge';
  node: Topic;
};

/** An enum representing supported TrackedActivity types. */
export enum TrackedActivityKind {
  StriseCompanyInboxCardExposed = 'STRISE_COMPANY_INBOX_CARD_EXPOSED',
  StriseCompanyStatusChanged = 'STRISE_COMPANY_STATUS_CHANGED',
  StriseCompanyStatusChangedDetails = 'STRISE_COMPANY_STATUS_CHANGED_DETAILS',
  StriseCompanyStatusChangedInbox = 'STRISE_COMPANY_STATUS_CHANGED_INBOX',
  StriseCompanyStatusChangedSettings = 'STRISE_COMPANY_STATUS_CHANGED_SETTINGS',
  StriseContactInfoReveal = 'STRISE_CONTACT_INFO_REVEAL',
  StriseCreditScoreFetched = 'STRISE_CREDIT_SCORE_FETCHED',
  StriseEntityDetailsExposed = 'STRISE_ENTITY_DETAILS_EXPOSED',
  StriseEntityExpandedDetailsExposed = 'STRISE_ENTITY_EXPANDED_DETAILS_EXPOSED',
  StriseEventExposed = 'STRISE_EVENT_EXPOSED',
  StriseEventRelevance = 'STRISE_EVENT_RELEVANCE',
  StriseEventUrlClicked = 'STRISE_EVENT_URL_CLICKED',
  StriseEventViewed = 'STRISE_EVENT_VIEWED',
  StriseExternalLinkClicked = 'STRISE_EXTERNAL_LINK_CLICKED',
  StriseFlagExposed = 'STRISE_FLAG_EXPOSED',
  StriseInteraction = 'STRISE_INTERACTION',
  StriseLinkClicked = 'STRISE_LINK_CLICKED',
  StriseNavigation = 'STRISE_NAVIGATION',
  StriseNoContactInfo = 'STRISE_NO_CONTACT_INFO',
  StriseNoContactInfoTeaser = 'STRISE_NO_CONTACT_INFO_TEASER',
  StriseOpportunityAdded = 'STRISE_OPPORTUNITY_ADDED',
  StriseOpportunityIgnored = 'STRISE_OPPORTUNITY_IGNORED',
  StriseOpportunitySimilarAdded = 'STRISE_OPPORTUNITY_SIMILAR_ADDED',
  StriseOpportunitySimilarIgnored = 'STRISE_OPPORTUNITY_SIMILAR_IGNORED',
  StriseOpportunitySimilarUndo = 'STRISE_OPPORTUNITY_SIMILAR_UNDO',
  StriseOpportunityUndo = 'STRISE_OPPORTUNITY_UNDO',
  StrisePepsSanctionsFetched = 'STRISE_PEPS_SANCTIONS_FETCHED',
  StriseReviewCreated = 'STRISE_REVIEW_CREATED',
  StriseSearchCanceled = 'STRISE_SEARCH_CANCELED',
  StriseSearchInput = 'STRISE_SEARCH_INPUT',
  StriseSearchSelected = 'STRISE_SEARCH_SELECTED',
  StriseSimilarCompanyRelevance = 'STRISE_SIMILAR_COMPANY_RELEVANCE',
  Unknown = 'Unknown'
}

/** When the value is determined to be a twitter handle, this implementation can be used to access relevant content. */
export type TwitterHandlePropertyValue = PropertyValue & {
  __typename: 'TwitterHandlePropertyValue';
  /** A url to the twitter profile. */
  twitterProfileUrl: Scalars['String']['output'];
  /** The value. */
  value: Scalars['String']['output'];
};

export type UpdateEntityBusinessRoleInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  entity: Scalars['ID']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  role: BusinessRoleInput;
};

export type UpdateEntityOwnerInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  entity: Scalars['ID']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  isBeneficialOwner?: InputMaybe<Scalars['Boolean']['input']>;
  ownership?: InputMaybe<Scalars['Float']['input']>;
};

/** A representation of users, which are a collection of entities. */
export type User = {
  __typename: 'User';
  /** The avatar of the user. */
  avatar?: Maybe<Scalars['String']['output']>;
  /** The creation date. */
  createdAt: Scalars['ISODateTime']['output'];
  /** The email of the user. */
  email?: Maybe<Scalars['String']['output']>;
  featuresV2: Features;
  /** The ID of the user. */
  id: Scalars['ID']['output'];
  /** The user that invited this user. */
  invitedBy?: Maybe<User>;
  /** The avatar of the user. */
  lastIp?: Maybe<Scalars['String']['output']>;
  /** The last login date. */
  lastLogin?: Maybe<Scalars['ISODateTime']['output']>;
  /** The number of logins. */
  loginCount?: Maybe<Scalars['Int']['output']>;
  /** The name of the user. */
  name: Scalars['String']['output'];
  /** The nickname. */
  nickname?: Maybe<Scalars['String']['output']>;
  /** The pseudonymized name of the user. */
  pseudonymizedName: Scalars['String']['output'];
  /**
   * Search for a user by query.
   * @deprecated Use `userSettings` instead.
   */
  settings: Settings;
  /** The teams this user is a member of. */
  teams: UserTeamConnection;
  trackedActivities: Array<TrackedActivityKind>;
  /** The last time the user was updated. */
  updatedAt?: Maybe<Scalars['ISODateTime']['output']>;
  userSettings: UserSettings;
};


/** A representation of users, which are a collection of entities. */
export type UserFeaturesV2Args = {
  team: Scalars['ID']['input'];
};


/** A representation of users, which are a collection of entities. */
export type UserTeamsArgs = {
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
};


/** A representation of users, which are a collection of entities. */
export type UserUserSettingsArgs = {
  team: Scalars['ID']['input'];
};

/** A Connection containing users. */
export type UserConnection = {
  __typename: 'UserConnection';
  edges: Array<UserConnectionEdge>;
  nextPage?: Maybe<UserPageInfo>;
  previousPage?: Maybe<UserPageInfo>;
  totalCount: Scalars['Int']['output'];
};

/** An edge containing the user. */
export type UserConnectionEdge = {
  __typename: 'UserConnectionEdge';
  /** The user. */
  node: User;
};

/** A representation of Settings used for control output from the API, based on user preference and context. */
export type UserCreationInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sendInviteEmail: Scalars['Boolean']['input'];
};

/** An enum representing fields to sort by. */
export enum UserField {
  Avatar = 'AVATAR',
  Created = 'CREATED',
  Email = 'EMAIL',
  Language = 'LANGUAGE',
  LastIp = 'LAST_IP',
  LastLogin = 'LAST_LOGIN',
  LoginCount = 'LOGIN_COUNT',
  Name = 'NAME',
  Nickname = 'NICKNAME',
  Updated = 'UPDATED'
}

export type UserGrowSettings = {
  __typename: 'UserGrowSettings';
  accountants: Array<EntityLike>;
  assignees: Array<SimpleUser>;
  collateralCreditors: Array<EntityLike>;
  coordinates: Array<Coordinate>;
  employees: EmployeeFilter;
  financialFieldsHighlighted: Array<Scalars['String']['output']>;
  financialHighlightCount: OpportunityFinancialHighlightCount;
  financials: Array<FinancialValueFilter>;
  flagKinds: Array<FlagKindInfo>;
  inception: MonthsAgoFilter;
  industries: Array<Industry>;
  legalForms: Array<LegalForm>;
  locations: Array<Location>;
  realEstateCollateralCreditors: Array<EntityLike>;
  requireNoAccountant: Scalars['Boolean']['output'];
  tags: Array<Sticker>;
};

export type UserPageInfo = {
  __typename: 'UserPageInfo';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  sort?: Maybe<Array<UserSort>>;
};

export type UserPageInfoInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UserSortInput>>;
};

export type UserPortfolioSettings = {
  __typename: 'UserPortfolioSettings';
  assignees: Array<Maybe<SimpleUser>>;
  countries: Array<ContentLanguage>;
  employees: EmployeeFilter;
  flagSeverities: Array<FlagSeverity>;
  locations: Array<Location>;
  revenue: RevenueFilter;
  statuses: Array<CompanyStatus>;
  tags: Array<Sticker>;
};

export enum UserPositionKind {
  Observer = 'OBSERVER',
  TeamLead = 'TEAM_LEAD',
  TeamMember = 'TEAM_MEMBER',
  TopManagement = 'TOP_MANAGEMENT'
}

export type UserReviewSettings = {
  __typename: 'UserReviewSettings';
  assignees: Array<Maybe<SimpleUser>>;
  statuses: Array<CompanyStatus>;
  tags: Array<Sticker>;
};

/** A user role, which determines the access level for a user. */
export type UserRole = {
  __typename: 'UserRole';
  /** The ID. */
  id: Scalars['ID']['output'];
  /** The name. */
  name: Scalars['String']['output'];
};

export type UserSearchFilterInput = {
  customersFilter?: InputMaybe<CustomersFilterInput>;
  teamUsersFilter?: InputMaybe<TeamUsersFilterInput>;
  teamsFilter?: InputMaybe<TeamsFilterInput>;
  userFilter?: InputMaybe<UsersFilterInput>;
};

export type UserSettings = {
  __typename: 'UserSettings';
  displayLanguage?: Maybe<DisplayLanguage>;
  generalTermsAccepted: Scalars['Boolean']['output'];
  grow: UserGrowSettings;
  portfolio: UserPortfolioSettings;
  positionKind?: Maybe<UserPositionKind>;
  privacyTermsAccepted: Scalars['Boolean']['output'];
  review: UserReviewSettings;
  sidepanel: UserSidepanelSettings;
  timeZone?: Maybe<Scalars['String']['output']>;
  useCaseKinds?: Maybe<Array<UserUseCaseKind>>;
};

export type UserSettingsInput = {
  application?: InputMaybe<ApplicationSettingsInput>;
  events?: InputMaybe<EventSettingsInput>;
  listView?: InputMaybe<ListViewSettingsInput>;
  opportunities?: InputMaybe<OpportunitySettingsInput>;
  review?: InputMaybe<ReviewSettingsInput>;
  terms?: InputMaybe<PrivacySettingsInput>;
};

export type UserSidepanelSettings = {
  __typename: 'UserSidepanelSettings';
  financialFieldsHighlighted: Array<Scalars['String']['output']>;
  sources: Array<EventType>;
  topics: Array<Topic>;
};

export type UserSort = {
  __typename: 'UserSort';
  field: UserField;
  ordering: SortOrdering;
};

export type UserSortInput = {
  field: UserField;
  ordering: SortOrdering;
};

export type UserTeamConnection = {
  __typename: 'UserTeamConnection';
  edges: Array<UserTeamConnectionEdge>;
};

export type UserTeamConnectionEdge = {
  __typename: 'UserTeamConnectionEdge';
  node: Team;
  role: UserRole;
  status: TeamUserStatus;
};

export enum UserUseCaseKind {
  Kyc = 'KYC',
  Prospecting = 'PROSPECTING'
}

export type UsersCreationInputV2 = {
  features: Array<FeatureInput>;
  users: Array<UserCreationInput>;
};

export type UsersFilterInput = {
  createdAt?: InputMaybe<TimeFilterInput>;
  email?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  loggedInAt?: InputMaybe<TimeFilterInput>;
  username?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** When the value is determined to be a website, this implementation can be used to access relevant content. */
export type WebsitePropertyValue = PropertyValue & {
  __typename: 'WebsitePropertyValue';
  /** The corresponding url for the website. */
  url: Scalars['String']['output'];
  /** The value. */
  value: Scalars['String']['output'];
};

export enum WhereOperatorKind {
  EqualTo = 'EQUAL_TO',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  NotEqualTo = 'NOT_EQUAL_TO'
}

/** When the value is determined to be a WikiMedia image, this implementation can be used to access relevant content. */
export type WikiMediaImagePropertyValue = PropertyValue & {
  __typename: 'WikiMediaImagePropertyValue';
  /** An image representing the wiki-media hosted image. */
  image: Image;
  /** The value. */
  value: Scalars['String']['output'];
};
