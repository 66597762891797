import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconCheckSmall = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m17.824 8.067-6.181 8.99-4.35-4.35 1.414-1.414 2.65 2.65 4.819-7.01 1.648 1.134Z'
      clipRule='evenodd'
    />
  </svg>
))
IconCheckSmall.displayName = 'IconCheckSmall'
