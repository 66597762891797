import * as React from 'react'
import { type SvgProps } from '@strise/ui-components'

const buildPairs = (values: number[]): Array<[number, number]> => {
  switch (values.length) {
    case 0:
    case 1: {
      return []
    }
    default: {
      const [v1, v2, ...rest] = values
      return [[v1 ?? 0, v2 ?? 0], ...buildPairs([v2 ?? 0, ...rest])]
    }
  }
}

export const FilledSparkLine = ({ className, values, ...props }: { values: number[] } & Omit<SvgProps, 'values'>) => {
  const data: Array<[number, number]> = buildPairs(values)
  const { max, min } = data.reduce(
    (acc, [v1, v2]) => ({
      min: Math.min(acc.min, v1, v2),
      max: Math.max(acc.max, v1, v2)
    }),
    { min: Number.POSITIVE_INFINITY, max: Number.NEGATIVE_INFINITY }
  )

  if (!data.length) return null

  const graphW = 100
  const graphH = 100
  const graphBottomPadding = 10
  const step = graphW / data.length

  const pathData = data.map(([v1, v2], i) => {
    const span = max - min
    const y1Pct = span ? (v1 - min) / span : 0
    const y2Pct = span ? (v2 - min) / span : 0
    const y1 = graphH - y1Pct * graphH - graphBottomPadding
    const y2 = graphH - y2Pct * graphH - graphBottomPadding
    const x1 = i * step
    const x2 = (i + 1) * step

    const dataString = `L ${x1} ${y1} L ${x2} ${y2}`
    if (i === 0) return `M 0 ${y1} ${dataString}`
    return dataString
  })

  return (
    <svg className={className} viewBox={`0 0 ${graphW} ${graphH}`} preserveAspectRatio='none' {...props}>
      <path d={`${pathData.join(' ')} V ${graphH} H 0 Z`} vectorEffect='non-scaling-stroke' />
    </svg>
  )
}
