import { cn, ExternalLink, Typography } from '@strise/ui-components'
import { Trans } from '@lingui/macro'
import React from 'react'
import { type DataSourceFragment } from '@graphqlTypes'
import { type DivProps } from '@strise/react-utils'

export const DataSources = ({
  className,
  dataSources,
  hideLinks,
  learnMoreLink,
  ...props
}: {
  className?: string
  dataSources: DataSourceFragment[]
  hideLinks?: boolean
  learnMoreLink?: string
} & DivProps) => {
  if (!dataSources.length) return null

  return (
    <div className={cn('flex items-center gap-1 p-4 pb-2 text-secondary-shade-40', className)} {...props}>
      <Typography className='' variant='body2'>
        {dataSources.length === 1 && <Trans>Source</Trans>}
        {dataSources.length > 1 && <Trans>Sources</Trans>}:
      </Typography>
      {dataSources.map((dataSource, index) => {
        const isLast = index === dataSources.length - 1
        return (
          <React.Fragment key={index}>
            {hideLinks ? (
              <Typography variant='body2'>
                {dataSource.name}
                {!isLast && ','}
              </Typography>
            ) : (
              <ExternalLink href={dataSource.link} className='' variant='body2'>
                {dataSource.name}
              </ExternalLink>
            )}
          </React.Fragment>
        )
      })}
      {learnMoreLink && (
        <ExternalLink className='ml-1' href={learnMoreLink} variant='body2'>
          <Trans>Learn more</Trans>
        </ExternalLink>
      )}
    </div>
  )
}
