import { cn, IconGarbage, Select, SelectItem, Typography } from '@strise/ui-components'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { CompanyStatusDot, companyStatuses } from '@strise/app-shared'
import { CompanyStatusSelectButton } from '@strise/app-shared/src/components/companyStatus/CompanyStatusSelectButton'
import { CompanyStatusSelectPaperHeaderText } from '@strise/app-shared/src/components/companyStatus/CompanyStatusSelectPaperHeaderText'
import { companyStatusTitles } from '@strise/app-shared/src/components/companyStatus/companyStatusUtils'
import { ConfirmDialog } from '@strise/ui-components-legacy'
import { CompanyStatus } from '@strise/types'
import { getTitle } from '@utils/enum'
import React, { useCallback, useMemo } from 'react'
import { useToggle } from 'usehooks-ts'
import { companyInPortfolio, type SelectCompaniesStatusProps } from './companyStatusUtils'
import { useIsTeamManager } from '@contexts/TeamContext/TeamContext'
import { TestIDs } from '@utils/testIDs'

export const CompaniesStatusSelect = ({
  className,
  companyIds,
  companyStatus,
  disabled,
  disabledStatuses,
  loading,
  onChange,
  opportunity,
  previousCompanyStatus,
  removable,
  updateCompanyStatus,
  ...props
}: SelectCompaniesStatusProps) => {
  const isTeamManager = useIsTeamManager()
  const [showRemoveConfirm, toggleShowRemoveConfirm] = useToggle(false)

  const handleChange = useCallback(
    (newCompanyStatus: CompanyStatus) => {
      if (newCompanyStatus === CompanyStatus.Ignored) {
        toggleShowRemoveConfirm()
        if (!showRemoveConfirm) {
          return
        }
      }
      updateCompanyStatus(newCompanyStatus, previousCompanyStatus ?? null)
      if (onChange) onChange()
    },
    [showRemoveConfirm]
  )

  const inPortfolio = useMemo(() => companyInPortfolio(companyStatus), [companyStatus])

  const filteredStatuses = companyStatuses.filter((status) =>
    opportunity ? status !== CompanyStatus.Disqualified : true
  )

  const value = inPortfolio ? companyStatus : null

  const isRemovable = isTeamManager && (removable ?? inPortfolio)

  return (
    <>
      {showRemoveConfirm && (
        <ConfirmDialog
          isOpen
          title={t`Do you want to remove the company from the portfolio?`}
          onConfirm={() => handleChange(CompanyStatus.Ignored)}
          onCancel={toggleShowRemoveConfirm}
          confirmText={t`Remove`}
          confirmButtonProps={{ 'data-id': TestIDs.SidePanel.CompanyStatus.confirmRemoveButton }}
          cancelText={t`Cancel`}
          danger
        >
          {companyIds.length > 1 ? (
            <Trans>{companyIds.length} companies will be removed for all users in your team.</Trans>
          ) : (
            <Trans>The company will be removed for all users in your team.</Trans>
          )}
        </ConfirmDialog>
      )}

      <Select
        className={cn(className)}
        contentProps={{ className: 'w-[350px]' }}
        value={value ?? undefined}
        onValueChange={handleChange}
        data-track='false'
        loading={loading}
        disabled={disabled}
        variant='contained'
        palette='tertiary'
        triggerText={<CompanyStatusSelectButton i18n={i18n} value={value} />}
        placeholder={<CompanyStatusSelectButton i18n={i18n} value={value} />}
        ariaLabel={t`Select company status`}
        {...props}
      >
        <CompanyStatusSelectPaperHeaderText />
        {filteredStatuses.map((status) => (
          <SelectItem key={status} value={status} disabled={disabledStatuses?.includes(status)}>
            <div className='flex w-full items-center'>
              <CompanyStatusDot status={status} className='mr-3' />
              {getTitle(companyStatusTitles[status])}
              {status === previousCompanyStatus && (
                <Typography
                  className='ml-8 mr-1 leading-normal text-text-secondary'
                  variant='aLabelSmall'
                  component='span'
                >
                  <Trans>Previous status</Trans>
                </Typography>
              )}
            </div>
          </SelectItem>
        ))}
        {isRemovable && (
          <SelectItem value={CompanyStatus.Ignored}>
            <IconGarbage size='md' className='mr-2' />
            <Trans>Remove from portfolio</Trans>
          </SelectItem>
        )}
      </Select>
    </>
  )
}
