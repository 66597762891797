import * as React from 'react'
import { useLogout } from './authUtils'
import { FullPageLoader } from '../FullPageLoader'

export const LogOutView = React.forwardRef((_, ref: React.ForwardedRef<HTMLDivElement>) => {
  const logout = useLogout()
  React.useEffect(() => {
    logout()
  }, [])
  return (
    <div ref={ref}>
      <FullPageLoader />
    </div>
  )
})
