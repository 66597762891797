import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconRiskValues = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path fill='currentColor' d='M10.5 9.658h-2v6.284h2V9.658ZM10.5 17.083h-2v2.002h2v-2.002Z' />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M18 22v-4h4a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v4H2a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1ZM8 6V3h13v13h-3V8.497l2.194-3.548-1.701-1.052-1.31 2.12C17.122 6.006 17.062 6 17 6H8Zm7.95 2.01.006-.01H3v13h13V8.04l-.05-.03Z'
      clipRule='evenodd'
    />
  </svg>
))
IconRiskValues.displayName = 'IconRiskValues'
