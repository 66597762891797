import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconAdverseFlagNegativeContrast = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref) => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M10.259 3.08c.765-1.354 2.716-1.354 3.482 0l9.572 16.936c.754 1.333-.21 2.984-1.74 2.984H2.427C.896 23-.067 21.35.687 20.016L10.259 3.08Z'
      />
      <path fill='#2E2E2E' d='M11 9h2v7h-2V9ZM11 17h2v2h-2v-2Z' />
    </svg>
  )
)
IconAdverseFlagNegativeContrast.displayName = 'IconAdverseFlagNegativeContrast'
