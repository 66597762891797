import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconRestore = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M3.316 12a8.636 8.636 0 0 0 8.637 8.636c4.77 0 8.73-3.866 8.73-8.636A8.636 8.636 0 0 0 4.86 7.206l4.782-1.115.49 2.102-8.166 1.905L.722 1.357l2.138-.305.623 4.374a10.777 10.777 0 0 1 8.563-4.222c5.82 0 10.565 4.607 10.788 10.373v.824c-.208 5.78-5.051 10.395-10.881 10.395S1.373 18.174 1.164 12.395L3.316 12Z'
    />
  </svg>
))
IconRestore.displayName = 'IconRestore'
