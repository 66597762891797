import { cn, Divider, Typography } from '@strise/ui-components'
import { DataSourceTooltip } from '@components/DataSourceTooltip'
import { type ActivityStatFragment } from '@graphqlTypes'
import { t } from '@lingui/macro'
import { FilledSparkLine } from '@strise/app-shared'
import * as React from 'react'
import { useActivityStatsStyle } from '../utils/activityHooks'
import { type DivProps } from '@strise/react-utils'

export const ActivityTeamStats: React.FC<
  { activityStat: ActivityStatFragment | undefined; label: string } & DivProps
> = ({ activityStat, className, label, ...props }) => {
  const { ActivityStatsIcon, borderRadius, iconColorClassName, percentage, pr, sparkLineClassName, tooltipContent } =
    useActivityStatsStyle(activityStat?.percentageChange)

  return (
    <div>
      <div className={cn('flex items-center justify-between overflow-hidden py-1', className)} {...props}>
        <div className='min-w-0'>
          <Typography variant='h4'>{activityStat?.totalCount || '-'}</Typography>
          <Typography variant='aLabelSmall'>{label}</Typography>
        </div>
        <div className='flex h-5 w-[100px] items-center justify-between'>
          <DataSourceTooltip content={tooltipContent}>
            <Typography
              className='mr-4 flex items-center'
              variant='aLabelSmall'
              component='div'
              style={{ paddingRight: pr, borderRadius }}
            >
              <ActivityStatsIcon className={iconColorClassName} size='md' />
              {percentage}
            </Typography>
          </DataSourceTooltip>
          <DataSourceTooltip content={`${t`Change last`} 7 ${t`days`}`}>
            <div className='h-5' style={{}}>
              <FilledSparkLine
                className={cn('bottom-0 left-0 h-full w-[45px]', sparkLineClassName)}
                values={activityStat?.counts ?? []}
              />
            </div>
          </DataSourceTooltip>
        </div>
      </div>
      <Divider className='my-2' />
    </div>
  )
}
