import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconInfo = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path fill='currentColor' d='M11 6h2v2h-2V6ZM11 10h2v8h-2v-8Z' />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M21 3H3v18h18V3ZM3 1a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3Z'
      clipRule='evenodd'
    />
  </svg>
))
IconInfo.displayName = 'IconInfo'
