import { Button, type ButtonProps, cn } from '@strise/ui-components'
import { Box, type BoxProps } from './Box'
import * as React from 'react'

export interface ButtonGroupProps<T> extends Omit<BoxProps, 'onClick' | 'palette'> {
  buttonProps?: ButtonProps
  onClick: (value: T) => void
  options: Array<{ buttonProps?: ButtonProps; text: string; value: T }>
  palette?: 'secondary'
  value: T
}

const extractButtonGroupBorderRadius = (index: number, optionsLength: number) => {
  if (index === 0) return 'rounded-l'
  if (index === optionsLength - 1) return 'rounded-r'
}
export const ButtonGroup = <T extends string | boolean>({
  buttonProps,
  className,
  onClick,
  options,
  palette = 'secondary',
  size,
  value,
  ...props
}: ButtonGroupProps<T> & Pick<ButtonProps, 'size'>) => (
  <Box className={className} display='inline-flex' flexDirection='row' {...props}>
    {options.map((option, index) => (
      <React.Fragment key={index}>
        <Button
          size={size}
          variant='contained'
          palette={value === option.value ? palette : 'tertiary'}
          onClick={() => {
            onClick(option.value)
          }}
          {...buttonProps}
          {...option.buttonProps}
          className={cn(
            'shrink-0 grow basis-0 justify-start truncate pl-3 text-sm',
            extractButtonGroupBorderRadius(index, options.length),
            buttonProps?.className,
            option.buttonProps?.className
          )}
        >
          {option.text}
        </Button>
        <Box flex='0 0 1px' />
      </React.Fragment>
    ))}
  </Box>
)
