const hexToInt = (h: string) => Number.parseInt(h, 16)

export const hexToRGB = (hex: string): [number, number, number] | null => {
  // 3 digits
  if (hex.length === 4) {
    return [
      hexToInt((hex[1] ?? '0') + (hex[1] ?? '0')),
      hexToInt((hex[2] ?? '0') + (hex[2] ?? '0')),
      hexToInt((hex[3] ?? '0') + (hex[3] ?? '0'))
    ]
  }

  // 6 digits
  if (hex.length === 7) {
    return [
      hexToInt((hex[1] ?? '0') + (hex[2] ?? '0')),
      hexToInt((hex[3] ?? '0') + (hex[4] ?? '0')),
      hexToInt((hex[5] ?? '0') + (hex[6] ?? '0'))
    ]
  }

  return null
}
