import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconFullScreen = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m22.707 2.707-20 20-1.414-1.414 20-20 1.414 1.414Z'
      clipRule='evenodd'
    />
    <path fill='currentColor' fillRule='evenodd' d='M1 23V12h2v9h9v2H1ZM23 1v11h-2V3h-9.5V1H23Z' clipRule='evenodd' />
  </svg>
))
IconFullScreen.displayName = 'IconFullScreen'
