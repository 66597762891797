import { cn, Skeleton } from '@strise/ui-components'
import * as React from 'react'

const EventSummary = ({ compact }: { compact?: boolean }) => (
  <>
    <Skeleton className={cn('mb-1 w-full', compact ? 'h-7' : 'h-10')} />
    <Skeleton className={cn('mb-1 w-[95%]', compact ? 'h-7' : 'h-10')} />
    {compact && <Skeleton className='h-[7px] w-[90%]' />}
  </>
)

const CardEventText = () => {
  return (
    <div className='mb-4 flex rounded-md bg-white'>
      <div className='mb-12 flex w-full p-4'>
        <div className='w-10/12'>
          <div className='mb-4 flex items-center'>
            <Skeleton className='h-[7px] w-[90%]' />
          </div>
          <Skeleton className='mb-4 w-10' />
          <EventSummary />
        </div>
        <div className='flex w-2/12 flex-col items-end justify-start'>
          <div className='flex flex-col items-center justify-center'>
            <div className='m-5 mt-2 flex flex-row'>
              <Skeleton className='size-[7px]' variant='circle' />
              <Skeleton className='size-[7px]' variant='circle' />
              <Skeleton className='size-[7px]' variant='circle' />
            </div>
            <div className='mt-2'>
              <Skeleton className='size-[30px]' variant='circle' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
// returns a given number of event text loaders
export const EventsLoader = ({ size = 3 }) => {
  return (
    <>
      {Array.from({ length: size }, (_, idx) => (
        <CardEventText key={idx} />
      ))}
    </>
  )
}
