import { type StyleFunction, type StyleFunctionProps } from './StyleFunction'
import { isObject, omit } from 'lodash-es'

// This is inspired by mui's implementation, but this supports nested rules
// so you can do: '&:hover': {}
// https://github.com/mui-org/material-ui/blob/02671c05c0eefef43fc126845da4b2a04052c208/packages/material-ui-system/src/css.js#L16
export const sx = (styleFunction: StyleFunction & { filterProps?: string[] }) => {
  return (props: StyleFunctionProps) => {
    // eslint-disable-next-line functional/no-let,@typescript-eslint/no-unsafe-assignment
    let output = styleFunction(props)

    if (props.sx) {
      for (const rule in props.sx) {
        if (isObject(props.sx[rule])) {
          // eslint-disable-next-line functional/immutable-data,@typescript-eslint/no-unsafe-assignment
          output[rule] = {
            ...omit(props.sx[rule] as object, styleFunction.filterProps ?? []),
            ...styleFunction({
              theme: props.theme,
              ...(props.sx[rule] as object)
            })
          }
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete,functional/immutable-data
          delete props.sx[rule]
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      output = {
        ...output,
        ...omit(props.sx, styleFunction.filterProps ?? []),
        ...styleFunction({ theme: props.theme, ...props.sx })
      }
    }

    return output
  }
}
