import base64 from 'base-64'
import utf8 from 'utf8'
import { chunk } from 'lodash-es'
import { extractBrowserInfo } from './browser'
import { type Mixpanel, type MixpanelEngageData, type MixpanelOperation, type MixpanelTrackData } from './typings'
import { objectKeys, anonymizeEmails } from '@strise/ts-utils'
import { getBrowserGlobals } from '@strise/react-utils'

const emptyConfig = {
  token: '',
  apiHost: '',
  distinctId: '',
  globalProps: {}
}
// eslint-disable-next-line functional/no-let
let mutableConfig = { ...emptyConfig }

const loaded = () => {
  return mutableConfig.token && mutableConfig.apiHost && mutableConfig.distinctId
}

const request = (endpoint: '/track' | '/engage', data: object[]) => {
  if (!loaded()) return

  try {
    const url = [mutableConfig.apiHost, endpoint].join('')
    const stringifiedData = JSON.stringify(data)
    const cleanedString = anonymizeEmails(stringifiedData)
    const body = ['data', base64.encode(utf8.encode(cleanedString))].join('=')

    getBrowserGlobals()?.window.fetch(url, { method: 'POST', body })
  } catch (e) {
    console.error(e)
  }
}

const reset = () => {
  mutableConfig = { ...emptyConfig }
}

const register = (props: object) => {
  mutableConfig.globalProps = { ...mutableConfig.globalProps, ...props }
}

const init = (token: string, apiHost: string) => {
  mutableConfig.token = token
  mutableConfig.apiHost = apiHost

  const browserInfo = extractBrowserInfo()
  register({
    token,
    $browser: browserInfo?.name,
    $browser_version: browserInfo?.version,
    $screen_width: getBrowserGlobals()?.window.screen.width,
    $screen_height: getBrowserGlobals()?.window.screen.height
  })
}

const identify = (distinctId: string, peopleOps: MixpanelOperation | null) => {
  mutableConfig.distinctId = distinctId

  register({
    distinct_id: distinctId,
    $user_id: distinctId
  })

  if (!peopleOps) return

  const data = objectKeys(peopleOps).map(
    (op): MixpanelEngageData => ({
      $token: mutableConfig.token,
      $distinct_id: mutableConfig.distinctId,
      [op]: peopleOps[op]
    })
  )

  request('/engage', data)
}

const track = (events: MixpanelTrackData[]) => {
  const data = events.map((event: MixpanelTrackData) => ({
    ...event,
    properties: {
      ...mutableConfig.globalProps,
      ...event.properties
    }
  }))

  request('/track', data)
}

const batchTrack = (events: MixpanelTrackData[]) => {
  // mixpanel can take max 50 at a time
  const eventsChunks = chunk(events, 50)
  eventsChunks.map((eventsChunk) => track(eventsChunk))
}

export const mixpanel: Mixpanel = {
  init,
  reset,
  track,
  batchTrack,
  register,
  identify
}
