import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconPortfolio = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M8 13h12v2H8v-2ZM8 17h12v2H8v-2ZM4 17h2v2H4v-2ZM4 13h2v2H4v-2ZM4 9h2v2H4V9ZM8 9h12v2H8V9ZM3 5h18v2H3V5Z'
      clipRule='evenodd'
    />
    <path fill='currentColor' fillRule='evenodd' d='M1 1h22v22H1V1Zm2 2v18h18V3H3Z' clipRule='evenodd' />
  </svg>
))
IconPortfolio.displayName = 'IconPortfolio'
