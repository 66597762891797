import * as React from 'react'
import { useEntityQuery } from '@graphqlOperations'
import { EntityLink } from './EntityLink'
import { type EntityLinkProps } from './entityLinkUtils'

export const EntityIdLink = React.forwardRef<HTMLDivElement, EntityLinkProps & { id: string }>(
  ({ id, ...props }: EntityLinkProps & { id: string }, ref) => {
    const { data } = useEntityQuery({
      variables: { id }
    })
    const entity = data?.entity
    if (!entity) return null
    return <EntityLink ref={ref} entity={entity} {...props} />
  }
)
