import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconConnect = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <g fill='currentColor' fillRule='evenodd' clipPath='url(#IconConnect_svg__a)' clipRule='evenodd'>
      <path d='M23.207.793a1 1 0 0 1 0 1.414l-1.965 1.965.657.657a4 4 0 0 1 0 5.656l-4.242 4.243L9.17 6.243 13.414 2a4 4 0 0 1 5.657 0l.757.757L21.793.793a1 1 0 0 1 1.414 0Zm-2.722 5.45a2 2 0 0 1 0 2.828L17.657 11.9 12 6.243l2.828-2.829a2 2 0 0 1 2.829 0l2.828 2.829ZM11.329 14.257l-1.586-1.585 1.964-1.965-1.414-1.414-1.964 1.964-2.086-2.086L2 13.414a4 4 0 0 0 0 5.657l.757.757-1.964 1.965a1 1 0 1 0 1.414 1.414l1.965-1.965.656.657a4 4 0 0 0 5.657 0l4.243-4.242-1.985-1.986 1.964-1.964-1.414-1.414-1.964 1.964Zm-7.915.571L6.243 12l5.656 5.657-2.828 2.828a2 2 0 0 1-2.828 0l-2.829-2.828a2 2 0 0 1 0-2.829Z' />
    </g>
    <defs>
      <clipPath id='IconConnect_svg__a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
))
IconConnect.displayName = 'IconConnect'
