import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconDayTime = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='m20.808 19.394-3.202-3.202a7.04 7.04 0 0 1-1.414 1.414l3.202 3.202c.511-.43.985-.903 1.414-1.414ZM4.606 20.808l3.202-3.202a7.04 7.04 0 0 1-1.414-1.414l-3.202 3.202c.43.511.903.985 1.414 1.414ZM3.192 4.606l3.202 3.202a7.04 7.04 0 0 1 1.414-1.414L4.606 3.192c-.511.43-.985.903-1.414 1.414Z'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M13 7.1c.638.13 1.233.38 1.757.728a5.026 5.026 0 0 1 1.415 1.415A4.972 4.972 0 0 1 17 12a4.972 4.972 0 0 1-.828 2.757 5.025 5.025 0 0 1-1.415 1.415A4.972 4.972 0 0 1 12 17a4.972 4.972 0 0 1-2.757-.828 5.026 5.026 0 0 1-1.415-1.415A4.972 4.972 0 0 1 7 12a4.972 4.972 0 0 1 .828-2.757 5.027 5.027 0 0 1 1.415-1.415A4.972 4.972 0 0 1 12 7c.342 0 .677.034 1 .1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='M13 1v4.07a7.06 7.06 0 0 0-2 0V1h2ZM1 11h4.07a7.06 7.06 0 0 0 0 2H1v-2ZM11 23v-4.07a7.062 7.062 0 0 0 2 0V23h-2ZM23 13h-4.07a7.062 7.062 0 0 0 0-2H23v2ZM20.707 4.707l-3.1 3.1a7.04 7.04 0 0 0-1.415-1.413l3.1-3.101 1.415 1.414Z'
    />
  </svg>
))
IconDayTime.displayName = 'IconDayTime'
