import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconFamily = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M9.63 3.316A4 4 0 1 0 3.353 8 3.99 3.99 0 0 0 2 11v2h1.055l.015.124a9.007 9.007 0 0 0 5.029 6.989A4.012 4.012 0 0 0 8 21v2h8v-2a4.01 4.01 0 0 0-.099-.887A9.007 9.007 0 0 0 20.945 13H22v-2a3.99 3.99 0 0 0-1.354-3 4 4 0 1 0-6.275-4.684A9.009 9.009 0 0 0 12 3c-.82 0-1.615.11-2.37.316ZM6 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3.99-1.707A3.99 3.99 0 0 1 8.645 8 3.99 3.99 0 0 1 10 11v.535A3.982 3.982 0 0 1 12 11c.729 0 1.412.195 2 .535V11a3.99 3.99 0 0 1 1.354-3 3.99 3.99 0 0 1-1.343-2.707A7.001 7.001 0 0 0 12 5c-.7 0-1.374.102-2.01.293ZM18.93 13h-3.465c.34.588.535 1.271.535 2a3.99 3.99 0 0 1-1.354 3c.119.105.231.217.337.335A7.009 7.009 0 0 0 18.929 13Zm-9.913 5.335c.106-.118.218-.23.337-.335A3.99 3.99 0 0 1 8 15c0-.729.195-1.412.535-2H5.071a7.009 7.009 0 0 0 3.946 5.335ZM16 11a2 2 0 1 1 4 0h-4Zm4-6a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-8 14a2 2 0 0 0-2 2h4a2 2 0 0 0-2-2Zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm-8-6a2 2 0 1 1 4 0H4Z'
      clipRule='evenodd'
    />
  </svg>
))
IconFamily.displayName = 'IconFamily'
