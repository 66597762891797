import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconDisable = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='#000'
      fillRule='evenodd'
      d='M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1ZM3 12a9 9 0 0 1 14.862-6.83L5.171 17.863A8.965 8.965 0 0 1 3 12Zm3.635 7.227A9 9 0 0 0 19.227 6.635L6.635 19.226Z'
      clipRule='evenodd'
    />
  </svg>
))
IconDisable.displayName = 'IconDisable'
