import * as React from 'react'
import { Button, Card, CardActionArea, CardContent, IconHeart, Typography } from '@strise/ui-components'
import { t } from '@lingui/macro'
import { useState } from 'react'

export const UserFeedbackCard: React.FC<{ origin: string }> = () => {
  const [feedbackProvided, setFeedbackProvided] = useState(false)

  const cardContentRender = () => {
    if (feedbackProvided) {
      return (
        <CardContent className='items-center justify-center space-y-4 pb-4'>
          <Typography variant='subtitle1'>{t`Thank you for your feedback!`} </Typography>
          <IconHeart />
        </CardContent>
      )
    }

    return (
      <CardContent className='space-y-4 pb-4'>
        <Typography variant='subtitle1'>{t`Did you find what you were looking for?`} </Typography>
        <CardActionArea className='flex items-center justify-center gap-2'>
          <Button
            variant='contained'
            palette='tertiary'
            size='lg'
            onClick={() => setFeedbackProvided(true)}
            data-track={`${origin} / Feedback / Yes`}
          >
            {t`Yes`}
          </Button>
          {/* TODO: Add redirect to feedback form */}
          <Button
            variant='contained'
            palette='tertiary'
            size='lg'
            onClick={() => setFeedbackProvided(true)}
            data-track={`${origin} / Feedback / No`}
          >
            {t`No`}
          </Button>
        </CardActionArea>
      </CardContent>
    )
  }

  return (
    <Card rounded='none' size='sm' className='w-full items-center justify-center border-none'>
      {cardContentRender()}
    </Card>
  )
}
