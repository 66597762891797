import { useEffect, useState } from 'react'
import { type RenderNodeContent, type Subsidiary } from './types'
import { type RootNodeType } from './RootNode'
import { orderBy } from 'lodash-es'
import { type SubsidiaryNodeType } from './SubsidiaryNode'
import { type SubsidiaryEdgeType } from './SubsidiaryEdge'
import { type ExpandSubsidiariesNodeType } from './ExpandSubsidiariesNode'
import { BigNumber, formatShare } from '@strise/ts-utils'
import {
  ROOT_NODE_HEIGHT,
  NODE_SEPARATION,
  NODE_WIDTH,
  NODE_HEIGHT,
  EXPAND_SUBSIDIARIES_NODE_SIZE,
  SUBSIDIARY_LEVEL_HORIZONTAL_OFFSET,
  SUBSIDIARY_HANDLE_OFFSET
} from './sizes'

// TODO: We struggle with duplicate subsidiaries, we need to find a way to handle them, using index is not enough
export const useSubsidiaries = <T extends Subsidiary<T>>(
  rootNode: RootNodeType,
  onExpandSubsidiaries?: (id: string) => Promise<T[]>,
  renderNodeContent?: RenderNodeContent<T>,
  formatOwnership?: (percentage: number) => React.ReactNode
) => {
  const [subsidiaries, setSubsidiaries] = useState<T[]>([])

  useEffect(() => {
    handleExpandSubsidiaries(rootNode.id, true)
  }, [onExpandSubsidiaries, rootNode.id])

  const handleExpandSubsidiaries = async (id: string, isExpanded: boolean) => {
    if (!onExpandSubsidiaries) return

    const expandedSubsidiaries = await onExpandSubsidiaries(id)
    const filteredSubsidiaries = expandedSubsidiaries.filter((sub) => sub.id !== rootNode.id)
    const sortedSubsidiaries = orderBy(filteredSubsidiaries, (sub) => -sub.sharePercentage)

    // If the expanded subsidiaries are empty, we don't need to update the tree, just store the first level
    if (subsidiaries.length === 0) {
      setSubsidiaries(sortedSubsidiaries)
      return
    }

    // Recursively update the subsidiaries tree
    setSubsidiaries((prevSubsidiaries) => {
      return updateSubsidiaryTree(prevSubsidiaries, id, sortedSubsidiaries, isExpanded)
    })
  }

  const subsidiaryNodes = createSubsidiaryNodes(
    subsidiaries,
    rootNode.position.x,
    rootNode.position.y,
    1,
    renderNodeContent,
    handleExpandSubsidiaries
  )

  const subsidiaryEdges = createSubsidiaryEdges(subsidiaries, rootNode.id, 1, formatOwnership)

  return {
    subsidiaryEdges,
    subsidiaryNodes
  }
}

const updateSubsidiaryTree = <T extends Subsidiary<T>>(
  subsidiaries: T[],
  parentId: string,
  newSubsidiaries: T[],
  isExpanded: boolean
): T[] => {
  return subsidiaries.map((sub) => {
    const parentOwnership = BigNumber(sub.sharePercentage)
    const subsidiariesWithIndirectOwnership = newSubsidiaries.map((newSub) => {
      const indirectOwnership = new BigNumber(newSub.sharePercentage).times(parentOwnership).dividedBy(100).toNumber()
      return {
        ...newSub,
        sharePercentage: indirectOwnership
      }
    })

    if (sub.id === parentId) {
      return {
        ...sub,
        subsidiaries: isExpanded ? subsidiariesWithIndirectOwnership : [] // Update with the newly fetched subsidiaries
      }
    }

    // Recursively update child nodes if they exist
    if (sub.subsidiaries) {
      return {
        ...sub,
        subsidiaries: updateSubsidiaryTree(sub.subsidiaries, parentId, newSubsidiaries, isExpanded)
      }
    }

    return sub
  })
}

const createSubsidiaryNodes = <T extends Subsidiary<T>>(
  subsidiaries: T[],
  parentX: number,
  parentY: number,
  level: number,
  renderNodeContent?: RenderNodeContent<T>,
  onExpandSubsidiaries?: (id: string, isExpanded: boolean) => Promise<void>
): Array<SubsidiaryNodeType<T> | ExpandSubsidiariesNodeType> => {
  // Keep track of where to position the next subsidiary node on the y axis
  // eslint-disable-next-line functional/no-let
  let mutableNextNodeY = parentY + NODE_SEPARATION + (level === 1 ? ROOT_NODE_HEIGHT : EXPAND_SUBSIDIARIES_NODE_SIZE)

  return subsidiaries.flatMap((subsidiary, index) => {
    const x = parentX + SUBSIDIARY_LEVEL_HORIZONTAL_OFFSET // Adjust X position based on level
    const y = mutableNextNodeY

    const subsidiaryNode: SubsidiaryNodeType<T> = {
      id: `${subsidiary.id}-${index}`,
      data: { ...subsidiary, renderNodeContent },
      position: { x, y },
      width: NODE_WIDTH,
      height: NODE_HEIGHT,
      type: 'subsidiary',
      draggable: false,
      connectable: false
    }

    const expandNodeY = y + NODE_HEIGHT + NODE_SEPARATION
    // Directly under the subsidiary nodes "subsidiaryParentHandle"
    const expandNodeX =
      x + SUBSIDIARY_LEVEL_HORIZONTAL_OFFSET - EXPAND_SUBSIDIARIES_NODE_SIZE / 2 - SUBSIDIARY_HANDLE_OFFSET

    const expandSubsidiariesNode = createExpandSubsidiariesNode(
      subsidiary,
      expandNodeX,
      expandNodeY,
      onExpandSubsidiaries
    )

    // Recursively process children if they exist
    const childNodes = subsidiary.subsidiaries
      ? createSubsidiaryNodes(
          subsidiary.subsidiaries,
          x,
          expandNodeY,
          level + 1,
          renderNodeContent,
          onExpandSubsidiaries
        )
      : []

    const lastChildY = childNodes[childNodes.length - 1]?.position.y

    mutableNextNodeY = calculateNextNodeY(y, expandSubsidiariesNode?.position.y, lastChildY)

    // Return the current node along with all of its children nodes
    return expandSubsidiariesNode
      ? [subsidiaryNode, expandSubsidiariesNode, ...childNodes]
      : [subsidiaryNode, ...childNodes]
  })
}

const calculateNextNodeY = (currentNodeY: number, expandSubsidiariesNodeY?: number, lastChildY?: number): number => {
  if (lastChildY) {
    return lastChildY + NODE_HEIGHT + NODE_SEPARATION
  }
  if (expandSubsidiariesNodeY) {
    return expandSubsidiariesNodeY + EXPAND_SUBSIDIARIES_NODE_SIZE + NODE_SEPARATION
  }
  return currentNodeY + NODE_HEIGHT + NODE_SEPARATION
}

const createExpandSubsidiariesNode = <T extends Subsidiary<T>>(
  subsidiary: T,
  x: number,
  y: number,
  onExpandSubsidiaries?: (id: string, isExpanded: boolean) => Promise<void>
): ExpandSubsidiariesNodeType | null => {
  if (subsidiary.numOwnerships === 0 || !onExpandSubsidiaries) return null

  return {
    id: `expand-${subsidiary.id}`,
    data: { subsidiaryId: subsidiary.id, numOwnerships: subsidiary.numOwnerships, onExpandSubsidiaries },
    position: { x, y },
    style: { width: EXPAND_SUBSIDIARIES_NODE_SIZE, height: EXPAND_SUBSIDIARIES_NODE_SIZE },
    type: 'expandSubsidiaries',
    connectable: false
  }
}

const getSubsidiaryNodeSource = (parentId: string, index: number, level: number) => {
  const parentIsRootNode = level === 1 && index === 0
  const parentIsExpandNode = level > 1 && index === 0

  if (parentIsRootNode) {
    return { source: parentId, sourceHandle: 'rootNodeBottomHandle' }
  }
  if (parentIsExpandNode) {
    return { source: `expand-${parentId}`, sourceHandle: 'expandNodeBottomHandle' }
  }
  return { source: `${parentId}-${index - 1}`, sourceHandle: undefined }
}

const createSubsidiaryEdges = <T extends Subsidiary<T>>(
  subsidiaries: T[],
  // Represents either the root node, or the parent subsidiary
  parentId: string,
  level = 1,
  formatOwnership?: (percentage: number) => React.ReactNode
): SubsidiaryEdgeType[] => {
  return subsidiaries.flatMap((subsidiary, index) => {
    const parent = subsidiaries[index - 1]?.id ?? parentId
    const { source, sourceHandle } = getSubsidiaryNodeSource(parent, index, level)
    const target = `${subsidiary.id}-${index}`

    const label = formatOwnership
      ? formatOwnership(subsidiary.sharePercentage)
      : formatShare(subsidiary.sharePercentage).short

    // Create the edge between the parent (which is either the root node, an expand node or the previous subsidiary) and subsidiary
    const mainEdge: SubsidiaryEdgeType = {
      id: `${source}-${target}`,
      source,
      sourceHandle,
      target: `${subsidiary.id}-${index}`,
      label,
      type: 'subsidiary',
      focusable: false,
      selectable: false
    }

    // Create the edge to the expand node, if there is one
    const expandEdge: SubsidiaryEdgeType | null =
      subsidiary.numOwnerships > 0
        ? {
            id: `${subsidiary.id}-expand`,
            source: target,
            sourceHandle: 'subsidiaryParentHandle',
            target: `expand-${subsidiary.id}`,
            type: 'subsidiary',
            focusable: false,
            selectable: false
          }
        : null

    // Recursively create edges for child subsidiaries
    const childEdges =
      subsidiary.numOwnerships > 0
        ? createSubsidiaryEdges(subsidiary.subsidiaries ?? [], subsidiary.id, level + 1, formatOwnership)
        : []

    // Return the main edge, the expand edge (if any), and all child edges
    return expandEdge ? [mainEdge, expandEdge, ...childEdges] : [mainEdge, ...childEdges]
  })
}
