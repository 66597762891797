import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconPdf = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M1 1h13.914L20 6.086V8h-2v-.5h-4.5V3H3v17h11.5v2H1V1Zm14.5 3.414L16.586 5.5H15.5V4.414Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M4 9h4.5a2 2 0 0 1 2 2v1.5a2 2 0 0 1-2 2H6V17H4V9Zm2 3.5h2.5V11H6v1.5ZM11 9h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4V9Zm2 2v4h2v-4h-2Zm5-2h5.5v2H20v1.5h2.5v2H20V17h-2V9ZM18 22v-4h2v4h-2Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m19 23.414-3.707-3.707 1.414-1.414L19 20.586l2.293-2.293 1.414 1.414L19 23.414Z'
      clipRule='evenodd'
    />
  </svg>
))
IconPdf.displayName = 'IconPdf'
