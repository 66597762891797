import * as React from 'react'
import {
  Card,
  CardContent,
  CardDescription,
  CardHighlight,
  CardTitle,
  IconArrowRight,
  Typography
} from '@strise/ui-components'
import { ReactRouterLink } from '@strise/app-shared'
import { t } from '@lingui/macro'

interface HomeCardProps {
  description: string
  icon: React.ReactNode
  title: string
  url: string
}

export const HomeCard = ({ description, icon, title, url }: HomeCardProps) => {
  return (
    <ReactRouterLink to={url} className='no-underline hover:no-underline'>
      <Card
        variant='contained'
        highlightPalette='primary'
        highlight='bottom'
        size='main'
        palette='primary'
        className='border-gray-10'
      >
        <CardContent className='h-full rounded bg-white'>
          <CardTitle className='pt-0'>{icon}</CardTitle>
          <Typography variant='h2' className='pb-4 pt-2'>
            {title}
          </Typography>
          <CardDescription className='h-14'>
            <Typography variant='body1' className='text-gray-60'>
              {description}
            </Typography>
          </CardDescription>
        </CardContent>
        <CardHighlight>
          <Typography variant='body1'>
            {t`Go to`} {title}
          </Typography>
          <IconArrowRight />
        </CardHighlight>
      </Card>
    </ReactRouterLink>
  )
}
