import type { SimpleUserFragment } from '@graphqlTypes'
import { type DivProps } from '@strise/react-utils'
import { UserAvatar } from '@strise/app-shared'
import * as React from 'react'
import { cn } from '@strise/ui-components'

interface UserProps extends DivProps {
  user: SimpleUserFragment
}

export const User = ({ className, user }: UserProps) => {
  return (
    <div className={cn('flex items-center gap-x-1', className)}>
      <UserAvatar user={user} className='mr-1 size-5' />
      {user.name}
    </div>
  )
}
