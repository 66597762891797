import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconPin = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m23.095 9.966-8.861-8.861-1.054 4.217L9.007 8.69a3.001 3.001 0 0 0-3.777.381l-3.444 3.444 2.99 2.989-2.99 6.651 6.89-2.75 3.01 3.01 3.443-3.444a3 3 0 0 0 .38-3.778l3.37-4.172 4.216-1.055ZM7.176 17.905l-.921-.921-.961 1.817 1.882-.896ZM15.12 5.807l.197-.79 3.866 3.866-.79.197a2 2 0 0 0-1.07.684L12.86 15.29l.854.853a1 1 0 0 1 0 1.414l-2.03 2.03-7.07-7.071 2.029-2.03a1 1 0 0 1 1.414 0l.853.854 5.525-4.461a2 2 0 0 0 .684-1.071Z'
      clipRule='evenodd'
    />
  </svg>
))
IconPin.displayName = 'IconPin'
