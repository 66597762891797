import { type SimpleUserFragment } from '@graphqlTypes'
import { t } from '@lingui/macro'
import { UserAvatar, useUserId } from '@strise/app-shared'
import { cn, Typography, type TypographyProps } from '@strise/ui-components'
import * as React from 'react'

export const ActivityActiveMemberStats: React.FC<{ user: SimpleUserFragment } & TypographyProps> = ({
  className,
  user,
  ...props
}) => {
  const currentUserId = useUserId()
  const name = currentUserId === user.id ? `${user.name} (${t({ id: 'you (deg)', message: 'you' })})` : user.name

  return (
    <Typography className={cn('flex items-center py-1', className)} component='div' variant='aLabel' {...props}>
      <UserAvatar className='mr-3 size-10 rounded-full border border-divider' user={user} />
      {name}
    </Typography>
  )
}
