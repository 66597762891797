import { type TransformedApiTableRow } from '@utils/apiTable/apiTableUtils'
import * as React from 'react'
import { ReviewCheckboxColumn } from '@components/Review/ReviewCheckboxColumn'
import { type BaseCheckboxSectionProps, extractTextColorClass } from '@utils/reviewUtils'
import { TransformedTableRow } from '@components/TransformedTable/TransformedTableRow'
import { ReviewCardInlineComments } from '@components/Review/ReviewCardInlineComments'
import { Checkbox, cn } from '@strise/ui-components'
import { ReviewSettingKind, TableRowKind } from '@strise/types'
import { TestIDs } from '@utils/testIDs'
import { setChildState } from '@strise/react-utils'

export const ReviewRow = ({
  checkedState,
  companyId,
  disabled,
  errors,
  inlineComments,
  loading,
  row,
  setInlineComments,
  teamReviewSettings,
  updateCheckedState
}: BaseCheckboxSectionProps & { row: TransformedApiTableRow }) => {
  const rowDisabled = !!row.disabled || disabled
  const checked = row.customCheckboxName
    ? Boolean(checkedState.customCheckedMap[row.customCheckboxName])
    : Boolean(checkedState.checkedMap[row.kind])
  const [isHovered, setIsHovered] = React.useState(false)
  const inlineCommentsEnabled = teamReviewSettings.settings.find(
    (setting) => setting.kind === ReviewSettingKind.EnableInlineComments
  )?.enabled
  const colorClass = extractTextColorClass(errors && !checked, checked)

  return (
    <div
      className={cn('relative flex border-b border-divider', isHovered ? 'bg-tertiary-main' : 'bg-transparent')}
      onMouseEnter={(): void => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-id={TestIDs.Review.Card.row(row.kind)}
    >
      <ReviewCheckboxColumn
        fillSpace={row.kind === TableRowKind.ReviewCustomCheckbox}
        className={cn('py-2', checked ? 'text-text-secondary' : 'text-text-primary')}
      >
        <Checkbox
          labelProps={{ className: colorClass }}
          label={row.label}
          disabled={rowDisabled || loading}
          id={`${row.key}-${companyId}`}
          data-track={`Review / ${row.key} / Toggle checkbox`}
          checked={checked}
          onCheckedChange={() => updateCheckedState(row.kind, row.customCheckboxName)}
        />
      </ReviewCheckboxColumn>

      <div
        className={cn('grow', checked ? 'text-text-secondary' : 'text-text-primary', {
          '[&>a]:text-text-secondary': checked
        })}
      >
        <TransformedTableRow row={row} context={`Review / ${row.key}`} />
      </div>
      {inlineCommentsEnabled && (
        <ReviewCardInlineComments
          customCheckboxName={row.customCheckboxName}
          rowKey={row.key}
          title={row.label}
          inlineComments={
            row.kind === TableRowKind.ReviewCustomCheckbox
              ? inlineComments[row.kind]?.filter((comment) => comment.customCheckboxName === row.customCheckboxName)
              : inlineComments[row.kind]
          }
          setInlineComments={setChildState(setInlineComments, row.kind)}
          isHovered={isHovered}
        />
      )}
    </div>
  )
}
