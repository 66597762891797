import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

/** Helper function that wraps `clsx` inside `twMerge` for easily merging several Tailwind classes */
export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}

/** Helper function for wrapping objects that have tailwind classes, so it gets picked up by `eslint-plugin-tailwindcss` and Intellisense */
export const twObject = <T extends object | unknown[]>(obj: RecursivePartial<T>): RecursivePartial<T> => obj

type RecursivePartial<T> = {
  [P in keyof T]: T[P] extends Array<infer U>
    ? Array<RecursivePartial<U>>
    : T[P] extends object
      ? RecursivePartial<T[P]>
      : T[P]
}
