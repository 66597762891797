import * as React from 'react'
import { HomeCard } from '@views/Home/components/HomeCard'
import { IconChat, IconHelp, IconPortfolio, IconReview, IconSettings, IconGrow } from '@strise/ui-components'
import { t } from '@lingui/macro'
import { HomeGreetingCard } from '@views/Home/components/HomeGreetingCard'
import { HomeCardActions } from '@views/Home/components/HomeCardActions'
import { useCurrentUser } from '@strise/app-shared'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { useHelpCenterLink } from '@utils/userHooks'
import { SettingsModalContext } from '@views/Settings/SettingsModalContextProvider'
import { openChat } from '@utils/intercom'
import { useContext } from '@strise/react-utils'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { contentViewToFeatureMap } from '@utils/contentViewUtils'
import { ContentViews } from '@utils/urls'

export const HomeView = () => {
  const user = useCurrentUser()
  const features = useCurrentUserFeatures()
  const team = useTeam()
  const helpCenterLink = useHelpCenterLink()
  const { setIsSettingsOpen } = useContext(SettingsModalContext)

  const menuPaths = [
    {
      title: t`Portfolio`,
      icon: <IconPortfolio />,
      description: t`Get an overview of all companies in your team portfolio`,
      path: ContentViews.Portfolio
    },
    {
      title: t`Review`,
      icon: <IconReview />,
      description: t`Onboard and conduct due diligence on your customers`,
      path: ContentViews.Review
    },

    {
      title: t`Grow`,
      icon: <IconGrow />,
      description: t`Target and find new prospects`,
      path: ContentViews.Grow
    }
  ]

  const cardActions = [
    {
      title: t`Help Center`,
      icon: <IconHelp />,
      url: helpCenterLink
    },
    {
      title: t`Settings`,
      icon: <IconSettings />,
      onClick: () => {
        setIsSettingsOpen(true)
      }
    },
    {
      title: t`Chat`,
      icon: <IconChat />,
      onClick: () => openChat()
    }
  ]

  const enrichedMenuPaths = menuPaths.map((menuPath) => {
    const feature = contentViewToFeatureMap[menuPath.path]
    return {
      ...menuPath,
      hide: feature ? !features[feature] : false
    }
  })
  const boxWrapper = 'flex flex-col justify-center gap-4 md:gap-8 md:flex-row'

  return (
    <div className='flex items-center justify-center py-2 lg:py-16'>
      <div className='flex flex-col items-start gap-8'>
        <HomeGreetingCard team={team.name} userName={user.name} />

        <div className={boxWrapper}>
          {enrichedMenuPaths.map((item, index) => {
            return (
              !item.hide && (
                <HomeCard
                  key={index}
                  title={item.title}
                  description={item.description}
                  icon={item.icon}
                  url={`/${item.path}`}
                />
              )
            )
          })}
        </div>

        <div className={boxWrapper}>
          {cardActions.map((item, index) => (
            <HomeCardActions key={index} icon={item.icon} title={item.title} onClick={item.onClick} url={item.url} />
          ))}
        </div>
      </div>
    </div>
  )
}
