import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconFacebook = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M16.81 5.32H19V2.14A33.887 33.887 0 0 0 15.808 2c-3.159 0-5.322 1.656-5.322 4.7v2.8H7v3.555h3.486V22h4.273v-8.944h3.345l.531-3.555h-3.877V7.05c.001-1.027.333-1.73 2.051-1.73Z'
    />
  </svg>
))
IconFacebook.displayName = 'IconFacebook'
