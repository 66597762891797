import { UiComponentsLegacyProvider } from '@strise/ui-components-legacy'
import * as React from 'react'
import { AuthInit } from '@components/Auth'
import { themeBreakpoints } from '../../theme'
import { useLocalStorageVersion } from '@utils/hooks'
import { ErrorView } from '@views/ErrorView'
import { Authorize, Toaster, UserRoleEnum } from '@strise/app-shared'
import { IdentifyServices } from '@components/IdentifyServices'
import * as Sentry from '@sentry/react'
import { spoof } from '@utils/spoof'
import { Routes } from '../../Routes'
import { ApolloClientContextProvider } from '../../apolloClient/ApolloClientContextProvider'
import { DisplayLanguageContextProvider } from '@contexts/DisplayLanguageContext/DisplayLanguageContextProvider'
import { useFetchUserFinancialFields, useSaveUserTimeZone } from '@utils/userHooks'
import { fixGoogleTranslateBug } from '@utils/googleTranslate'
import { CurrentUserSettingsContextProvider } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContextProvider'
import { TeamContextProvider } from '@contexts/TeamContext/TeamContextProvider'
import { PreLoaderContextProvider } from '@contexts/PreLoaderContext/PreLoaderContextProvider'
import { PreLoaderContextStop } from '@contexts/PreLoaderContext/PreLoaderContextStop'
import { useNavigateToSetup } from '@views/Setup/setupUtils'
import { SettingsModalContextProvider } from '@views/Settings/SettingsModalContextProvider'
import 'react-datepicker/dist/react-datepicker.css'
import { UiComponentsProvider } from '@strise/ui-components'
import { RecentlyVisitedEntitiesContextProvider } from '@contexts/RecentlyVisitedEntitiesContext/RecentlyVisitedEntitiesContextProvider'
import { IdentifyErrorService } from '@components/IdentifyErrorService'

import '../../renderer/PageLayout.css'
import '@strise/ui-components-legacy/src/index.css'
import '@strise/ui-components/src/tailwind.css'
import '@strise/app-shared/src/react-flow.css'
import { initTracking } from '@utils/tracking'
import { InitAppSharedContext } from '@contexts/AppSharedContext/InitAppSharedContext'

const InitUser = ({ children }: { children: React.ReactNode }) => {
  useSaveUserTimeZone()
  useNavigateToSetup()
  const loadingFinFields = useFetchUserFinancialFields()

  if (loadingFinFields) return null

  return <>{children}</>
}

const Init = ({ children }: { children: React.ReactNode }) => (
  <PreLoaderContextProvider>
    <AuthInit>
      <IdentifyErrorService>
        <ApolloClientContextProvider>
          <Authorize minRequiredRole={UserRoleEnum.TeamMember}>
            <UiComponentsProvider>
              <TeamContextProvider>
                <RecentlyVisitedEntitiesContextProvider>
                  <CurrentUserSettingsContextProvider>
                    <SettingsModalContextProvider>
                      <InitUser>
                        <DisplayLanguageContextProvider>
                          <PreLoaderContextStop>{children}</PreLoaderContextStop>
                        </DisplayLanguageContextProvider>
                      </InitUser>
                    </SettingsModalContextProvider>
                  </CurrentUserSettingsContextProvider>
                </RecentlyVisitedEntitiesContextProvider>
              </TeamContextProvider>
            </UiComponentsProvider>
          </Authorize>
        </ApolloClientContextProvider>
      </IdentifyErrorService>
    </AuthInit>
  </PreLoaderContextProvider>
)

export const Page = () => {
  useLocalStorageVersion('0.5')
  fixGoogleTranslateBug()
  initTracking()
  spoof.init()

  return (
    <UiComponentsLegacyProvider themeOverrides={themeBreakpoints}>
      <Sentry.ErrorBoundary fallback={<ErrorView />}>
        <Init>
          <InitAppSharedContext>
            <Toaster />
            <IdentifyServices>
              <Routes />
            </IdentifyServices>
          </InitAppSharedContext>
        </Init>
      </Sentry.ErrorBoundary>
    </UiComponentsLegacyProvider>
  )
}
