import { t } from '@lingui/macro'
import { useToggle } from 'usehooks-ts'

export const useTruncatedList = <T>(items: T[], truncateAfter: number | null | undefined) => {
  const [showAll, toggleShowAll] = useToggle(false)

  const truncatedItems = showAll || !truncateAfter ? items : items.slice(0, truncateAfter)

  const hasReachedTreshold = truncateAfter && items.length > truncateAfter

  return {
    truncatedItems,
    showAll,
    toggleShowAll,
    buttonText: showAll ? t`Show less` : t`Show all`,
    itemsLeftText: `${truncatedItems.length} / ${items.length}${showAll ? '' : ' - '}`,
    hasReachedTreshold
  }
}
