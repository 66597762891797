import * as React from 'react'
import { useMemo } from 'react'
import { setContext } from '@apollo/client/link/context/index.js'
import { ApolloLink, from, HttpLink } from '@apollo/client/index.js'
import { camelCaseToText, decamelize } from '@strise/ts-utils'
import { getMainDefinition } from '@apollo/client/utilities/index.js'
import { onError } from '@apollo/client/link/error/index.js'
import { toast } from '../Toast/toast'
import { AuthError } from '../auth/authError'
import { useAccessToken, useLogout } from '../auth/authUtils'
import { Kind, OperationTypeNode } from 'graphql/language'
import { getBrowserGlobals } from '@strise/react-utils'

export const successLink = new ApolloLink((operation, forward) => {
  const operationName = camelCaseToText(operation.operationName)
  const definition = getMainDefinition(operation.query)
  const operationType = definition.kind === Kind.OPERATION_DEFINITION && definition.operation

  return forward(operation).map((res) => {
    const context = operation.getContext()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (operationType === OperationTypeNode.MUTATION && context.response.status === 200) {
      toast.success(`${operationName} succeeded`)
    }

    return res
  })
})

export const useErrorLink = () => {
  const logout = useLogout()
  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, networkError, operation }) => {
        const statusCode = networkError && 'statusCode' in networkError && networkError.statusCode
        const operationContext = operation.getContext()

        const disableLogging =
          statusCode &&
          Array.isArray(operationContext.disableErrorLogging) &&
          operationContext.disableErrorLogging.includes(statusCode)

        if (statusCode === 401) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const hasToken = Boolean(operationContext.headers.authorization)
          const errorString = `${operation.operationName} with token: ${String(hasToken)}`
          console.error(errorString)
          logout(AuthError.ApiAuthenticationFailed, '401')
        } else if (graphQLErrors && !disableLogging) {
          graphQLErrors.forEach(({ message }) => {
            const errorString = `${operation.operationName}: ${message}`
            console.error(errorString)
          })

          const definition = getMainDefinition(operation.query)
          const operationType = definition.kind === Kind.OPERATION_DEFINITION && definition.operation

          if (operationType === OperationTypeNode.QUERY) {
            toast.error(`Error occurred when fetching ${decamelize(operation.operationName, ' ')}`)
          } else if (operationType === OperationTypeNode.MUTATION) {
            toast.error(`Error occurred when trying to ${decamelize(operation.operationName, ' ')}`)
          }
        }
      }),
    [logout]
  )
  return errorLink
}

const omitTypename = (key: string, value: string) => {
  return key === '__typename' ? undefined : value
}

export const omitTypenameLink = new ApolloLink((operation, forward) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,functional/immutable-data -- Not possible to copy operation (getContext() not working),
  operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)

  return forward(operation)
})

export const useAuthLink = (options?: {
  cacheDisabled?: boolean
  spoofId?: string | null
  teamId?: string | null
}): ApolloLink => {
  const getAccessToken = useAccessToken()
  const authLink = React.useMemo(
    () =>
      setContext(async (_, prevContext) => {
        const accessToken = await getAccessToken()

        return {
          ...prevContext,
          headers: {
            ...(prevContext.headers as object),
            authorization: accessToken ? `Bearer ${accessToken}` : null,
            ...(options?.teamId ? { 'X-Strise-Team': options.teamId } : undefined),
            ...(options?.spoofId ? { 'X-Impersonate': options.spoofId } : undefined),
            ...(options?.cacheDisabled ? { 'Cache-Control': 'no-cache' } : undefined)
          }
        }
      }),
    [options]
  )

  return authLink
}

export const useHttpLink = (url: string) => {
  const httpLink = React.useMemo(() => {
    return new HttpLink({ uri: url })
  }, [url])
  return httpLink
}

export const useLink = (apiUrl: string, cacheDisabled?: boolean) => {
  const errorLink = useErrorLink()
  const authLink = useAuthLink({ cacheDisabled })
  const httpLink = useHttpLink(apiUrl)

  const link = React.useMemo(() => {
    return from([successLink, omitTypenameLink, errorLink, authLink, httpLink])
  }, [successLink, omitTypenameLink, authLink, errorLink, httpLink])

  return link
}

export const getSandboxCustomer = (): string => {
  const host = getBrowserGlobals()?.window.location.hostname || ''
  const parts = host.split('.')
  return parts[0] || 'strise'
}
