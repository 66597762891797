import * as React from 'react'
import { IconCheckSmall, cn, Combobox, type ComboboxItem, type ComboboxProps, IconSearch } from '@strise/ui-components'
import { type SetStateFn } from '@strise/react-utils'
import { t, Trans } from '@lingui/macro'
import { type ApplicationSearchReturnType, type ContentLanguage } from '@strise/types'
import { EntityLocationFilterKind, useSearch } from '../Search/searchUtils'
import { EntityMeta } from '../EntityMeta/EntityMeta'
import { type EntityLikeMetaFragment } from '@strise/app-shared/src/graphqlTypes'
import { useDebounceValue } from 'usehooks-ts'
import { filterNullishValues } from '@strise/ts-utils'
import { useEntityMetaQuery } from '@graphqlOperations'
import { type BaseEntityLikeFragment } from '@graphqlTypes'

type EntitySearchInputSelectProps = Omit<ComboboxProps<EntityLikeMetaFragment>, 'items' | 'value' | 'onChange'>

type EntitySearchInputMultipleProps = {
  countries?: ContentLanguage[]
  dataTrack: string
  entityKindFilter: ApplicationSearchReturnType
  entityLocationFilters?: EntityLocationFilterKind[]
  onChange: (entities: BaseEntityLikeFragment[]) => void
  placeholder?: string
  selectedEntities: BaseEntityLikeFragment[]
  showSelected?: boolean
  transform?: (value: string[]) => string[]
} & EntitySearchInputSelectProps

const entityResultOptions = (
  entityResults: EntityLikeMetaFragment[],
  selectedEntityId: string | null
): Array<ComboboxItem<EntityLikeMetaFragment>> => {
  return entityResults.map((entity) => {
    const isSelected = selectedEntityId === entity.id

    return {
      label: entity.name ?? t`Unknown name`,
      id: entity.id,
      value: entity,
      searchText: entity.name ?? t`Unknown name`,
      menuItemProps: { className: 'h-full' },
      renderNode: (
        <div className='flex items-center pl-4'>
          {isSelected && <IconCheckSmall className='mr-4' />}
          <EntityMeta entity={entity} variant='small' noFlags />
        </div>
      )
    }
  })
}

const EntitySearchInputSelect = ({
  className,
  closeOnSelect = true,
  countries,
  dataTrack,
  entityKindFilter,
  entityLocationFilters,
  onChange,
  onDelete,
  placeholder,
  selectedEntities,
  showSelected,
  ...props
}: {
  countries?: ContentLanguage[]
  dataTrack: string
  entityKindFilter: ApplicationSearchReturnType
  entityLocationFilters?: EntityLocationFilterKind[]
  onChange: (value: Array<ComboboxItem<EntityLikeMetaFragment>>) => void
  onDelete?: (entity: ComboboxItem<EntityLikeMetaFragment>) => void
  placeholder?: string
  selectedEntities: BaseEntityLikeFragment[]
  showSelected?: boolean
} & EntitySearchInputSelectProps) => {
  const [inputValue, setInputValue] = React.useState('')
  const [debouncedInputValue] = useDebounceValue(inputValue, 300)

  const { loading, searchItems } = useSearch(
    debouncedInputValue,
    entityLocationFilters ?? [EntityLocationFilterKind.NORDICS],
    entityKindFilter,
    countries
  )
  const options = entityResultOptions(searchItems, Array.isArray(selectedEntities) ? null : selectedEntities)

  return (
    <Combobox
      closeOnSelect={closeOnSelect}
      className={cn('w-full', className)}
      search={inputValue}
      setSearch={setInputValue}
      value={selectedEntities.map((entity) => ({
        label: entity.name ?? t`Unknown name`,
        id: entity.id,
        // We render EntityMeta in the items, but not the value
        value: entity as EntityLikeMetaFragment
      }))}
      items={options}
      onChange={onChange}
      onRemove={onDelete}
      inputPlaceholder={placeholder}
      showSelectedInline={showSelected}
      loading={loading}
      data-track={dataTrack}
      {...props}
    />
  )
}

export const EntitySearchInputMultiple = ({
  className,
  countries,
  dataTrack,
  entityKindFilter,
  entityLocationFilters,
  onChange,
  selectedEntities,
  showSelected,
  ...props
}: EntitySearchInputMultipleProps) => {
  const handleChange = (value: Array<ComboboxItem<EntityLikeMetaFragment>>) => {
    onChange(value.map((item) => item.value))
  }

  const handleDelete = (item: ComboboxItem<EntityLikeMetaFragment>) =>
    onChange(selectedEntities.filter((entity) => entity.id !== item.id))

  const button = (
    <div className='flex items-center gap-x-2'>
      <IconSearch size='sm' />
      <Trans>Search</Trans>
    </div>
  )

  return (
    <EntitySearchInputSelect
      className={className}
      selectedEntities={selectedEntities}
      onChange={handleChange}
      entityKindFilter={entityKindFilter}
      entityLocationFilters={entityLocationFilters}
      dataTrack={dataTrack}
      onDelete={handleDelete}
      countries={countries}
      showSelected={showSelected}
      closeOnSelect={false}
      variant='outlined'
      inlineSearch
      customSelectedItemsRenderer={button}
      {...props}
    >
      {button}
    </EntitySearchInputSelect>
  )
}

export const EntitySearchInput: React.FC<
  {
    dataTrack: string
    entityKindFilter: ApplicationSearchReturnType
    entityLocationFilters?: EntityLocationFilterKind[]
    selectedEntity: BaseEntityLikeFragment | null
    setSelectedEntity: SetStateFn<BaseEntityLikeFragment | null>
  } & EntitySearchInputSelectProps
> = ({
  className,
  dataTrack,
  entityKindFilter,
  entityLocationFilters,
  selectedEntity,
  setSelectedEntity,
  ...props
}) => {
  const handleChange = (value: Array<ComboboxItem<BaseEntityLikeFragment>>) => {
    setSelectedEntity(() => {
      if (!Array.isArray(value)) return null
      if (value.length !== 1) return null
      if (!value[0]) return null

      return value[0].value
    })
  }

  const button = (
    <div className='flex items-center gap-x-2'>
      <IconSearch size='sm' />
      <Trans>Search</Trans>
    </div>
  )

  return (
    <EntitySearchInputSelect
      className={className}
      selectedEntities={filterNullishValues([selectedEntity])}
      entityKindFilter={entityKindFilter}
      entityLocationFilters={entityLocationFilters}
      onChange={handleChange}
      dataTrack={dataTrack}
      showSelected
      {...props}
    >
      {button}
    </EntitySearchInputSelect>
  )
}

export const EntityIdSearchInput: React.FC<
  {
    dataTrack: string
    entityKindFilter: ApplicationSearchReturnType
    entityLocationFilters?: EntityLocationFilterKind[]
    selectedEntity: string | null
    setSelectedEntity: (value: string | null) => void
  } & EntitySearchInputSelectProps
> = ({
  className,
  dataTrack,
  entityKindFilter,
  entityLocationFilters,
  selectedEntity,
  setSelectedEntity,
  ...props
}) => {
  const { data, loading } = useEntityMetaQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: selectedEntity! },
    skip: !selectedEntity
  })

  const handleChange = (value: Array<ComboboxItem<EntityLikeMetaFragment>>) => {
    if (!Array.isArray(value) || !value.length || value.length > 1 || !value[0]) {
      setSelectedEntity(null)
    } else {
      setSelectedEntity(value[0].value.id)
    }
  }

  return (
    <EntitySearchInputSelect
      loading={loading}
      className={className}
      selectedEntities={filterNullishValues([data?.entity])}
      entityKindFilter={entityKindFilter}
      entityLocationFilters={entityLocationFilters}
      onChange={handleChange}
      dataTrack={dataTrack}
      showSelected
      {...props}
    />
  )
}
