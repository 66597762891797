import { SidepanelCard } from '@components/Sidepanel/SidepanelCard'
import React from 'react'
import {
  Button,
  cn,
  IconButton,
  IconHelp,
  IconPenBox,
  Skeleton,
  Tabs,
  TabsList,
  TabsTrigger,
  Tag,
  Typography
} from '@strise/ui-components'
import { t, Trans } from '@lingui/macro'

import { formatDate } from '@strise/app-shared'
import { DataSourceTooltip } from '@components/DataSourceTooltip'
import { type SetStateFn } from '@strise/react-utils'
import { type SidepanelTab } from '@utils/urls'

export interface SidepanelEditableCardProps {
  actions?: React.ReactNode[]
  children?: React.ReactNode
  className?: string
  context: string
  description?: React.ReactNode
  editDisabled?: boolean
  editMode: boolean
  editTitle: string
  hasCustomData: boolean
  hasEditAccess: boolean
  lastEditedAt: Date | null | undefined
  loading?: boolean
  resetContent?: React.ReactNode
  setEditMode: SetStateFn<boolean>
  setShowOriginal: SetStateFn<boolean>
  showOriginal: boolean
  tab?: SidepanelTab
  title: React.ReactNode
  toggleEditButtonDataId: string
  toggleHelp?: () => void
}

const EditPanel = ({
  className,
  context,
  editDisabled,
  editMode,
  hasCustomData,
  lastEditedAt,
  resetContent,
  title,
  toggleEdit,
  toggleEditButtonDataId,
  ...props
}: {
  context: string
  editDisabled?: boolean
  editMode: boolean
  hasCustomData: boolean
  lastEditedAt: Date | null | undefined
  resetContent?: React.ReactNode
  title: string
  toggleEdit: (isEditMode: boolean) => void
  toggleEditButtonDataId: string
} & React.HtmlHTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('flex shrink-0 items-center', className)} {...props}>
      {!editMode && (
        <DataSourceTooltip
          content={
            <div>
              <Typography variant='body2'>{title}</Typography>
              {lastEditedAt && (
                <Typography className='self-center text-text-secondary' variant='body3'>
                  <Trans>Last edited: {formatDate(lastEditedAt)}</Trans>
                </Typography>
              )}
            </div>
          }
        >
          <IconButton
            className={cn('size-10', !editDisabled && 'text-gray-60')}
            variant='ghost'
            onClick={() => {
              toggleEdit(true)
            }}
            data-track={`${context} / Toggle edit`}
            data-id={toggleEditButtonDataId}
            disabled={editDisabled}
          >
            <IconPenBox />
          </IconButton>
        </DataSourceTooltip>
      )}

      {editMode && (
        <div className='flex gap-1'>
          {hasCustomData && resetContent && resetContent}
          <div>
            <Button
              variant='contained'
              palette='primary'
              className='rounded'
              onClick={() => {
                toggleEdit(false)
              }}
              data-track={`${context} / Save edit ${context}`}
            >
              <Typography variant='aLabelSmall'>
                <Trans>Done</Trans>
              </Typography>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

enum Tab {
  ORIGINAL = 'ORIGINAL',
  EDITED = 'EDITED'
}

export const SidepanelEditableCard = React.forwardRef<HTMLDivElement, SidepanelEditableCardProps>(
  (
    {
      actions,
      children,
      className,
      context,
      description,
      editDisabled,
      editMode,
      editTitle,
      hasCustomData,
      hasEditAccess,
      lastEditedAt,
      loading,
      resetContent,
      setEditMode,
      setShowOriginal,
      showOriginal,
      tab,
      title,
      toggleEditButtonDataId,
      toggleHelp
    },
    ref
  ) => {
    const handleTabChange = (value: Tab) => {
      setShowOriginal(value === Tab.ORIGINAL)
    }

    const headerElement = (
      <div className='grid gap-2'>
        <div
          className={cn(
            'grid w-full grid-cols-[150px_1fr_150px] flex-wrap items-center justify-items-center px-4 pb-2 pt-4',
            {
              'rounded bg-primary-shade-5': editMode,
              'border-b border-tertiary-main': !editMode
            }
          )}
        >
          <div className='flex items-center justify-self-start'>
            <Typography variant='subtitle1'>{title}</Typography>

            {toggleHelp && (
              <DataSourceTooltip content={t`How does this work?`}>
                <IconButton
                  className='size-10 rounded-full bg-transparent text-text-secondary'
                  variant='contained'
                  palette='tertiary'
                  onClick={toggleHelp}
                  data-track={`${context} / Help modal / Open`}
                >
                  <IconHelp />
                </IconButton>
              </DataSourceTooltip>
            )}
          </div>

          <div>
            {hasCustomData && (
              <Tabs
                palette='primary'
                value={showOriginal ? Tab.ORIGINAL : Tab.EDITED}
                // Using `as` since we can't use generic tab values (it's always string in Radix UI)
                onValueChange={(value) => handleTabChange(value as Tab)}
              >
                <TabsList>
                  <TabsTrigger
                    className={cn('px-2 hover:bg-transparent active:bg-transparent', {
                      'border-transparent': editMode
                    })}
                    value={Tab.ORIGINAL}
                    disabled={editMode}
                  >
                    <Trans>Original</Trans>
                  </TabsTrigger>
                  <TabsTrigger
                    className={cn('flex gap-2 px-2 hover:bg-transparent active:bg-transparent', {
                      'border-transparent': editMode
                    })}
                    value={Tab.EDITED}
                    disabled={editMode}
                  >
                    <Trans>Edited</Trans>
                    <Tag palette='pink'>
                      <Trans>Beta</Trans>
                    </Tag>
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            )}
          </div>
          <div className='flex flex-wrap gap-2 justify-self-end'>
            {!editMode && actions?.map((action, index) => <div key={index}>{action}</div>)}
            {hasEditAccess && (
              <EditPanel
                className='justify-self-end'
                editDisabled={editDisabled}
                editMode={editMode}
                title={editTitle}
                hasCustomData={hasCustomData}
                context={context}
                lastEditedAt={lastEditedAt}
                resetContent={resetContent}
                toggleEditButtonDataId={toggleEditButtonDataId}
                toggleEdit={(isEditMode) => {
                  setEditMode(isEditMode)
                }}
              />
            )}
          </div>
        </div>

        {description && (
          <div className='px-2'>
            <Typography className='text-text-secondary' variant='body2'>
              {description}
            </Typography>
          </div>
        )}
      </div>
    )

    return (
      <SidepanelCard ref={ref} tab={tab} className={className} headerElement={headerElement}>
        {loading && <Skeleton className='ml-4 mt-2 h-4 w-[312px]' />}
        {children}
      </SidepanelCard>
    )
  }
)
