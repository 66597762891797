'use client'

import * as React from 'react'
import { useState } from 'react'
import { Command as CommandPrimitive } from 'cmdk'
import { basePopperClasses } from '../variants/common'
import { cn } from '../../utils/className'
import { Popover, type PopoverProps } from '../Popover'
import { Button, type ButtonProps, IconButton } from '../Button'
import {
  IconCheckBoxChecked,
  IconCheckBoxUnchecked,
  IconCheckSmall,
  IconChevronDownSuperSmall,
  IconChevronUpSuperSmall,
  IconOverflowHorizontal,
  IconSearch
} from '../../icons/general'
import { Chip, Chips, type ChipsProps } from '../Chip'
import { Tooltip } from '../Tooltip'
import { LoaderRound } from '../LoaderRound'
import { type Primitive } from '@strise/types'
import { genericForwardRef, type DivProps } from '@strise/react-utils'
import { orderBy } from 'lodash-es'

export interface ComboboxProps<T extends Primitive | object>
  extends Omit<ButtonProps, 'onChange' | 'value' | 'defaultValue'>,
    Pick<ChipsProps, 'maxLabelLengthProps'> {
  /** Optional props for the action button in the `PopoverContent` */
  actionButtonProps?: ButtonProps
  /** Always renders the remove triggers of selected items in `Chip` if `true`. */
  alwaysShowSelectedRemoveTrigger?: boolean
  /** Closes the `PopoverContent` on select if `true`. */
  closeOnSelect?: boolean
  /** Optional prefix React node for the creation menu item. (Only applies if `enableCreate` is `true`) */
  createPrefixNode?: React.ReactNode
  /** Custom rendering function for selected items that returns a React node. Default is standard `Chip` with `onRemove` triggered by its `onDelete` */
  customSelectedItemsRenderer?: React.ReactNode
  /** Optional custom trigger, should not be used together with inlineSearch */
  customTrigger?: React.ReactNode
  /** Optional default value of the `Combobox` (only applies when using uncontrolled value) */
  defaultValue?: Array<ComboboxItem<T>>
  /** Optional prop to disable search */
  disableSearch?: boolean
  /** Disable sort on open */
  disableSelectedOnTop?: boolean
  /** Optional render function for empty component */
  emptyComponentRenderer?: (search: string) => React.ReactNode
  /** Enables creation of items based on the `onAdd` and `onChange` functions if `true`. */
  enableCreate?: boolean
  /** Hides the default item start icon if `true`. */
  hideItemsStartIcon?: boolean
  /** Hides the items when search input is empty if `true`. */
  hideItemsWhenNoSearch?: boolean
  /** Hides the default search icon in the input if `true`. */
  hideSearchIcon?: boolean
  /** Hides the selected items in the `Combobox` trigger if `true`. */
  hideSelected?: boolean
  /** The dropdown indicator variant */
  indicatorVariant?: IndicatorVariant
  /** Displays the search inline (over the trigger button) if `true`. */
  inlineSearch?: boolean
  /** Custom className on the input field. Use className for the combobox trigger */
  inputClassName?: string
  /** Optional placeholder string for the input field */
  inputPlaceholder?: string
  /** Optional props for the input field */
  inputProps?: DivProps
  /** Optional custom className for the item */
  itemClassName?: string
  /** The items to search and select in the `Combobox` */
  items: Array<ComboboxItem<T>>
  /** Optional props for the items wrapper */
  itemsWrapperProps?: DivProps
  /** Optional function that is triggered when an item is to be added when selected. Can use `onChange` as well */
  onAdd?: (item: ComboboxItem<T>) => void
  /** Optional function that is triggered when an item is selected. Can use `onAdd` and `onRemove` as well */
  onChange?: (value: Array<ComboboxItem<T>>) => void
  /** Optional function that is triggered when the internal input field value is updated. */
  onInputChange?: (value: string) => void
  /** Optional function that is triggered when the `Popover` open state is set to `true`. */
  onOpen?: () => void
  /** Optional function that is triggered when the `Popover` open state changes. */
  onOpenChange?: (open: boolean) => void
  /** Optional function that is triggered when an item is to be removed when selected. Can use `onChange` as well */
  onRemove?: (item: ComboboxItem<T>) => void
  /** Optional props for pagination in the `Combobox` */
  paginationProps?: ComboboxPaginationProps
  /** Optional props for the `Popover` */
  popoverProps?: PopoverProps
  /** Optional controlled search input value of the `Combobox`. When provided, this value controls the search input's value, allowing for external control. */
  search?: string
  /** Optional function to set the controlled search input value of the `Combobox`. This function should be used to update the `search` state externally. */
  setSearch?: (value: string) => void
  /** Displays the `items` label inside a `Chip` if `true`. */
  showItemsAsChips?: boolean
  /** Shows the selected items in the inline search field if `true`. */
  showSelectedInline?: boolean
  /** The uncontrolled state and `onChange` function always uses a single item. (`onAdd` and `onRemove` can override this with controlled `value` state) if `true`. */
  singleSelect?: boolean
  /** Optional controlled value of the `Combobox` */
  value?: Array<ComboboxItem<T>>
}

export interface ComboboxPaginationProps {
  disabled: boolean
  hasNextPage: boolean
  loadMoreText: string
  loading: boolean
  onLoadMore: () => void
}

export interface ComboboxItem<T extends Primitive | object> {
  /** Optional actions node rendered in a `Popover` */
  actions?: (setOpen: React.Dispatch<React.SetStateAction<boolean>>) => React.ReactNode
  /** The id of the item */
  id: string
  /** The label of the item */
  label: string
  /** Optional render node of the item */
  renderNode?: React.ReactNode
  /** Optional search string to be used for the item */
  searchString?: string
  /** The full value of the item */
  value: T
}

type IndicatorVariant = 'show' | 'hide' | 'hover'

const ComboboxInner = <T extends Primitive | object>(
  {
    actionButtonProps,
    alwaysShowSelectedRemoveTrigger,
    children,
    className,
    closeOnSelect,
    createPrefixNode,
    customSelectedItemsRenderer,
    customTrigger,
    defaultValue,
    disableSearch,
    disableSelectedOnTop,
    emptyComponentRenderer,
    enableCreate,
    hideItemsStartIcon,
    hideItemsWhenNoSearch,
    hideSearchIcon,
    hideSelected,
    indicatorVariant = 'hover',
    inlineSearch,
    inputClassName,
    inputPlaceholder,
    inputProps,
    itemClassName,
    items,
    itemsWrapperProps,
    maxLabelLengthProps,
    onAdd,
    onChange,
    onInputChange,
    onOpen,
    onOpenChange,
    onRemove,
    paginationProps,
    palette,
    popoverProps,
    search: controlledSearch,
    setSearch: setControlledSearch,
    showItemsAsChips,
    showSelectedInline,
    singleSelect,
    startIcon,
    value: controlledValue,
    variant,
    ...props
  }: ComboboxProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const triggerRef = React.useRef<HTMLButtonElement>(null)
  // Uncontrolled value state if no `value` is passed in
  const [uncontrolledValue, setUncontrolledValue] = useState<Array<ComboboxItem<T>>>(defaultValue ?? [])
  const [isOpen, setIsOpen] = React.useState(false)
  const [uncontrolledSearch, setUncontrolledSearch] = React.useState('')

  // Used to apply similar dimensions as the trigger button to the inlineSearch input
  const triggerDimensions = triggerRef.current?.getBoundingClientRect()

  const value = controlledValue ?? uncontrolledValue
  const search = controlledSearch ?? uncontrolledSearch

  const setSearch = (val: string) => {
    setControlledSearch?.(val)
    setUncontrolledSearch(val)
  }

  const itemIds = items.map(({ id }) => id)

  const filteredItems = React.useMemo(() => {
    // Don't filter if search is controlled, as this means backend searching/filtering
    if (controlledSearch) return items
    if (!search) return items

    return items.filter((item) => {
      const filterString = item.searchString ?? item.label
      return filterString.toLowerCase().includes(search.toLowerCase())
    })
    // Use JSON.stringify as items is often not memoed, which will result in a different reference each rerender
  }, [search, JSON.stringify(itemIds)])

  // Sort selected items on top on open
  const sortedItems = React.useMemo(() => {
    if (!isOpen) return filteredItems
    if (disableSelectedOnTop) return filteredItems
    return orderBy(filteredItems, (item) => {
      const isSelected = value.some(({ id: otherId }) => otherId === item.id)
      return [!isSelected]
    })
  }, [isOpen, search, filteredItems])

  const hasSelectedItems = value.length > 0

  // eslint-disable-next-line @typescript-eslint/promise-function-async
  const itemsRenderer = () => {
    if (!hasSelectedItems) return null
    if (customSelectedItemsRenderer) return customSelectedItemsRenderer
    return selectedItemsRenderer({
      alwaysShowSelectedRemoveTrigger,
      value,
      isOpen: isOpen && !!showSelectedInline,
      handleRemove,
      maxLabelLengthProps,
      triggerVariant: variant
    })
  }

  // `cmdk` input only supports string values, so need to find the item each time
  const handleSelect = (id: string) => {
    closeOnSelect && setIsOpen(false)
    setSearch('')

    // `cmdk` values are always in lower case
    const item = items.find(({ id: otherId }) => otherId.toLowerCase() === id.toLowerCase())

    if (!item) return

    const isSelected = value.some((val) => val.id === item.id)

    if (isSelected) {
      handleRemove(item)
      return
    }

    handleAdd(item)

    inputRef.current?.focus()
  }

  const handleRemove = (item: ComboboxItem<T>) => {
    const newVal = value.filter((val) => val.id !== item.id)

    onChange?.(newVal)
    onRemove?.(item)

    if (!controlledValue) setUncontrolledValue(newVal)
  }

  const handleAdd = (item: ComboboxItem<T>) => {
    if (singleSelect) {
      onChange?.([item])
      if (!controlledValue) setUncontrolledValue([item])
    } else {
      onChange?.([...value, item])
      if (!controlledValue) setUncontrolledValue([...value, item])
    }

    if (onAdd) onAdd(item)
  }

  const handleCreate = (item: ComboboxItem<T>) => {
    setSearch('')
    handleAdd(item)
  }

  const handleInputChange = (val: string) => {
    onInputChange?.(val)
    setSearch(val)
  }

  const handleOnOpenChange = (open: boolean) => {
    onOpenChange?.(open)
    open && onOpen?.()
    setIsOpen(open)
  }

  const emptyComponent = emptyComponentRenderer
    ? emptyComponentRenderer(search)
    : search && <div className='px-2 py-3'>No results found.</div>

  const popoverContent = (
    <CommandPrimitive
      shouldFilter={false}
      loop
      className={cn(
        'flex max-h-[22rem] flex-col text-white group-data-[side=top]/popover-content:flex-col-reverse',
        !inlineSearch && 'max-w-2xl'
      )}
      style={inlineSearch ? { width: triggerDimensions?.width } : { minWidth: triggerDimensions?.width }}
    >
      {!disableSearch && (
        <Button
          asChild
          startIcon={!hideSearchIcon && <IconSearch size='md' className='mr-2 shrink-0' />}
          endIcon={
            inlineSearch && (
              <IconChevronUpSuperSmall
                onClick={() => setIsOpen(false)}
                className={cn('ml-auto h-full shrink-0 cursor-pointer')}
              />
            )
          }
          loaderProps={{ className: 'ml-auto mr-2', size: 'sm' }}
          loading={props.loading}
          variant={inlineSearch ? variant : null}
          palette={inlineSearch ? palette : null}
          className={cn(
            'h-10 w-full text-white',
            { 'bg-tertiary-main text-text-primary': inlineSearch },
            'cursor-default px-2',
            className
          )}
          style={{ minHeight: triggerDimensions?.height }}
        >
          <div>
            {inlineSearch && !hideSelected && showSelectedInline && itemsRenderer()}
            <CommandPrimitive.Input
              autoFocus={true}
              value={search}
              onValueChange={handleInputChange}
              ref={inputRef}
              placeholder={inputPlaceholder}
              {...inputProps}
              className={cn(
                'size-full min-w-12 flex-1 bg-transparent text-inherit outline-none',
                inputClassName,
                inputProps?.className
              )}
            />
          </div>
        </Button>
      )}
      {!inlineSearch && <div className='border-b border-secondary-shade-40' />}
      <CommandPrimitive.List
        {...itemsWrapperProps}
        className={cn('max-h-72 overflow-y-auto overflow-x-hidden', itemsWrapperProps?.className)}
      >
        {!(hideItemsWhenNoSearch && !search) && (
          <>
            <CommandPrimitive.Empty>
              {props.loading ? (
                <div className='my-3 flex justify-center'>
                  <LoaderRound size='md' />
                </div>
              ) : (
                emptyComponent
              )}
            </CommandPrimitive.Empty>
            {sortedItems.map((item, index) => {
              const itemStartIcon = extractItemStartIcon(item, hideItemsStartIcon, singleSelect, value)
              return (
                <Button
                  key={`${item.id}-${index}`}
                  variant='contained'
                  palette='secondary'
                  startIcon={itemStartIcon}
                  endIcon={
                    item.actions ? (
                      <ComboboxItemActions actions={item.actions} actionButtonProps={actionButtonProps} />
                    ) : undefined
                  }
                  className={cn(basePopperClasses.item, 'group/item px-2', itemClassName)}
                  asChild
                >
                  <CommandPrimitive.Item value={item.id} onSelect={handleSelect}>
                    <Tooltip key={index} content={item.label} delayDuration={200}>
                      {item.renderNode ||
                        (showItemsAsChips ? (
                          <Chip className='truncate' label={item.label} size='md' />
                        ) : (
                          <span className='truncate'>{item.label}</span>
                        ))}
                    </Tooltip>
                  </CommandPrimitive.Item>
                </Button>
              )
            })}

            {paginationProps && paginationProps.hasNextPage && (
              <CommandPrimitive.Item>
                <Button
                  variant='contained'
                  palette='secondary'
                  className={cn(basePopperClasses.item, 'group/item justify-center px-2')}
                  disabled={paginationProps.disabled}
                  onClick={paginationProps.onLoadMore}
                  loading={paginationProps.loading}
                >
                  {paginationProps.loadMoreText}
                </Button>
              </CommandPrimitive.Item>
            )}

            {enableCreate && search && (
              <Button variant='contained' palette='secondary' className={cn(basePopperClasses.item, 'px-2')} asChild>
                {/* TODO: search as T is not correct unless T is string, consider splittng up creation into a separate prop */}
                <CommandPrimitive.Item onSelect={() => handleCreate({ id: '', label: search, value: search as T })}>
                  {createPrefixNode || 'Create'}{' '}
                  <Chip
                    className={cn('ml-2 cursor-pointer truncate', { invisible: !search })}
                    label={search}
                    size='md'
                  />
                </CommandPrimitive.Item>
              </Button>
            )}
          </>
        )}
      </CommandPrimitive.List>
    </CommandPrimitive>
  )

  return (
    <Popover
      open={isOpen}
      content={popoverContent}
      onOpenChange={handleOnOpenChange}
      align='start'
      ref={ref}
      sideOffset={inlineSearch && triggerDimensions ? -triggerDimensions.height : undefined}
      {...popoverProps}
      className={cn('group/popover-content', popoverProps?.className)}
    >
      {customTrigger || (
        <Button
          startIcon={(!hasSelectedItems || hideSelected) && startIcon}
          role='combobox'
          ref={triggerRef}
          variant={variant}
          palette={palette}
          className={cn('group/combobox-trigger w-full px-2 data-[state=open]:bg-tertiary-main', className)}
          loaderProps={{ className: 'ml-auto', size: 'sm' }}
          endIcon={
            isOpen ? (
              <IconChevronUpSuperSmall
                className={cn('pointer-events-none ml-auto shrink-0', { invisible: indicatorVariant === 'hide' })}
              />
            ) : (
              <IconChevronDownSuperSmall
                className={cn(
                  'pointer-events-none ml-auto shrink-0',
                  { invisible: indicatorVariant !== 'show' },
                  { 'group-hover/combobox-trigger:visible': indicatorVariant === 'hover' }
                )}
              />
            )
          }
          {...props}
        >
          {hasSelectedItems && !hideSelected ? itemsRenderer() : children}
        </Button>
      )}
    </Popover>
  )
}

ComboboxInner.displayName = 'Combobox'
export const Combobox = genericForwardRef(ComboboxInner)

const ComboboxItemActionsInner = <T extends Primitive | object>(
  {
    actionButtonProps,
    actions,
    ...props
  }: Pick<ComboboxItem<T>, 'actions'> & PopoverProps & { actionButtonProps?: ButtonProps },
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const [open, setOpen] = React.useState(false)
  const actionsRender = actions?.(setOpen)
  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      onClick={(event) => event.stopPropagation()}
      content={actionsRender}
      {...props}
    >
      <IconButton
        ref={ref}
        className='invisible ml-auto rounded bg-transparent group-hover/item:visible'
        palette='secondary'
        variant='contained'
        onClick={(event) => event.stopPropagation()}
        {...actionButtonProps}
      >
        <IconOverflowHorizontal />
      </IconButton>
    </Popover>
  )
}

ComboboxItemActionsInner.displayName = 'ComboboxItemActions'
const ComboboxItemActions = genericForwardRef(ComboboxItemActionsInner)

const extractItemStartIcon = <T extends Primitive | object>(
  item: ComboboxItem<T>,
  hideItemsStartIcon?: boolean,
  singleSelect?: boolean,
  value?: Array<ComboboxItem<T>>
) => {
  if (hideItemsStartIcon) return undefined
  const selected = !!value?.find(({ id: selectedValue }) => selectedValue === item.id)
  if (singleSelect) return <IconCheckSmall className={cn('mr-2', { invisible: !selected })} />
  if (selected) return <IconCheckBoxChecked className='ml-2 mr-4 shrink-0' />
  return <IconCheckBoxUnchecked className='ml-2 mr-4 shrink-0' />
}

interface SelectedItemsRendererProps<T extends Primitive | object> {
  alwaysShowSelectedRemoveTrigger?: boolean
  handleRemove: (item: ComboboxItem<T>) => void
  isOpen: boolean
  maxLabelLengthProps: ChipsProps['maxLabelLengthProps']
  triggerVariant?: ButtonProps['variant']
  value: Array<ComboboxItem<T>>
}

const selectedItemsRenderer = <T extends Primitive | object>({
  alwaysShowSelectedRemoveTrigger,
  handleRemove,
  isOpen,
  maxLabelLengthProps,
  triggerVariant,
  value
}: SelectedItemsRendererProps<T>): React.ReactNode => (
  <Chips
    chips={value.map((item) => ({
      className:
        triggerVariant === 'contained'
          ? 'group-hover/combobox-trigger:bg-tertiary-main'
          : 'group-hover/combobox-trigger:bg-tertiary-shade-20',
      label: item.label,
      size: 'md',
      onDelete:
        isOpen || alwaysShowSelectedRemoveTrigger
          ? (e) => {
              e.nativeEvent.stopImmediatePropagation()
              handleRemove(item)
            }
          : undefined
    }))}
    maxLabelLengthProps={isOpen ? undefined : maxLabelLengthProps}
  />
)
