import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconYoutube = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M22.065 6.35c-.597-.983-1.244-1.164-2.563-1.233-1.317-.083-4.63-.117-7.5-.117-2.874 0-6.188.034-7.504.116-1.316.07-1.965.25-2.567 1.233C1.316 7.33 1 9.021 1 11.996v.01c0 2.963.316 4.666.93 5.637.603.983 1.25 1.161 2.567 1.244 1.317.071 4.63.113 7.506.113 2.87 0 6.182-.042 7.5-.112 1.319-.083 1.967-.26 2.563-1.244.62-.97.934-2.673.934-5.636v-.01c0-2.977-.314-4.668-.935-5.649ZM9.25 15.817V8.182L16.125 12 9.25 15.818Z'
    />
  </svg>
))
IconYoutube.displayName = 'IconYoutube'
