import { type SetStateFn } from '@strise/react-utils'
import React, { useMemo, useState } from 'react'
import { SidepanelContext } from './SidepanelContext'

export const SidepanelContextProvider = ({
  children,
  expanded,
  id,
  scrollEl,
  setExpanded,
  setId
}: {
  children: React.ReactNode
  expanded: boolean
  id: string
  scrollEl: React.RefObject<HTMLElement | null>
  setExpanded: SetStateFn<boolean | null>
  setId: SetStateFn<string | null>
}) => {
  const [showHeaderEntity, setShowHeaderEntity] = useState(false)

  const sidepanelContextValue = useMemo(
    () => ({
      id,
      setId,
      expanded,
      setExpanded,
      scrollEl,
      showHeaderEntity,
      setShowHeaderEntity
    }),
    [id, setId, expanded, setExpanded, showHeaderEntity]
  )

  return <SidepanelContext.Provider value={sidepanelContextValue}>{children}</SidepanelContext.Provider>
}
