import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconTab = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M8.293 7.293h-2V13h2V13h5.586l-2.293 2.293L13 16.707 17.707 12 13 7.293l-1.414 1.414L13.879 11H8.293V7.293Z'
    />
  </svg>
))
IconTab.displayName = 'IconTab'
