import { type UserSettingsFragment } from '@graphqlTypes'
import { type ContentLanguage } from '@strise/types'
import { type FeaturesMap } from '@strise/app-shared/src/utils/features'
import { createContext, useContext, type SetStateFn } from '@strise/react-utils'

interface CurrentUserSettingsContextProps {
  enabledContentLanguages: ContentLanguage[]
  features: FeaturesMap
  saveSettings: SetStateFn<UserSettingsFragment>
  settings: UserSettingsFragment
}

export const CurrentUserSettingsContext = createContext<CurrentUserSettingsContextProps>()

export const useCurrentUserFeatures = () => {
  const { features } = useContext(CurrentUserSettingsContext)
  return features
}

export const useCurrentUserEnabledContentLanguages = () => {
  const { enabledContentLanguages } = useContext(CurrentUserSettingsContext)
  return enabledContentLanguages
}
