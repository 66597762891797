import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconBulletList = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 16 16'
    {...props}
  >
    <path
      stroke='#454545'
      strokeLinecap='square'
      strokeWidth={1.361}
      d='M4.083 2.722h10.89M4.084 8.168h10.889M4.084 13.61h10.889'
    />
    <path stroke='#454545' strokeWidth={1.361} d='M.68 13.61h1.362M.68 8.168h1.362M.68 2.722h1.362' />
  </svg>
))
IconBulletList.displayName = 'IconBulletList'
