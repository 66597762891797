import * as React from 'react'
import { MenuContent } from './MenuContent'
import { Dim } from '../Dim'
import { useReactiveVar } from '@apollo/client/index.js'
import { useLocation } from 'react-router-dom'
import { menuState } from '@state'
import { MenuState } from './menuUtils'
import { useOnClickOutside } from 'usehooks-ts'

export const Menu = () => {
  const state = useReactiveVar(menuState)

  const { pathname } = useLocation()
  React.useEffect(() => {
    state === MenuState.MOBILE_OPEN && menuState(MenuState.DEFAULT)
  }, [pathname])

  const ref = React.useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => menuState(MenuState.DEFAULT))

  return (
    <>
      <div className='relative z-[14]' ref={ref}>
        <MenuContent />
      </div>
      {state === MenuState.MOBILE_OPEN && <Dim />}
    </>
  )
}
