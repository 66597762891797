import { cn } from '@strise/ui-components'
import * as React from 'react'
import { type DivProps } from '@strise/react-utils'
import { type EntityLikeMetaFragment } from '@graphqlTypes'
import { ContentLanguage } from '@strise/types'
import { OwnershipsContextProvider } from './OwnershipsContext/OwnershipsContextProvider'
import { useOwners } from './ownershipChartUtils'
import { type ChartProps, EditableOwnershipChart } from './EditableOwnershipChart'

interface OwnershipsProps extends DivProps, ChartProps {
  above25: boolean
  disableControls?: boolean
  disableMinimap?: boolean
  editMode: boolean
  entity: EntityLikeMetaFragment
  isExport: boolean
  noFlags: boolean
  noLink: boolean
  noStatus: boolean
  noSubsidiaries?: boolean
  noSubsidiaryCount: boolean
  showOriginal: boolean
}

export const Ownerships = React.forwardRef<HTMLDivElement, OwnershipsProps>(
  (
    {
      above25,
      className,
      disableControls,
      disableMinimap,
      editMode,
      entity,
      isExport,
      noFlags,
      noLink,
      noStatus,
      noSubsidiaries,
      noSubsidiaryCount,
      showOriginal,
      ...chartProps
    },
    ref
  ) => {
    const { ownerChart } = useOwners(entity, above25 ? 25 : 5, showOriginal)

    const isCompany = entity.__typename === 'Company' || entity.__typename === 'GlobalCompany'

    const danish =
      (entity.__typename === 'Company' && entity.registerCountry === ContentLanguage.Danish) ||
      (entity.__typename === 'Person' && entity.nationality === ContentLanguage.Danish)

    return (
      <OwnershipsContextProvider
        rootEntity={entity}
        danish={danish}
        noFlags={noFlags}
        noLink={noLink}
        noStatus={noStatus}
        noSubsidiaryCount={noSubsidiaryCount}
        isExport={isExport}
        editMode={editMode}
        above25={above25}
        showOriginal={showOriginal}
      >
        <div className={cn('relative')} ref={ref} id='ownerships'>
          <EditableOwnershipChart
            chart={ownerChart}
            className={className}
            disableControls={disableControls}
            disableMinimap={disableMinimap}
            rootEntity={entity}
            displaySubsidiaries={!noSubsidiaries}
            shouldHaveChart={isCompany}
            {...chartProps}
          />
        </div>
      </OwnershipsContextProvider>
    )
  }
)
