import { Button, cn, type ComboboxItem, type ComboboxProps } from '@strise/ui-components'
import * as React from 'react'
import { ConfirmDialog, type ConfirmDialogProps } from '@strise/ui-components-legacy'
import { FinancialSelect } from '@components/FinancialSelect'
import { t, Trans } from '@lingui/macro'
import { useDefaultHighlightedFinancials } from '@utils/financials'
import { type FinancialId, type FinancialRowsMap } from '@utils/financialTypes'
import { useReactiveVar } from '@apollo/client/index.js'
import { financialFieldsMap } from '@state'
import { type DivProps } from '@strise/react-utils'
import { type ContentLanguage } from '@strise/types'
import { replaceItemFromArray } from '@strise/ts-utils'

interface SparkLineHighlightsEditProps extends Omit<DivProps, 'children'> {
  children?: (figure: FinancialId) => React.ReactNode
  country?: ContentLanguage | null
  financialsRowsMap?: FinancialRowsMap
  ids: FinancialId[]
  onChange: (index: number, newValue: FinancialId) => void
  selectProps?: Omit<ComboboxProps<FinancialId>, 'options' | 'value'>
  selectWrapperProps?: DivProps
}

export const SparkLineHighlightsEdit = ({
  children,
  className,
  country,
  ids,
  onChange,
  selectProps,
  selectWrapperProps,
  ...props
}: SparkLineHighlightsEditProps) => {
  const financialsFieldMapState = useReactiveVar(financialFieldsMap)
  const defaultHighlightedFinancials = useDefaultHighlightedFinancials()

  return (
    <div className={cn('grid gap-4', className)} {...props}>
      {ids.map((id, index) => {
        const financialId = defaultHighlightedFinancials[index]

        if (!financialId) return null

        const selectedFieldInfo = financialsFieldMapState[id] ?? financialsFieldMapState[financialId]

        if (!selectedFieldInfo) return null

        const matchingInternationalFieldInfo = selectedFieldInfo.matchingInternationalFields.find(
          (f) => f.country === country
        )
        const fieldInfo = matchingInternationalFieldInfo ?? selectedFieldInfo

        const countries = [fieldInfo.country, ...selectedFieldInfo.matchingInternationalFields.map((f) => f.country)]

        const handleChange = (newFigure: Array<ComboboxItem<FinancialId>>) => {
          if (!newFigure[0]) return
          if (newFigure.length !== 1) return
          onChange(index, newFigure[0].value)
        }

        return (
          <div
            key={`${id}-${index}`}
            {...selectWrapperProps}
            className={cn('flex flex-col gap-4', selectWrapperProps?.className)}
          >
            <FinancialSelect
              fieldInfo={fieldInfo}
              countries={countries}
              onChange={handleChange}
              data-id='financial-sparkline-select'
              {...selectProps}
            />

            {children?.(fieldInfo.id)}
          </div>
        )
      })}
    </div>
  )
}

export const SparkLineHighlightsEditModal: React.FC<
  {
    country?: ContentLanguage | null
    financialsRowsMap: FinancialRowsMap
    highlightedFields: FinancialId[]
    onConfirm: (value: FinancialId[]) => void
  } & Pick<ConfirmDialogProps, 'onCancel'>
> = ({ country, financialsRowsMap, highlightedFields, onConfirm, ...props }) => {
  const defaultHighlightedFinancials = useDefaultHighlightedFinancials()

  const [selected, setSelected] = React.useState(highlightedFields)

  const handleChange = (index: number, financialId: FinancialId) => {
    setSelected((currValue) => {
      return replaceItemFromArray(currValue, index, financialId)
    })
  }

  const handleConfirm = () => {
    onConfirm(selected)
  }

  const handleReset = () => {
    setSelected(defaultHighlightedFinancials)
  }

  return (
    <ConfirmDialog
      isOpen
      title={t`Customize favourites`}
      confirmText={t`Save`}
      cancelText={t`Cancel`}
      contentMaxWidth={800}
      onConfirm={handleConfirm}
      {...props}
    >
      <Trans>Use the menus beneath to set your favorite financial figures.</Trans>
      <SparkLineHighlightsEdit
        className='mt-4'
        ids={selected}
        country={country}
        onChange={handleChange}
        financialsRowsMap={financialsRowsMap}
      />

      <Button
        className='mt-4'
        variant='outlined'
        palette='danger'
        onClick={handleReset}
        data-track='Sidepanel / Financials / Reset to default'
      >
        <Trans>Reset to default</Trans>
      </Button>
    </ConfirmDialog>
  )
}
