import {
  LineLoader,
  Button,
  cn,
  IconCross,
  Skeleton,
  Tooltip,
  Typography,
  IconInfoBlue,
  Alert
} from '@strise/ui-components'
import {
  useAddTagsToCompaniesMutation,
  useAssignUsersToCompaniesMutation,
  useSimilarCompaniesQuery
} from '@graphqlOperations'
import { type GrowSuggestedCompanyFragment } from '@graphqlTypes'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { useContext, usePersistentArrayState, type DivProps, type DivPropsWithChildren } from '@strise/react-utils'
import { undoToast } from '@strise/app-shared/src/i18n'
import { ellipsis } from '@strise/ts-utils'
import { CompanyStatus, TrackedActivityKind } from '@strise/types'
import { track } from '@utils/tracking'
import React, { type Dispatch, useCallback, useEffect, useMemo } from 'react'
import { companyInPortfolio } from '../CompanyStatus/companyStatusUtils'
import { useSelectCompaniesStatus } from '../CompanyStatus/useSelectCompaniesStatus'
import { OpenChatLink } from '../ContactStrise'
import { EntityMeta } from '../EntityMeta/EntityMeta'
import { Event } from '../Events/Event'
import { Detail } from '../Sidepanel/SidepanelDetail'
import { SparkLineHighlights } from '../SparkLine/SparkLineHighlights'
import { GrowAssigneeSelect } from './GrowAssigneeSelect'
import { GrowTagsSelect } from './GrowTagsSelect'
import { type GrowStateAction, useGrowState } from './opportunitiesState'
import { MAX_COMPANIES_SUGGESTIONS, type TrackContext } from '@components/Grow/utils'
import {
  CurrentUserSettingsContext,
  useCurrentUserEnabledContentLanguages
} from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { STORAGE_KEYS } from '@constants'
import { userGrowSettingsToInput } from '@utils/settingsUtils'

const BorderBox = ({ className, ...props }: DivPropsWithChildren) => (
  <div
    className={cn('flex items-center justify-between border border-secondary-main px-4 py-8', className)}
    {...props}
  />
)

const LoadingBox = ({ children, className, ...props }: DivPropsWithChildren) => (
  <BorderBox className={className} {...props}>
    <div>{children}</div>
    <LineLoader />
  </BorderBox>
)

const OpportunityIgnored: React.FC<{
  undo: () => void
}> = ({ undo }) => {
  return (
    <div className='mt-4 flex items-center justify-between rounded-md border-2 border-solid border-secondary-shade-20 bg-tertiary-main p-4'>
      <div>
        <Typography className='mb-2' variant='h3'>
          <Trans>Opportunity ignored</Trans>
        </Typography>
        <Typography>
          <Trans>This feedback will help us provide you with even more relevant opportunities.</Trans>
        </Typography>
      </div>
      <Button onClick={undo} variant='contained' palette='tertiary' data-track='false'>
        <Trans>Undo</Trans>
      </Button>
    </div>
  )
}

const GrowSuggestedCompany: React.FC<{
  addProcessedCompany: (id: string) => void
  company: GrowSuggestedCompanyFragment
  dispatch?: Dispatch<GrowStateAction>
  excludeCompanies?: string[]
  replaceIndex?: number
  similarToCompany?: string
  trackContext: string
}> = ({ addProcessedCompany, company, dispatch, excludeCompanies, replaceIndex, similarToCompany, trackContext }) => {
  const { settings } = useContext(CurrentUserSettingsContext)

  const [assignCompany] = useAssignUsersToCompaniesMutation()
  const [tagCompany] = useAddTagsToCompaniesMutation()
  const setAssigneeAndTags = () => {
    if (settings.grow.assignees.length) {
      const assignEeIds = settings.grow.assignees.map((assignee) => assignee.id)
      assignCompany({
        variables: { users: assignEeIds, companies: [company.id] }
      })
    }
    if (settings.grow.tags.length) {
      const tagIds = settings.grow.tags.map((tag) => tag.id)
      tagCompany({ variables: { tags: tagIds, companies: [company.id] } })
    }
  }

  const initialStatus = useMemo(() => company.statusV2?.status || null, [])
  const previousStatus = useMemo(() => company.statusV2?.previousStatus || null, [])
  const { SelectCompaniesStatus, updateCompanyStatus } = useSelectCompaniesStatus([company])

  const replaceable = replaceIndex !== undefined && dispatch

  const undoStatusChange = useCallback(() => {
    updateCompanyStatus(initialStatus, previousStatus)
    track(TrackedActivityKind.StriseOpportunityUndo, {
      companyId: company.id,
      similar: similarToCompany,
      context: trackContext
    })
  }, [])

  const ignoreOpportunity = useCallback(() => {
    updateCompanyStatus(CompanyStatus.Ignored, previousStatus, !replaceable)
    track(TrackedActivityKind.StriseOpportunityIgnored, {
      companyId: company.id,
      similar: similarToCompany,
      context: trackContext
    })
    addProcessedCompany(company.id)

    if (replaceable) {
      dispatch({ type: 'replace', id: company.id })

      undoToast({
        label: t`Opportunity ignored`,
        body: <>{t`This feedback will help us provide you with even more relevant opportunities.`}</>,
        onUndo: () => {
          undoStatusChange()
          dispatch({ type: 'reAdd', index: replaceIndex, id: company.id })
        },
        i18n
      })
    }
  }, [replaceable])

  const financials = company.financials ?? company.consolidatedFinancials

  const ignored = company.statusV2?.status === CompanyStatus.Ignored
  const added = companyInPortfolio(company.statusV2?.status)

  if (ignored && !replaceable) {
    return <OpportunityIgnored undo={undoStatusChange} />
  }

  const corporatePurposeWithLimit = ellipsis(company.corporatePurpose ?? '', 220)

  return (
    <div
      className={cn('relative mt-4 rounded-md border-secondary-shade-20', {
        'border-2 bg-tertiary-main': added && !similarToCompany
      })}
      data-id='opportunities-company'
    >
      <div className='rounded-md bg-background-paper p-4'>
        <div className='mb-1 flex'>
          <EntityMeta entity={company} variant='medium' link className='flex-col flex-nowrap items-start' />

          <div className='ml-auto flex items-start'>
            {added ? (
              <>
                <Button variant='ghost' palette='tertiary' onClick={undoStatusChange} data-track='false'>
                  <Trans>Undo</Trans>
                </Button>
                {replaceable && (
                  <Tooltip content='Hide added opportunity'>
                    <Button
                      variant='contained'
                      palette='tertiary'
                      onClick={() => {
                        dispatch({ type: 'replace', id: company.id })
                        addProcessedCompany(company.id)
                      }}
                      className='ml-2'
                      data-track='false'
                    >
                      Close
                    </Button>
                  </Tooltip>
                )}
              </>
            ) : (
              <>
                <SelectCompaniesStatus
                  opportunity
                  onChange={setAssigneeAndTags}
                  triggerText={<span className='pl-2'>{t`Add`}</span>}
                  data-track={
                    similarToCompany
                      ? TrackedActivityKind.StriseOpportunitySimilarAdded
                      : TrackedActivityKind.StriseOpportunityAdded
                  }
                  data-track-similar={similarToCompany}
                  data-track-context={trackContext}
                />

                <Button
                  className='ml-2 flex gap-2 p-2'
                  variant='ghost'
                  palette='tertiary'
                  onClick={ignoreOpportunity}
                  data-track='false'
                  data-id='opportunities-company-ignore'
                  endIcon={<IconCross size='sm' />}
                >
                  <Trans>Ignore</Trans>
                </Button>
              </>
            )}
          </div>
        </div>

        {company.corporatePurpose && (
          <div title={corporatePurposeWithLimit === company.corporatePurpose ? undefined : company.corporatePurpose}>
            <Typography className='mb-4 text-text-secondary'>{corporatePurposeWithLimit}</Typography>
          </div>
        )}

        {(similarToCompany || !added) && (
          <>
            {financials?.meta.length ? (
              <SparkLineHighlights
                disableEdit
                financials={financials}
                country={company.registerCountry}
                settingsKey='grow'
                data-id='opportunities-company-financials'
              />
            ) : (
              <Typography className='py-4 text-center text-text-secondary' component='div' variant='body2'>
                <Trans>No financials recorded</Trans>
              </Typography>
            )}

            {!!company.events.edges.length && (
              <div className='mt-4'>
                {company.events.edges.map(({ node: event, summary }, index) => {
                  return (
                    <Event
                      key={event.id}
                      entityId={company.id}
                      event={event}
                      summary={summary.text}
                      className={cn(index !== company.events.edges.length - 1 && 'mb-4')}
                      disableInteractions
                    />
                  )
                })}
              </div>
            )}
          </>
        )}
      </div>

      {!similarToCompany && added && (
        <div className='p-4'>
          <SimilarOpportunities
            addProcessedCompany={addProcessedCompany}
            companyId={company.id}
            companyName={company.name}
            trackContext={trackContext}
            excludeCompanies={excludeCompanies}
          />
        </div>
      )}
    </div>
  )
}

const SimilarOpportunities: React.FC<{
  addProcessedCompany: (id: string) => void
  companyId: string
  companyName?: string | null
  excludeCompanies?: string[]
  trackContext: string
}> = ({ addProcessedCompany, companyId, companyName, excludeCompanies, trackContext }) => {
  const { settings } = useContext(CurrentUserSettingsContext)
  const eventLanguages = useCurrentUserEnabledContentLanguages()
  const [similarOpportunities, dispatch] = useGrowState()
  const settingsInput = userGrowSettingsToInput(settings)

  const { data, loading } = useSimilarCompaniesQuery({
    variables: {
      // @ts-expect-error
      eventsCacheKey: 'opportunity-events',
      company: companyId,
      size: 3,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      settings: settingsInput.opportunities!,
      eventLanguages
    },
    skip: !settingsInput.opportunities
  })

  const company = data?.company
  const similarCompanies = company?.similarCompanies.edges ?? []

  useEffect(() => {
    // init state if we have companies and no state
    const allOpportunities = [
      ...similarOpportunities.visible,
      ...similarOpportunities.hidden,
      ...similarOpportunities.processed
    ]

    const isUnititialized = allOpportunities.length === 0
    const hasChanges = allOpportunities.some((id) => !similarCompanies.some((c) => c.node.id === id))

    if (hasChanges || isUnititialized) {
      dispatch({
        type: 'init',
        data: {
          visible: similarCompanies.map((c) => c.node.id),
          hidden: [],
          processed: []
        }
      })
    }
  }, [similarCompanies])

  const hasIgnoredAllCompanies =
    similarOpportunities.visible.length === similarOpportunities.hidden.length && !!similarCompanies.length

  return (
    <div>
      <Typography className='w-full py-8 text-center' component='div' variant='h3'>
        <Trans>Here are some similar companies to {companyName}</Trans>
      </Typography>

      {loading && (
        <div className='p-4'>
          <LoadingBox>
            <Typography variant='h4'>
              <Trans>Fetching similar opportunities ...</Trans>
            </Typography>
          </LoadingBox>
        </div>
      )}

      {!loading && !similarOpportunities.visible.length && (
        <div className='p-4'>
          <BorderBox>
            <Typography variant='h4'>
              {hasIgnoredAllCompanies ? (
                <Trans>No more similar companies found</Trans>
              ) : (
                <Trans>No similar opportunities found</Trans>
              )}
            </Typography>
          </BorderBox>
        </div>
      )}

      {company &&
        similarOpportunities.visible.map((id, index) => {
          const similarCompany = similarCompanies.find(({ node }) => node.id === id)
          return (
            <React.Fragment key={[id, index].join('-')}>
              {similarCompany && (
                <GrowSuggestedCompany
                  replaceIndex={index}
                  company={similarCompany.node}
                  dispatch={dispatch}
                  trackContext={trackContext}
                  excludeCompanies={excludeCompanies}
                  addProcessedCompany={addProcessedCompany}
                  similarToCompany={companyId}
                />
              )}
            </React.Fragment>
          )
        })}
    </div>
  )
}

const getUpdateFiltersText = (shouldRefetch: boolean) => {
  return shouldRefetch
    ? t`Please try updating your filters or try refetching to get more suggestions`
    : t`Please try updating your filters`
}

export const GrowSuggestedCompanies = ({
  className,
  companies,
  exclude,
  loading,
  rateLimited,
  refetchCompanies,
  trackContext,
  ...props
}: {
  companies: GrowSuggestedCompanyFragment[]
  exclude?: string[]
  loading: boolean
  rateLimited: boolean
  refetchCompanies?: () => void
  trackContext: TrackContext
} & DivProps) => {
  const [opportunities, dispatch] = useGrowState()
  const { addItem: addProcessedCompany, state: processedCompanies } = usePersistentArrayState<string>(
    STORAGE_KEYS.growProcessedCompanies,
    200
  )
  const excludeCompanies = React.useMemo(() => {
    return [...(exclude ?? []), ...companies.map(({ id }) => id)]
  }, [companies, exclude])

  useEffect(() => {
    // init state if we have companies and no state
    const allOpportunities = [...opportunities.visible, ...opportunities.hidden, ...opportunities.processed]
    const isUnititialized = !allOpportunities.length
    const hasChanges = allOpportunities.some((id) => !companies.some((company) => company.id === id))
    const filterIgnoredCompanies = companies.filter((company) => !processedCompanies.includes(company.id))

    if (hasChanges || isUnititialized) {
      dispatch({
        type: 'init',
        data: {
          visible: filterIgnoredCompanies.slice(0, 10).map(({ id }) => id),
          hidden: filterIgnoredCompanies.slice(10, MAX_COMPANIES_SUGGESTIONS).map(({ id }) => id),
          processed: [...processedCompanies]
        }
      })
    }
  }, [companies, processedCompanies])

  return (
    <div className={className} {...props}>
      <div className='flex flex-col p-4'>
        <Detail label={t`Assign to`} contentContainerProps={{ className: 'my-0' }}>
          <div className='w-[300px]'>
            <GrowAssigneeSelect className='h-12' />
          </div>
        </Detail>
        <Detail label={t`Tag with`} contentContainerProps={{ className: 'my-0' }}>
          <div className='w-[300px]'>
            <GrowTagsSelect className='h-12' />
          </div>
        </Detail>
      </div>

      <div className='p-4'>
        {loading && (
          <div className='grid gap-4'>
            {Array.from({ length: 10 }).map((_, index) => {
              return (
                <div className='rounded-md bg-background-paper p-4' key={index}>
                  <Skeleton className='mb-2 h-4 w-44' />
                  <div className='mb-12 flex space-x-4'>
                    <Skeleton className='h-3 w-24' />
                    <Skeleton className='h-3 w-24' />
                    <Skeleton className='h-3 w-24' />
                  </div>
                  <Skeleton className='h-16 w-full' />
                </div>
              )
            })}
          </div>
        )}

        {!loading && rateLimited && (
          <Alert variant='warning' className='w-auto'>
            <Trans>You have reached your weekly opportunities limit. Please </Trans>
            <OpenChatLink msg={t`I want to the increase opportunities rate limit.`}>
              <Trans>contact Strise</Trans>
            </OpenChatLink>{' '}
            <Trans>to increase it.</Trans>
          </Alert>
        )}

        {!loading && (
          <div>
            <Typography variant='body2'>
              <Trans>
                Displaying {opportunities.visible.length} of{' '}
                {[...opportunities.visible, ...opportunities.hidden].length} available opportunities. Ignore
                opportunities to get more suggestions.
              </Trans>
            </Typography>
          </div>
        )}

        {!loading &&
          !rateLimited &&
          opportunities.visible.map((companyId, index) => {
            const company = companies.find(({ id }) => id === companyId)
            return (
              <React.Fragment key={[companyId, index].join('-')}>
                {company && (
                  <GrowSuggestedCompany
                    addProcessedCompany={addProcessedCompany}
                    replaceIndex={index}
                    company={company}
                    dispatch={dispatch}
                    trackContext={trackContext}
                    excludeCompanies={excludeCompanies}
                  />
                )}
              </React.Fragment>
            )
          })}

        {!loading && !opportunities.visible.length && (
          <div className='mt-2 grid gap-4 border p-4'>
            <Typography variant='h4'>
              <Trans>No more opportunities found.</Trans>
            </Typography>
            <Typography variant='body2'>{getUpdateFiltersText(!!refetchCompanies)}</Typography>
            {!!refetchCompanies && (
              <Button
                variant='contained'
                className='w-fit'
                palette='primary'
                onClick={() => refetchCompanies()}
                data-track='Grow / Refetch Suggestions'
              >
                <Trans>Fetch more suggestions</Trans>
              </Button>
            )}
          </div>
        )}

        {!loading && opportunities.processed.length === 0 && !!opportunities.hidden.length && (
          <div className='mx-2 mt-6'>
            <Typography variant='aLabelBold' className='flex items-center gap-2'>
              <IconInfoBlue /> <Trans>Remember to add or ignore opportunities to get new suggestions</Trans>
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}
