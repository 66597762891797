import { cn, IconButton, Tooltip, Typography, IconCopy } from '@strise/ui-components'
import { type DivProps } from '@strise/react-utils'
import { ellipsis } from '@strise/ts-utils'
import { type TransformedApiTableItem, type TransformedApiTableRow } from '@utils/apiTable/apiTableUtils'
import * as React from 'react'
import { isString } from 'lodash-es'

// eslint-disable-next-line @typescript-eslint/promise-function-async -- value.content is not a promise
const extractContent = (value: TransformedApiTableItem, expanded: boolean) => {
  if (!isString(value.content)) return value.content ?? '-'
  if (!expanded) return ellipsis(value.content, 20)
  return value.content
}
export const EntityKeyMetaItem: React.FC<
  { dataTrack?: string; expanded: boolean; item: TransformedApiTableRow } & DivProps
> = ({ className, expanded, item, ...props }) => {
  const value = item.sections[0]?.items[0]

  if (!value) return null

  const content = extractContent(value, expanded)

  const hasCopyAndTrackValue = value.handleCopy && value.dataTrack

  return (
    <div
      className={cn('group/meta-item z-[1] rounded-sm border border-secondary-shade-15 px-4 py-3', className)}
      {...props}
    >
      <Tooltip content={value.tooltipContent ?? value.content}>
        <div className='relative flex items-center gap-2 overflow-hidden'>
          {hasCopyAndTrackValue && (
            <div className='invisible absolute size-full bg-gradient-to-l from-white from-20% to-transparent to-50% group-hover/meta-item:visible' />
          )}
          {value.Icon && <value.Icon className='size-4 shrink-0' />}
          <div className='flex min-w-0 flex-col flex-nowrap justify-start'>
            <Typography className='overflow-hidden truncate' variant='aLabelSmallBold' component='p'>
              {content}
            </Typography>
            <Typography className='overflow-hidden truncate text-secondary-shade-40' variant='aLabelSmall'>
              {item.label}
            </Typography>
          </div>
          {hasCopyAndTrackValue && (
            <IconButton
              className='invisible absolute right-0 z-[2] ml-auto justify-end bg-secondary-shade-30 p-2 group-hover/meta-item:visible'
              variant='contained'
              palette='tertiary'
              onClick={value.handleCopy}
              data-track={value.dataTrack || ''}
            >
              <IconCopy className='size-4 text-white' />
            </IconButton>
          )}
        </div>
      </Tooltip>
    </div>
  )
}
