import * as React from 'react'
import { REVIEW_CHECKBOX_COLUMN_WIDTH_CLASSES } from '@utils/reviewUtils'
import { cn } from '@strise/ui-components'
import { type DivPropsWithChildren } from '@strise/react-utils'

interface ReviewCheckboxColumnProps extends DivPropsWithChildren {
  /** Controls whether the checkbox can fill the entire review row
   * use when the checkbox is the only element in the row
   */
  fillSpace?: boolean
}

export const ReviewCheckboxColumn: React.FC<ReviewCheckboxColumnProps> = ({
  children,
  className,
  fillSpace,
  ...props
}) => {
  return (
    <div
      className={cn(
        'shrink-0 break-words pl-4 pr-1',
        fillSpace ? 'w-full' : REVIEW_CHECKBOX_COLUMN_WIDTH_CLASSES,
        className
      )}
      {...props}
    >
      <div className={cn('flex w-full items-center', fillSpace ? 'h-auto' : 'h-10')}>{children}</div>
    </div>
  )
}
