import { InvalidationPolicyCache } from '@nerdwallet/apollo-cache-policies'

import { LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist'
import possibleTypesResult from '@strise/types/src/graphqlPossibleTypes'

import { typePolicies } from './typePolicies'
import { invalidationPolicies, SCHEMA_VERSION, SCHEMA_VERSION_KEY } from './invalidationPolicies'
import { apiEnvState } from '@state'
const { possibleTypes } = possibleTypesResult

export const initCache = async (window: Window) => {
  // Initialize the cache with custom policies
  const cache = new InvalidationPolicyCache({
    possibleTypes,
    typePolicies,
    invalidationPolicies
  })

  // Create a cache persistor to store cache in localStorage
  const persistor = new CachePersistor({
    key: 'strise-cache',
    debug: apiEnvState().get() !== 'PROD',
    cache,
    maxSize: 4_000_000, // 4 MB
    storage: new LocalStorageWrapper(window.localStorage)
  })

  // Check if the stored schema version matches the current version
  if (window.localStorage.getItem(SCHEMA_VERSION_KEY) === SCHEMA_VERSION) {
    // If versions match, restore the persisted cache
    await persistor.restore()
  } else {
    // If versions don't match, purge the outdated cache
    await persistor.purge()
    // Update the schema version in localStorage
    window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
  }

  return { cache, persistor }
}
