import { useCustomerId } from '@contexts/TeamContext/TeamContext'
import { type RateLimitKind } from '@strise/types'
import { useLatestRateLimitQuery } from '@graphqlOperations'

export const useLatestRateLimit = (kind: RateLimitKind, featureEnabled: boolean) => {
  const customerId = useCustomerId()

  const { data, loading } = useLatestRateLimitQuery({
    fetchPolicy: 'network-only',
    variables: { customer: customerId, kind },
    skip: !featureEnabled
  })

  return { data, loading }
}
