import { Typography } from '@strise/ui-components'
import { EntityLink } from '@components/EntityLink/EntityLink'
import { PepInfoContent, PepPersonAddressInfo } from '@components/PepAndSanctions/PepPersonContent'
import { type TableRowValueFragment } from '@graphqlTypes'
import React from 'react'

export const PepsWithEntity = ({
  value
}: {
  value: TableRowValueFragment & { __typename: 'TableRowValuePepsWithEntity' }
}) => {
  return (
    <div className='flex w-full gap-2'>
      <div className='min-w-[220px]'>
        {value.maybeEntity ? <EntityLink entity={value.maybeEntity} /> : <Typography>{value.entityName}</Typography>}
        <PepPersonAddressInfo address={value.pepInfo.address} />
      </div>
      <PepInfoContent className='mt-1' pepInfo={value.pepInfo} />
    </div>
  )
}
