import { Trans } from '@lingui/macro'
import {
  type CompanyNetworkEntityPersonFragment,
  type BaseEntityLikeFragment,
  type SanctionInfoFragment
} from '@strise/app-shared/src/graphqlTypes'
import { Card, CardContent, CardTitle, IconSanction, Typography, IconInfo, ExternalLink } from '@strise/ui-components'
import * as React from 'react'
import { EntityLink } from '../EntityLink/EntityLink'
import { JsonRawDataTooltip } from '../JsonRawDataTooltip'
import { AssociatedCompanyLink } from './AssociatedCompanyLink'

const extractDomain = (href: string): string => {
  try {
    const url = new URL(href)
    return url.hostname.replace(/^www\./, '')
  } catch {
    return href
  }
}

interface SanctionCardProps {
  associatedCompanies: CompanyNetworkEntityPersonFragment['associatedCompanies']
  currentCompanyId: string
  entity: BaseEntityLikeFragment
  sanctions: SanctionInfoFragment
}

export const SanctionCard = ({ associatedCompanies, currentCompanyId, entity, sanctions }: SanctionCardProps) => {
  return (
    <Card palette='danger' size='sm' className='border-none p-4' rounded='sm'>
      <CardContent className='gap-4 p-0'>
        <CardTitle className='flex justify-between p-0'>
          <div className='flex items-center gap-2 self-end'>
            <EntityLink entity={entity} noFlags />
            <IconSanction className='text-semantic-danger-main' />
          </div>
          <div className='flex flex-col'>
            {associatedCompanies.map((c) => (
              <AssociatedCompanyLink key={c.id} currentCompanyId={currentCompanyId} associatedCompany={c} />
            ))}
          </div>
        </CardTitle>
        <div className='flex items-center gap-1'>
          <Typography className='w-fit' variant='aLabel'>
            {sanctions.sanctionedBy.join(', ')}
          </Typography>
          <JsonRawDataTooltip json={sanctions.sourceJson}>
            <IconInfo className='text-gray-50' size='md' />
          </JsonRawDataTooltip>
        </div>
        <div className='flex flex-col'>
          <Typography className='mb-2 text-gray-50' variant='aLabelSmall'>
            <Trans>References</Trans>
          </Typography>
          {sanctions.sourceLinks.map((reference, index) => (
            <ExternalLink
              key={index}
              href={reference}
              className='text-sm leading-4 text-text-link'
              iconProps={{ size: 'xs' }}
            >
              {extractDomain(reference)}
            </ExternalLink>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}
