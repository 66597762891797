import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconGlobe = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M23 12c0 6.074-4.922 10.997-10.995 11H12C5.926 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11Zm-9.182 6.932C12.963 20.766 12.179 21 12.001 21c-.18 0-.962-.234-1.818-2.068-.123-.263-.24-.545-.35-.845a22.33 22.33 0 0 1 2.168-.105c.746 0 1.47.037 2.166.104-.11.3-.226.583-.349.846Zm-4.93-5.97c.05 1.14.189 2.207.394 3.172.876-.1 1.786-.152 2.72-.152.932 0 1.842.052 2.717.152a19.48 19.48 0 0 0 .394-3.171H8.888Zm.004-2c.053-1.113.19-2.156.39-3.1.875.098 1.785.15 2.716.15.933 0 1.844-.052 2.72-.151.201.945.338 1.988.391 3.102H8.892Zm8.223 2a21.626 21.626 0 0 1-.418 3.488 17.26 17.26 0 0 1 2.64.764 8.95 8.95 0 0 0 1.612-4.251h-3.835Zm3.826-2h-3.83a21.562 21.562 0 0 0-.416-3.417 17.27 17.27 0 0 0 2.639-.764 8.95 8.95 0 0 1 1.607 4.182Zm-14.051 0H3.06a8.95 8.95 0 0 1 1.606-4.18c.805.309 1.692.566 2.64.764a21.575 21.575 0 0 0-.416 3.417Zm-3.84 2h3.837a21.62 21.62 0 0 0 .418 3.488c-.949.198-1.836.455-2.642.764a8.95 8.95 0 0 1-1.612-4.251Zm12.286 7.4c.318-.583.602-1.246.845-1.977a16.36 16.36 0 0 1 1.692.435 8.997 8.997 0 0 1-2.537 1.541ZM7.82 18.385c-.598.12-1.165.267-1.692.434a8.997 8.997 0 0 0 2.537 1.542 12.186 12.186 0 0 1-.845-1.976Zm6.345-12.478c-.696.068-1.42.104-2.167.104a22.33 22.33 0 0 1-2.162-.103c.109-.299.225-.58.347-.841C11.04 3.234 11.822 3 12.001 3c.178 0 .962.234 1.817 2.068.122.261.238.542.347.84Zm3.703-.732c-.527.166-1.092.312-1.689.433a12.18 12.18 0 0 0-.843-1.97c.93.371 1.785.894 2.532 1.537ZM7.822 5.61c.243-.728.526-1.39.843-1.972a8.998 8.998 0 0 0-2.534 1.538c.527.167 1.093.313 1.69.434Z'
      clipRule='evenodd'
    />
  </svg>
))
IconGlobe.displayName = 'IconGlobe'
