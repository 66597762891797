import { Divider } from '@strise/ui-components'
import { t, Trans } from '@lingui/macro'
import * as React from 'react'
import { SidepanelCard } from '../SidepanelCard'
import { type SidepanelCompanyFragment } from '@graphqlTypes'
import { Register } from '@components/Register'
import { type DivProps } from '@strise/react-utils'
import { Empty } from '@strise/app-shared'

export const SidepanelCompanyRegistersCard = ({
  className,
  company,
  ...props
}: { company: SidepanelCompanyFragment } & DivProps) => {
  const registers = company.registers.edges

  return (
    <SidepanelCard className={className} title={t`Registers`} {...props}>
      <div className='flex grow flex-col px-4 pb-4'>
        {!registers.length && (
          <Empty title={t`No registers found`}>
            <Trans>We couldn't find any registers for this company</Trans>
          </Empty>
        )}

        {registers.map(({ node: register }, index) => {
          const isLast = index === registers.length - 1

          return (
            <React.Fragment key={index}>
              <Register className='mb-2 py-2' entityId={company.id} register={register} />
              {!isLast && <Divider />}
            </React.Fragment>
          )
        })}
      </div>
    </SidepanelCard>
  )
}
