import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconBell = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M11 3.082V1h2v2.082a6.124 6.124 0 0 1 4.94 4.556L21.28 21H13v2h-2v-2H2.72L6.06 7.638A6.124 6.124 0 0 1 11 3.082ZM12 5a4.123 4.123 0 0 0-4 3.123L5.28 19h13.44L16 8.123A4.123 4.123 0 0 0 12 5Z'
      clipRule='evenodd'
    />
  </svg>
))
IconBell.displayName = 'IconBell'
