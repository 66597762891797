import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconOpenSidePanel = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M11 4h9v2h-7v5h3v2h-3v5h7v2h-9v-7H6.414l2.293 2.293-1.414 1.414L2.586 12l4.707-4.707 1.414 1.414L6.414 11H11V4Z'
    />
  </svg>
))
IconOpenSidePanel.displayName = 'IconOpenSidePanel'
