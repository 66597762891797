import { Trans } from '@lingui/macro'
import { cn, Input, type InputProps, Typography } from '@strise/ui-components'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import * as React from 'react'

const PercentageIcon = <Typography variant='aLabelSmall'>%</Typography>

export interface FromToFilterState {
  from?: number | null
  to?: number | null
}

export interface MoreThanLessThanFilterState {
  lessThan?: number | null
  moreThan?: number | null
}

export interface FromToFilterProperties extends InputProps {
  allowNegativeNumbers?: boolean
  currency?: string
  filterFormat?: string
  fromLabel?: string
  fromValue: number | null | undefined
  inputPlaceholder?: string
  outerInputProps?: InputProps
  percentage?: boolean
  setFromValue: (value: number | null) => void
  setToValue: (value: number | null) => void
  toLabel?: string
  toValue: number | null | undefined
}

export const FromToFilter = ({
  allowNegativeNumbers,
  currency,
  filterFormat,
  fromLabel,
  fromValue,
  inputPlaceholder,
  outerInputProps,
  percentage,
  setFromValue,
  setToValue,
  toLabel,
  toValue,
  ...properties
}: FromToFilterProperties & DivProps) => {
  const from = String(fromValue ?? '')
  const to = String(toValue ?? '')

  const [inputFromState, setInputFromState] = React.useState(from)
  const [inputToState, setInputToState] = React.useState(to)

  const sanitize = (value: string): string => {
    if (!value) return ''

    const regex = allowNegativeNumbers ? /[^\d-]+/ : /\D+/
    const trimmed = value.replace(regex, '')
    if (!trimmed) return ''

    return trimmed
  }

  const toDecimal = (value: string) => {
    if (!value) return null
    return Number(value)
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: (v: number | null) => void,
    setInputState: SetStateFn<string>
  ) => {
    const sanitized = sanitize(e.target.value)
    setInputState(sanitized)

    const decimal = toDecimal(sanitized)
    setValue(Number.isNaN(decimal) ? null : decimal)
  }

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, setFromValue, setInputFromState)
  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, setToValue, setInputToState)

  // Focus the input element onClick because in Firefox then input is not
  // focused when you click on the arrows.
  // https://bugzilla.mozilla.org/show_bug.cgi?id=1012818

  const inputProperties = {
    type: 'text',
    onClick: (e: React.MouseEvent<HTMLInputElement>) => {
      const input = e.target as HTMLInputElement
      input.focus()
    },
    endIcon: percentage && PercentageIcon,
    variant: 'outlined' as const,
    palette: 'tertiary' as const
  }

  const labelSuffix = `${filterFormat ? ` ${filterFormat}` : ''}${currency ? ` ${currency}` : ''}`

  const className = cn(outerInputProps?.className)

  return (
    <div {...properties} className={cn('flex items-center gap-4', properties.className)}>
      <div className='flex flex-col gap-y-1'>
        <Input
          value={inputFromState}
          onChange={handleFromChange}
          placeholder={inputPlaceholder ?? ''}
          {...inputProperties}
          {...outerInputProps}
          className={className}
        />

        <Typography className='text-text-primary' variant='aLabelSmall'>
          {fromLabel ?? <Trans>From</Trans>}
          {labelSuffix}
        </Typography>
      </div>
      <div className='flex flex-col gap-y-1'>
        <Input
          value={inputToState}
          onChange={handleToChange}
          placeholder={inputPlaceholder ?? ''}
          {...inputProperties}
          {...outerInputProps}
          className={className}
        />

        <Typography className='text-text-primary' variant='aLabelSmall'>
          {toLabel ?? <Trans>To</Trans>}
          {labelSuffix}
        </Typography>
      </div>
    </div>
  )
}
