import { updateQueryString } from '@strise/ts-utils'
import { QUERY_PARAMS } from '@constants'

export enum ContentViews {
  Activity = 'activity',
  Portfolio = 'portfolio',
  Review = 'review',
  Grow = 'grow',
  Notifications = 'notifications',
  Home = 'home'
}

// Beware of refactoring this, as they're used in backend
export enum SidepanelTab {
  Company = 'company',
  Credit = 'credit',
  CCJs = 'ccj',
  People = 'people',
  Financials = 'financials',
  Events = 'events',
  Grow = 'grow',
  Person = 'person',
  Ownership = 'ownership',
  Roles = 'roles',
  Counterparties = 'counterparties',
  RiskSummary = 'risk-summary'
}

export const entityPath = (
  contentView: ContentViews,
  entityId: string,
  currentQueryString: string | null,
  sidepanelTab?: SidepanelTab
) => {
  const updatedQueryString = updateQueryString(currentQueryString, {
    [QUERY_PARAMS.sidepanelTab]: sidepanelTab,
    [QUERY_PARAMS.sidepanelEntityId]: entityId
  })

  return `/${contentView}?${updatedQueryString}`
}

export const eventPath = (
  contentView: ContentViews,
  entityId: string,
  eventId: string,
  currentQueryString: string | null,
  mentions?: string[]
) => {
  const updatedQueryString = updateQueryString(currentQueryString, {
    [QUERY_PARAMS.sidepanelEntityId]: entityId,
    [QUERY_PARAMS.eventId]: eventId,
    [QUERY_PARAMS.eventEntityId]: entityId,
    [QUERY_PARAMS.eventMentions]: mentions?.join(',')
  })

  return `/${contentView}?${updatedQueryString}`
}
