import { ExternalLinkIconButton, IconHelp, Tooltip, Typography } from '@strise/ui-components'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type SidepanelPersonFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { TableRowKind } from '@strise/types'
import { type TransformedApiTableRow } from '@utils/apiTable/apiTableUtils'
import { useHelpCenterLink } from '@utils/userHooks'
import * as React from 'react'
import { SidepanelPersonPep } from '../../PepAndSanctions/SidepanelPersonPep'
import { SidepanelTableCard } from '@components/Sidepanel/SidepanelTableCard'

const HelpTooltipLink = ({ content, href }: { content: React.ReactNode; href: string }) => {
  return (
    <Tooltip content={content}>
      <div>
        <ExternalLinkIconButton data-track='Sidepanel / Person / Pep / Article' href={href}>
          <IconHelp />
        </ExternalLinkIconButton>
      </div>
    </Tooltip>
  )
}

export const PersonDetailsCard = ({ person }: { person: SidepanelPersonFragment | undefined }) => {
  const helpCenterLink = useHelpCenterLink()
  const features = useCurrentUserFeatures()

  if (!person) return null

  const additionalRows: TransformedApiTableRow[] = [
    {
      key: 'pep',
      kind: TableRowKind.Website,
      hide: !features.PEP_AND_SANCTIONS_V2,
      paginationThreshold: null,
      label: (
        <div className='flex items-center'>
          <span>{t`PEP`} </span>
          <HelpTooltipLink
            content={
              <div className='text-center'>
                <Typography variant='body2'>
                  <Trans>
                    What is a PEP? <br /> Read our article explaining this
                  </Trans>
                </Typography>
              </div>
            }
            href={`${helpCenterLink}/articles/20734-what-is-a-pep`}
          />
        </div>
      ),

      sections: [
        {
          label: null,
          paginationThreshold: null,
          items: [
            {
              content: <SidepanelPersonPep person={person} />
            }
          ]
        }
      ]
    }
  ]

  return (
    <SidepanelTableCard
      entity={person}
      trackId='Person / Details'
      table={person.personInformation}
      additionalRows={additionalRows}
    />
  )
}
