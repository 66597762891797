/**
 * Gets browser builtin globals in a server-side-rendering-safe way
 *
 * @returns The globals if in a browser environment, or undefined if in an
 * SSR environment
 */
export function getBrowserGlobals() {
  // eslint-disable-next-line no-restricted-globals
  return typeof window === 'undefined' ? undefined : { window, document }
}

export const hardRefresh = async (reloadAfterClear = true) => {
  const window = getBrowserGlobals()?.window

  if (window && 'caches' in window) {
    const cacheKeys = await caches.keys()

    await Promise.all(cacheKeys.map(async (key) => await caches.delete(key)))

    if (reloadAfterClear) window.location.reload()
  }
}
