import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconCalendar = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M21 7H3v14h18V7ZM1 1v21a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V1H1Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='M4.5 8.667h2v2h-2v-2ZM4.5 12.997h2v2h-2v-2ZM4.5 17.327h2v2h-2v-2ZM8.827 17.327h2v2h-2v-2ZM13.157 17.327h2v2h-2v-2ZM13.157 12.997h2v2h-2v-2ZM13.157 8.667h2v2h-2v-2ZM17.487 8.667h2v2h-2v-2ZM17.487 12.997h2v2h-2v-2ZM8.827 12.997h2v2h-2v-2ZM8.827 8.667h2v2h-2v-2Z'
    />
  </svg>
))
IconCalendar.displayName = 'IconCalendar'
