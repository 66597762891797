import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconTagAdd = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 25 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m11.001 22.416-.59.584H1v-9.414L14.494.092l9.557 9.39-3.243 3.214a5.959 5.959 0 0 0-2.172-.663l2.568-2.545-6.698-6.58L3 14.414V21h6.586l-1.292-1.292a3 3 0 1 1 1.414-1.414l1.3 1.3 1.02-1.012c.075.78.299 1.515.643 2.178l-.25.248.786.785-1.414 1.414-.792-.791Zm-3-5.416-1 1a1 1 0 1 1 1-1Z'
      clipRule='evenodd'
    />
    <path fill='currentColor' d='M19 17h3v2h-3v3h-2v-3h-3v-2h3v-3h2v3Z' />
  </svg>
))
IconTagAdd.displayName = 'IconTagAdd'
