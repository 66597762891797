import { type EntityLikeMetaFragment } from '../../graphqlTypes'
import { extractEntityCountries } from '../../utils/entity'
import * as React from 'react'
import { NationFlag, type NationFlagBaseProps } from './NationFlag'

export const EntityNationFlag = ({
  className,
  entity,
  ...props
}: { entity: EntityLikeMetaFragment } & NationFlagBaseProps) => {
  const countries = extractEntityCountries(entity)

  return <NationFlag className={className} countryIsoCode={countries[0]?.isoAlpha2Code} {...props} />
}
