import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconNotificationLess = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M2.414 1 1 2.414l20.58 20.58 1.414-1.414L2.414 1ZM2.72 21 5.81 8.639l1.65 1.649L5.28 19h10.89l2 2h-5.17v2h-2v-2H2.72ZM16 8.123l1.683 6.732 2.75 2.749-2.492-9.966A6.124 6.124 0 0 0 13 3.082V1h-2v2.082a6.124 6.124 0 0 0-3.355 1.736l1.415 1.414A4.123 4.123 0 0 1 16 8.123Z'
    />
  </svg>
))
IconNotificationLess.displayName = 'IconNotificationLess'
