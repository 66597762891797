import * as React from 'react'
import { isFuture, isToday, isTomorrow, parseISO } from 'date-fns'
import { t } from '@lingui/macro'
import { usePalette } from '@strise/ui-components-legacy'
import { formatDate, formatRelativeDate } from '@strise/app-shared'
import { type ReminderFragment } from '@graphqlTypes'
import { formatNumber } from '@strise/ts-utils'
import { ReminderStatus } from '@strise/types'
import {
  type ButtonProps,
  IconChevronDownSuperSmall,
  IconChevronRightSuperSmall,
  IconChevronUpSuperSmall,
  type IconComponent,
  IconReminderDefault,
  IconReminderOverdue,
  IconReminderToday
} from '@strise/ui-components'
import { isString } from 'lodash-es'

interface ActivityStyle {
  ReminderIcon: IconComponent
  backgroundColorClass?: string
  colorClassName?: string
  isInFuture?: boolean
  text: string
}

const extractBgColor = (isInToday: boolean, isInTomorrow: boolean) => {
  if (isInToday) return 'semantic.info.10'
  if (isInTomorrow) return 'secondary.10'
}

export const useActivityStyle = (timestamp: Date | string): ActivityStyle => {
  return React.useMemo(() => {
    const at = t({ id: 'at (time)', message: 'at' })
    const parsedTimestamp = isString(timestamp) ? parseISO(timestamp) : timestamp

    const isInToday = isToday(parsedTimestamp)
    const isInTomorrow = isTomorrow(parsedTimestamp)
    const isInFuture = isFuture(parsedTimestamp)

    const isScheduled = isInFuture || isInTomorrow

    const relativeFormattedTimestamp = formatRelativeDate(timestamp)
    const formattedTimestamp = formatDate(timestamp, {
      time: true,
      format: isScheduled ? `d LLL u '${at}' HH:mm` : undefined
    })

    if (!isScheduled) {
      return {
        colorClassName: 'text-semantic-danger-main',
        backgroundColorClass: 'bg-accent-pink-shade-5',
        ReminderIcon: IconReminderOverdue,
        text: formattedTimestamp
      }
    }

    return {
      isInFuture,
      ReminderIcon: isInToday ? IconReminderToday : IconReminderDefault,
      colorClassName: isInToday ? 'text-semantic-info-main' : undefined,
      backgroundColor: extractBgColor(isInToday, isInTomorrow),
      text: isInToday || isInTomorrow ? relativeFormattedTimestamp : formattedTimestamp
    }
  }, [timestamp])
}

interface ActivityStatsStyle {
  ActivityStatsIcon: IconComponent
  borderRadius?: number | string
  iconColorClassName?: string
  percentage: string | null
  pr?: number
  sparkLineClassName: string
  tooltipContent: string
}

export const useActivityStatsStyle = (percentageChange: number | undefined | null): ActivityStatsStyle => {
  return React.useMemo(() => {
    const noActivityChange = !percentageChange
    const isPositiveChange = !noActivityChange && percentageChange > 0
    const formattedPercentage = !noActivityChange && formatNumber(percentageChange)

    if (noActivityChange) {
      return {
        tooltipContent: t`No changes`,
        iconColorClassName: 'text-text-secondary',
        sparkLineClassName: 'fill-secondary-shade-10',
        ActivityStatsIcon: IconChevronRightSuperSmall,
        percentage: '-',
        borderRadius: '4px',
        pr: 2
      }
    }

    if (!isPositiveChange) {
      return {
        tooltipContent: t`Decrease`,
        iconColorClassName: 'text-semantic-danger-main',
        sparkLineClassName: 'fill-semantic-danger-shade-10',
        ActivityStatsIcon: IconChevronDownSuperSmall,
        percentage: formattedPercentage ? `${formattedPercentage}%` : null
      }
    }

    return {
      tooltipContent: t`Increase`,
      ActivityStatsIcon: IconChevronUpSuperSmall,
      iconColorClassName: 'text-semantic-success-main',
      sparkLineClassName: 'fill-accent-green-shade-30',
      percentage: formattedPercentage ? `${formattedPercentage}%` : null
    }
  }, [percentageChange])
}

interface ActivityCompleteButtonStyle extends ButtonProps {
  backgroundImage?: string
  buttonText: string
  isActivityCompleted?: boolean
  trackId: string
}

export const useActivityCompleteButtonStyle = (
  timestamp: Date | string,
  reminder: ReminderFragment | undefined | null
): ActivityCompleteButtonStyle => {
  const paperBgColor = usePalette('semantic.success.5')
  const paperBgColor0 = usePalette('semantic.success.10')

  return React.useMemo(() => {
    const parsedTimestamp = isString(timestamp) ? parseISO(timestamp) : timestamp

    const isActivityCompleted = reminder?.status === ReminderStatus.Inactive

    const isInTomorrow = isTomorrow(parsedTimestamp)
    const isInFuture = isFuture(parsedTimestamp)

    const isScheduled = isInFuture || isInTomorrow

    if (isActivityCompleted) {
      return {
        isActivityCompleted,
        className: `text-semantic-success-shade-100 hover:bg-semantic-success-shade-10 active:bg-semantic-success-shade-20 ${
          isScheduled ? 'border border-semantic-success-shade-50' : ''
        }`,
        buttonText: t`Completed`,
        trackId: 'Team Activity / Completed',
        backgroundImage: `linear-gradient(90deg, ${paperBgColor0} 50%, ${paperBgColor} 50%)`
      }
    }

    return {
      palette: 'tertiary',
      className: isScheduled ? 'border border-white' : '',
      buttonText: t`Mark complete`,
      trackId: 'Team Activity / Mark complete'
    }
  }, [timestamp, reminder])
}
