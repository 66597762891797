import { useTransformedTable } from '@utils/apiTable/apiTableValueBaseUtils'
import { type BaseEntityLikeFragment } from '@graphqlTypes'
import { EntityKeyMetaItem } from '@components/EntityMeta/EntityKeyMetaItem'
import * as React from 'react'
import { type DivProps } from '@strise/react-utils'

interface EntityKeyMetaItemsProps extends DivProps {
  entity: BaseEntityLikeFragment
  expanded: boolean
  itemProps?: DivProps
}

export const EntityKeyMetaItems = React.forwardRef<HTMLDivElement, EntityKeyMetaItemsProps>(
  ({ className, entity, expanded, itemProps, ...props }: EntityKeyMetaItemsProps, ref) => {
    const table = useTransformedTable(
      entity,
      // @ts-expect-error Suppressing this because we've forced TableValueContentProps to use TableRowFragment
      'keyMeta' in entity ? entity.keyMeta : null
    )

    return (
      <div className={className} ref={ref} {...props}>
        {table?.rows.map((row, index) => {
          return <EntityKeyMetaItem key={index} item={row} expanded={expanded} {...itemProps} />
        })}
      </div>
    )
  }
)
