import { Combobox, IconSearch, Typography, type ComboboxItem } from '@strise/ui-components'
import * as React from 'react'
import { Trans, t } from '@lingui/macro'
import { type Location } from '@strise/types'
import { useSearchLocationsQuery } from '@graphqlOperations'
import { useDebounceValue } from 'usehooks-ts'
import { NationFlag } from '@strise/app-shared'
import { TestIDs } from '@utils/testIDs'
import { type LocationFragment } from '@graphqlTypes'

export type LocationDownshiftItem = Pick<Location, 'id' | 'name' | 'description'> & {
  parent?: Location['countriesV2'][number]
}

export const GrowLocationSearchInput = ({
  addLocation
}: {
  addLocation: (location: LocationDownshiftItem) => void
}) => {
  const [inputValue, setInputValue] = React.useState('')
  const [debouncedInputValue] = useDebounceValue(inputValue, 500)

  const { data, loading } = useSearchLocationsQuery({
    variables: { q: debouncedInputValue },
    skip: !debouncedInputValue
  })
  const items: Array<ComboboxItem<LocationFragment>> =
    data?.locationsV2.edges.map(({ node }) => {
      const parent = node.countries.find((country) => !!country.id)
      return {
        id: node.id,
        label: node.name ?? t`Unknown`,
        value: { ...node, parent },
        parent: parent as LocationDownshiftItem['parent'],
        description: node.description,
        renderNode: (
          <div className='grid grid-cols-[max-content_1fr] items-center gap-2'>
            <NationFlag countryIsoCode={parent?.isoAlpha2Code} />
            <div className='flex flex-col'>
              <Typography>{node.name}</Typography>
              <Typography variant='aLabelSmall'>
                {node.description?.charAt(0).toUpperCase()}
                {node.description?.slice(1)}
              </Typography>
            </div>
          </div>
        )
      }
    }) ?? []

  const handleAdd = (location: ComboboxItem<LocationDownshiftItem>) => {
    addLocation(location.value)
  }

  const button = (
    <div className='flex items-center gap-x-2'>
      <IconSearch size='sm' />
      <Trans>Search</Trans>
    </div>
  )

  return (
    <Combobox
      hideSelected
      inlineSearch
      variant='outlined'
      loading={loading || debouncedInputValue !== inputValue}
      hideItemsStartIcon
      hideItemsWhenNoSearch
      search={inputValue}
      setSearch={setInputValue}
      onAdd={handleAdd}
      items={items}
      data-track='Grow Filter / Location / Select'
      customSelectedItemsRenderer={button}
      data-id={TestIDs.Grow.Location.input}
      inputPlaceholder={t`Search locations...`}
    >
      {button}
    </Combobox>
  )
}
