import { cn, Typography } from '@strise/ui-components'
import { type TableRowValueFragment } from '@graphqlTypes'
import { Trans } from '@lingui/macro'
import { getTitle } from '@utils/enum'
import * as React from 'react'
import { EntityLink } from './EntityLink/EntityLink'
import { beneficialOwnerRelationKindToTitle } from './Ownerships/ownershipUtils'
import { ShareClasses } from './Sidepanel/SidepanelCards/ShareClasses'
import { type DivProps } from '@strise/react-utils'
import { CustomMeta } from '@utils/apiTable/CustomMeta'
import { PersonReviewInfo } from './PersonReviewInfo'
import { useIsReview } from '@contexts/RenderContext/RenderContext'

export const OwnerDetail = ({
  className,
  owner,
  ...props
}: {
  owner: TableRowValueFragment & { __typename: 'TableRowValueOwnership' }
} & DivProps) => {
  const isReview = useIsReview()
  const customOwnershipMeta = owner.customMeta ?? null
  const showAsPep =
    owner.maybeEntity?.__typename === 'Person' &&
    owner.maybeEntity.isGlobalPerson &&
    (owner.maybeEntity.pepInfo?.pep || owner.maybeEntity.pepInfo?.rca)

  const assumedFamilyElement =
    owner.uboMeta?.familyRelationships.map((familyRelationship, index) => {
      if (!owner.uboMeta) return null

      const relationshipsTitle = familyRelationship.relationships
        .map((relationship) => getTitle(beneficialOwnerRelationKindToTitle[relationship]))
        .join(', ')

      return (
        <div className='flex items-center pl-6' key={index}>
          <EntityLink
            disableOnNoAccess
            entity={familyRelationship.person}
            className='text-text-primary underline'
            noFlags
            noLifeStatus
            wrapperProps={{ className: 'list-item' }}
          />
          <Typography className='ml-1'>({relationshipsTitle})</Typography>
        </div>
      )
    }) ?? null

  return (
    <div className='flex w-full items-center justify-evenly'>
      <div
        className={cn(
          'my-1 mr-1 w-full rounded',
          { 'px-2': customOwnershipMeta },
          customOwnershipMeta ? 'border-dashed border-accent-blue-shade-5' : 'border-none',
          showAsPep ? 'bg-semantic-notice-shade-5' : '',
          className
        )}
        {...props}
      >
        <div>
          <div className='grid'>
            <div>
              {owner.maybeEntity ? (
                <div className='flex flex-col'>
                  <EntityLink disableOnNoAccess entity={owner.maybeEntity} />
                  {isReview &&
                    (owner.maybeEntity.__typename === 'Person' || owner.maybeEntity.__typename === 'GlobalPerson') && (
                      <PersonReviewInfo person={owner.maybeEntity} />
                    )}
                </div>
              ) : (
                <Typography>{owner.entityName}</Typography>
              )}
            </div>
          </div>
          <div className='mt-2 flex'>
            {owner.ownership && (
              <div className='max-w-[190px] grow'>
                <Typography>
                  <Trans>Ownership</Trans>: {owner.ownership}
                </Typography>
              </div>
            )}

            {owner.uboMeta?.meta && (
              <div className='ml-2 max-w-[190px] grow'>
                <Typography>
                  {owner.uboMeta.meta.name}: {owner.uboMeta.meta.value}
                </Typography>
              </div>
            )}
          </div>
        </div>
        {owner.uboMeta?.reason && (
          <div className='mt-2'>
            <Typography>{owner.uboMeta.reason}</Typography>
            {assumedFamilyElement}
          </div>
        )}

        <ShareClasses shareClasses={owner.shareClasses} className='mt-2' />
      </div>
      {owner.customMeta && (
        <div>
          <CustomMeta customMeta={owner.customMeta} />
        </div>
      )}
    </div>
  )
}
