import { Button, cn, Typography } from '@strise/ui-components'
import { useAssigneeMutations, useIsCompanyAssignee } from '@components/Assignee/assigneeHooks'
import { TeamActivityForm } from '@components/TeamActivity/TeamActivityForm'
import { type TeamActivityFormData } from '@components/TeamActivity/teamActivityUtils'
import { useCompanyAssigneesQuery, useCreateTeamActivityV2Mutation } from '@graphqlOperations'
import { t, Trans } from '@lingui/macro'
import { refreshActivityViewState } from '@state'
import { toast, useCurrentUser } from '@strise/app-shared'
import { Modal, ModalContent, ModalHeader } from '@strise/ui-components-legacy'
import { ReminderStatus, TeamActivityKind, type TeamActivityPageInfoInput } from '@strise/types'
import * as React from 'react'
import { TestIDs } from '@utils/testIDs'

export const ActivityForm: React.FC<{
  closeForm: () => void
  isActivityModal?: boolean
  pageInfo: TeamActivityPageInfoInput
}> = ({ closeForm, isActivityModal, pageInfo }) => {
  const user = useCurrentUser()

  const defaultFormData: TeamActivityFormData = {
    kind: TeamActivityKind.Note,
    timestamp: null,
    user,
    assignee: user,
    note: '',
    reminderStatus: ReminderStatus.Active,
    company: null
  }

  const [formData, setFormData] = React.useState<TeamActivityFormData>(defaultFormData)
  const [submissionErrors, setSubmissionErrors] = React.useState<boolean>(false)

  const { data, loading: companyLoading } = useCompanyAssigneesQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
    variables: { id: formData.company?.id! },
    skip: !formData.company?.id
  })
  const company = companyLoading ? undefined : data?.company

  const assignees = company?.assignee.edges ?? []
  const { assign, assignLoading } = useAssigneeMutations()
  const isCompanyAssignee = useIsCompanyAssignee(assignees, formData.assignee?.id)
  const assignee = formData.assignee

  const resetForm = (): void => setFormData(defaultFormData)

  const handleCancel = () => {
    resetForm()
    closeForm()
  }

  const handleCreateCompleted = () => {
    refreshActivityViewState(refreshActivityViewState() + 1)

    if (!isCompanyAssignee && assignee?.name) {
      toast.success(t`Created activity and assigned ${assignee.name} to the company`)
    } else {
      toast.success(t`Team activity created`)
    }

    resetForm()
    closeForm()
  }

  const [create, { loading: createLoading }] = useCreateTeamActivityV2Mutation({ onCompleted: handleCreateCompleted })
  const loading = createLoading || assignLoading || companyLoading

  const handleSubmit = async () => {
    if (!formData.company) return

    const input = {
      note: formData.note,
      kind: formData.kind,
      timestamp: formData.timestamp || new Date().toISOString(),
      assignee: formData.assignee,
      reminderStatus: formData.reminderStatus
    }

    formData.assignee &&
      !assignee &&
      assign({
        usersToAssign: [formData.assignee],
        companies: [formData.company.id],
        existingAssignees: assignees
      })

    await create({
      variables: {
        companyId: formData.company.id,
        input: {
          ...input,
          assignee: input.assignee?.id
        },
        page: pageInfo
      }
    })
  }

  const handleCreateClicked = async () => {
    if (!formData.company) {
      setSubmissionErrors(true)
      return
    }

    setSubmissionErrors(false)

    await handleSubmit()
  }

  const hasErrors = submissionErrors && !formData.company

  return (
    <Modal isOpen contentMaxWidth={800} onRequestClose={() => closeForm()}>
      <ModalHeader bgcolor='secondary.5' color='secondary' pt={8} pb={0} px={8}>
        <Trans>New activity</Trans>
      </ModalHeader>
      <ModalContent pt={8} pb={0} px={0} bgcolor='secondary.5'>
        <div className={cn('px-6', { 'mb-3': hasErrors })} data-id='new-activity-form'>
          <TeamActivityForm
            company={formData.company}
            isActivityModal={isActivityModal}
            assignees={assignees}
            formData={formData}
            setFormData={setFormData}
            resetForm={resetForm}
            pageInfo={pageInfo}
          />

          {hasErrors && (
            <Typography className='text-semantic-danger-main' variant='aLabel'>
              <Trans>Please select a company before creating an activity</Trans>
            </Typography>
          )}
        </div>
      </ModalContent>
      <div className='flex bg-secondary-shade-5'>
        <Button
          className='w-full'
          variant='contained'
          palette='tertiary'
          disabled={loading}
          onClick={handleCancel}
          data-track='Activity view / Cancel create team activity'
          data-id='activity-view-cancel-team-activity-button'
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          className='w-full'
          variant='contained'
          palette='primary'
          data-track='Activity view / Create team activity'
          loading={loading}
          onClick={handleCreateClicked}
          data-id={TestIDs.Activities.createButton}
        >
          <Trans>Create</Trans>
        </Button>
      </div>
    </Modal>
  )
}
