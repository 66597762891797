import { Trans } from '@lingui/macro'
import {
  type PepInfoFragment,
  type CompanyNetworkEntityPersonFragment,
  type PepRoleFragment
} from '@strise/app-shared/src/graphqlTypes'
import { Card, CardContent, CardTitle, Typography } from '@strise/ui-components'
import { EntityLink } from '../EntityLink/EntityLink'
import * as React from 'react'
import { AssociatedCompanyLink } from './AssociatedCompanyLink'
import { TruncatedList } from '@components/TruncatedList'

interface PepCardProps {
  associatedCompanies: CompanyNetworkEntityPersonFragment['associatedCompanies']
  currentCompanyId: string
  pepInfo: PepInfoFragment | null
  person: CompanyNetworkEntityPersonFragment['person']
}

export const PepCard = ({ associatedCompanies, currentCompanyId, pepInfo, person }: PepCardProps) => {
  return (
    <Card palette='warning' size='sm' className='border-none p-4' rounded='sm'>
      <CardContent className='gap-2 p-0'>
        <CardTitle className='flex justify-between p-0'>
          <EntityLink entity={person} noFlags className='self-end' />
          <div className='flex flex-col'>
            {associatedCompanies.map((c) => (
              <AssociatedCompanyLink key={c.id} currentCompanyId={currentCompanyId} associatedCompany={c} />
            ))}
          </div>
        </CardTitle>
        {pepInfo?.pep && (
          <>
            {!pepInfo.roles.length && (
              <Typography>
                <Trans>Unknown roles</Trans>
              </Typography>
            )}
            <TruncatedList
              items={pepInfo.roles}
              truncateAfter={3}
              context='Pep roles'
              className='flex flex-col gap-2 p-0'
            >
              {(role, index) => {
                return <PepRole key={index} role={role} />
              }}
            </TruncatedList>
          </>
        )}
        {pepInfo?.rca && (
          <>
            {!pepInfo.relations.length && (
              <Typography>
                <Trans>Unknown relationships</Trans>
              </Typography>
            )}
            {pepInfo.relations.map((relation, index) => {
              return <PepRelation key={index} relation={relation} />
            })}
          </>
        )}
      </CardContent>
    </Card>
  )
}

interface PepRolesProps {
  role: PepRoleFragment
}

const PepRole = ({ role }: PepRolesProps) => {
  return (
    <div className='flex flex-col gap-1'>
      <Typography variant='aLabel'>{role.roleCategory}</Typography>
      <Typography className='text-gray-50' variant='body2' key={role.roleCategory}>
        {role.localizedRoleDescription}
      </Typography>
    </div>
  )
}

interface PepRelationProps {
  relation: PepInfoFragment['relations'][number]
}

const PepRelation = ({ relation }: PepRelationProps) => {
  return (
    <div className='flex flex-col gap-2'>
      <Typography variant='aLabel'>
        {relation.relationDescription} {relation.relatedPep.name}
      </Typography>
      {relation.relatedPep.roles.map((role, index) => (
        <PepRole key={index} role={role} />
      ))}
    </div>
  )
}
