import { Typography } from '@strise/ui-components'
import { useApolloClient, useReactiveVar } from '@apollo/client/index.js'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useReviewCompanyLazyQuery } from '@graphqlOperations'
import {
  type TeamReviewSettingsV2Fragment,
  type BaseReviewCompanyFragment,
  type ReviewCompanyFragment,
  type ReviewCompanyQuery
} from '@graphqlTypes'

import { Trans } from '@lingui/macro'
import { refreshReviewCompanyMap, refreshReviewState } from '@state'
import { TableRowKind, TableSectionKind, TrackedActivityKind } from '@strise/types'
import { type SetStateFn } from '@strise/react-utils'
import { extractTablesWithLoader, type TableWithLoaders } from '@utils/apiTable/apiTableValueBaseUtils'
import { ReviewCardContentView, defaultCheckedState, type ReviewState } from '@utils/reviewUtils'
import { track } from '@utils/tracking'
import { BEHIND_Z_INDEX } from '@utils/zIndexes'
import * as React from 'react'
import { useEffect } from 'react'
import { updateTableCache } from '../../apolloClient/tableCache'
import { ReviewCardFooter } from './ReviewCardFooter'
import { ReviewCardHeader } from './ReviewCardHeader'
import { ReviewCompanyCardContent } from './ReviewCompanyCardContent'
import { ReviewCompanyCardSummaryContent } from './ReviewCompanyCardSummaryContent'
import { ReviewCompanyStartButton } from './ReviewCompanyStartButton'
import { TestIDs } from '@utils/testIDs'

const ReviewCardAnchor = React.forwardRef(({ ...props }, ref: React.ForwardedRef<HTMLDivElement>) => {
  return (
    <div
      className='absolute top-[-theme(header.height)]'
      ref={ref}
      style={{
        zIndex: BEHIND_Z_INDEX
      }}
      {...props}
    />
  )
})

const FlyIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='174' height='36' fill='none'>
    <path
      fill='#B9B9B9'
      fillRule='evenodd'
      d='M167.535 2c0 .176-.03.344-.086.501l.086-.001a3 3 0 1 1-3 3l.001-.086a1.5 1.5 0 1 1 .145-2.769A1.5 1.5 0 1 1 167.535 2Zm-1.5.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-.646.854a.473.473 0 0 1 .016.034l.018-.018-.034-.016Zm-.854.646a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z'
      clipRule='evenodd'
    />

    <path
      stroke='#B9B9B9'
      strokeDasharray='2 2'
      d='m163.213 10-7.887 5.498a66.35 66.35 0 0 1-61.412 7.629l-16.086-6.083C71.088 14.494 72.915 4.5 80.12 4.5c7.521 0 9.007 10.66 1.772 12.716L45.425 27.58A41.303 41.303 0 0 1 .625 12'
    />
  </svg>
)

export type ReviewCompany = BaseReviewCompanyFragment &
  Omit<ReviewCompanyFragment, 'reviewTables'> & {
    reviewTables: TableWithLoaders[]
  }

const useReviewCompany = (
  baseCompany: BaseReviewCompanyFragment,
  cardView: ReviewCardContentView,
  setCardView: SetStateFn<ReviewCardContentView>,
  reviewState: ReviewState | undefined,
  setReviewState: SetStateFn<ReviewState>
): { fetchReviewCompany: () => void; reviewCompany: ReviewCompany | null } => {
  const features = useCurrentUserFeatures()
  const apolloClient = useApolloClient()

  const handleTrackFetchedReview = (data: ReviewCompanyQuery) => {
    const hasFetchedCreditScore = data.company.reviewTables.some((table) =>
      table.rows.some((row) => row.kind === TableRowKind.ReviewCreditScore)
    )
    const hasFetchedPepsAndSanctions = data.company.reviewTables.some((table) => {
      const hasPeps = table.rows.some((row) => row.kind === TableRowKind.ReviewPeps)
      const hasSanctions = table.rows.some((row) => row.kind === TableRowKind.ReviewSanctions)
      return hasPeps && hasSanctions
    })
    if (hasFetchedCreditScore) {
      track(TrackedActivityKind.StriseCreditScoreFetched, {
        companyId: data.company.id,
        origin: 'REVIEW'
      })
    }

    if (hasFetchedPepsAndSanctions) {
      track(TrackedActivityKind.StrisePepsSanctionsFetched, {
        companyId: data.company.id,
        origin: 'REVIEW'
      })
    }
  }

  const handleFetchCompleted = (data: ReviewCompanyQuery) => {
    handleTrackFetchedReview(data)
    data.company.reviewTables.forEach((table) => updateTableCache(apolloClient, data.company, table))
  }

  const [fetchCompanyInformation, { data: companyInformationData, loading: companyInformationLoading }] =
    useReviewCompanyLazyQuery({
      variables: { entity: baseCompany.id, includeReviewSections: [TableSectionKind.ReviewCompanyInformation] },
      onCompleted: handleFetchCompleted
    })

  const [fetchManagement, { data: managementData, loading: managementLoading }] = useReviewCompanyLazyQuery({
    variables: { entity: baseCompany.id, includeReviewSections: [TableSectionKind.ReviewManagementAndRightsHolders] },
    onCompleted: handleFetchCompleted,
    fetchPolicy: 'network-only'
  })

  const [fetchAms, { data: amsData, loading: amsLoading }] = useReviewCompanyLazyQuery({
    variables: { entity: baseCompany.id, includeReviewSections: [TableSectionKind.ReviewAms] },
    onCompleted: handleFetchCompleted,
    fetchPolicy: 'network-only'
  })

  const [fetchEsg, { data: esgData, loading: esgLoading }] = useReviewCompanyLazyQuery({
    variables: { entity: baseCompany.id, includeReviewSections: [TableSectionKind.ReviewEsg] },
    onCompleted: handleFetchCompleted
  })

  const [fetchCustomCheckboxes, { data: customCheckboxesData, loading: customCheckboxesLoading }] =
    useReviewCompanyLazyQuery({
      variables: { entity: baseCompany.id, includeReviewSections: [TableSectionKind.ReviewCustomCheckboxes] },
      onCompleted: handleFetchCompleted
    })

  const fetchReviewCompany = () => {
    fetchCompanyInformation()
    fetchManagement()
    if (features.ADVERSE_MEDIA_SCREENING) fetchAms()
    if (features.ESG) fetchEsg()
    if (features.REVIEW_CUSTOM_CHECKBOXES) fetchCustomCheckboxes()
    if (!reviewState?.opened) {
      const now = Date.now()
      setReviewState({
        opened: true,
        lastModifiedAt: now,
        openedAt: now,
        checkedV2: defaultCheckedState,
        inlineComments: {},
        comment: '',
        amsEventFeedback: [],
        customChecked: {}
      })
    }
    setCardView(ReviewCardContentView.IN_REVIEW)
  }

  const refreshedReview = useReactiveVar(refreshReviewState)

  useEffect(() => {
    // refetch review company if review is previously opened
    if (!refreshedReview) return
    if (cardView !== ReviewCardContentView.IN_REVIEW) return
    fetchReviewCompany()
  }, [refreshedReview])

  useEffect(() => {
    if (reviewState?.opened && cardView === ReviewCardContentView.LOADING_REVIEW) {
      fetchReviewCompany()
    }
  }, [reviewState?.opened])

  const reviewCompany = {
    ...baseCompany,
    ...companyInformationData?.company,
    reviewTables: [
      ...extractTablesWithLoader(
        companyInformationData?.company.reviewTables,
        companyInformationLoading || cardView === ReviewCardContentView.LOADING_REVIEW,
        8
      ),
      ...extractTablesWithLoader(
        managementData?.company.reviewTables,
        managementLoading || cardView === ReviewCardContentView.LOADING_REVIEW,
        6
      ),
      ...extractTablesWithLoader(amsData?.company.reviewTables, amsLoading, 2),
      ...extractTablesWithLoader(esgData?.company.reviewTables, esgLoading, 2),
      ...extractTablesWithLoader(customCheckboxesData?.company.reviewTables, customCheckboxesLoading, 2)
    ]
  }

  return { reviewCompany, fetchReviewCompany }
}

const getInitialCardView = (existingReview: boolean, reviewStateActive: boolean): ReviewCardContentView => {
  if (reviewStateActive) {
    return ReviewCardContentView.LOADING_REVIEW
  }
  if (existingReview) {
    return ReviewCardContentView.AFTER_REVIEW
  }
  return ReviewCardContentView.BEFORE_REVIEW
}

interface ReviewCompanyCardProps {
  baseCompany: BaseReviewCompanyFragment
  filterHeight: number | undefined
  resetReviewState: (companyId: string) => void
  reviewState: ReviewState | undefined
  setReviewState: SetStateFn<ReviewState>
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
}

// This component is memoized because we keep the state outside to enable persistent state.
// The memoization avoids re-rendering of all cards every time you e.g. write a character in the comment section
const _ReviewCompanyCard = React.forwardRef<HTMLDivElement, ReviewCompanyCardProps>(
  ({ baseCompany, filterHeight, resetReviewState, reviewState, setReviewState, teamReviewSettings }, ref) => {
    const existingReviews = baseCompany.reviews.edges
    const existingReview = existingReviews.length ? existingReviews[0]?.node : null

    const [cardView, setCardView] = React.useState<ReviewCardContentView>(() =>
      getInitialCardView(!!existingReview, !!reviewState?.opened)
    )

    const [isReviewFinished, setIsReviewFinished] = React.useState<boolean>(false)

    const cardAnchorRef = React.useRef<HTMLDivElement>(null)

    const { fetchReviewCompany, reviewCompany } = useReviewCompany(
      baseCompany,
      cardView,
      setCardView,
      reviewState,
      setReviewState
    )

    const refreshReview = useReactiveVar(refreshReviewCompanyMap)

    useEffect(() => {
      if (!refreshReview[baseCompany.id]) return

      setCardView(ReviewCardContentView.LOADING_REVIEW)

      fetchReviewCompany()

      if (reviewState?.opened) {
        setReviewState((prevState) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const checkedV2 = prevState.checkedV2 ?? defaultCheckedState
          return {
            ...prevState,
            checkedV2: {
              ...checkedV2,
              checkedMap: {
                ...checkedV2.checkedMap,
                [TableRowKind.ReviewPeps]: false,
                [TableRowKind.ReviewSanctions]: false,
                [TableRowKind.ReviewBeneficialOwners]: false,
                [TableRowKind.ReviewAlternativeBeneficialOwners]: false,
                [TableRowKind.ReviewOtherOwners]: false,
                [TableRowKind.ReviewGlobalOwnerships]: false
              }
            }
          }
        })
      }
    }, [refreshReview[baseCompany.id]])

    return (
      <div className='my-4 min-w-[700px]' ref={ref} data-id={TestIDs.Review.Card.root(baseCompany.name)}>
        <ReviewCardAnchor ref={cardAnchorRef} />
        <ReviewCardHeader company={baseCompany} filterHeight={filterHeight} />
        <div className='border-t-2 border-solid border-tertiary-main bg-background-paper py-4'>
          {cardView === ReviewCardContentView.BEFORE_REVIEW && (
            <div className='grid grid-cols-[1fr_max-content] grid-rows-[1fr_min-content] items-center gap-4 px-4 pt-4'>
              <div className='col-[1/3] mb-1 self-center'>
                <FlyIcon />
              </div>
              <Typography className='text-text-secondary'>
                <Trans>This entity has not been reviewed yet.</Trans>
              </Typography>
              <ReviewCompanyStartButton
                setCardView={setCardView}
                fetchReviewCompany={fetchReviewCompany}
                data-track='Review / Start Review'
                data-id={TestIDs.Review.Card.startReviewButton}
                className='mt-0'
              >
                <Trans>Start Review</Trans>
              </ReviewCompanyStartButton>
            </div>
          )}

          {(cardView === ReviewCardContentView.IN_REVIEW || cardView === ReviewCardContentView.LOADING_REVIEW) &&
            reviewCompany && (
              <ReviewCompanyCardContent
                cardAnchorRef={cardAnchorRef}
                company={reviewCompany}
                teamReviewSettings={teamReviewSettings}
                reviewState={reviewState}
                setReviewState={setReviewState}
                resetReviewState={resetReviewState}
                setIsReviewFinished={setIsReviewFinished}
                setCardView={setCardView}
              />
            )}

          {cardView === ReviewCardContentView.AFTER_REVIEW && (
            <ReviewCompanyCardSummaryContent
              setCardView={setCardView}
              review={existingReview ?? null}
              fetchReviewCompany={fetchReviewCompany}
            />
          )}
        </div>
        <ReviewCardFooter company={baseCompany} isReviewFinished={isReviewFinished} />
      </div>
    )
  }
)

export const ReviewCompanyCard = React.memo(_ReviewCompanyCard, (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.reviewState) === JSON.stringify(nextProps.reviewState) &&
    JSON.stringify(prevProps.baseCompany) === JSON.stringify(nextProps.baseCompany) &&
    prevProps.filterHeight === nextProps.filterHeight
  )
})
