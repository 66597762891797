import {
  Alert,
  Button,
  type ButtonProps,
  Chip,
  cn,
  Divider,
  ExternalLink,
  IconCheckBoxChecked,
  IconInfoBlue,
  IconSuccessFill,
  IconWarning,
  IconWarningFill,
  IconWarningRed,
  IconWarningYellow,
  Radio,
  Tooltip,
  Typography,
  Progress,
  Label
} from '@strise/ui-components'
import * as React from 'react'
import { useState } from 'react'
import {
  Accordion,
  type AccordionProps,
  Modal,
  ModalContent,
  ModalHeader,
  Table,
  TableCell,
  TableRow
} from '@strise/ui-components-legacy'
import { type SetStateFn } from '@strise/react-utils'
import { defineMessage, t, Trans } from '@lingui/macro'
import { ContentType, triggerDownload } from '@strise/react-utils/src/utils/file'
import { Assignees } from './assignee/Assignees'
import { CompanyStatusSelectButton } from './companyStatus/CompanyStatusSelectButton'
import { DragAndDropFile } from './DragAndDropFile'
import { type I18n } from '@lingui/core'
import { CloudUploadIcon } from './icons/CloudUploadIcon'
import { UploadCsvIcon } from './icons/UploadCsvIcon'
import { type EntityLikeMetaFragment, type ValidatePortfolioCsvQuery } from '../graphqlTypes'
import { CompanyStatus, PortfolioMergeStrategyKind, type Team } from '@strise/types'
import { useAddRowsToPortfolioMutation, useValidatePortfolioCsvLazyQuery } from '../graphqlOperations'
import { filterNullishValues } from '@strise/ts-utils'
import { toast } from './Toast/toast'
import { useToggle } from 'usehooks-ts'
import { Img } from './Img'

const DefaultToggleButton = ({ className, ...props }: ButtonProps) => {
  return (
    <Button className={cn('px-2', className)} variant='ghost' palette='secondary' title='Import portfolio' {...props}>
      <CloudUploadIcon />
    </Button>
  )
}

interface UploadCompaniesProps {
  portfolioId: string
  team: Pick<Team, 'id' | 'name'>
}

enum Section {
  DUPLICATES = 'duplicates',
  ERRORS = 'errors'
}

enum ProgressState {
  READING = 'reading',
  VALIDATING = 'validating',
  VALIDATED = 'validated',
  ERROR = 'error'
}

const getProgressVariant = (progressState: ProgressState | null) => {
  switch (progressState) {
    case ProgressState.VALIDATED: {
      return 'success'
    }
    case ProgressState.ERROR: {
      return 'error'
    }
    default: {
      return 'loading'
    }
  }
}

const getProgressPercentage = (progressState: ProgressState | null) => {
  switch (progressState) {
    case ProgressState.READING: {
      return 33
    }
    case ProgressState.VALIDATING: {
      return 50
    }
    case ProgressState.VALIDATED:
    case ProgressState.ERROR: {
      return 100
    }
    default: {
      return 0
    }
  }
}

const SectionAccordion = ({ children, className, ...props }: AccordionProps) => {
  return (
    <Accordion className={className} p={4} labelProps={{ py: 0 }} mt={2} borderRadius={12} {...props}>
      {children}
    </Accordion>
  )
}

const Duplicates: React.FC<{
  duplicateMode: PortfolioMergeStrategyKind
  i18n: I18n
  openSection: Section | null
  result: ValidatePortfolioCsvQuery['validatePortfolioCsv']
  setDuplicateMode: SetStateFn<PortfolioMergeStrategyKind>
  setOpenSection: SetStateFn<Section | null>
}> = ({ duplicateMode, i18n, openSection, result, setDuplicateMode, setOpenSection }) => {
  const hasErrors = !!result.errors.length

  const duplicateCount = result.duplicates.length
  const duplicateLineNumbers = result.duplicates
  const duplicateLineNumbersString = duplicateLineNumbers.join(', ')

  const handleNextClick = () => {
    setOpenSection(Section.ERRORS)
  }

  const toggleAccordion = () =>
    setOpenSection((prevOpenSection) => (prevOpenSection === Section.DUPLICATES ? null : Section.DUPLICATES))

  const downloadDuplicates = () => {
    const duplicateLines = result.lines.filter((_, index) => duplicateLineNumbers.includes(index + 1))

    if (!duplicateLines.length) {
      toast.error(i18n._(defineMessage({ message: 'Could not download duplicates' })))
      return
    }

    const duplicateLinesJoined = duplicateLines.join('\n')

    triggerDownload(duplicateLinesJoined, 'strise_duplicates', ContentType.CSV)
  }

  const accordionHeader = (
    <div className='flex items-center'>
      <IconCheckBoxChecked className='text-primary-main' />
      <Typography className='mx-2' variant='aLabel'>
        <Trans>Duplicates</Trans>
      </Typography>
      <Tooltip content={`${i18n._(defineMessage({ message: 'Row' }))} ${duplicateLineNumbersString}`}>
        <Chip
          className='bg-accent-blue-shade-5 py-4'
          icon={<IconInfoBlue />}
          label={<Trans>{duplicateCount} duplicates</Trans>}
        />
      </Tooltip>
    </div>
  )

  return (
    <SectionAccordion open={openSection === Section.DUPLICATES} label={accordionHeader} toggle={toggleAccordion}>
      <Typography className='mb-6 mt-4'>
        <Trans>Some accounts already exist in Portfolio. Do you want to overwrite them with this file?</Trans>
      </Typography>
      <DuplicateModes duplicateMode={duplicateMode} setDuplicateMode={setDuplicateMode} />
      <div className='mt-6 flex items-center justify-between'>
        <Button
          variant='outlined'
          palette='tertiary'
          onClick={downloadDuplicates}
          data-track='Import portfolio / Download duplicates'
        >
          <Trans>Download duplicates</Trans>
        </Button>
        {hasErrors && (
          <Button
            onClick={handleNextClick}
            palette='secondary'
            variant='contained'
            data-track='Import portfolio / Open errors'
          >
            <Trans>Next</Trans>
          </Button>
        )}
      </div>
    </SectionAccordion>
  )
}

const DuplicateModes: React.FC<{
  duplicateMode: PortfolioMergeStrategyKind
  setDuplicateMode: SetStateFn<PortfolioMergeStrategyKind>
}> = ({ duplicateMode, setDuplicateMode }) => {
  const duplicateModes = [
    {
      title: <Trans>Merge</Trans>,
      description: <Trans>Data from file and data in Portfolio will be merged.</Trans>,
      value: PortfolioMergeStrategyKind.Merge
    },
    {
      title: <Trans>Overwrite</Trans>,
      description: <Trans>Data from file will overwrite existing data in Portfolio.</Trans>,
      value: PortfolioMergeStrategyKind.Overwrite
    },
    {
      title: <Trans>Ignore</Trans>,
      description: <Trans>Duplicates in file will be ignored and Portfolio data retained.</Trans>,
      value: PortfolioMergeStrategyKind.Ignore
    }
  ]

  return (
    <div className='pl-2'>
      {duplicateModes.map((mode, index) => {
        const id = index.toString()
        return (
          <div className='mb-3' key={index}>
            <Label className='mr-4 flex cursor-pointer items-start' variant='aLabel'>
              <Radio
                className='mr-1'
                value={id}
                checked={mode.value === duplicateMode}
                name='duplicate-mode'
                onChange={() => setDuplicateMode(mode.value)}
              />

              <div>
                <Typography>{mode.title}</Typography>
                <Typography className='text-text-secondary'>{mode.description}</Typography>
              </div>
            </Label>
          </div>
        )
      })}
    </div>
  )
}

const UploadError: React.FC<{
  i18n: I18n
  ignoreErrors: boolean
  openSection: Section | null
  replaceFileButton: React.ReactElement
  result: ValidatePortfolioCsvQuery['validatePortfolioCsv']
  setIgnoreErrors: SetStateFn<boolean>
  setOpenSection: SetStateFn<Section | null>
}> = ({ i18n, ignoreErrors, openSection, replaceFileButton, result, setIgnoreErrors, setOpenSection }) => {
  const errorLineNumbers = filterNullishValues(result.errors.map((error) => error.line))
  const errorLineNumbersString = errorLineNumbers.join(', ')

  const handleIgnoreErrors = () => {
    setIgnoreErrors(true)
    setOpenSection(null)
  }

  const toggleAccordion = () =>
    setOpenSection((prevOpenSection) => (prevOpenSection === Section.ERRORS ? null : Section.ERRORS))

  const downloadErrors = () => {
    const errorLines = result.lines.filter((_, index) => errorLineNumbers.includes(index + 1))

    if (!errorLines.length) {
      toast.error(i18n._(defineMessage({ message: 'Could not download errors' })))
      return
    }

    const errorLinesJoined = errorLines.join('\n')

    triggerDownload(errorLinesJoined, 'strise_errors', ContentType.CSV)
  }

  const accordionHeader = (
    <div className='flex items-center'>
      {ignoreErrors ? (
        <IconCheckBoxChecked className='text-primary-main' />
      ) : (
        <IconWarning className='text-primary-main' />
      )}
      <Typography className='mx-2' variant='aLabel'>
        <Trans>Errors</Trans>
      </Typography>
      <Tooltip content={`${i18n._(defineMessage({ message: 'Row' }))} ${errorLineNumbersString}`}>
        <Chip
          className='bg-semantic-warning-shade-5 py-4'
          icon={<IconWarningYellow />}
          label={<Trans>{result.errors.length} errors</Trans>}
        />
      </Tooltip>
    </div>
  )

  return (
    <SectionAccordion open={openSection === Section.ERRORS} label={accordionHeader} toggle={toggleAccordion}>
      <Typography className='mb-6 mt-4'>
        <Trans>Do you want to ignore errors in file? You can add the accounts manually in Strise after upload.</Trans>
      </Typography>
      <div>
        {result.errors.map((error, index) => {
          return (
            <div
              className={`mb-1 flex w-full items-center p-2 ${
                ignoreErrors ? 'bg-secondary-shade-5' : 'bg-semantic-warning-shade-5'
              }`}
              key={index}
            >
              <IconWarningFill
                className={cn('mr-8 shrink-0', ignoreErrors ? 'text-secondary-shade-50' : 'text-semantic-notice-main')}
              />
              <Typography
                className={cn('mr-6', ignoreErrors ? 'text-text-secondary' : 'text-semantic-warning-shade-100')}
                variant='body2'
              >
                {error.line}
              </Typography>
              <Typography>{error.message}</Typography>
            </div>
          )
        })}
      </div>
      <div className='mt-6 flex w-full items-center justify-between'>
        <Button
          variant='outlined'
          palette='tertiary'
          onClick={downloadErrors}
          data-track='Import portfolio / Download errors'
        >
          <Trans>Download errors</Trans>
        </Button>
        <div className='flex items-center'>
          {replaceFileButton}
          <Button
            variant='contained'
            palette='secondary'
            className='ml-4'
            onClick={handleIgnoreErrors}
            disabled={ignoreErrors}
            data-track='Import portfolio / Ignore errors'
          >
            <Trans>Ignore errors</Trans>
          </Button>
        </div>
      </div>
    </SectionAccordion>
  )
}

const FileFormatInstructions = () => {
  return (
    <div className='mx-auto max-w-4xl border border-gray-50 bg-white p-8'>
      <Typography className='mb-1'>
        <Trans>File format</Trans>
      </Typography>
      <Typography className='text-text-secondary'>
        <Trans>Please upload a csv-file with the following structure:</Trans>
      </Typography>
      <Alert variant='info' className='mt-2'>
        <Trans>You can only upload companies for one country at a time</Trans>
      </Alert>
      <Img
        src='https://files.strise.ai/upload-portfolio-format.png'
        className='mx-auto mt-16 block w-full'
        alt={t`Portfolio format`}
      />
    </div>
  )
}

const Preview: React.FC<{
  EntityMetaComponent?: React.FC<{ column?: boolean; entity: EntityLikeMetaFragment }>
  i18n: I18n
  result: ValidatePortfolioCsvQuery['validatePortfolioCsv']
}> = ({ EntityMetaComponent, i18n, result }) => {
  const totalRows = result.rows.length
  const filteredRows = result.rows.slice(0, 20)

  return (
    <div>
      <Typography variant='subtitle1'>
        <Trans>Preview</Trans>
      </Typography>
      <Typography className='text-text-secondary'>
        <Trans>
          Showing the first {filteredRows.length} of {totalRows} rows in your file.
        </Trans>
      </Typography>
      <Table>
        <tbody>
          {filteredRows.map((row, index) => {
            const assigneeNodes = row.assignees.map((assignee) => ({
              node: assignee,
              __typename: 'TeamSimpleUserConnectionEdge'
            }))

            return (
              <React.Fragment key={index}>
                {row.company && !row.errors.length && (
                  <TableRow>
                    <TableCell width={68}>
                      <IconSuccessFill className='text-semantic-success-main' />
                    </TableCell>
                    <TableCell minWidth={300}>
                      <div className='flex items-center'>
                        <Typography className='mr-5 text-text-secondary' variant='body2'>
                          {index + 1}
                        </Typography>
                        <div>
                          <Typography variant='aLabel'>{row.company.name}</Typography>
                          {EntityMetaComponent && <EntityMetaComponent entity={row.company} column />}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {assigneeNodes.length ? <Assignees assignees={assigneeNodes} /> : <Trans>No assignee</Trans>}
                    </TableCell>
                    <TableCell>
                      {row.tags.length ? (
                        <div className='flex flex-wrap'>
                          {row.tags.map((tag, tagIndex) => (
                            <Chip className='m-1' key={tagIndex} label={tag.name} />
                          ))}
                        </div>
                      ) : (
                        <Trans>No tags</Trans>
                      )}
                    </TableCell>
                    <TableCell>
                      <CompanyStatusSelectButton i18n={i18n} value={row.status || CompanyStatus.Following} />
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

const StickyBottomUploadBanner: React.FC<{
  hasErrors: boolean
  ignoreErrors: boolean
  loading: boolean
  progressState: ProgressState | null
  upload: () => void
}> = ({ hasErrors, ignoreErrors, loading, progressState, upload }) => {
  const errorsChecked = (hasErrors && ignoreErrors) || !hasErrors
  const isReadyToUpload = progressState === ProgressState.VALIDATED && errorsChecked

  const sections = [
    {
      title: <Trans>Upload file</Trans>,
      checked: true
    },
    {
      title: <Trans>Duplicates</Trans>,
      checked: true
    },
    {
      title: <Trans>Errors</Trans>,
      checked: errorsChecked
    }
  ]

  return (
    <div className='sticky inset-x-[16px] bottom-[16px] rounded-[12px] bg-white p-4 shadow-[0px_15px_47px_rgba(185,185,185,0.86)]'>
      <div className='flex items-center justify-between'>
        {progressState === ProgressState.ERROR ? (
          <div className='flex items-center'>
            <IconWarningRed className='mr-6' />
            <div>
              <Typography>
                <Trans>Check errors!</Trans>
              </Typography>
              <Typography className='text-text-secondary'>
                <Trans>Fix errors and replace file before uploading.</Trans>
              </Typography>
            </div>
          </div>
        ) : (
          <>
            {sections.map((section, index) => {
              return (
                <React.Fragment key={index}>
                  <div className='flex shrink-0 items-center'>
                    {section.checked ? (
                      <IconCheckBoxChecked className='mr-2 text-primary-main' />
                    ) : (
                      <IconWarning className='mr-2 text-primary-main' />
                    )}

                    <Typography>{section.title}</Typography>
                  </div>
                  <Divider className='mx-4 w-full' />
                </React.Fragment>
              )
            })}
          </>
        )}

        <Button
          className='shrink-0'
          variant='contained'
          palette='primary'
          onClick={upload}
          loading={loading}
          disabled={!isReadyToUpload}
          data-track='Import portfolio / Upload'
        >
          <Trans>Upload</Trans>
        </Button>
      </div>
    </div>
  )
}

export const UploadPortfolio: React.FC<
  {
    EntityMetaComponent?: React.FC<{ column?: boolean; entity: EntityLikeMetaFragment }>
    ToggleButton?: React.ElementType
    i18n: I18n
  } & UploadCompaniesProps
> = ({ EntityMetaComponent, ToggleButton = DefaultToggleButton, i18n, portfolioId, team }) => {
  const [open, toggleDialog, setOpen] = useToggle(false)
  const [file, setFile] = React.useState<File | null>(null)

  const [openSection, setOpenSection] = React.useState<Section | null>(null)

  const [progressState, setProgressState] = React.useState<ProgressState | null>(null)
  const progressVariant = getProgressVariant(progressState)
  const progressPercentage = getProgressPercentage(progressState)

  const [duplicateMode, setDuplicateMode] = useState(PortfolioMergeStrategyKind.Merge)

  const [ignoreErrors, setIgnoreErrors] = React.useState(false)

  const handleAddRowsToPortfolioCompleted = () => {
    toast.success(
      i18n._(
        defineMessage({ message: 'Companies uploaded. It will take some time for them to appear in your portfolio.' })
      )
    )
    onDialogClose()
  }

  const handleValidatePortfolioCsvCompleted = (data: ValidatePortfolioCsvQuery) => {
    if (data.validatePortfolioCsv.rows.length) {
      const hasDuplicates = !!data.validatePortfolioCsv.duplicates.length
      const hasErrors = !!data.validatePortfolioCsv.errors.length

      setProgressState(ProgressState.VALIDATED)
      setIgnoreErrors(!hasErrors)
      setOpenSection(() => {
        if (hasDuplicates) return Section.DUPLICATES
        if (hasErrors) return Section.ERRORS

        return null
      })
    } else {
      setProgressState(ProgressState.ERROR)
    }
  }

  const handleValidatePortfolioError = () => {
    setProgressState(ProgressState.ERROR)
  }

  const [addRowsToPortfolio, { loading: mutationLoading }] = useAddRowsToPortfolioMutation({
    onCompleted: handleAddRowsToPortfolioCompleted
  })
  const [validateCsv, { data }] = useValidatePortfolioCsvLazyQuery({
    onCompleted: handleValidatePortfolioCsvCompleted,
    onError: handleValidatePortfolioError,
    fetchPolicy: 'no-cache'
  })

  const validatedPortfolio = data?.validatePortfolioCsv
  const hasErrors = !!validatedPortfolio?.errors.length
  const hasDuplicates = !!validatedPortfolio?.duplicates.length

  React.useEffect(() => {
    if (!file) return
    void (async () => {
      setProgressState(ProgressState.READING)
      const content = await file.text()
      setProgressState(ProgressState.VALIDATING)
      validateCsv({
        variables: { csv: content, team: team.id, portfolio: portfolioId }
      })
    })()
  }, [file])

  const onDialogClose = () => setOpen(false)

  const handleReplaceFile = () => {
    setFile(null)
    setProgressState(null)
    setIgnoreErrors(false)
  }

  const handleUpload = () => {
    const sanitizedRows =
      validatedPortfolio?.rows
        .filter((row) => !!row.company && !row.errors.length)
        .map((row) => {
          const assignees = row.assignees.map((assignee) => assignee.id)
          const tags = row.tags.map((tag) => tag.name)

          return {
            company: row.company?.id ?? '',
            assignees,
            tags,
            status: row.status || CompanyStatus.Following
          }
        }) ?? []

    if (!sanitizedRows.length) {
      toast.error(i18n._(defineMessage({ message: 'No rows' })))
      return
    }

    addRowsToPortfolio({
      variables: {
        rows: sanitizedRows,
        team: team.id,
        portfolio: portfolioId,
        mergeStrategyKind: duplicateMode
      }
    })
  }

  const replaceFileButton = (
    <Button
      variant='ghost'
      color='primary'
      className='underline'
      onClick={handleReplaceFile}
      data-track='Import portfolio / Replace file'
    >
      <Trans>Replace file</Trans>
    </Button>
  )

  return (
    <>
      <ToggleButton onClick={toggleDialog} />
      <Modal isOpen={open} onRequestClose={onDialogClose} shouldCloseOnOverlayClick={false}>
        <ModalHeader onClose={onDialogClose} />
        <ModalContent bgcolor='background.default' position='relative' p={8}>
          <div>
            <div className='mb-6 flex w-full items-center justify-between'>
              <Typography variant='subtitle1'>
                <Trans>Import portfolio</Trans>
              </Typography>
              <ExternalLink
                variant='body1'
                className='text-text-link'
                href='https://files.strise.ai/upload-portfolio-format.png'
              >
                <Trans>File format</Trans>
              </ExternalLink>
            </div>
            <div className='mb-24 rounded-[12px] bg-white p-4'>
              {file ? (
                <>
                  <Progress
                    title={file.name}
                    variant={progressVariant}
                    progressPercentage={progressPercentage}
                    resetButton={replaceFileButton}
                    className={cn((hasDuplicates || hasErrors) && 'mb-6')}
                  />

                  {progressPercentage === 100 && validatedPortfolio && hasDuplicates && (
                    <Duplicates
                      i18n={i18n}
                      result={validatedPortfolio}
                      duplicateMode={duplicateMode}
                      setDuplicateMode={setDuplicateMode}
                      openSection={openSection}
                      setOpenSection={setOpenSection}
                    />
                  )}

                  {progressPercentage === 100 && validatedPortfolio && hasErrors && (
                    <UploadError
                      i18n={i18n}
                      result={validatedPortfolio}
                      replaceFileButton={replaceFileButton}
                      ignoreErrors={ignoreErrors}
                      setIgnoreErrors={setIgnoreErrors}
                      openSection={openSection}
                      setOpenSection={setOpenSection}
                    />
                  )}
                </>
              ) : (
                <DragAndDropFile
                  setFile={setFile}
                  uploadIcon={<UploadCsvIcon className='mb-2' />}
                  supportedFilesText={<Trans>Supported files: .csv only</Trans>}
                />
              )}
            </div>
            {progressPercentage !== 100 && <FileFormatInstructions />}
            {progressPercentage === 100 && (
              <>
                {!!validatedPortfolio?.rows.length && (
                  <div className='mb-20'>
                    <Preview i18n={i18n} result={validatedPortfolio} EntityMetaComponent={EntityMetaComponent} />
                  </div>
                )}

                <StickyBottomUploadBanner
                  upload={handleUpload}
                  loading={mutationLoading}
                  progressState={progressState}
                  hasErrors={hasErrors}
                  ignoreErrors={ignoreErrors}
                />
              </>
            )}
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}
