import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconPerson = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M15.643 11.768a6 6 0 1 0-7.286 0A9.002 9.002 0 0 0 3 20v3h2v-3a7 7 0 1 1 14 0v3h2v-3a9.002 9.002 0 0 0-5.357-8.232ZM8 7a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z'
      clipRule='evenodd'
    />
  </svg>
))
IconPerson.displayName = 'IconPerson'
